h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0px !important;
}
a {
  text-decoration: none;
  display: inline-block;
}
body {
  margin: 0;
  padding: 0;
}
body::-webkit-scrollbar {
  display: none;
}
main {
  /* padding-top: 84px; */
  padding-top: 113px;

}
* {
  margin: 0;
  padding: 0;
  font-family: "Gotham";
}
@font-face {
  font-family: "Gotham";
  src: url("../fonts/gotham_book.woff2") format("woff2"),
    url("../fonts/gotham_book.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gotham";
  src: url("../fonts/gotham_medium.woff2") format("woff2"),
    url("../fonts/gotham_medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gotham";
  src: url("../fonts/gotham_light.woff2") format("woff2"),
    url("../fonts/gotham_light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gotham";
  src: url("../fonts/gotham_bold.woff2") format("woff2"),
    url("../fonts/gotham_bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gotham";
  src: url("../fonts/gotham_black.woff2") format("woff2"),
    url("../fonts/gotham_black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Bookman";
  src: url("../fonts/bookmanopti-bold-webfont1.woff2") format("woff2"),
    url("../fonts/bookmanopti-bold-webfont1.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Bookman";
  src: url("../fonts/bookmanopti-light-webfont1.woff2") format("woff2"),
    url("../fonts/bookmanopti-light-webfont1.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.container {
  margin: 0 auto;
  max-width: 1364px !important;
}
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}
img {
  max-width: 100%;
}
a {
  text-decoration: none;
}
p {
  margin: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
img.mob-search {
  max-width: 20px;
}
div#partner_machine {
  overflow: hidden;
}

.form-control:focus {
  box-shadow: none;
  border-color: inherit;
}

/* TopLoader.css */
.custom-loader>div>div {
  box-shadow: none !important;
}

.show-brown-border {
  border-bottom: 4px solid #A18B64;
}

.show-transparent-border {
  border-bottom: 4px solid transparent;
}

.custom-loader,
.show-brown-border-home {
  background: #A18B64;
  top: 82px !important;
  left: 0 !important;
  width: 100% !important;
  height: 4px !important;
  position: fixed;
  z-index: 999999 !important;
}

@media screen and (max-height: 450px) {
  .sidebar {
    padding-top: 15px;
  }

  .sidebar a {
    font-size: 18px;
  }
}

/* replacement-page-css-start */
.replacement-banner {
  background-image: url(https://hallmanimagesnew.s3-accelerate.amazonaws.com/header/replacement-bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 393px;
}

.home-our-best-seller-section.find-part {
  padding: 0px;
  border: 0px;
}

.find-bottom-text {
  margin-top: 22px;
}

.home-our-best-seller-section .our-single-product-seller .find-bottom-text h3 {
  color: #001e42;
  font-family: "Bookman";
  font-size: 16px;
  font-weight: 5;
  line-height: 24px;
  letter-spacing: 0.05em;
  text-align: center;
  text-transform: uppercase;
  min-height: 0px;
}

.find-bottom-text a {
  color: #1d1d1b;
  font-family: "Gotham";
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: center;
  text-decoration: underline;
  text-underline-offset: 3px;
}

.find-your-range-replacement {
  transform: scale(1);
  transition: ease-in-out 300ms;
  margin: 90px 0px 96px 0px;
}

.find-your-range-replacement img {
  width: 100%;
  height: 100%;
  transition: ease-in-out 300ms;
}

.find-your-range-replacement img:hover {
  transform: scale(1.05);
  transition: ease-in-out 300ms;
  animation-fill-mode: backwards;
  filter: opacity(0.9);
}

.find-your-range-content {
  padding: 30px;
}

.find-your-range-content H2 {
  font-family: "Bookman";
  font-size: 30px;
  font-weight: 5;
  line-height: 42.12px;
  letter-spacing: 0.05em;
  text-align: right;
  color: #fff;
  width: 100%;
  text-transform: uppercase;
  margin-bottom: 16px !important;
}

.find-your-range-content a {
  color: #fff;
  font-family: "Gotham";
  font-size: 14px;
  font-weight: 400;
  line-height: 13.4px;
  text-align: center;
  padding: 15px 41px;
  border: 1px solid #fff;
  text-transform: uppercase;
  transition: ease-in-out 300ms;
}

.find-your-range-replacement:hover .find-your-range-content a {
  background-color: #c0974f;
  border: 1px solid #c0974f;
  transition: ease-in-out 300ms;
}

.frequent-bottom-text .width-panel a {
  color: #001f3f;
  background-color: #e6e6e6;
  font-size: 12px;
  font-weight: 400;
  line-height: 11.48px;
  letter-spacing: 0.02em;
  width: 110px;
  height: 25px;
  margin: 0 auto;
  z-index: 2;
  font-family: "Gotham";
  width: 137px;
}

.frequent-bottom-text h3 {
  color: #022741;
  font-family: "Gotham";
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  margin-top: 20px;
}

.home-our-best-seller-section .our-single-product-seller .frequent-bottom-text p {
  color: #000000;
  font-family: "Gotham";
  font-size: 14px;
  font-weight: 400;
  line-height: 13.4px;
  letter-spacing: 0.06em;
  text-align: center;
  margin: 7px 0px 12px 0px;
}

ul.navbar-nav>li.nav-item:hover>div.drop-down:not(.hidden),
ul.navbar-nav>li.nav-item:hover>ul.drop-down:not(.hidden) {
  border-top: 4px solid #a18b64;
  max-height: 600px;
  opacity: 1;
  box-shadow: 0 5px 10px rgb(0 0 0 / 0.2);
  z-index: 99;
}

.home-our-best-seller-section .our-single-product-seller .frequent-bottom-text h4 {
  color: #000000;
  font-family: "Gotham";
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.06em;
  text-align: center;
  padding: 5px 0px;
}

.flow {
  overflow: hidden;
  padding: 0px;
}

.home-new-product-arriving-section .register-detail {
  padding-left: 50px;
}

.home-new-product-arriving-section .register-detail h2 {
  font-size: 30px;
  font-weight: 500;
  line-height: 48px;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  text-align: left;
  padding-bottom: 22px;
  font-family: "Bookman";
  width: 80%;
}

.home-new-product-arriving-section .register-detail a {
  font-size: 14px;
  font-weight: 400;
  line-height: 13.4px;
  text-align: center;
  color: #ffffff;
  border: 1px solid #171717;
  width: 151px;
  padding: 15px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-family: "Gotham";
  border: 1px solid #fff;
}

.home-new-product-arriving-section .register-detail a:hover {
  background-color: #cb9b46;
  color: #fff;
  transition: all 0.5s;
}

.helpful-links-area {
  background: #f1f1f1;
}

.helpful-link-section {
  margin: 96px 0px 100px;
}

.helpful-link-section h2 {
  color: #001e42;
  font-family: "Bookman";
  font-size: 30px;
  font-weight: 5;
  line-height: 34.02px;
  letter-spacing: 0.05em;
  text-align: left;
  text-transform: uppercase;
  padding-bottom: 30px;
}

.regis {
  height: 100%;
}

.helpfully img {
  height: 100%;
}

.helpful-detail h3 {
  color: #001e42;
  font-family: "Bookman";
  font-size: 30px;
  font-weight: 5;
  line-height: 34.02px;
  letter-spacing: 0.05em;
  text-align: left;
  text-transform: uppercase;
}

.helpful-detail p {
  color: #1d1d1b;
  font-family: "Gotham";
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.05em;
  width: 80%;
}

.helpful-detail p {
  color: #1d1d1b;
  font-family: "Gotham";
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.05em;
  width: 80%;
  padding: 16px 0px 14px 0px;
}

.helpful-detail a {
  color: #001e42;
  font-family: 'Gotham';
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  text-decoration: underline;
  text-underline-offset: 3px;
}

.helpful-detail {
  padding-left: 40px;
}

img.tag {
  width: 50%;
}

.our-single-product-seller.freq-width:hover img.Product-list-pic {
  opacity: 1;
}

.freq-nospace.home-our-best-seller-section {
  margin-bottom: 0px;
}

/* replacement-page-css-end */
/* Faq-page-css-start */
.faq-banner {
  /* background-image: url(../../public/images/faq-bg.png); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 410px;
}
.manual-product-search {
  background: #ffffff;
  border: 1px solid #ffffff;
  width: 100%;
  margin: auto;
  margin-top: 21px;
  max-width: 660px;
}

.manual-product-search input {
  padding: 14.33px 15px;
  max-width: 90%;
  background: none;
  border: none;
  outline: none;
  width: 100%;
  color: #4A4A4A;
  font-size: 14px;
  font-weight: 400;
  font-family: "Gotham";
  background: transparent;
}

span.manual-rgt-search {
  padding-right: 15px;
  cursor: pointer;
  padding-bottom: 1px;
}

.common-all {
  padding: 30px 0px 65px 0px;
}

.most-comn-main {
  padding: 30px 50px;
}

.most-common-question h4 {
  color: #001e42;
  font-family: "Bookman";
  font-size: 30px;
  font-weight: 5;
  line-height: 34.02px;
  letter-spacing: 0.05em;
  text-align: center;
  text-transform: uppercase;
}
.most-common-question h2.cmn {
  color: #001e42;
  font-family: "Bookman";
  font-size: 30px;
  font-weight: 5;
  line-height: 34.02px;
  letter-spacing: 0.05em;
  text-align: center;
  text-transform: uppercase;
}

.most-comn-main img {
  height: 186px;
  object-fit: contain;
}

.most-common-text h5 {
  color: #001e42;
  font-family: "Gotham";
  font-size: 30px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  margin-top: 30px;
  text-transform: capitalize;
}

.most-common-text h3.cmn {
  color: #001e42;
  font-family: "Gotham";
  font-size: 30px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  margin-top: 30px;
  text-transform: capitalize;
}

.most-common-text p {
  color: #1d1d1b;
  font-family: "Gotham";
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  padding: 10px 0px 5px 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 3;
}

.common-read a {
  color: #001e42;
  font-family: "Gotham";
  font-size: 16px;
  font-weight: 500;
  line-height: 13px;
  text-align: center;
}

.common-read a svg {
  vertical-align: middle;
  margin-left: 4px;
  margin-top: 2px;
}

.most-comn-main-image img {
  transition: 300ms all ease-in-out;
}

.most-comn-main:hover img {
  transform: scale(1.07);
  transition: ease-in-out 300ms;
  animation-fill-mode: backwards;
  filter: opacity(0.9);
}

.common-all {
  padding: 30px 0px 65px 0px;
  border-bottom: 1px solid #dadada;
}

.row.most-com-bottom {
  padding-bottom: 65px;
  border-bottom: 1px solid #dadada;
}

.faq-appliaces-text h3 {
  color: #001e42;
  font-family: "Gotham";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  margin-top: 24px;
  min-height: 0px !important;
}

.more-frequent-ask {
  padding: 97px 0px 78px 0px;
}

.more-frequent-ask h4 {
  color: #001e42;
  font-family: "Bookman";
  font-size: 30px;
  font-weight: 5;
  line-height: 34.02px;
  letter-spacing: 0.05em;
  text-align: center;
  text-transform: uppercase;
}

.more-frequent-ask-all {
  padding: 21px 0px;
  row-gap: 24px;
}

a.more-frequent-ask-steps {
  padding: 23px 25px 23px 37px;
  border: 1px solid #dadada;
}

.more-frequent-ask-left h5 {
  color: #1d1d1b;
  font-family: "Gotham";
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  text-align: left;
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

a.more-frequent-ask-steps:hover {
  background: #001e42;
  transition: 300ms all ease-in-out;
}

a.more-frequent-ask-steps:hover .more-frequent-ask-left h5,
span.frequent-read svg path {
  color: #fff;
}

span.frequent-read {
  padding-right: 7px;
  transition: all 0.5s;
}

a.more-frequent-ask-steps:hover span.frequent-read {
  padding-right: 0px;
}

a.more-frequent-ask-steps:hover span.frequent-read svg path {
  fill: #fff;
  padding-right: 0px;
  padding-left: 5px;
  transition: all 0.5s;
}

.home-our-best-seller-section.appliane-faq {
  margin-bottom: 0px;
}

/* Faq-page-css-end */
/* pro-dealer-main-css-start */
.pro-dealer-banner {
  background-image: url(https://hallmanimagesnew.s3-accelerate.amazonaws.com/header/online-dealer-bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 410px;
}

.pro-dealer-main .pro-dealer-banner-content h1 {
  color: #ffff;
  font-family: "Bookman";
  font-size: 40px;
  font-weight: 5;
  line-height: 55px;
  letter-spacing: 0.05em;
  text-align: center;
  text-transform: uppercase;
  padding-bottom: 6.41px;
  width: 70%;
  margin: auto;
}

.pro-dealer-main .pro-dealer-banner-content p {
  color: #ffff;
  font-family: "Gotham";
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0.02em;
  text-align: center;
  width: 60%;
  margin: auto;
}

.pro-dealer-banner-btn a {
  background: #c0974f;
  color: #fff;
  font-family: "Gotham";
  font-size: 14px;
  font-weight: 600;
  line-height: 13.4px;
  text-align: left;
  padding: 17px 38px;
  margin-top: 21px;
}

.pro-dealer-top-content {
  padding: 0px 20px;
}

.pro-dealer-top-content h4 {
  color: #001f3f;
  font-family: "Gotham";
  font-size: 27px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0.05em;
  text-align: center;
}

.carousel-control-prev,
.carousel-control-next {
  z-index: 2 !important;
}

.carousel .slider-wrapper.axis-horizontal .slider .slide {
  z-index: 1 !important;
}

.pro-dealer-top-content p {
  color: #1d1d1b;
  font-family: "Gotham";
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  width: 91%;
  margin: auto;
  margin-top: 10px;
}

.pro-dealer-mid-images {
  width: 85%;
  margin: auto;
  padding: 30px 0px 0px 0px;
}

/* pro-dealer-main-css-end */
/* online-dealer-page-css-start */
.online-dealer-banner {
  min-height: 260px;
  background-image: url(https://hallmanimagesnew.s3-accelerate.amazonaws.com/header/online-dealer-bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 410px;
}

.shop-with-confidence {
  padding: 30px 0px 0px 0px;
}

.shop-confidence-top-text h2 {
  font-size: 25px;
  line-height: 30.02px;
}

.shop-conf-text p {
  font-size: 13px;
  padding-bottom: 25px;
}

.confidence-logo-section {
  padding: 40px 0px 30px 0px;
}

.become-dealer h2 {
  color: #001e42;
  font-family: "Bookman";
  font-size: 30px;
  font-weight: 5;
  line-height: 34.02px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  padding-bottom: 30px;
}

.home-new-product-arriving-section.become-dealer {
  padding: 97px 0px 100px 0px;
  overflow-x: hidden;
}

.home-new-product-arriving-section.become-dealer .dealer-detail {
  padding: 77px 50px;
}

.home-new-product-arriving-section .dealer-detail h2 {
  font-size: 30px;
  font-weight: 500;
  line-height: 48px;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  text-align: left;
  padding-bottom: 22px;
  font-family: "Bookman";
  width: 85%;
}

.home-new-product-arriving-section.become-dealer .dealer-detail p {
  color: #ffffff;
  font-family: "Gotham";
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  width: 70%;
  margin-bottom: 26px !important;
}

.home-new-product-arriving-section.become-dealer a.see-detail {
  background: transparent;
  border: none;
  font-family: "Gotham";
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  padding: 0px;
  width: fit-content;
  text-transform: capitalize;
  text-decoration: underline;
  text-underline-offset: 3px;
  text-decoration-color: rgb(255 255 255 / 40%);
  color: #fff;
}

/* online-dealer-page-css-end */
/* select your choice css start */
/* Faq-two-page-css-start */
.limited-warrenty-main-faq {
  margin: 50px 0px 0px 0px;
  position: relative;
  z-index: 1;
}

.faq-ques-banner {
  background-image: url(https://hallmanimagesnew.s3-accelerate.amazonaws.com/header/faq-ques-bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 410px;
  position: relative;
  z-index: 1;
}

.faq-ques-banner:before {
  content: "";
  position: absolute;
  background: rgb(0 14 29 / 70%);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.warrenty-banner-content.ques {
  width: 100%;
}

.warrenty-banner-content.ques h1 {
  color: #fff;
  font-family: "Bookman";
  font-size: 40px;
  font-weight: 5;
  line-height: 55px;
  letter-spacing: 0.05em;
  text-align: center;
  text-transform: uppercase;
  width: 65%;
  margin: auto;
}

.faq-question-top-heading p {
  padding: 40px 0px 0px 0px;
  font-family: "Gotham";
  font-size: 22px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0.05em;
  text-align: center;
  color: #001f3f;
  width: 85%;
  margin: auto;
}

.faq-question-accordions {
  width: 80%;
  margin: auto;
}

.faq-question .accordion-item {
  background-color: #fff;
  border-bottom: 1px solid #000000 !important;
  padding: 33px 0px;
  border-radius: 0;
}

.faq-question-accordions .accordion-item {
  border: 0px;
}
.faq-question-accordions .accordion-body {
  padding: 0px 0px;
}

.faq-question-accordions .accordion-button {
  color: #001e42;
  background-color: transparent;
  box-shadow: none;
  font-family: "Gotham";
  padding: 0px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  position: relative;
  width: 100%;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    border-radius 0.15s ease;
}

.faq-question-accordions .accordion-button:not(.collapsed) {
  color: #001e42;
  background-color: transparent;
  box-shadow: none;
  font-family: "Gotham";
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
}

.faq-question-accordions .faq-question-content-head p {
  color: #1d1d1b;
  font-family: "Gotham";
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}
.faq-question-accordions .faq-question-content-steps {
  padding: 0px 0px 16px 0px;
}
.faq-question-accordions .faq-question-content-steps ul {
  padding-left: 20px;
}
.faq-question-accordions .faq-question-content-steps ul li {
  list-style-type: decimal;
  font-family: "Gotham";
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  margin-top: 12px;
}
.faq-question-accordions .faq-question-content-steps p {
  font-family: "Gotham";
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  color: #1d1d1b;
}
.faq-question-accordions .faq-question-shipping-policy h4 {
  font-family: "Gotham";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #1d1d1b;
}

.faq-question-accordions .faq-question-shipping-policy p {
  font-family: "Gotham";
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  color: #1d1d1b;
}

.faq-question-accordions a.shiping-highlight {
  color: #1977f3;
}

.faq-question-accordions .accordion-button {
  color: #001e42;
  background-color: transparent;
  box-shadow: none;
  font-family: "Gotham";
  padding: 0px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  position: relative;
  width: 100%;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    border-radius 0.15s ease;
}

.faq-question-accordions .faq-question-content-head h4 {
  font-family: "Gotham";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #1d1d1b;
  padding: 18px 0px 0px 0px;
}

.faq-question-accordions ul.shipping-policy-list li {
  color: #1d1d1b;
  font-family: "Gotham";
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  list-style-type: disc;
}

.faq-question-accordions ul.shipping-policy-list {
  padding: 16px 0px 16px 20px;
}

.faq-question-accordions .accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url(https://hallmanimagesnew.s3-accelerate.amazonaws.com/header/faq-plus.svg);
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}

.faq-question-accordions .accordion-button:not(.collapsed)::after {
  background-image: url(https://hallmanimagesnew.s3-accelerate.amazonaws.com/header/faq-minus.svg) !important;
  transform: rotate(0deg);
  transition: transform 0.2s ease-in-out;
}

.faq-qustion-main-area {
  padding-bottom: 100px;
}

.faq-btm .see-more-faqs {
  font-family: "Gotham";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: #001e42;
  border: none;
  outline: none;
  background: transparent;
  text-decoration: underline;
  text-underline-offset: 3px;
  margin-top: 34px;
}

/* Faq-two-page-css-end */
/* image galler css start */
.image-gallery-main-top {
  padding: 25px 0px 15px 0px;
}

.image-gallery-main-top .gallery-main-title h1 {
  color: #001e42;
  font-family: "Bookman";
  font-size: 40px;
  font-weight: 5;
  line-height: 45.36px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

a.one p {
  font-family: "Gotham";
  font-size: 12px;
  font-weight: 600;
  line-height: 11.48px;
  text-align: left;
  color: #2d58c6;
  margin-left: 5px;
}

.one {
  border-bottom: 1px solid #2d58c6;
  padding-bottom: 6px;
}

.br-upload {
  border-bottom: 1px solid #2d58c6;
  cursor: pointer;
}

span.file-txt p {
  margin-left: 5px;
  color: #2d58c6;
  font-family: "Gotham";
  font-size: 12px;
  font-weight: 600;
  line-height: 11.48px;
  text-align: left;
}

.small-image:hover .small-hidden-txt {
  display: flex;
}

.large-image:hover .small-hidden-txt {
  display: flex;
}

.large-image {
  height: 471px;
}

.large-image img {
  width: 100%;
  transition: 0.7s all ease-in-out;
}

.large-image img:hover {
  animation-fill-mode: backwards;
  filter: opacity(.9);
  transform: scale(1.2) !important;
  transition: all .7s ease-in-out;
}
.gallery-images-collections .large-image:hover img {
  animation-fill-mode: backwards;
  filter: opacity(.9);
  transform: scale(1.2) !important;
  transition: all .7s ease-in-out;
}

.gallery-images-collections {
  margin: 35px 0px 50px 0px;
}

.small-hidden-txt {
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  left: 0px;
  right: 0px;
  top: 0;
  bottom: 0;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  line-height: 32.68px;
  letter-spacing: 0.05em;
  font-family: "Bookman";
  display: none;
  transition: ease-out 300ms;
  text-align: center;
  /* z-index: 99; */
}
.small-hidden-txt p{
  z-index: 99;
}

.small-image {
  height: 471px;
  width: 100%;
}
.my-img-sec .small-image {
  height: 300px;
  width: 100%;
}
.my-img-sec .remove-btn {
  position: absolute;
  color: #fff;
  top: 5px;
  right: 20px;
  font-size: 20px;
  line-height: 12px;
  cursor: pointer;
  z-index: 2;
  background: none;
  border: none;
  border-radius: 50%;
  background: #000;
  padding: 5px;
}
.small-image img {
  height: 100%;
  width: 100%;
  transition: 0.7s all ease-in-out;
  z-index: 1;
  position: relative;
}

.small-image img:hover {
  animation-fill-mode: backwards;
  filter: opacity(0.9);
  transform: scale(1.2) !important;
  transition: 0.5s ease-out;
}

.gallery-images-collections .small-image:hover img {
  animation-fill-mode: backwards;
  filter: opacity(.9);
  transform: scale(1.2) !important;
  transition: all .7s ease-in-out;
}

.image-gallery-tabs-section .nav-tabs .nav-link {
  margin-bottom: -2px;
  background: 0 0;
  border: 0px solid transparent;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.image-gallery-tabs-section .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #000000;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: 4px solid #c0974f !important;
}

.image-gallery-tabs-section .nav-link {
  display: block;
  padding: 0px 25px;
  color: #001e42;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
  font-family: "Gotham";
  font-size: 13px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.06em;
  text-align: center;
}

.image-gallery-tabs-section .nav-tabs {
  border-bottom: 1px solid #e1e1e1;
}

.img-gallery-select {
  margin-bottom: 40px;
}

.image-gallery-series-selection a.gallary-filter {
  color: #2d58c6;
  font-family: "Gotham";
  font-size: 14px;
  font-weight: 600;
  line-height: 13.4px;
  letter-spacing: 0.02em;
  text-align: left;
  text-decoration: underline;
  text-underline-offset: 2px;
}
.images-carousel .carousel-item{
  position: relative;
}
.images-carousel .carousel-indicators .carousel-video-item{
  display: flex;
  object-fit: cover;
}

.image-gallery-series-selection .form-select {
  display: block;
  width: 100%;
  padding: 5px 26px 7px 10px;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 14px;
  font-weight: 400;
  line-height: 13.4px;
  font-family: "Gotham";
  color: #1d1d1b;
  letter-spacing: 0.03em;
  background-color: #fff;
  background-image: url(https://hallmanimagesnew.s3-accelerate.amazonaws.com/header/select-1.svg);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 10px 8px;
  border: 0px solid #ced4da;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.image-gallery-series-selection .form-select:focus {
  border-color: transparent;
  outline: 0;
  box-shadow: none;
}

.select-option-parts {
  position: relative;
}

.select-option-parts::after {
  content: "";
  position: absolute;
  border-right: 1px solid #e1e1e1;
  height: 23px;
  right: -30px;
  top: 0px;
}

.row.gallery-image-spacing {
  row-gap: 23px;
}
.image-more-load a {
  background: #c0974f;
  padding: 18px 30px;
  font-family: "Gotham";
  font-size: 14px;
  font-weight: 600;
  line-height: 13.4px;
  text-align: left;
  color: #fff;
  border: 1px solid #c0974f;
  text-transform: uppercase;
  transition: all .5s;
}

.image-more-load a:hover {
  background-color: #fff;
  color: #001E42;
  border: 1px solid #001E42;
  transition: all .5s;
}

/* image gallery css end */
.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}

.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
}

#mgs-ajax-loading .preloader {
  background: url("https://hallmanimagesnew.s3-accelerate.amazonaws.com/header/loader.svg") no-repeat 0 0;
  background-size: 100%;
  width: 50px;
  height: 50px;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}

/* manual docs css start */
.manual-banner {
  background-image: url("https://hallmanimagesnew.s3-accelerate.amazonaws.com/header/manual-doc-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 393px;
  position: relative;
  z-index: 9;
}

.popular-manual-light-back {
  background: #f1f1f1;
  padding: 48.9px 0px 50.89px 0px;
  margin-top: 92px;
}

.popular-manual-light-back h2 {
  color: #001e42;
  font-family: "Bookman";
  font-size: 30px;
  font-weight: 5;
  line-height: 34.02px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

img.popular-top-pic {
  height: 69px;
  width: auto;
}

.popular-manual-light-parts h4 {
  color: #001e42;
  font-family: "Gotham";
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.05em;
  text-align: left;
  padding: 23px 0px 27px 0px;
}

.popular-manual-light-back .popular-manual-light-parts h3 {
  color: #001e42;
  font-family: "Gotham";
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.05em;
  text-align: left;
  padding: 23px 0px 27px 0px;
}

.row.manual-light-area {
  padding-top: 60px;
}

.popular-manual-list {
  padding-bottom: 20px;
}

.popular-manual-list img {
  width: 17px;
  height: 21px;
}

.popular-manual-list a {
  color: #595959;
  font-family: "Gotham";
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  margin-left: 6.46px;
  text-decoration: underline;
  text-underline-offset: 2px;
  transition: 0.5s all ease-in-out;
}

.popular-manual-list a:hover {
  font-weight: 500;
  transition: 0.5s all ease-in-out;
}

/* manual docs css end */
/* header css start here */
.navbar-nav a.navbar-brand {
  margin-right: 0px;
}

nav li#cart-icon-for-mobile {
  display: none;
}

.navbar .mobile-logo {
  display: none;
}

.navbar-toggler:focus {
  box-shadow: none;
}
.navbar-toggler {
  border-radius: 0;
}
ul.show-brown-border {
  opacity: 1 !important;
}
ul.drop-down, div.drop-down {
  max-height: 0;
  display: block;
  overflow: hidden;
  opacity: 0;
}

.drop-down.open {
  border-top: 4px solid #a18b64;
  max-height: 600px;
  opacity: 1;
  box-shadow: 0 5px 10px rgb(0 0 0 / 0.2);
  z-index: 99;
}
.account.show-brown-border.open {
  border-top: 4px solid #a18b64;
}
nav .navbar-nav .nav-link svg.ico-cent path {
  stroke: #fff;
  color: #fff;
  fill: #fff;
  opacity: 1;
  display: inline-flex;
}

nav ul#logo-desktop {
  width: 14%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

#home-page-header .navbar-nav .nav-item a:last-child {
  padding-right: 0;

  text-decoration: none;
}

nav #right-menu {
  width: 43%;
  text-align: right;
  display: flex;
  justify-content: end;
  align-items: center;
}

.support-list-parts:hover {
  transition: 0.5s all ease-in-out;
}

.support-list-parts:hover .support-txt h4 {
  border-bottom: 2px solid #a18b64;
}

li.nav-item.full-width-menu {
  position: static;
}

ul#left-menu {
  width: 43%;
}

.navbar-nav .nav-item a.nav-link {
  color: #ffffff !important;
  font-size: 14px;
  padding-right: 0;
  padding-left: 0px !important;
  font-weight: 500;
  line-height: 13.4px;
  font-family: "Gotham";
  text-decoration: none;
  width: 100%;
}

.product-result p {
  color: #595855;
  font-size: 14px;
  font-weight: 400;
  line-height: 13.4px;
  font-family: "Gotham";
  text-align: left;
}

.search-item-top .view-all-products a {
  color: #2d58c6;
  font-size: 15px;
  font-weight: 500 !important;
  line-height: 14.36px;
  font-family: "Gotham";
  padding: 30px 0px 30px 0px;
  text-decoration: underline;
}

.product-result-list .pros-parts {
  display: flex;
  gap: 10px;
  border-bottom: 1px solid #dedede;
  padding: 20px 0px;
  align-items: center;
}
.product-result-list .pros-parts-rgt {
  width: calc(100% - 30%);
}

.product-result-list .pros-parts-pic {
  max-width: 71.15px;
  width: 100%;
}

.product-result-list .pros-parts-pic img {
  width: 100%;
  object-fit: contain;
}

.product-result-list .pros-parts-txt h4 {
  color: #001f3f;
  font-size: 15px;
  font-weight: 500 !important;
  line-height: 24px;
  font-family: "Gotham";
}
.product-result-list .pros-parts-txt p.sku {
  color: #001f3f;
  font-size: 15px;
  font-weight: 500 !important;
  line-height: 24px;
  font-family: "Gotham";
}

.product-result-list .pros-parts-txt p {
  margin-top: 4px;
  color: #1d1d1b;
  font-size: 14px;
  font-weight: 400 !important;
  line-height: 13.4px;
  letter-spacing: 0.05em;
  font-family: "Gotham";
  text-align: left;
}

.navbar-expand-lg .navbar-nav .nav-link:last-child {
  padding-right: 0px;
  padding-left: 0px;
}

.navbar-nav .nav-item a.nav-link:focus-visible {
  box-shadow: none;
}

.home-interting-fincing {
  position: absolute;
  top: 40px;
  right: 40px;
  z-index: 99;
  color: #fff;
}

/* Slideshow container */
.home-interting-fincing .slideshow-container {
  max-width: 1000px;
  position: relative;
  margin: auto;
}
/* Number text (1/3 etc) */
.home-interting-fincing .numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
}
/* The dots/bullets/indicators */
.home-interting-fincing .dot {
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: none;
  transition: background-color 0.6s ease;
}

.home-interting-fincing .active {
  background-color: #717171;
}
/* Fading animation */
.home-interting-fincing .fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from {
    opacity: 0.4;
  }

  to {
    opacity: 1;
  }
}

.home-interting-fincing .numbertext p {
  font-family: "Bookman";
  font-size: 16px;
  font-weight: 500;
  line-height: 0px;
  text-transform: capitalize;
  white-space: nowrap;
  text-align: right;
  color: #f6f5ee;
  position: absolute;
  right: 0;
}

/* 
showroom partner program css */
.limited-warranty-main-left {
  margin: 20px 0px 0px 0px;
}
.limited-warranty-main-left .limited-warranty-main-title .back-page-btn p {
  color: #616161;
  font-family: "Gotham";
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  margin-left: 10px;
  margin-top: 2px;
  margin: 2px 0 0 10px;
}
.limited-warranty-main-left .back-page-btn {
  margin-bottom: 18px;
  cursor: pointer;
}
.limited-warrenty-main-area {
  overflow: hidden;
}

.limited-warranty-main-left .limited-warranty-main-title h1 {
  color: #001E42;
  font-family: 'Bookman';
  font-size: 30px;
  font-weight: 5;
  line-height: 37px;
  letter-spacing: 0.05em;
  text-align: left;
  text-transform: uppercase;
  width: 80%;
}

.limited-warranty-main-left .limited-warranty-main-title p.five {
  color: #001E42;
  font-family: 'Gotham';
  font-size: 18px;
  font-weight: 500;
  line-height: 17.23px;
  letter-spacing: 0.01em;
  text-align: left;
  padding: 12px 0px 16px 0px;
}

.limited-warranty-main-left .limited-warranty-main-title p.six {
  color: #1D1D1B;
  font-family: 'Gotham';
  font-size: 15px;
  font-weight: 500;
  line-height: 25px;
  text-align: left;
  width: 95%;
  margin-top: 16px;
}

.limited-warranty-main-left .limited-warranty-main-title p.four {
  color: #001E42;
  font-family: 'Bookman';
  font-size: 19px;
  font-weight: 5;
  line-height: 22.68px;
  letter-spacing: 0.05em;
  text-align: left;
  text-transform: uppercase;
}

.limited-warranty-main-left .limited-warranty-main-title p {
  color: #1D1D1B;
  font-family: 'Gotham';
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  width: 90%;
  margin-top: 11px;
}
.limited-warranty-main-left .read-benefits a {
  color: #001E42;
  font-family: 'Gotham';
  font-size: 14px;
  font-weight: 500;
  line-height: 30px;
  text-decoration: underline;
  text-underline-offset: 3px;
  margin: 10px 0px 30px 0px;
}
.limited-warranty-main-left-image img {
  max-width: 100%;
}
.limited-warranty-main-left-image img {
  width: 95%;
  object-fit: cover;
  height: 100%;
  margin-bottom: 70px;
}
.limited-warranty-main-left .limited-warranty-main-title p.three {
  color: #1D1D1B;
  font-family: 'Gotham';
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  text-align: left;
  width: 95%;
  margin: 16px 0px;
}

.limited-warranty-main-right {
  background: #F5F5F5;
  padding: 37px 0px 60px 55px;
  height: 100%;
  display: grid;
  align-items: center;
}

.limited-warranty-main-right.sign {
  position: relative;
}

.limited-warranty-main-right.sign:before {
  position: absolute;
  top: 0;
  display: block;
  width: calc((99vw - 100%) / 2);
  height: 100%;
  background-color: #f5f5f5;
  content: '';
  right: -50%;
  z-index: -1;
}

.created-account h2 {
  color: #001E42;
  font-family: 'Gotham';
  font-size: 30px;
  font-weight: 500;
  line-height: 57px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin-bottom: 7px !important;
}

.created-account-fields-list {
  margin-bottom: 20px;
}

.created-account-fields-list label {
  color: #1D1D1B;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  margin-bottom: 6px;
}

.created-account-fields-list input {
  background: #fff;
  border: none;
  outline: none;
  border: 1px solid #DADADA;
  width: 100%;
  padding: 14px 10px;
  color: #1D1D1B;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: 'Gotham';
}

.confirm-check .form-check-input:checked {
  background-color: #c0974f;
  border-color: #c0974f;
}

.confirm-check .form-check-input {
  margin-top: 5px !important;
  max-width: 20px;
  border: 1px solid #9b9b9b;
}

.confirm-check label.form-check-label {
  color: #1D1D1B;
  font-family: 'Gotham';
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  margin-left: 8px;
  width: 85%;
}

.created-account-button button.created-acc-btn {
  color: #FFFFFF;
  background: #C0974F;
  outline: none;
  border: none;
  font-family: 'Gotham';
  font-size: 14px;
  font-weight: 500;
  line-height: 13.4px;
  text-align: center;
  width: 100%;
  padding: 18px 0px;
  margin-top: 22px;
  border: 1px solid #C0974F;
  transition: all .5s ease-in-out;
}

.we-promise {
  padding: 97px 0px 91px 0px;
  background: #001E42;
}

.we-promise .we-promise-main h2 {
  color: #FFFFFF;
  font-family: 'Bookman';
  font-size: 35px;
  font-weight: 5;
  line-height: 50px;
  letter-spacing: 0.05em;
  text-align: center;
  text-transform: uppercase;
  width: 90%;
  margin: auto;
}

.we-promise .we-promise-main p.btm {
  color: #fff;
  font-family: 'Gotham';
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-align: center;
  width: 90%;
  margin: auto;
  padding: 13px 0px 24px 0px;
}

/* limited-warranty-one-page-css-end */
/* limited-warranty-two-page-css-start */
span.mendatory-fields {
  color: #EB1C26;
}

.limited-warranty-main-title.warranty-two h1 {
  color: #001E42;
  font-family: 'Bookman';
  font-size: 30px;
  font-weight: 5;
  line-height: 45px;
  letter-spacing: 0.05em;
  text-align: left;
  text-transform: uppercase;
  width: 100%;
}

.details-info-form .get-started-fields label {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  color: #1D1D1B;
  margin-bottom: 6px;
}

.dzu-dropzone {
  border: 0 !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}
.details-info-form .get-started-fields input,
.details-info-form .get-started-fields select {
  border: 1px solid #E4E4E4;
  background: #fff;
  outline: none;
  width: 100%;
  padding: 14px 10px;
  color: #1D1D1B;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: 'Gotham';
}
.get-started-fields {
  margin-bottom: 30px;
}
.details-info-form .file-uploader .text-file {
  border: none;
  outline: none;
  width: 100%;
  padding: 16px 0px;
  color: #1D1D1B;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: 'Gotham';
  margin: 12px 0px 30px 0px;
  background: #fff;
  background-image: repeating-linear-gradient(-23deg, #9b9b9b, #9b9b9b 5px, transparent 5px, transparent 9px, #9b9b9b 9px), repeating-linear-gradient(67deg, #9b9b9b, #9b9b9b 5px, transparent 5px, transparent 9px, #9b9b9b 9px), repeating-linear-gradient(157deg, #9b9b9b, #9b9b9b 5px, transparent 5px, transparent 9px, #9b9b9b 9px), repeating-linear-gradient(247deg, #9b9b9b, #9b9b9b 5px, transparent 5px, transparent 9px, #9b9b9b 9px);
  background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
  border-radius: 0px;
}

.details-info-form .dzu-dropzone {
  color: #707070;
  font-family: 'Gotham';
  font-size: 10px;
  font-weight: 600;
  line-height: 9.57px;
  text-align: left;
  min-height: 30px;
}

.details-info-form .dzu-dropzone p {
  color: #1D1D1B;
  font-family: 'Gotham';
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  margin-right: 18px;
}

.details-info-form .dzu-dropzone span {
  color: #001E42;
  font-family: 'Gotham';
  font-size: 14px;
  font-weight: 500;
  line-height: 9.57px;
  text-align: left;
  text-decoration: underline;
  text-underline-offset: 3px;
}

.showroom_partner_sec #react-tags-listbox {
  width: 200px;
  height: 200px;
  overflow-y: scroll;
}

.manual-product-search .seach-contents,
.love-appliances-top .seach-contents,
.manual-product-search .seach-contents,
.showroom_partner_sec #react-tags-listbox {
  padding: 0px 15px;
  border-top: 1px solid rgb(199 199 199 / 50%);
  background-color: #fff;
  position: absolute;
  width: 100%;
  z-index: 3;
  box-shadow: 0 5px 10px rgb(0 0 0 / 0.2);
}

#Microsoft_Omnichannel_LCWidget_Chat_Iframe_Window {
  /* z-index: 9999 !important; */
  padding-right: 0px !important;
}

.yarl__container {
  background-color: rgba(0, 0, 0, 0.8) !important;
}

.manual-product-search .seach-contents p {
  color: #001f3f !important;
  text-align: left !important;
}
.dzu-previewContainer {
  padding: 0px 3%;
  margin-bottom: 0px !important;
  border-bottom: 0px !important;
}

.details-info-form .dzu-dropzone svg {
  margin-right: 5px;
}

.details-info-form .for-mats p.label {
  color: #1d1d1b;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  text-transform: none;
}

.details-info-form .for-mats p {
  color: #E92626;
  font-family: 'Gotham';
  font-size: 12px;
  font-weight: 400;
  line-height: 11.48px;
  text-align: left;
  text-transform: uppercase;
}

.created-account-button button.created-acc-btn {
  color: #FFFFFF;
  background: #C0974F;
  outline: none;
  border: none;
  font-family: 'Gotham';
  font-size: 14px;
  font-weight: 500;
  line-height: 13.4px;
  text-align: center;
  width: 100%;
  padding: 18px 0px;
  margin-top: 22px;
  border: 1px solid #C0974F;
  transition: all .5s ease-in-out;
}

.created-account-button button.created-acc-btn:hover {
  background-color: #fff;
  border: 1px solid #001e42;
  color: #0b0b0b;
  transition: all .5s ease-in-out;
}
.regards-msg p {
  color: #001E42;
  font-family: 'Gotham';
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  width: 69%;
  margin-bottom: 30px !important;
}

/* limited-warranty-two-page-css-send */
.home-interting-fincing span {
  font-weight: 400;
  font-family: "Gotham";
  font-size: 15px;
  line-height: 20px;
  text-transform: capitalize;
  text-align: right;
  cursor: pointer;
  color: #f6f5ee;
}
.large-menu-all {
  display: flex;
  width: 100%;
}

.right-large-menu-side .pic-onee .pic-onee img {
  max-width: 320px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: ease-out 300ms;
}

.right-large-menu-side .pic-onee img:hover {
  transform: scale(1.05);
  transition: ease-out 300ms;
  filter: hue-rotate(28deg);
  animation-fill-mode: backwards;
  filter: opacity(0.9);
}

.right-large-menu-side .picture-one {
  padding: 0px 15px;
  border-left: 0px solid #DEDEDE;
}
.right-large-menu-side .picture-one:last-child {
  padding-right: 0px;
}
.right-large-menu-side .pic-onee-txt p a {
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-decoration-color: #a18b64;
  text-underline-offset: 9px;
}

.picture-all {
  display: flex;
  justify-content: space-between;
}
.right-large-menu-side {
  width: 100%;
  padding: 37px 0px 50px 0px;
}

.navbar-nav .nav-item #dropdown .list-anchor>h4 a {
  color: #001f3f !important;
  font-size: 16px;
  font-weight: 5;
  line-height: 17.01px;
  letter-spacing: 0.05em;
  text-align: left;
  font-family: "Bookman";
  text-transform: uppercase;
  padding: 0px !important;
  padding-top: 30px !important;
  text-decoration: none;
}

.pic-onee-txt p>a {
  color: #001f3f;
  font-size: 15px;
  font-weight: 500;
  line-height: 17.1px;
  font-family: "Bookman";
  padding-top: 20px;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.05em;
}
.navbar-nav .nav-item .drop-menu a {
  color: #001f3f !important;
  font-size: 15px;
  font-size: 15px;
  font-weight: 5;
  line-height: 17.01px;
  letter-spacing: 0.05em;
  text-align: left;
  font-family: "Bookman";
  text-transform: uppercase;
  padding: 0px !important;
}

.left-large-menu-side {
  width: 100%;
  padding: 30px 0px;
}
.support-list-parts a {
  display: flex;
  align-items: center;
  gap: 5px;
}

.support-list-parts {
  padding: 15px 0px 10px 10px;
  transition: 0.5s all ease-in-out;
}
.hallman-create-account-back,
.hallman-Login-top {
  padding: 22px 0px;
  max-width: 493px;
}

.support-list-left img {
  width: 25px;
  height: auto;
}
.create-account-heading {
  margin-bottom: 40px;
}

.support-txt p {
  color: #001f3f !important;
  font-size: 16px;
  font-weight: 500;
  line-height: 17.01px;
  letter-spacing: 0.05em;
  text-align: left;
  font-family: "Bookman";
  text-transform: capitalize;
  margin-bottom: 0px;
  border-bottom: 2px solid transparent;
}

.support-list-parts:hover .support-txt p {
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-decoration-color: #a18b64;
  text-underline-offset: 9px;
}
.support-area.account {
  right: auto;
  max-width: 220px !important;
  min-width: 220px;
}
.support-list-left img {
  width: 25px;
}
.main-top-title h2 {
  color: #001f3f;
  font-size: 25px;
  font-weight: 500;
  line-height: 28.35px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  padding-bottom: 5px;
  display: inline-block;
  font-family: "Bookman";
  margin-bottom: 0px;
  position: relative;
}

.main-top-title h2:after {
  position: absolute;
  content: "";
  height: 3px;
  width: 145px;
  background: #a18b64;
  bottom: 0;
  left: 0;
  box-shadow: 0 4px 4px 0 rgb(0 0 0 / 25%);
}
/* confirmation popup content start */
.confirm-popup .modalHeader.modal-header {
  background: transparent;
  border-bottom: none;
  padding: 10px !important;
}
.confirm-popup {
  font-family: "Gotham";
}
/* confirmation popup content end */
.products-main-list .list-anchor h4 {
  margin-bottom: 0px;
}
.single-product-customizer {
  padding-top: 40px;
}
.select-your-choice-data,
.banner-list,
.section-products.result-amt,
.pro-dealer-main,
.design-arch-banner {
  padding: 60px 0px 0px 0px;
}
.select-top-content p {
  color: #001e42;
  font-size: 16px;
  font-weight: 400;
  line-height: 15.31px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  font-family: "Gotham";
}
.select-your-choice-data {
  padding: 60px 0px;
}
.select-your-choice-data .range-col {
  padding-left: 8px;
  padding-right: 8px;
}
.select-classic {
  max-width: 431px;
  position: relative;
  overflow: hidden;
  transition: 0.5s all ease-in-out;
}
.react-slideshow-container .nav {
  z-index: 2 !important;
}
.select-choice-up.bold-top {
  max-width: 130px;
  height: 45px;
  width: 100%;
}
.select-choice-up {
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
}
.select-classic-bottom {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  padding: 0px 10px;
}
.select-classic-bottom p {
  color: #03202f;
  font-size: 15px;
  line-height: 14.36px;
  text-transform: uppercase;
  font-family: "Gotham";
  font-weight: 400;
}
.select-classic-bottom h2 {
  color: hsl(213, 100%, 13%);
  font-size: 36px;
  line-height: 42px;
  font-weight: 500;
  letter-spacing: 0.05em;
  margin: 10px 0px 0px 0px;
  padding-bottom: 3px;
  text-transform: uppercase;
  font-family: "Bookman";
}
.select-classic-bottom p.series-small {
  text-transform: capitalize;
  font-size: 16px;
  padding-bottom: 14px;
}
.select-classic-bottom p.start {
  font-size: 14px;
  line-height: 13.4px;
  font-family: "Gotham";
  text-transform: none;
  padding-bottom: 15px;
}

.select-classic-bottom a.shop-now {
  width: fit-content;
  margin: auto;
}

a.shop-now {
  color: #03202f;
  font-size: 14px;
  font-weight: 400;
  line-height: 13.4px;
  font-family: "Gotham";
  padding: 15px 32px;
  outline: none;
  border: 1px solid #03202f;
  background: transparent;
  transition: 0.5s all ease-in-out;
  text-transform: uppercase;
  text-decoration: none;
}

.select-classic:hover .select-choice-up img {
  transform: scale(1);
}
.select-classic img {
  transition: ease-out 300ms;
  transform: scale(1);
  height: 100%;
}
.select-classic:hover img {
  transform: scale(1.05);
  transition: ease-out 300ms;
  animation-fill-mode: backwards;
  filter: opacity(0.9);
}

.select-classic:hover a.shop-now {
  background: #c0974f;
  transition: 0.5s all ease-in-out;
  color: #fff;
  border: 1px solid #c0974f;
}
a.shop-now:hover {
  background: #c0974f;
  transition: 0.5s all ease-in-out;
  color: #fff;
  border: 1px solid #c0974f;
}
.select-top-content h1 {
  color: #001e42;
  font-size: 45px;
  font-weight: 500;
  line-height: 51.3px;
  text-transform: uppercase;
  font-family: "Bookman";
  padding: 14px 0px 0px 0px;
}

.pagination .active>.page-link {
  background-color: #c0974f !important;
  border-color: #c0974f !important;
  color: white !important;
  cursor: pointer;
}

.page-item.disabled span.page-link {
  height: 33px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: "Gotham";
}

.pagination .page-link {
  color: #171717 !important;
  border-radius: 0px !important;
  font-size: 14px;
  padding: 0.375rem 0.75rem;
  letter-spacing: 0.04em;
  font-weight: 400;
  margin: 0px !important;
  line-height: 13.4px;
}

.pagination.justify-content-center {
  margin-bottom: 50px;
}
ul.pagination li:last-child a {
  border: none;
}
ul.pagination li:last-child a:hover,
ul.pagination li:first-child a:hover {
  background: transparent;
  background-color: transparent !important;
  color: #c0974f !important;
}
.page-item:hover span {
  color: #c0974f;
}
.page-item:hover svg path {
  fill: #c0974f;
}
.pagination li.page-item.disabled:last-child,
.pagination li.page-item.disabled:first-child {
  display: none;
}
ul.pagination li:last-child a,
ul.pagination li:last-child span,
ul.pagination li:first-child a,
ul.pagination li:first-child span {
  border: none;
}
.pagination li.page-item a {
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Gotham";
  font-weight: 400;
}
.pagination .page-link:focus {
  box-shadow: none;
}
.pagination .page-link:hover {
  background-color: black !important;
  color: white !important;
  transition: all 0.5s !important;
}
nav .submenu {
  position: absolute;
  left: 0;
  top: 100%;
  overflow: hidden;
  z-index: 999;
  color: #fff;
  background: white;

}
.single-product-seller h2,
h4.product-old-price,
.wishlist_sec .box h3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.short-name {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  width: 500px;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
nav .submenu li a {
  color: #fff !important;
}
.navbar-nav a.navbar-brand {
  display: flex;
  flex-direction: column;
}
.navbar-nav .nav-item .drop-menu a {
  color: #001f3f !important;
  font-size: 15px;
  font-size: 15px;
  font-weight: 5;
  line-height: 17.01px;
  letter-spacing: 0.05em;
  text-align: left;
  font-family: "Bookman";
  text-transform: uppercase;
  padding: 0px !important;
}
.head-items {
  display: none;
}
.support-area {
  box-shadow: 0 5px 10px rgb(0 0 0 / 0.2);
  background: #ffffff !important;
  max-width: 310px;
  min-width: 310px;
  width: 100%;
  position: absolute;
  z-index: 999;
  top: 100%;
  display: none !important;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  display: block !important;
  opacity: 1;
  padding: 0px;
}
.submenu li {
  display: block;
  margin: 0;
  cursor: pointer;
}
/* header css end here */
/* home page css start here hallman industires project */
#home-page-header {
  /* background: url('./public/images/home-banner.svg'); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}
.home-page .navbar-nav .nav-item a.nav-link {
  color: #f6f5ee !important;
}
.home-banner-content h1,
.home-banner-content .banner-title {
  font-size: 31px;
  font-weight: 500;
  line-height: 34.4px;
  color: #f6f5ee;
  letter-spacing: 0.05em;
  text-align: left;
  padding-bottom: 18px;
  text-transform: uppercase;
  font-family: "Bookman";
  position: relative;
  z-index: 999;
  margin-bottom: .5rem !important;
}
.home-banner-content p {
  font-size: 16px;
  font-weight: 400;
  color: #f6f5ee;
  line-height: 27px;
  text-align: left;
  padding-bottom: 25px;
  font-family: "Gotham";
  position: relative;
  z-index: 999;
  max-width: 40%;
  width: 100%;
}
.home-banner-content a {
  border: 1px solid #f6f5ee;
  width: 151px;
  padding: 15px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 17.6px;
  text-align: center;
  color: #f6f5ee;
  font-family: "Gotham";
  text-transform: uppercase;
  position: relative;
  z-index: 999;
}
.home-banner-content .div {
  display: block;
}
.font-Gotham {
  font-family: "Gotham";
}
.toast-container {
  right: 0 !important;
  top: unset !important;
  left: unset !important;
}
.toast-body {
  color: white;
  font-family: "Gotham";
}
.home-banner-content a:hover {
  background-color: #c0974f;
  border-color: transparent;
  color: #fff;
  transition: 0.1s;
}
.home-banner-content {
  padding-bottom: 64px;
  border-bottom: 6px solid #a18b64;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
  position: relative;
  aspect-ratio: 1440/602;
  width: 100%;
  display: flex;
  align-items: end;
  z-index: -1;
}
/* .home-banner-content:after, .home-page .back-slider .carousel-item:after {
  content: "";
  position: absolute;
  background-color: rgb(0 0 0 / 25%);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
} */
.our-single-product-seller img.tag {
  width: 100%;
  height: 100%;
  max-width: 162px;
}
.top-search-bar {
  position: fixed;
  top: 86px;
  left: 0;
  right: 0;
  z-index: 999999;
  box-shadow: 0 5px 10px rgb(0 0 0 / 0.2);
}
.top-search-bar .bar-area {
  padding: 30px 47px 30px 47px;
  background: #fff;
}
.search-bar-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search-left {
  max-width: 95%;
  width: 100%;
}
.search-left input {
  width: 100%;
  outline: none;
  border: none;
  color: #595855;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  font-family: "Gotham";
  letter-spacing: 0.05rem;
}
.search-close-btn {
  max-width: 5%;
  width: 100%;
  display: flex;
  justify-content: end;
}
.left-large-menu-side .pros-data-list:hover a {
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-decoration-color: #a18b64;
  text-underline-offset: 9px;
}
button.seach-close {
  border: none;
  outline: none;
  background: transparent;
}
.seach-contents {
  padding: 31px 47px 14px 47px;
  border-top: 1px solid rgb(199 199 199 / 50%);
  background-color: #fff;
}
.logo-slider .swiper-slide {
  text-align: center;
  display: flex;
  align-items: center;
  padding: 15px 0px 15px 0px;
}
.logo-slider .swiper-wrapper {
  height: 76px;
  justify-content: center;
}
.logo-slider {
  border-bottom: 1px solid #eaeaea;
  overflow-x: hidden;
}
.product-fliter .accordion-header button.accordion-button {
  padding-left: 0px;
}
.logo-slider .swiper-slide {
  width: auto;
}
.logo-slider .swiper-slide img {
  margin-right: 61px;
}
.logo-slider h3 {
  font-size: 16px;
  font-weight: 500;
  color: #001f3f;
  line-height: 18.14px;
  letter-spacing: 0.04em;
  text-align: left;
  font-family: "Bookman";
}

.single-produt-choose-color .form-check-flie .choose-color.active label {
  border: 1px solid #001e42;
}

/* discover categories section css strat here */
.discover-category-section,
.single-product-specification {
  padding: 70px 0px;
}
.discover-category-section h2.heading {
  font-size: 30px;
  font-weight: 500;
  line-height: 34.2px;
  letter-spacing: 0.05em;
  text-align: center;
  text-transform: uppercase;
  padding-bottom: 21.74px;
  font-family: "Bookman";
  color: #001f3f;
}
.discover-category-section h1.heading {
  font-size: 30px;
  font-weight: 500;
  line-height: 34.2px;
  letter-spacing: 0.05em;
  text-align: center;
  text-transform: uppercase;
  padding-bottom: 21.74px;
  font-family: "Bookman";
  color: #001f3f;
}
.discover-category-section .row .categorie-image-wrpaer h2 {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  padding: 23px 0 2px 0px;
  text-align: left;
  color: #001f3f;
  font-family: "Gotham";
}

.discover-category-section .categorie-image-wrpaer a img {
  width: 100%;
  height: 417px;
  object-fit: cover;
}

.discover-category-section .categorie-image-wrpaer a.single-product {
  overflow: hidden;
  display: block;
  margin: 0 auto;
  padding: 0;
}
.discover-category-section .categorie-image-wrpaer:hover a.single-product img {
  transform: scale(1.05);
  transition: ease-out 300ms;
}
.last-categoryparent .categorie-image-wrpaer {
  margin-right: 0px;
}
.discover-category-section .row .categorie-image-wrpaer h3 {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  padding: 23px 0 2px 0px;
  text-align: left;
  color: #001f3f;
  font-family: "Gotham";
}
.newletter-join:focus, .newletter-join:hover {
  border: 1px solid #cac7c7 !important;
}
.brown-onhover:hover a.shop-now {
  background: #c0974f;
  transition: 0.5s all ease-in-out;
  color: #fff;
  border: 1px solid #c0974f;
}
.discover-category-section .row .categorie-image-wrpaer .shop-now a {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #454545;
  text-decoration: underline;
  text-align: left;
  text-underline-offset: 1px;
  font-family: "Gotham";
}
.discover-category-section .row .categorie-image-wrpaer .shop-now a:hover {
  color: #c0974f;
  transition: all 0.9s;
}
.cate-gallery-wraper .cate-gorygallery h3 {
  font-size: 25px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.05em;
  padding: 35px 0 8px 0;
  text-align: left;
  text-transform: uppercase;
  font-family: "Bookman";
  color: #001f3f;
}
.cate-gallery-wraper .home-gallery {
  overflow: hidden;
}
.discover-category-section .cate-gallery-wraper {
  padding-top: 83.13px;
}
.cate-gallery-wraper .cate-gorygallery .shop-now a {
  font-family: "Gotham";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #454545;
  text-decoration: underline;
  text-align: left;
  text-underline-offset: 1px;
}
.cate-gallery-wraper .cate-gorygallery .shop-now a:hover {
  color: #c0974f;
  transition: all 0.9s;
}
.cate-gallery-wraper .cate-gorygallery img {
  overflow: hidden;
}
.cate-gallery-wraper .home-gallery img:hover {
  transform: scale(1.05);
  transition: ease-out 300ms;
  filter: hue-rotate(28deg);
  animation-fill-mode: backwards;
  filter: opacity(0.9);
}
.cate-gallery-wraper .financing-div {
  padding-right: 0px;
}
/* discover categories section css end here */
/* Buy with confidence section css start here  */
.home-buy-with-confidence h2 {
  font-size: 35px;
  font-weight: 500;
  line-height: 58px;
  letter-spacing: 0.05em;
  text-align: center;
  color: #1d1d1b;
  text-transform: uppercase;
  font-family: "Bookman";
  max-width: 1200px;
  margin: auto;
}
span.two-years-warrenty {
  color: #082f58;
}
span.buy-with-rating {
  color: #082f58;
}

.home-buy-with-confidence {
  padding: 68px 0px 59px 0px;
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
}

/* Buy with confidence section css end here  */
/* new product arriving section css start here  */
.home-new-product-arriving-section {
  padding: 99px 0px 0px 0px;
  overflow-x: hidden;
}

.new-pro-back {
  background: #001f3f;
}
.flow img {
  height: 100%;
}

.home-new-product-arriving-section .new-product-detail {
  padding-left: calc((100vw - 1364px) / 2);
}
.home-new-product-arriving-section .new-product-detail h2 {
  font-size: 35px;
  font-weight: 500;
  line-height: 50px;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  text-align: left;
  padding-bottom: 22px;
  font-family: "Bookman";
  max-width: 90%;
  width: 100%;
}
.new-product-detail h2 {
  padding-left: 70px;
}

.new-product-detail a {
  margin-left: 70px;
}

.home-new-product-arriving-section .new-product-detail p {
  font-family: "Gotham";
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  padding-bottom: 28px;
  text-align: left;
  color: #595855;
}

.home-new-product-arriving-section .new-product-detail a {
  font-size: 14px;
  font-weight: 400;
  line-height: 13.4px;
  text-align: center;
  color: #ffffff;
  border: 1px solid #171717;
  width: 151px;
  padding: 15px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-family: "Gotham";
  border: 1px solid #fff;
  text-decoration: none;
  transition: 0.5s all ease-in-out;
}
/* new product arriving section css end here */
/* Our best sellers section css start here */
.home-our-best-seller-section {
  padding: 80px 0px 0px 0px;
  border-top: 1px solid #cac7c7;
  border-bottom: 1px solid #cac7c7;
  margin-bottom: 70px;
}
.shimmer-button--lg {
  width: 100% !important;
}
.home-our-best-seller-section h2 {
  font-size: 30px;
  font-weight: 400;
  line-height: 34.2px;
  letter-spacing: 0.05em;
  padding-bottom: 34px;
  text-align: center;
  color: #001f3f;
  text-transform: uppercase;
  font-family: "Bookman";
}
.home-our-best-seller-section.cart-product h4 {
  margin-bottom: 0px !important;
  padding-bottom: 18px;
}
.carousel .slide img {
  object-fit: cover;
  width: 600px;
  height: 450px;
}

.product-slider_single-show.left .carousel-root .slide img {
  transition: 0.5s all ease-in-out;
}
.product-slider_single-show.left .carousel-root:hover .slide img {
  transform: scale(1.1);
  transition: 0.5s all ease-in-out;
  filter: opacity(0.9);
}

.product-slider_single-show.left .carousel ul>li.slide.placeholderImg img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.home-our-best-seller-section .our-single-product-seller h3 {
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  color: #022741;
  padding: 5px 0 3px 0;
  font-family: "Gotham";
  min-height: 74px;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
}
.home-our-best-seller-section .our-single-product-seller h4.rate{
  color: #1d1d1b;
  font-family: 'Gotham';
  font-size: 14px;
  font-weight: 400;
  letter-spacing: .06em;
  line-height: 24px;
  padding: 0 0 12px;
  text-align: center;
}

.product-hover-pic .carousel ul>li.slide img {
  height: auto;
}
.carousel ul>li.slide {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.carousel .slider {
  border: 1px solid #eaeaea;
  height: 610px;
}
.home-our-best-seller-section .our-single-product-seller {
  text-align: center;
}
.home-our-best-seller-section .our-single-product-seller p {
  font-size: 14px;
  font-weight: 500;
  line-height: 13.4px;
  letter-spacing: 0.06em;
  color: #1d1d1b;
  font-family: "Gotham";
  padding: 18px 0px 18px 0px;
}
.home-our-best-seller-section .our-single-product-seller h4 {
  font-size: 14px;
  font-weight: 400;
  /* line-height: 24px; */
  letter-spacing: 0.06em;
  color: #1d1d1b;
  padding: 0px 0px 16px 0px;
  font-family: "Gotham";
  margin-bottom: 10px;
}
.our-single-product-seller img {
  max-width: 381px;
  width: auto;
}
.col-sm-6.d-flex.justify-content-end.flow {
  overflow: hidden;
}
.col-sm-6.d-flex.justify-content-end.flow:hover img {
  transform: scale(1.1);
}
.new-pro-back:hover .flow img {
  transform: scale(1.05);
}
.home-new-product-arriving-section .register-detail a:hover {
  background-color: #cb9b46;
  color: #fff;
  transition: 0.5s all ease-in-out;
}
.home-new-product-arriving-section a:hover a {
  background: #cb9b46;
  border: 1px solid #cb9b46;
}
.home-our-best-seller-section .our-single-product-seller .lower-cart-im {
  margin-top: 12px;
}
.home-our-best-seller-section .single-product-seller {
  position: relative;
}
.single-product-better-together .single-product-seller {
  position: relative;
}
.single-product-better-together .single-product-seller .image-hover {
  position: absolute;
  top: 40px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease-in;
  z-index: 1;
}
.single-product-better-together .single-product-seller:hover .image-hover {
  visibility: visible;
  cursor: pointer;
  opacity: 1;
}
.short-name,
.single-product-seller h2,
h3.product-old-price,
.wishlist_sec .box h3 {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
}
.home-our-best-seller-section .single-product-seller .image-hover {
  position: absolute;
  top: 5px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease-in;
  z-index: 1;
}
.home-our-best-seller-section .single-product-seller:hover .image-hover {
  visibility: visible;
  cursor: pointer;
  opacity: 1;
}
.product-slider-single-pro .real-life-single-pro {
  height: auto;
  margin-top: 35px;
}
.product-slider-single-pro .real-life-single-pro img {
  width: 100%;
  object-fit: fill;
  height: 100% !important;
}
.single-product-better-together {
  padding-top: 70px;
  position: relative;
}
.real-life-single-pro h3 {
  font-family: "Bookman";
  font-size: 30px;
  font-weight: 500;
  line-height: 34.2px;
  letter-spacing: 0.05em;
  text-align: center;
  padding-bottom: 25px;
  padding-top: 0;
  color: #001e42;
  text-transform: uppercase;
}
.product-list-web-page header nav {
  border-bottom: 0px;
}
.single-product-better-together h3.better-together {
  font-family: "Bookman";
  font-size: 30px;
  font-weight: 400;
  line-height: 34.2px;
  text-align: center;
  padding-bottom: 35px;
  letter-spacing: 0.05em;
  color: #001e42;
  text-transform: uppercase;
}
.select-itmes .form-check-input {
  margin-top: 5px !important;
}

.single-product-better-together .container {
  border-bottom: 1px solid #dedede;
}
.better-together-parent .single-ventil .select-itmes {
  text-align: left;
  display: flex;
  gap: 8.44px;
  padding-bottom: 16px;
}
.better-together-parent .single-ventil .pAREMT {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.better-together-parent .single-ventil .select-itmes label {
  font-family: "Gotham";
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.06em;
  text-align: left;
  color: #1d1d1b;
}
.rate-checout {
  padding-top: 15px;
}
.single-ventil .single-ventils img.Product-list-pic {
  height: 224.82px;
  max-width: 100%;
  width: 100%;
  margin-bottom: -10px;
}
.single-ventils img.product-hover-pic {
  height: 224.82px;
  max-width: 100%;
  width: 100%;
  object-fit: cover;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.5s all ease-in-out;
}
.single-ventils:hover img.product-hover-pic {
  opacity: 1;
  transition: 0.5s all ease-in-out;
}
.better-together-parent .single-ventil .select-itmes input[type="checkbox"] {
  width: 15.57px;
  height: 15.57px;
  border-radius: 0;
  border: 1px solid #dcdcdc;
  cursor: pointer;
}
.better-together-parent .single-ventil .select-itmes input[type="checkbox"]:checked {
  background-color: #c0974f;
  border-color: #c0974f;
}
.better-together-parent .single-ventil h3 {
  font-family: "Gotham";
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.04em;
  padding: 16px 0 4px 0;
  color: #001e42;
  text-transform: capitalize;
}
.better-together-parent .single-ventil p {
  font-family: "Gotham";
  font-size: 13px;
  font-weight: 400;
  padding-bottom: 6px;
  line-height: 24px;
  letter-spacing: 0.06em;
  text-align: center;
  color: #1d1d1b;
}
.single-product-better-together .better-together-parent {
  padding-bottom: 48.67px;
}
.better-together-parent .single-ventil {
  text-align: center;
}
.product-fliter input.form-check-input {
  border-radius: 0px;
}
.product-fliter .part-1 {
  display: grid;
  gap: 19.87px;
  width: 100%;
  margin-bottom: 0px !important;
}
.product-fliter .color-txt p {
  color: #1d1d1b;
  font-size: 14px;
  line-height: 18.4px;
  font-family: "Gotham";
  margin-top: 5px;
}
.better-together-parent .single-ventil .rating h6 {
  font-family: "Gotham";
  font-size: 14px;
  padding: 16px 0 0 0;
  font-weight: 400;
  line-height: 13.4px;
  letter-spacing: 0.06em;
  color: #1d1d1b;
}
.better-together-parent .single-ventil a.classico-x {
  background-color: #e6e6e6;
  font-family: "Gotham";
  font-size: 12px;
  font-weight: 400;
  padding: 8px 20px;
  line-height: 11.48px;
  letter-spacing: 0.02em;
  display: flex;
  width: auto;
  display: inline-block;
  align-items: center;
  justify-content: center;
  justify-content: center;
  margin: 0 auto;
  position: relative;
  z-index: 2;
  color: #001f3f;
}
.gateway-on-cart {
  padding: 23px 9px;
  border: 1px solid #eaeaea;
  background: #fafafa;
  margin-bottom: 20px;
}
.gateway-on-cart>.logo {
  width: 100px;
}
.better-together-parent .single-ventil a.classico-x:hover {
  background-color: #ffff;
  color: #171717;
  transition: all 0.5s;
}
.better-together-parent .single-ventil .rate-checout {
  width: 269px;
  height: 183px;
  margin: 0 auto;
}
.better-together-parent .single-ventil-sub-total .add-cart a {
  background-color: #c0974f;
  color: #ffffff;
  font-family: "Gotham";
  font-size: 14px;
  border: 1px solid #c0974f;
  font-weight: 500;
  line-height: 13.4px;
  letter-spacing: 0.02em;
  margin-top: 25px;
  padding: 18px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  width: 90%;
  text-align: center;
  text-transform: uppercase;
}
.better-together-parent .single-ventil-sub-total .add-cart a:hover {
  background-color: #ffffff;
  border: 1px solid #c0974f;
  color: #0b0b0b;
  transition: 0.5s all ease-in-out;
}
.better-together-parent .single-ventil-sub-total .add-cart {
  text-align: right;
}
.better-together-parent .single-ventil-sub-total .sub-total {
  display: flex;
  align-items: flex-start;
  justify-content: end;
  gap: 13px;
}
.better-together-parent .single-ventil-sub-total .sub-total .subtotal-rating h2 {
  font-family: "Bookman";
  font-size: 30px;
  font-weight: 500;
  line-height: 34.2px;
  letter-spacing: 0.05em;
  text-align: right;
  color: #001e42;
}
.better-together-parent .single-ventil-sub-total .sub-total .subtotla-head h3 {
  font-family: "Gotham";
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.06em;
  text-align: left;
  color: #595959;
  padding-top: 8px;
}
.better-together-parent .single-ventil-sub-total p.line-through {
  font-family: "Gotham";
  font-size: 14px;
  font-weight: 400;
  line-height: 13.4px;
  padding: 6.65px 0px 11.46px;
  letter-spacing: 0.05em;
  text-align: left;
  color: #595959;
  text-decoration: line-through;
}
.better-together-parent .single-ventil-sub-total span {
  color: #0a8035;
  font-family: "Gotham";
  font-size: 16px;
  font-weight: 500;
  line-height: 15.31px;
  letter-spacing: 0.05em;
  text-align: right;
  text-transform: uppercase;
  display: inline-flex;
  justify-content: end;
}
.better-together-parent .plsu_single-ventiled {
  display: flex;
  align-items: center;
}
.single-ventil {
  max-width: 90%;
  width: 100%;
}
.better-together-parent .plsu_single-ventiled .plus-singl-pro {
  padding-bottom: 43%;
  position: absolute;
  right: 10px;
}
/* Our best sellers section css end here */
/* single product specification css start here */
.single-pro-spread-sheet .accordion-item h2 button {
  font-family: "Gotham";
  font-size: 18px;
  font-weight: 400;
  border-radius: 0;
  line-height: 24px;
  padding: 13px 20px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #171717;
  border: 1px solid #dedede;
  box-shadow: none;
}
.single-pro-spread-sheet .accordion-item {
  border-radius: 0 !important;
  margin-bottom: 19.4px !important;
}
.single-pro-spread-sheet .accordion-item .accordion-button:not(.collapsed) {
  background-color: #171717;
  color: #fff;
}
.prd-specification-guid-content p {
  font-family: "Gotham";
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #1d1d1b;
}
.prd-specification-guid-content p.our-design {
  padding-top: 10px;
}
.single-spci-manual-table {
  background-color: #fafafa;
  border: 1px solid #dfdfdf;
}
.single-spci-manual-table .manual-cont {
  display: inline-flex;
  width: 100%;
  background-color: #fff;
  justify-content: space-between;
}
.single-spci-manual-table .manual-cont th h4 {
  font-family: "Gotham";
  font-size: 16px;
  font-weight: 500;
  line-height: 13.4px;
  letter-spacing: 0.02em;
  color: #001e42;
  text-align: left;
  padding: 15px 0 15px 19px;
}
.single-spci-manual-table .manual-cont th {
  text-align: center;
  width: 49%;
}
.single-spci-manual-table .heading-manual th {
  text-align: center;
  width: 100%;
  background-color: #e6e6e6;
}
.single-spci-manual-table .manual-cont th.dual-manual {
  display: flex;
  align-items: center;
  padding: 19px 18px;
  justify-content: start;
  gap: 10px;
}
.contnet {
  padding-top: 5px;
}
.contnet h5 {
  font-family: "Gotham";
  font-size: 11px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #001e42;
  text-transform: uppercase;
}
.contnet h6 {
  font-family: "Gotham";
  font-size: 11px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #001e42;
}
.single-spci-manual-table .manual-cont th p {
  font-family: "Gotham";
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #1d1d1b;
  margin-top: 5px;
}
.listli ul {
  padding: 15px 40px;
  border: 1px solid #dfdfdf;
}
.listli ul li {
  list-style-type: disc;
  color: #1d1d1b;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  font-family: "Gotham";
}
.single-spci-manual-table table {
  width: 100%;
}
.compiled-heading {
  margin-top: 30px;
}
h6.burner-heading {
  background-color: #e6e6e6;
  font-family: "Gotham";
  font-size: 16px;
  font-weight: 500;
  line-height: 15.31px;
  letter-spacing: 0.02em;
  padding: 16px 18px 14px 18px;
  text-align: left;
  margin-bottom: 0px;
  color: #001e42;
}
.single-hallman-burner table {
  width: 100%;
}
.single-product-specification-body .single-hallman-burner tr,
.single-product-information-body .single-hallman-burner tr {
  border-bottom: 1px solid #dddddd;
  text-align: left;
  display: flex;
  width: 50%;
}
.single-product-information-body .single-hallman-burner tr {
  border-top: 1px solid #dddddd;
  border-left: 1px solid #dddddd;
}
.single-hallman-burner tr .four-contnet {
  font-family: "Gotham";
  font-size: 13px;
  font-weight: 500;
  line-height: 12.44px;
  letter-spacing: 0.02em;
  background-color: #f0f0f0;
  text-align: left;
  color: #1d1d1b;
  width: 25%;
  border-right: 1px solid #dfdfdf;
  padding: 16px 18px 14px 18px;
}
.single-product-information-body tr .first-rate {
  font-family: "Gotham";
  font-size: 13px;
  font-weight: 500;
  line-height: 12.44px;
  background-color: #f4f4f4;
  letter-spacing: 0.02em;
  color: #1d1d1b;
  padding: 16px 18px 14px 18px;
  border-right: 1px solid #dfdfdf;
}
.single-product-information-body tr .btu-text {
  font-family: "Gotham";
  font-size: 13px;
  font-weight: 400;
  line-height: 12.44px;
  letter-spacing: 0.02em;
  color: #1d1d1b;
  padding: 16px 18px 14px 18px;
  border-right: 1px solid #dfdfdf;
}
.single-pro-spread-sheet .single-note-content {
  background-color: #fafafa;
  padding: 24px 99px 24px 11px;
}
.single-pro-spread-sheet .single-note-content li {
  font-family: "Gotham";
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #262626;
  list-style: disc;
}
.single-pro-spread-sheet .single-note-content li.last-due-ct {
  padding-top: 10px;
}
.single-pro-spread-sheet .accordion-item {
  border-radius: 0;
  margin-bottom: 19.4px;
}
.single-pro-spread-sheet .accordion-body {
  font-family: "Gotham";
  font-weight: normal;
  font-size: 15px;
}
.single-pro-spread-sheet .accordion-item:last-child {
  margin-bottom: 0;
}
.product-slider-single-pro .sigle-product-content .accordion-button:not(.collapsed) {
  background-color: #fafafa;
  box-shadow: none;
  border-bottom: 1px solid #EAEAE9;
  padding: 1rem 1.25rem;
}
.product-slider-single-pro .sigle-product-content .accordion-header button {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #001E42 !important;
  font-family: 'Bookman';
}
.single-pro-spread-sheet .accordion-item h2 button {
  color: black;
  font-family: "Gotham";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  padding: 13px 20px;
  color: #001e42;
  border: none !important;
}
.single-pro-spread-sheet .accordion-item .accordion-button:not(.collapsed) {
  background-color: #001e42;
  color: #fff;
  border-radius: 0 !important;
}
.single-pro-spread-sheet #accordionExample1 {
  margin-bottom: 19.4px;
}
.single-pro-spread-sheet #accordionExample1 .accordion-button:not(.collapsed)::after {
  background-image: url("https://hallmanimagesnew.s3-accelerate.amazonaws.com/header/collapese-white.svg") !important;
  transform: rotate(358deg);
}
.single-pro-spread-sheet .accordion-item .accordion-button::after {
  background-image: url("https://hallmanimagesnew.s3-accelerate.amazonaws.com/header/collapse-top-arrow.svg");
  rotate: -180deg;
}
.single-pro-spread-sheet .accordion-item .accordion-button::after {
  background-image: url("https://hallmanimagesnew.s3-accelerate.amazonaws.com/header/collapse-top-arrow.svg");
}

.single-pro-spread-sheet .accordion-item .accordion-button:not(.collapsed) {
  background-color: #001e42;
  color: #ffff;
}
.single-pro-spread-sheet .accordion-item .accordion-button::after {
  background-image: url("https://hallmanimagesnew.s3-accelerate.amazonaws.com/header/collapse-top-arrow.svg");
  rotate: 180deg;
  width: 12.73px;
  height: 7.78px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.single-pro-spread-sheet .accordion-button:not(.collapsed)::after {
  background-image: url("https://hallmanimagesnew.s3-accelerate.amazonaws.com/header/collapese-white.svg") !important;
  transform: rotate(360deg);
}
/* single product specification end start here */
/* home page css end here hallman industires project */
/* banner section start here */
.banner-list {
  text-align: center;
}
.banner-list .container {
  border-bottom: 1px solid #dedede;
}
.banner-list h1 {
  font-family: "Gotham";
  font-size: 40px;
  text-transform: uppercase;
  font-weight: 400;
  line-height: 45.36px;
  letter-spacing: 0.05em;
  padding-bottom: 20.43px;
  font-family: "Bookman";
  color: #001e42;
}
.category-back {
  /* background-image: url(/public/images/banner-1.jpg); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 410px;
  margin-bottom: 20px;
}
.shimmer-title.w-25 {
  width: 25% !important;
}
.banner-list p {
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.02em;
  text-align: center;
  color: #1d1d1b;
  font-family: "Gotham";
}
.banner-list img {
  padding-bottom: 20.52px;
  height: 100%;
  width: 100%;
}
.plp-desc button {
  color: #2d58c6 !important;
  font-weight: 600;
  font-family: "Gotham";
  text-underline-offset: 2px;
}
/* banner section end here */
/* main product section start here */
.single-product .part-1 {
  text-align: center;
}
.section-products {
  padding: 48.78px 0 60px 0;
}
.single-product .part-1 a,
.home-our-best-seller-section .our-single-product-seller .classisco-heading a {
  background-color: #e6e6e6;
  color: #001f3f;
  font-family: "Gotham";
  font-size: 12px;
  font-weight: 400;
  padding: 3px 15px;
  letter-spacing: 0.02em;
  display: flex;
  width: auto;
  align-items: center;
  justify-content: center;
  justify-content: center;
  margin: 0 auto;
  position: relative;
  top: -10px;
  z-index: 2;
  text-decoration: none;
  font-family: "Gotham";
  display: inline-block;
}
.single-product .part-1 a:hover {
  background-color: #e6e6e6;
  color: #171717;
  transition: all 0.5s;
}
#product-list-itmes .product-parent {
  position: relative;
  transition: 0.2s;
}
#product-list-itmes .product-parent .image-hover {
  position: absolute;
  top: 8px;
  opacity: 0;
  visibility: hidden;
  z-index: 1;
  transition: all 0.5s ease-in;
}
#product-list-itmes .product-parent:hover .image-hover {
  visibility: visible;
  cursor: pointer;
  opacity: 1;
}
.single-product .part-1 h3.product-old-price a {
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.04em;
  text-align: center;
  padding: 5px 0 0px 0;
  color: #022741;
  font-family: "Gotham";
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  top: 0;
  background: none;
}
.col-sm-4.product-parent {
  margin-bottom: 50px;
}
.single-product .part-1 img.Product-list-pic {
  max-width: 369px;
  width: 100%;
  height: 300px;
  object-fit: cover;
}
.image-wrapper {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

img.product-hover-pic,
div.product-hover-pic {
  height: 300px;
  max-width: 369px;
  width: 100%;
  object-fit: cover;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.5s all ease-in-out;
}
.part-1:hover .product-hover-pic {
  opacity: 1;
  transition: 0.5s all ease-in-out;
}
.single-product .part-1 h4 {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.06em;
  text-align: center;
  color: #1d1d1b;
  padding: 0px 0 18px 0px;
  font-family: "Gotham";
}

.single-product .part-1 h4 .dicnt {
  text-decoration: line-through;
  color: #1d1d1b !important;
  text-decoration-color: #1d1d1b;
  font-weight: 400;
  margin-right: 5px;
}
.lower-cart-im .tag {
  width: 45%;
  height: 100%;
}

.section-products .single-product .lower-cart-im .tag {
  width: 100%;
  height: 100%;
  max-width: 162px;
}
.single-product .part-1 p {
  font-size: 14px;
  font-weight: 400;
  line-height: 13.4px;
  letter-spacing: 0.06em;
  text-align: center;
  color: #1d1d1b;
  font-family: "Gotham";
  padding: 18px 0px 18px 0px;
}
.section-products .filter-row h2 {
  font-size: 20px;
  font-weight: 700;
  color: #001e42;
  line-height: 19.14px;
  letter-spacing: 0.05em;
  text-align: left;
  font-family: "Gotham";
  transition: 0.3s all ease-in-out;
}
.section-products .filter-row .clear-filter h3 {
  text-align: right;
  font-family: "Gotham";
  font-size: 14px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0.02em;
  color: #2d58c6;
  text-decoration: underline;
  text-underline-offset: 2px;
}
.section-products .product-fliter .price-range {
  position: relative;
  align-items: center;
}
.section-products .product-fliter .price-ranged p {
  font-family: "Gotham";
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.02em;
}
.section-products .filter-row .showing-filter h3 {
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #1d1d1b;
  font-family: "Gotham";
}
.select-size {
  display: flex;
  gap: 15px;
}
button.selct-size {
  width: 55px;
  height: 50px;
  border: none;
  outline: none;
  border: 1px solid #d3d3d3;
  color: #595959;
  font-size: 14px;
  font-weight: 400;
  line-height: 13.4px;
  font-family: "Gotham";
  background: #fff;
}
button.selct-size.active {
  border: 1px solid #001e42;
  color: #001e42;
  font-weight: 500;
}
.section-products .product-fliter h6 {
  font-family: "Gotham";
  font-size: 14px;
  font-weight: 700;
  line-height: 13.5px;
  letter-spacing: 0.05em;
  color: #001e42;
  text-align: left;
  font-family: "Gotham";
}
.section-products .product-fliter .form-check label {
  font-size: 14px;
  font-weight: 400;
  color: #1d1d1b;
  line-height: 18.4px;
  letter-spacing: 0.02em;
  text-align: left;
  cursor: pointer;
  font-family: "Gotham";
  padding-top: 5px;
}
.product-fliter .form-check-input {
  margin-top: 5px !important;
  cursor: pointer;
}
.section-products .product-fliter .accordion-header button {
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.05em;
  color: #001e42;
  text-align: left;
  font-family: "Gotham";
}
.section-products .product-fliter .accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0px;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    border-radius 0.15s ease;
}
.section-products .product-fliter .accordion-button:after {
  flex-shrink: 0;
  width: 12px;
  height: 8px;
  margin-left: auto;
  content: "";
  background-image: url("https://hallmanimagesnew.s3-accelerate.amazonaws.com/header/range-drop.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transition: transform 0.2s ease-in-out;
  transform: rotate(180deg);
}
.section-products .product-fliter .accordion-button:not(.collapsed):after {
  background-image: url("https://hallmanimagesnew.s3-accelerate.amazonaws.com/header/range-drop.svg") !important;
  transform: rotate(0deg);
}
.section-products .product-fliter .accordion-button:not(.collapsed) {
  box-shadow: none;
}
.product-fliter.filter-desktop .accordion-body {
  padding: 0px;
}
.section-products .product-fliter .accordion-item,
.accordion-button:not(.collapsed) {
  border: none;
  padding: 10px 0px;
  border-bottom: 1px solid #dedede;
}
div#product-1 {
  margin-top: -20px;
}

.section-products .product-fliter .accordion-item:last-child {
  border: 0;
}
.section-products .product-fliter .form-check input {
  height: 18px;
  width: 18px;
  border-color: #969696;
}
label.color-red input {
  height: 18px;
  width: 18px;
}
label.color-red.color-txt {
  display: inline-flex;
  align-items: center;
}
.section-products .product-fliter .form-check {
  display: flex;
  align-items: center;
  gap: 9px;
  cursor: pointer;
}
.section-products .product-fliter .form-check input {
  border-radius: 0;
}
.section-products .product-fliter .form-check-input:checked {
  background-color: #c0974f;
  border-color: #c0974f;
}
.section-products .product-fliter .form-check-input:focus {
  box-shadow: none;
}
.section-products .product-fliter .form-check-input.tick-black:checked {
  background-color: white;
  border-color: #acacac;
}
.section-products .product-fliter .form-check-input.tick-black:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27><path fill=%27none%27 stroke=%27%23000%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27m6 10 3 3 6-6%27/></svg>");
  background-size: auto !important;
}
.section-products .product-fliter .color-picker .radio-box {
  gap: 31px;
  display: inline-flex;
  width: 20px;
}
.section-products .product-fliter .color-picker .radio-box .form-check-input {
  width: 28.54px;
  height: 28.54px;
}
.section-products .product-fliter .color-picker .radio-box .color-red .form-check-input {
  background-color: #b4bdc7;
  border-color: #eaeaea;
}
button.dropdown-toggle,
.dropdown-toggle>a {
  text-align: left;
  background: transparent !important;
  border: none;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.section-products .product-fliter .color-picker .radio-box .color-orange .form-check-input {
  background-color: #faebd7;
  border-color: #faebd7;
}
.section-products .product-fliter .accordion-body {
  padding-top: 10px;
  display: grid;
  gap: 10px;
}
.section-products .product-fliter .color-picker .radio-box .color-black .form-check-input {
  background-color: #110011;
  border-color: #110011;
}
.section-products .product-fliter .color-picker .radio-box .color-blure .form-check-input {
  background-color: #414244;
  border-color: #414244;
}
.section-products .product-fliter .color-picker .radio-box .color-blure .form-check-input {
  background-color: #414244;
  border-color: #414244;
}
.section-products .product-fliter .color-picker .radio-box .color-teal .form-check-input {
  background-color: #474b4e;
  border-color: #474b4e;
}
.section-products .product-fliter .part-1 {
  margin-bottom: 30px;
}
.section-products .product-pagination nav {
  text-align: center;
  margin-top: 78px;
}
.section-products .product-pagination nav ul {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 18px;
}
.section-products .product-pagination nav ul .page-item .page-link {
  border-radius: 0;
  font-family: "Gotham";
  font-size: 14px;
  font-weight: 400;
  line-height: 13.4px;
  letter-spacing: 0.04em;
  text-align: center;
  color: #171717;
  width: 33px;
}
.section-products .product-pagination nav ul .page-item .page-link:focus {
  box-shadow: none;
  border-color: inherit;
}
.section-products .product-pagination nav ul .page-item .page-link:hover {
  background-color: #000;
  color: #fff;
  transition: all 0.5s;
}
.section-products .product-pagination nav ul .page-item.active .page-link {
  background-color: #c0974f;
  color: #fff;
  border-color: inherit;
}
.section-products .product-pagination nav ul .page-items .page-links {
  color: #353535;
  font-size: 14px;
  font-weight: 400;
  line-height: 13.4px;
  letter-spacing: 0.02em;
  font-family: "Gotham";
  text-decoration: none;
}
.section-products .product-fliter .price-ranged input[type="range"] {
  appearance: none;
  width: 168px;
  outline: none;
  position: absolute;
  margin: auto;
  top: 5px;
  bottom: 0;
  left: 0;
  background-color: transparent;
  pointer-events: none;
}
.section-products .filter-row .showing-filter {
  width: 45%;
}
.filter-list {
  width: 17%;
}
.clear-filter {
  width: 13%;
}
.section-products .product-fliter .price-ranged .slider-track {
  width: 160px;
  height: 3px;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  border-radius: 5px;
}
.section-products .product-fliter .price-ranged input[type="range"]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  height: 3px;
}
.section-products .product-fliter .price-ranged input[type="range"]::-moz-range-track {
  -moz-appearance: none;
  height: 3px;
}
.section-products .product-fliter .price-ranged input[type="range"]::-ms-track {
  appearance: none;
  height: 3px;
}
.section-products .product-fliter .price-ranged input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 16px;
  width: 16px;
  background-color: white;
  border: 4px solid #000;
  cursor: pointer;
  margin-top: -9px;
  pointer-events: auto;
  border-radius: 50%;
}
.section-products .product-fliter .price-ranged input[type="range"]::-moz-range-thumb {
  -webkit-appearance: none;
  height: 16px;
  width: 16px;
  cursor: pointer;
  border-radius: 50%;
  background-color: #3264fe;
  pointer-events: auto;
  border: none;
}
.section-products .product-fliter .price-ranged input[type="range"]::-ms-thumb {
  appearance: none;
  height: 16px;
  width: 16px;
  cursor: pointer;
  border-radius: 50%;
  background-color: #000;
  pointer-events: auto;
}
.section-products .product-fliter .price-ranged .values {
  width: 63%;
  position: relative;
  padding: 10px 0;
  border-radius: 5px;
  text-align: center;
  font-weight: 500;
  color: #000;
}
.section-products .product-fliter .price-ranged #range1,
#range2 {
  position: absolute;
  top: 40px;
}
#filter-row {
  position: relative;
}
.section-products .filter-row {
  display: flex;
  padding-bottom: 12px;
  align-items: center;
}
.sidebar {
  height: 100%;
  width: 0;
  position: absolute;
  background-color: #fff;
  z-index: 1;
  display: none;
  top: 0;
  left: 0;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
  z-index: 3;
}
.sidebar .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 0px;
}
.openbtn {
  cursor: pointer;
}
.section-products .product-fliter .price-ranged #range1 {
  left: 41%;
}
/* main product css end here */
/* shiping css start here */
.Ship-details {
  padding: 41px 0px 40px 0px;
  background: #c0974f;
  position: relative;
  z-index: 1;
}
.shiping-list .shiping-conteted h2 {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.04em;
  text-align: center;
  display: flex;
  gap: 10px;
  font-family: "Bookman";
  color: #fff;
  margin-bottom: 0px;
}
.shiping-list .second-shiping-conteted h3 {
  justify-content: center !important;
}
/* shiping css end here */
/* footer section start here */
ul.footer-social-links {
  display: flex;
  gap: 14px;
  margin: 17.8px 0px 40.71px 0px;
}
.footer-social-links li a {
  background: #03202f;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
}
footer {
  border-bottom: 1px solid #314c59;
  padding: 80.16px 0 80px 0;
  background: #03202f;
  position: relative;
  z-index: 1;
}
footer .newsletter h2 {
  font-size: 20px;
  font-weight: 500;
  line-height: 22.68px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  text-align: left;
  font-family: "Bookman";
  color: #fff;
}
footer .newsletter p {
  font-size: 14px;
  font-weight: 400;
  line-height: 13.4px;
  color: #ffffff;
  font-family: "Gotham";
  margin-top: 13.28px;
  text-align: left;
}
footer .newsletter .form-control {
  border: 0;
  border-bottom: 1px solid #cac7c7;
  border-radius: 0 !important;
  color: #595855;
  font-family: "Gotham";
  font-size: 14px;
  font-weight: 400;
  padding: 0px 10px 10px 0px;
  background: transparent;
  line-height: 27px;
  width: 55%;
  color: #fff;
}
footer .newsletter .nreslatter-email {
  display: flex;
  justify-content: start;
  gap: 20px;
  align-items: baseline;
}
footer .newsletter button {
  border: 1px solid #cac7c7;
  font-family: "Gotham";
  font-size: 16px;
  font-weight: 400;
  line-height: 17.6px;
  width: 117px;
  padding: 15px 0px;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 29px;
  color: #fff;
  transition: 0.5s all ease-in-out
}
button.btn.btn-block.mb-4.newletter-join:focus, button.btn.btn-block.mb-4.newletter-join:hover {
  background: #c0974f;
  color: #fff;
}
input#form2Example1::placeholder {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 27px;
}
footer .newsletter button:hover {
  background-color: #c0974f;
  color: #fff;
  transition: 0.5s all ease-in-out
}
.btn-check:focus+.btn,
.btn:focus {
  box-shadow: none;
  border-color: inherit;
}
footer .newsletter .form-control::placeholder {
  color: rgb(255 255 255 / 70%);
  line-height: 24px;
}
.cate-goreis li a:hover {
  color: rgb(255 255 255 / 80%);
}
.cate-goreis .footer-navigations li a {
  font-size: 14px;
  font-weight: 400;
  transition: transform 0.5s;
  line-height: 40px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #ffffff;
  font-family: "Gotham";
  text-decoration: none;
  text-transform: capitalize;
}
.cate-goreis .footer-navigations li a:hover {
  color: #969696;
}

footer .cate-goreis h2 {
  font-size: 18px;
  color: #ffffff;
  font-weight: 500;
  text-transform: uppercase;
  line-height: inherit;
  letter-spacing: 0.05em;
  font-family: "Bookman";
}
.copyright-section .footer-social {
  display: flex;
  gap: 20px;
}
.copyright-section {
  padding: 25px 20px;
  background: #03202f;
}
.copyright-section .review-logo {
  display: flex;
  align-items: center;
  justify-content: end;
  text-align: right;
  gap: 33px;
  position: relative;
}
.copyright-section .review-logo p {
  font-size: 14px;
  font-weight: 400;
  line-height: 13.4px;
  text-align: left;
  color: #fff;
  font-family: "Gotham";
  margin-bottom: 0px;
  position: relative;
}
.copyright-section .review-logo h2:after {
  content: "";
  position: absolute;
  border-right: 1px solid #fff;
  height: 23px;
  margin-left: 10px;
  top: -3px;
  right: -17px;
}
.copyright-section .copyright-email {
  text-align: left;
  display: flex;
  flex-direction: column;
}
.copyright-section .copyright-email>div {
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  line-height: 13.4px;
  text-align: left;
  font-family: "Gotham";
  text-decoration: none;
}
/* footer section end here */
/* single-product page css start here */
.single-product-navigation {
  background-color: #e6e6e6;
  position: fixed;
  width: 100%;
  z-index: 9;
  /* top: 86px; */
  top: 113px;
}
.cart-empty {
  text-align: center;
  font-size: 15px;
  padding: 24px 15px 12px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-family: "Gotham";
}
.single-product-navigation nav {
  padding: 1px 0;
}
.single-product-navigation nav ul {
  display: flex;
  column-gap: 10px;
  align-items: center;
  justify-content: start;
  margin-bottom: 0px;
  padding-left: 0px;
  flex-wrap: wrap;
}
.large-screen.carousel-container {
  display: flex;
  align-items: flex-start;
}
.large-screen .thumbs-wrapper {
  display: flex;
  flex-direction: column;
  width: auto;
  margin-right: 20px;
  overflow: hidden;
  height: 550px;

}
.carousel .control-prev:before,
.carousel.carousel-slider .control-prev:before {
  content: '<' !important;
}
.carousel .control-next:after,
.carousel.carousel-slider .control-next:after {
  content: '>' !important;
}
.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  opacity: .8 !important;
}
.large-screen .thumbs {
  display: flex;
  flex-direction: column;
}
.large-screen .thumbs::-webkit-scrollbar {
  display: none;
}
.mt-60px {
  margin-top: 60px;
}
.thumbs img {
  margin: 5px 0;
  cursor: pointer;
  width: 85px;
  height: 85px;
  object-fit: contain;
  border: 1px solid #eaeaea;
  padding: 5px;
}
.custom-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
}
.custom-arrow-left {
  left: 10px;
}
.custom-arrow-right {
  right: 10px;
}
.custom-arrow.disabled {
  display: none;
}

.product-hover-pic .slider-wrapper {
  flex: 1;
  height: 303px;
}

.product-hover-pic .carousel .slider {
  height: 303px;
}

.slider-wrapper {
  flex: 1;
  height: 610px;
}
.cart-page .slider-wrapper {
  padding: 0;
  height: auto;
}
.single-product-navigation nav ul li a {
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #001e42;
  font-family: "Gotham";
  text-decoration: none;
}
.single-product-customizer .series-buton {
  background-color: #c0974f;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  padding: 7px;
  line-height: 14px;
  letter-spacing: 0.02em;
  display: flex;
  width: -moz-fit-content;
  width: fit-content;
  align-items: center;
  justify-content: start;
  justify-content: center;
  margin-top: 28px;
  text-decoration: none;
  font-family: "Gotham";
  border: 1px solid #c0974f;
}
.single-product-customizer .series-buton a:hover {
  background-color: #ffff;
  transition: all 0.5s;
  color: #171717;
}
.accordian .shimmer-button--lg {
  width: 100%;
  border-radius: 0px;
}
.single-product-customizer .single-product-heading {
  font-size: 35px;
  font-weight: 500;
  line-height: 39.69px;
  letter-spacing: 0.05em;
  padding: 16px 0px 7.5px 0px;
  text-align: left;
  text-transform: uppercase;
  color: #001e42;
  font-family: "Bookman";
}
.single-product-customizer h2.sku-title {
  font-family: "Gotham";
  font-size: 14px;
  font-weight: 500 !important;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #1d1d1b;
  padding-bottom: 11.6px;
}
.single-product-customizer h2.sku-title span {
  color: #1d1d1b;
  font-weight: 400;
}
.single-product-customizer h4.Reviews {
  font-family: "Gotham";
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #1d1d1b;
  display: flex;
  align-items: center;
  gap: 10px;
}
.relative-product {
  padding-top: 70px;
  border: 0;
}
.our-single-product-seller img.Product-list-pic {
  height: 303px;
  max-width: 369px;
  width: 100%;
  margin-bottom: -10px;
  object-fit: cover;
}
.our-single-product-seller:hover .product-hover-pic {
  opacity: 1;
  transition: 0.5s all ease-in-out;
}
.slider-wrapper .image-wrapper .product-hover-pic{
  height: 303px;
  object-fit: contain;
}
.our-single-product-seller:hover img.Product-list-pic{
  opacity: 0;
}

.large-screen .carousel .thumbs.animated {
  flex-wrap: wrap;
  align-items: start;
  gap: 10px;
  height: 85px;
  margin-right: 15px;
}
.large-screen .thumbs.animated>.thumb {
  cursor: pointer;
  border: 1px solid #eaeaea;
  width: 85px;
  height: 85px;
  display: inline-block;
  align-items: center;
  justify-content: center;
}
.carousel .thumb.selected,
.carousel .thumb:hover {
  border: 1px solid #001e42 !important;
}
.product-slider-single-pro .customizer-product {
  display: flex;
  padding-top: 10px;
}
.color-varient-tab {
  display: flex;
  gap: 19px;
  align-items: center;
  justify-content: start;
}
.product-slider-single-pro .product-image-vertical {
  width: 84%;
  height: 610px;
  border: 1px solid #eaeaea;
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  background: #fafafa;
}
.single-product-customizer .product-slider-single-pro .tab-three li#contact {
  background: aliceblue;
  opacity: 0.9;
  filter: hue-rotate(28deg);
  position: relative;
}
.carousel-status {
  display: none;
}
.thumbs-wrapper .active {
  border: 1.5px solid #001e42;
}
.single-product-customizer .product-slider-single-pro .tab-three li#contact:after {
  content: "11+";
  background-color: rgb(47 47 47 / 60%);
  position: absolute;
  color: #fff;
  width: 84px;
  height: 84px;
  background-position: center;
  font-family: "Gotham";
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.05em;
  display: flex;
  justify-content: center;
  align-items: center;
}
.product-slider-single-pro .product-image-vertical img {
  width: 600px;
  cursor: pointer;
  height: 450px;
}
/* Slideshow container */
.real-life-single-pro .slideshow-container {
  position: relative;
  height: 100%;
  overflow: hidden;
}
.real-life-single-pro .mySlides.fade {
  height: 100%;
}
/* Caption text */
.real-life-single-pro .text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}
/* The dots/bullets/indicators */
.real-life-single-pro .dot {
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: none;
  transition: background-color 0.6s ease;
}
.real-life-single-pro .fade {
  animation-name: fade;
  animation-duration: 1.5s;
}
.real-life-single-pro .fade:not(.show) {
  opacity: 0.8;
}
.real-life-single-pro .mySlides.fade img {
  width: 100%;
  height: 100%;
}
@keyframes fade {
  from {
    opacity: 0.4;
  }

  to {
    opacity: 1;
  }
}
.product-slider-single-pro .product-image-vertical img {
  transition: ease-out 300ms;
}
ul.slider:hover li.slide.selected img {
  transition: ease-out 300ms;
}
.product-slider-single-pro .sigle-product-content .price-section {
  display: flex;
  justify-content: start !important;
  align-items: center;
  gap: 40px;
}
.product-slider-single-pro .sigle-product-content .price-section h2 {
  font-size: 30px;
  font-weight: 500;
  line-height: 34.2px;
  letter-spacing: 0.05em;
  color: #001e42;
  text-align: left;
  font-family: "Bookman";
}
.product-slider-single-pro .single-product-table {
  margin: 25px 0;
}
/* .product-slider-single-pro .sigle-product-content .single-product-table .single-des-one {
  padding-bottom: 20px;
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 20px;
} */
.product-slider-single-pro .sigle-product-content .accordion-button:not(.collapsed)::after {
  background-image: url(https://hallmanimagesnew.s3-accelerate.amazonaws.com/header/collapse-top-arrow.svg) !important;
  transform: rotate(0deg);
  max-width: 12.73px;
  width: 100%;
  height: 7.78px;
  background-position: center;
  background-size: cover;
}
.product-slider-single-pro .sigle-product-content .accordion-button::after {
  background-image: url(https://hallmanimagesnew.s3-accelerate.amazonaws.com/header/collapse-top-arrow.svg) !important;
  transform: rotate(180deg);
  max-width: 12.73px;
  width: 100%;
  height: 7.78px;
  background-position: center;
  background-size: cover;
}
.product-slider-single-pro .sigle-product-content .price-section h3 {
  font-size: 16px;
  font-weight: 400;
  line-height: 18.14px;
  letter-spacing: 0.05em;
  text-align: left;
  color: #595959;
  text-decoration: line-through;
  font-family: "Bookman";
  margin-top: 4.42px;
}
.single-product-table .table-body {
  background-color: #fafafa;
}
.product-slider-single-pro {
  padding: 30px 0;
}
.product-slider-single-pro .sigle-product-content .lower-cart-icon a {
  font-family: "Gotham";
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  display: flex;
  justify-content: center;
  margin-top: 5px;
  color: #2d58c6;
  text-decoration: underline;
  text-underline-offset: 2px;
}
.product-slider-single-pro .sigle-product-content #accordionExample .accordion-header button {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #001e42 !important;
  font-family: "Bookman";
}
.product-slider-single-pro .sigle-product-content #accordionExample .accordion-button:not(.collapsed) {
  color: #171717;
  background-color: #fafafa;
  padding: 15.5px 20px;
  border-bottom: 1px solid #eaeaea;
  box-shadow: none;
}
.bread-pay-text h2 {
  font-size: 15px;
  font-weight: 500;
  line-height: 14.36px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #001e42;
  font-family: "Gotham";
  padding-bottom: 5.66px;
}
.bread-pay-text p,
.bread-pay-text a {
  font-size: 14px;
  font-weight: 400;
  line-height: 20.4px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #1d1d1b;
  font-family: "Gotham";
}
.bread-pay-text p a {
  color: #0c4ff9;
  text-decoration: underline;
  text-underline-offset: 2px;
}
.single-product-des .logo {
  max-width: 20%;
  position: relative;
  width: 100%;
}
.single-product-des .bread-pay-text {
  max-width: 70%;
  width: 100%;
}
.single-product-des .logo:after {
  content: "";
  background-color: #e3e3e3;
  height: 31px;
  width: 2px;
  position: absolute;
  right: -32px;
}
.product-slider-single-pro .single-product-table .single-product-des {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.product-slider-single-pro .sigle-product-content .accordion-item {
  border-radius: 0;
  border: 1px solid #eaeaea;
  background: #fafafa;
}
.accordion-button:focus {
  box-shadow: none;
}
.product-image-vertical #first {
  overflow: hidden;
}
.product-image-vertical div {
  display: none;
}
.product-image-vertical div:first-child {
  display: block;
}
.product-slider-single-pro .carousel-control-next-icon, .cart-page .carousel-control-next-icon, .product-hover-pic .carousel-control-next-icon  {
  display: inline-block;
  /* padding: 25px; */
  height: 30px;
  width: 30px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
  color: #000;
  border-radius: 50%;
  z-index: 9;
  background-color: #fff;
  box-shadow: 0px 2px 4px 0px #969696;
}
.product-slider-single-pro .carousel-control-prev-icon, .cart-page .carousel-control-prev-icon, .product-hover-pic .carousel-control-prev-icon  {
  box-shadow: 0px -2px 4px 0px #969696;
  color: #000;
  /* padding: 25px; */
  height: 30px;
  width: 30px;
  z-index: 9;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
  color: #000;
  border-radius: 50%;
}
.product-slider-single-pro button.carousel-control-prev {
  position: absolute;
  left: -10px;
}
.product-slider-single-pro button.carousel-control-next {
  position: absolute;
  right: -10px;
}
.product-slider-single-pro .carousel-item {
  transition: none !important;
}
.product-slider-single-pro .carousel-control-next-icon, .cart-page .carousel-control-next-icon,  .product-hover-pic .carousel-control-next-icon{
  background-image: url(https://hallmanimagesnew.s3-accelerate.amazonaws.com/header/crousel-icon.svg);
  background-size: 8px;
}
.product-slider-single-pro  .carousel-control-prev-icon , .cart-page .carousel-control-prev-icon, .product-hover-pic .carousel-control-prev-icon{
  background-image: url(https://hallmanimagesnew.s3-accelerate.amazonaws.com/header/crousel-icon.svg);
  transform: rotate(177deg);
  background-size: 8px;
}
.product-slider-single-pro .carousel-control-next,
.carousel-control-prev {
  opacity: 1;
}
.product-slider-single-pro .single-product-invetory h2 {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #001e42;
  font-family: "Gotham";
}
.product-slider-single-pro .single-product-invetory h3 {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #389b5c;
  font-family: "Gotham";
}
.product-slider-single-pro .single-product-invetory {
  padding-bottom: 22.5px;
  border-bottom: 1px solid #eaeaea;
}
.product-slider-single-pro .quick-gap.main-area {
  display: flex;
  justify-content: space-between;
}
.product-slider-single-pro .product-slider_single-show.left {
  max-width: 720px;
  width: 100%;
}
.product-slider-single-pro .product-slider_single-show.right {
  max-width: 572px;
  width: 100%;
}
.product-slider-single-pro .single-product-varient-size {
  padding: 30px 0;
}
.product-slider-single-pro .single-product-varient-size h4 {
  font-family: "Gotham";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding-bottom: 12px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #171717;
}
.single-product-varient-size .form-check-flie .innser-select-size input[type="radio"] {
  display: none;
}
.single-product-varient-size .form-check-flie .innser-select-size label {
  cursor: pointer;
  border: 1px solid #eaeaea;
  padding: 12px 14px;
}
.single-product-varient-size .form-check-flie {
  display: flex;
  gap: 8px;
  justify-content: start;
  align-items: center;
}
.sigle-product-content .single-produt-choose-color {
  border-bottom: 1px solid #eaeaea;
  padding: 21px 0px 29px 0px;
}
.single-produt-choose-color h4 {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  padding-bottom: 12px;
  letter-spacing: 0.02em;
  color: #001e42;
  font-family: "Gotham";
}
.single-produt-choose-color .form-check-flie .choose-color input[type="radio"] {
  display: none;
}
.single-product-des .fin-more .single-product-varient-size .form-check-flie .innser-select-size input:checked+label {
  color: #171717;
  border-color: #171717;
}
.single-product-des .fin-more .fin-learn-more a {
  color: #0C4FF9;
  font-family: 'Gotham';
  font-size: 14px;
  font-weight: 400;
  line-height: 13.4px;
  text-align: left;
  text-decoration: underline;
  text-underline-offset: 2px;
}
.single-produt-choose-color .form-check-flie {
  display: flex;
  gap: 19px;
  align-items: unset;
  justify-content: start;
  flex-wrap: wrap;
}
.single-produt-choose-color .form-check-flie .choose-color label {
  cursor: pointer;
  border: 1px solid #eaeaea;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 2px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.choose-color label.slect-time img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.single-produt-choose-color .form-check-flie .choose-color h5 {
  font-family: "Gotham";
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.05em;
  color: #1d1d1b;
  padding: 12px 0px 0px 0px;
  min-height: 0px;
}
.single-produt-choose-color .form-check-flie .choose-color {
  text-align: center;
  max-width: 78px;
  width: 100%;
  height: 78px;
  margin-bottom: 30px;
}
.single-produt-choose-color .form-check-flie .choose-color input:checked+label {
  color: #171717;
  border-color: #171717;
}
.choose-color.active h5 {
  font-weight: 500 !important;
  color: #001e42 !important;
}
.slect-time .active h5 {
  font-weight: 700;
}
.single-produt-choose-color .form-check-flie .choose-color a img {
  width: 100%;
}
.single-produt-choose-color.ral-product img {
  cursor: pointer;
}
.single-produt-choose-color.ral-product h4 span {
  color: #1d1d1b;
  font-weight: 400;
}
.single-product-delivery {
  padding: 25px 0;
  border-bottom: 1px solid #EAEAEA;
}
.single-product-delivery h2.delivery {
  font-family: "Gotham";
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #001e42;
  padding-bottom: 18px;
}
.single-product-delivery h2 a {
  text-decoration: underline;
  color: #2d58c6;
  font-weight: 400;
}
.single-product-delivery .choose-delivery-date .form-checking label h2 {
  font-family: "Gotham";
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #001e42;
}
.single-product-delivery .choose-delivery-date .form-checking label span {
  color: #0a8035;
  font-weight: bold;
}
.single-product-delivery .choose-delivery-date .form-checking label p {
  font-family: "Gotham";
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #171717;
}
.single-product-delivery .choose-delivery-date .rating-delivery-single h4 {
  font-family: "BOOKMAN";
  font-size: 20px;
  font-weight: 500;
  line-height: 22.68px;
  letter-spacing: 0.05em;
  text-align: right;
  color: #001e42;
}
.single-product-delivery .choose-delivery-date {
  display: flex;
  justify-content: space-between;
}
.single-product-delivery .choose-delivery-date .form-checking {
  display: flex;
  gap: 17px;
  align-items: center;
}
.delvery-schedule-date p {
  color: #1d1d1b;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  font-family: "Gotham";
}
.multi-range-slider .bar-left {
  padding: 2px 0px !important;
}
.custom-thumb-left {
  width: 20px;
  height: 20px;
  background-color: red;
  border: 2px solid #007aff;
  border-radius: 50%;
  cursor: pointer;
}
.single-product-customizer li.slide {
  background: #fafafa;
}
.single-product-customizer li.slide img {
  mix-blend-mode: multiply;
  object-fit: contain;
}
.thumb-left::before {
  height: 11px !important;
  width: 11px !important;
  background: #fff !important;
  border: 2px solid #c0974f !important;
  border-radius: 0 !important;
  margin: 0 !important;
  top: -4px !important;
}
.thumb-right::before {
  height: 11px !important;
  width: 11px !important;
  background: #fff !important;
  border: 2px solid #c0974f !important;
  border-radius: 0 !important;
  margin: 0 !important;
  top: -4px !important;
}
.multi-range-slider .thumb .caption *,
.multi-range-slider .thumb .caption {
  height: auto !important;
  width: fit-content !important;
  padding: 2px 5px !important;
  box-shadow: none !important;
  background-color: #c0974f !important;
  border-radius: 0px !important;
  border: none !important;
  color: #fff !important;
  font-size: 80% !important;
  margin-top: 20px;
}
.multi-range-slider .caption {
  display: block !important;
  bottom: 13px !important;
}
.no-data-found {
  font-weight: 400;
  font-family: "gotham";
  text-align: center;
}
.single-product-delivery .choose-delivery-date .form-checking .form-check-input:checked {
  background-color: #c0974f;
  border-color: #c0974f;
  box-shadow: none;
}
.single-product-delivery .choose-delivery-date .form-checking input {
  width: 19px;
  height: 19px;
  cursor: pointer;
}
.form-check-input:focus {
  box-shadow: none;
}
.mt-68px {
  margin-top: 68px;
}
.single-product-delivery-option {
  border-bottom: 1px solid #eaeaea;
}
.single-product-delivery-option h3 {
  font-family: "Gotham";
  font-size: 14px;
  font-weight: 500;
  line-height: 14.4px;
  letter-spacing: 0.02em;
  text-align: left;
  padding-bottom: 10px;
  color: #001e42;
}
.single-product-delivery-option .car-doller-del-single {
  background-color: #fafafa;
  padding: 16px;
  border: 1px solid #eaeaea;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
}
.single-product-delivery-option .car-doller-del-single .delivery-option-doller {
  display: flex;
  align-items: center;
  gap: 12px;
}
.single-product-delivery-option .car-doller-del-single .delivery-option-doller input[type="checkbox"] {
  width: 18px;
  height: 18px;
  border-radius: 0;
  border-color: #a1a1a1;
  margin-top: 0px;
  cursor: pointer;
}
.single-product-delivery-option .car-doller-del-single .delivery-option-doller input[type="checkbox"]:checked {
  color: #171717;
  background-color: #c0974f;
  border-color: #c0974f;
}
.single-product-delivery-option .car-doller-del-single .delivery-option-doller label {
  font-family: "Gotham";
  font-size: 14px;
  font-weight: 400;
  line-height: 13.4px;
  letter-spacing: 0.02em;
  color: #001e42;
  text-align: left;
}
span.rate-highlight {
  font-weight: 500;
  margin-left: 5px;
  color: #001e42;
  font-family: "Gotham";
}
.unfilled-button:hover {
  color: #fff;
  background: #c0974f;
  transition: 0.5s all ease-in-out;
  border: 1px solid #c0974f;
}
.unfilled-button {
  color: #03202f;
  font-size: 14px;
  font-weight: 400;
  font-family: "Gotham";
  padding: 4px 5px;
  outline: none;
  border: 1px solid #03202f;
  background: transparent;
  transition: 0.5s all ease-in-out;
  text-transform: uppercase;
  text-decoration: none;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.filled-button {
  background-color: #c0974f;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  font-family: "Gotham";
  cursor: pointer;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  text-align: left;
  padding: 5px 6px;
  display: flex;
  align-items: center;
  border: 1px solid #c0974f;
  text-decoration: none;
  transition: 0.3s all ease-in-out;
}
.filled-button:hover {
  background: #fff;
  color: #03202f;
  transition: all .3s ease-in-out;
  border: 1px solid #03202f;
}
.single-product-delivery-option .add-to-cart-wraper-single-pro .cart-button a {
  background-color: #c0974f;
  color: #ffffff;
  font-family: "Gotham";
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  width: 100%;
  padding: 12px 0px;
  align-items: center;
  justify-content: center;
  display: flex;
  border: 1px solid #c0974f;
  text-decoration: none;
}
.lower-cart-icon.ced {
  text-align: center;
}
.lower-cart-icon.ced .clearance-tag {
  background: #001e42;
  border: 1px dashed #fff;
  padding: 2px 10px;
  cursor: pointer;
  clip-path: polygon(100% 0, 97% 51%, 100% 100%, 0% 100%, 4% 50%, 0% 0%);
}
.lower-cart-icon.ced.listing .clearance-tag {
  width: fit-content;
  margin: 0 auto;
}
.lower-cart-icon.ced.listing .clearance-tag p {
  line-height: 22px;
}
.lower-cart-icon.ced .clearance-tag p {
  color: #fff;
  padding: 0;
  text-transform: uppercase;
  font-family: 'Bookman';
}
.lower-cart-icon.ced a {
  color: #2d58c6;
  font-size: 12px;
  font-weight: 400;
  line-height: 11.48px;
  font-family: "Gotham";
  text-decoration: none;
  padding-top: 9.21px;
  text-decoration: underline;
  text-underline-offset: 2px;
}
.lower-cart-icon.ced .tag {
  width: 100%;
  height: 100%;
  max-width: 192px;
}
.single-product-delivery-option .add-to-cart-wraper-single-pro .cart-button a:hover {
  background-color: #fff;
  color: #171717;
  transition: all 0.5s;
  border: 1px solid #001e42;
}
.single-product-delivery-option .add-to-cart-wraper-single-pro {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding-bottom: 30px;
  padding-top: 30px;
}
.single-product-delivery-option .add-to-cart-wraper-single-pro.unavailable{
  justify-content: left;
}
.single-product-delivery-option .add-to-cart-wraper-single-pro #sinolo {
  border: 1px solid #dedede;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
  width: 100%;
}

.single-product-delivery-option .sinolo-outer {
  width: 29%;
}

.single-product-delivery-option .comp-wish.mobile {
  display: flex;
  width: 100%;
  justify-content: space-between;
  display: none;
}
.single-product-delivery-option .add-to-cart-wraper-single-pro .riht {
  max-width: 50%;
  width: 100%;
}

.single-product-delivery-option .comp-wish {
  display: flex;
  width: 20%;
  justify-content: space-between;
}
.modalHeader.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 20px !important;
  border-bottom: 1px solid #001e42;
  background: #001e42;
}
.cart-page .carousel .slider-wrapper.axis-horizontal .slider .slide {
  flex-flow: nowrap !important;
  height: fit-content;
}
.cart-page .carousel .slider {
  border: 0;
  height: auto !important;
}
.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
  width: 100%;
  display: flex;
  justify-content: center;
}
.text-danger {
  font-family: "Gotham";
  font-size: smaller;
}
.modal-dialog {
  max-width: 550px;
}
.modal-body {
  padding-left: 30px;
  padding-right: 30px;
}
.share-cart-modal .modal-body {
  padding: 26px 40px 44px 40px;
}
.share-cart-modal .modal-header .btn-close {
  margin: 0px;
  position: absolute;
  background: transparent url(https://hallmanimagesnew.s3-accelerate.amazonaws.com/hallmanusa_cms/lower-close.svg);
  background-position: center;
  background-repeat: no-repeat;
  padding: 0px;
  right: 20px;
  opacity: 1;
}
.share-cart-modal .modal-header .share-logo a img {
  margin: auto;
}
.share-cart-modal .modal-header .share-logo {
  display: contents;
}
.share-cart-modal .modal-dialog {
  max-width: 572px;
  top: 62px;
}
.share-cart-modal .modal-content {
  border-radius: 0px !important;
}
.share-cart-modal .modal-body .share-pop h2 { 
  color: #001e42;
  font-family: "Bookman";
  font-size: 25px;
  font-weight: 5;
  line-height: 30.02px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin-bottom: 20px !important;
  text-align: center;
}
.share-cart-modal .modal-body .share-pop-fields label {
  color: #1d1b1b;
  font-family: "Gotham";
  font-size: 14px;
  font-weight: 400;
  line-height: 13.4px;
  text-align: left;
  margin-bottom: 10px;
}
.share-cart-modal .modal-body .share-pop-fields input, .share-cart-modal .modal-body .share-pop-fields select {
  border: 1px solid #dadada;
  padding: 16px;
  border-radius: 0px;
  color: #1d1b1b;
  font-family: "Gotham";
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  width: 100%;
  outline: none;
  background: #fff;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.share-cart-modal .modal-body textarea {
  width: 100%;
  outline: none;
  min-height: 165px;
  padding: 18px;
  color: #1d1b1b;
  font-family: "Gotham";
  font-size: 14px;
  font-weight: 400;
  line-height: 20.4px;
  border: 1px solid #dadada;
}
.share-cart-modal .modal-body button.share-pop-btns {
  width: 100%;
  background: #c0974f;
  color: #fff;
  font-family: "Gotham";
  font-size: 14px;
  font-weight: 500;
  line-height: 13.4px;
  text-align: center;
  padding: 17px 0px;
  border: 1px solid #c0974f;
  outline: none;
  text-transform: uppercase;
  transition: 0.5s all ease-in-out;
}
.share-cart-modal .modal-body button.share-pop-btns:hover {
  color: #001e42;
  border: 1px solid #001e42;
  background: #fff;
  transition: 0.5s all ease-in-out;
}
.share-cart-modal .share-pop-fields sub,
sup {
  position: relative !important;
  font-size: 20px !important;
  line-height: 0;
  color: #EB1C26;
  top: 1px;
}
.share-cart-modal .modal-body .share-pop-fields {
  margin-bottom: 20px;
}
.share-pop-fields .file-uploader .text-file {
  margin-top: 0;
}
.cart-value p {
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  font-family: "Gotham";
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding: 5px 5px 4px 5px;
  border: 2px solid #fff;
}
.single-product-delivery-option .add-to-cart-wraper-single-pro #sinolo input,
.cart-value-increse-decrease input {
  width: 45%;
  border: none;
  text-align: center;
  box-shadow: none;
  outline: 0;
  font-family: "Gotham";
  font-size: 16px;
  font-weight: 400;
  line-height: 15.31px;
  letter-spacing: 0.02em;
}
.cart-value-increse-decrease input {
  width: 100%;
}
.file-uploader label.dzu-inputLabel p {
  color: #1D1D1B;
  font-family: 'Gotham';
  font-size: 14px;
  font-weight: 400;
  line-height: 13.4px;
  text-align: left;
}
.file-uploader span.choosen-files {
  color: #001E42;
  font-family: 'Gotham';
  font-size: 14px;
  font-weight: 500;
  line-height: 13.4px;
  text-align: left;
  border-bottom: 1px solid;
}
.file-uploader label.dzu-inputLabel svg {
  margin-right: 10px;
}
.file-uploader .dzu-dropzone {
  flex-direction: unset;
  min-height: 39px;
}
.single-product-delivery-option .add-to-cart-wraper-single-pro #sinolo .btn-check:focus+.btn,
.btn:focus {
  border: 0;
}
.tow-single-pro-ex-del .extend-warranty-single-pro {
  padding: 29px 0;
}
.tow-single-pro-ex-del .extend-warranty-single-pro h3 {
  font-family: "Bookman";
  font-size: 16px;
  font-weight: 500;
  line-height: 18.14px;
  letter-spacing: 0.02em;
  text-align: left;
  padding-bottom: 20px;
  color: #1d1d1b;
}
.tow-single-pro-ex-del .extend-warranty-single-pro h3 a {
  font-weight: 400;
  font-size: 14px;
  color: #0c4ff9;
  text-decoration: underline;
  font-family: "Gotham";
  text-underline-offset: 2px;
}
.tow-single-pro-ex-del .prop65-main {
  padding: 25px 0 0 0;
  border-top: 1px solid #eaeaea;
}
.tow-single-pro-ex-del .prop65-main .prop65-content {
  display: inline-flex;
  width: 100%;
}
.tow-single-pro-ex-del .prop65-main h3 {
  font-family: "Bookman";
  font-size: 16px;
  font-weight: 500;
  line-height: 21.14px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #1d1d1b;
  width: 70%;
  display: flex;
  align-items: center;
  padding-left: 10px;
}
.tow-single-pro-ex-del .prop65-main a {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  color: #0c4ff9;
  text-decoration: underline;
  font-family: "Gotham";
  text-underline-offset: 2px;
  width: 30%;
  padding-left: 15px;
}
.tow-single-pro-ex-del .prop65-main img{
  cursor: pointer;
}
.extend-warranty-single-pro .extend-by label {
  font-family: "Gotham";
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #1d1d1b;
  padding-left: 10px;
}
.extend-warranty-single-pro .extend-by input[type="checkbox"] {
  width: 18px;
  height: 18px;
  border-radius: 0;
  border: 1px solid #a1a1a1;
  cursor: pointer;
  margin-top: 2px !important;
}
.extend-warranty-single-pro .extend-by input[type="checkbox"]:checked {
  background-color: #c0974f;
  border-color: #c0974f;
}
.extend-warranty-single-pro .checked-extend-single {
  display: grid;
  gap: 15px;
}
/* single-product page css end here */
/* Warrenty-page-css-start */
.limited-warrenty-main {
  padding: 60px 0px 91px 0px;
}
.limited-warrenty-main .warrenty-banner-content h1 {
  color: #ffff;
  font-family: "Bookman";
  font-size: 40px;
  font-weight: 500;
  line-height: 45.36px;
  letter-spacing: 0.05em;
  text-align: center;
  padding-bottom: 12px;
  text-transform: uppercase;
}
.limited-warrenty-main .warrenty-banner-content p {
  color: #ffff;
  font-family: "Gotham";
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0.02em;
  text-align: center;
  width: 60%;
  margin: auto;
}
.we-love-appliances-area {
  max-width: 75%;
  margin: auto;
  width: 100%;
}
.love-appliances-top h2 {
  color: #001e42;
  font-family: "Bookman";
  font-size: 24px;
  font-weight: 5;
  line-height: 58px;
  letter-spacing: 0.05em;
  text-align: left;
  text-transform: uppercase;
}
.love-appliances-top {
  padding-bottom: 50px;
  border-bottom: 1px solid #e4e4e4;
  position: relative;
  z-index: 1;
}
.industry-product-search {
  background: #f1f1f1;
  border: 1px solid #c9c9c9;
}
.industry-product-search input {
  padding: 17.33px 15px;
  max-width: 90%;
  background: none;
  border: none;
  outline: none;
  width: 100%;
  color: #474747;
  font-size: 14px;
  font-weight: 400;
  font-family: "Gotham";
  background: transparent;
}
.industry-product-search input::placeholder {
  color: #474747;
}
span.indus-rgt-search {
  padding-right: 15px;
  cursor: pointer;
  padding-bottom: 2px;
}
.unlock-each-benefits {
  margin-top: 31px;
}
.unlock-each-head h2 {
  color: #001e42;
  font-family: "Bookman";
  font-size: 24px;
  font-weight: 5;
  line-height: 58px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}
.unlock-each-services-list h3 {
  color: #001e42;
  font-family: "Gotham";
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  margin-left: 11.8px;
}
.unlock-each-services-list {
  padding-bottom: 11.25px;
}
.unlock-each-services-list:last-child {
  padding-bottom: 0px;
}
.unlock-each-benefits p {
  color: #1d1d1b;
  font-family: "Gotham";
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
}
.warenty-bold {
  color: #1d1d1b;
  font-family: "Gotham";
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
}
.limited-warranty-image-area {
  margin: 68px 0px;
}
.unlock-each-heading h2 {
  color: #001e42;
  font-family: "Bookman";
  font-size: 24px;
  font-weight: 5;
  line-height: 40px;
  text-transform: uppercase;
  margin-bottom: 7px !important;
}
.unlock-each-services a {
  color: #1977f3;
}
.unlock-each-head-if-part h2 {
  color: #001e42;
  font-family: "Bookman";
  font-size: 24px;
  font-weight: 5;
  line-height: 38px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin-bottom: 7px !important;
  width: 90%;
}
.unlock-each-head-if h2 {
  color: #001e42;
  font-family: "Bookman";
  font-size: 24px;
  font-weight: 5;
  line-height: 38px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin-bottom: 7px !important;
}
.unlock-each-benefit-parts {
  margin-top: 62.14px;
}
.parts-covered ul {
  padding-left: 20px;
}
.parts-covered li {
  list-style-type: disc;
  color: #1d1d1b;
  font-family: "Gotham";
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  padding-bottom: 10px;
}
.unlock-each-heading-small h2 {
  color: #001e42;
  font-family: "Bookman";
  font-size: 24px;
  font-weight: 5;
  line-height: 37px;
  margin-bottom: 5px !important;
}
.unlock-each-benefit-small {
  margin-bottom: 67px;
}
.limited-warranty-image-area img {
  width: 100%;
  height: 100%;
}
.unlock-each-services-small {
  max-width: 100%;
  width: 100%;
}
.unlock-each-services-small p {
  color: #1d1d1b;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  font-family: "Gotham";
}
.main-images {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 393px;
}
/* Warrenty-page-css-end */
/* new-changes-start */
svg.social-circle {
  border: 1px solid #fff;
  border-radius: 50px;
}
svg.social-circle:hover {
  background: #c0974f;
}
nav.navbar.navbar-expand-lg.head-back {
  flex-wrap: nowrap;
  justify-content: flex-start;
  background: #001e42;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 999999;
  height: 86px;
  padding: 0px;
  box-shadow: 0 4px 4px 0px rgb(0 0 0 / 25%);
}
nav.navbar.navbar-expand-lg.head-back ul.navbar-nav {
  display: flex;
  height: 86px;
  align-items: center;
}
nav.navbar.navbar-expand-lg.head-back ul.navbar-nav>li.nav-item {
  display: flex;
  height: 100%;
  align-items: center;
  padding-left: 20px !important;
}
.home-page nav.navbar.navbar-expand-lg.navbar-light.head-back {
  border-bottom: 5px solid #a18b64;
  background: rgb(0 30 66 / 85%) !important;
}
svg.ico-cent {
  margin-bottom: 2px;
}
p.estb {
  color: #fff;
  font-size: 11px;
  line-height: 12.47px;
  text-align: center;
  font-family: "Bookman";
  letter-spacing: 0.1em;
  margin-top: 0px;
}
.next-pages {
  background: #e6e6e6;
  padding: 6px 0px;
}
.next-pages-all {
  display: flex;
  gap: 8px;
}
.next-pages-one a {
  color: #001e42;
  font-size: 13px;
  font-weight: 400;
  line-height: 12.44px;
  letter-spacing: 0.5px;
}
.products-main-list li.dropdown-item.pros-data-list a {
  color: #001f3f !important;
  font-size: 15px;
  font-size: 15px;
  font-weight: 5;
  line-height: 17.01px;
  letter-spacing: 0.05em;
  text-align: left;
  font-family: "Bookman";
  text-transform: uppercase;
}

.products-main-list li.dropdown-item.pros-data-list:first-child {
  padding-top: 0px;
}

.products-main-list li.dropdown-item.pros-data-list {
  padding-top: 20px;
}
/* new-changes-end */
/* 155-result-css-start */
.product-result-amount h1 {
  color: #001e42;
  font-family: "Bookman";
  font-size: 30px;
  font-weight: 500;
  line-height: 28.71px;
  padding-bottom: 13.69px;
}
.product-result-amount p {
  color: #595959;
  font-size: 14px;
  font-weight: 400;
  line-height: 13.4px;
  font-family: "Gotham";
  padding-bottom: 29.79px;
  border-bottom: 1px solid #dedede;
}
.product-result-amount span.gas-range-number {
  color: #001e42;
}
.product-result-amount {
  margin-bottom: 24.42px;
}
.part-1:hover img.Product-list-pic {
  opacity: 0;
}
.extend-by .form-check-input {
  margin-top: 4px !important;
}
img.filter-molile-show {
  width: 17px;
  display: none;
}
.home-finacing {
  gap: 61px;
}
.banner-bottom-section .row>* {
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.bannner-botm-space .home-finacing-txt p {
  color: #001f3f;
  font-size: 16px;
  line-height: 18.14px;
  letter-spacing: 0.04rem;
  font-weight: 400;
  font-family: "Bookman";
}
.bannner-botm-space {
  padding: 30px 18px;
}
.limited-space {
  margin-left: 30px;
}
svg.finace-icon-show {
  display: none;
}
.banner-bottom-section {
  border-bottom: 1px solid #eaeaea;
}
/* Login-page-css-start */
.password-toggle-icon {
  right: 12px;
  position: absolute;
  display: block;
  transform: scale3d(1.5, 1.5, 1.5);
  justify-content: center;
  align-items: center;
  transform: translate(0, -50%);
  top: 50%;
  cursor: pointer;
}
.hallman-Login-top {
  max-width: 493px;
  width: 100%;
  margin: auto;
}
.login-height {
  background-color: #ffff;
}
.row.log-back {
  --bs-gutter-x: 0px;
}
.create-account-select-types h2 {
  color: #001e42;
  font-family: "Bookman";
  font-size: 16px;
  line-height: 18.14px;
  letter-spacing: 0.05em;
  margin-bottom: 20px !important;
}
.create-selected-types label {
  background: #f7f7f7;
  border: 1px solid #e4e4e4;
  padding: 17px 14px;
  cursor: pointer;
}
.create-selected-types {
  margin-bottom: 14px;
}
input.cart-check-select {
  display: none;
}
span.radio-icon {
  height: 15px;
  width: 15px;
  background: #fff;
  border: 1px solid #808fa0;
  border-radius: 50px;
}
.back-page-btn p {
  color: #616161;
  font-family: "Gotham";
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  margin-left: 10px;
}
.create-account-steps {
  margin-top: 70px;
  margin-bottom: 100px;
}
a.sign-in-cont,
.cart-checkout a {
  width: 100%;
  outline: none;
  color: #ffffff;
  font-family: "Gotham";
  font-size: 14px;
  font-weight: 500;
  line-height: 13.4px;
  height: 50px;
  padding: 17px 0px;
  background: #c0974f;
  border: 1px solid #c0974f;
  transition: 0.5s all ease-in-out;
  margin-top: 21px;
}
/* Lower-tag-modal-css-start */
.lower-modals .modal-header .btn-close {
  padding: .5rem .5rem;
  margin: 0px;
  position: absolute;
  right: 9px;
  top: 9px;
}
.lower-modals .modal-content {
  padding: 20px;
}
.lower-modals .modal-dialog {
  max-width: 600px !important;
  margin: 1.75rem auto;
  width: 100%;
}
.lower-level-head p {
  color: #1D1D1B;
  font-family: 'Gotham';
  font-size: 21px;
  font-weight: 500;
  line-height: 13.4px;
  text-align: left;
  margin-top: 20px;
}
span.flat-rate {
  background: #C0974F;
  color: #FFF;
  padding: 0px 7px;
}
.lower-level-content {
  background: #EFEFEF;
  border-radius: 5px;
}
.lower-text-price {
  padding: 20px;
  border-bottom: 1px solid #CECECE;
}
.lower-text-price:last-child {
  border-bottom: 0px;
}
.lower-text-price p {
  color: #1D1D1B;
  font-family: 'Gotham';
  font-size: 16px;
  font-weight: 400;
  line-height: 13.4px;
  letter-spacing: 0.05em;
  text-align: left;
}
.lower-text-price h4 {
  color: #1D1D1B;
  font-family: 'Gotham';
  font-size: 18px;
  font-weight: 500;
  line-height: 13.4px;
  letter-spacing: 0.05em;
  text-align: left;
}
.lower-text-price h4 span {
  text-decoration: line-through;
}
button.Lower-price-btn {
  color: #FFFFFF;
  outline: none;
  border: none;
  background: #C0974F;
  padding: 10px 20px;
  font-family: 'Gotham';
  border: 1px solid #C0974F;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  text-transform: uppercase;
  transition: 0.5s all ease-in-out;
}
button.Lower-price-btn:hover {
  background-color: #fff;
  color: #171717;
  border: 1px solid #C0974F;
  transition: 0.5s all ease-in-out;
}
.modal:not(.removeTopRadius) .modal-content {
  border-radius: .3rem !important;
}
.modalHeader.modal-header {
  border-radius: 0px;
}
.removeTopRadius .modal-content {
  border-top: none !important;
}
a.sign-in-cont:hover,
.cart-checkout a:hover {
  background: #fff;
  border: 1px solid #001e42 !important;
  color: #001e42;
  transition: 0.5s all ease-in-out;
}
.create-selected-types input:checked+label {
  background: #001e42;
}
.create-selected-types input:checked+label .radio-icon {
  height: 15px;
  width: 15px;
  background: #001e42;
  border: 4px solid #ffffff;
  border-radius: 50px;
}
.create-selected-types input:checked+label p {
  color: #fff;
  font-weight: 500;
}
.cart-check-select-all p {
  color: #1d1b1b;
  font-family: "Gotham";
  font-size: 14px;
  font-weight: 400;
  line-height: 13.4px;
  text-align: left;
  margin: 1px 0px 0px 10px;
}
.create-account-heading h1 {
  color: #001e42;
  font-family: "Bookman";
  font-size: 30px;
  font-weight: 5;
  line-height: 34.02px;
  letter-spacing: 0.05em;
  text-align: left;
  text-transform: uppercase;
}
.create-account-heading p {
  color: #1d1d1b;
  font-family: "Gotham";
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  margin-top: 2px;
}
.hallman-Login-top h1 {
  color: #001e42;
  font-family: "Bookman";
  font-size: 30px;
  font-weight: 5;
  line-height: 34.02px;
  letter-spacing: 0.05em;
}
.hallman-Login-fields {
  margin-top: 30px;
}
.hallman-Login-fields label {
  color: #1d1d1b;
  font-family: "Gotham";
  font-size: 14px;
  font-weight: 400;
  line-height: 13.4px;
  text-align: left;
  text-transform: capitalize;
  margin-bottom: 12px;
}
.hallman-Login-fields input {
  background: #fff;
  border: none;
  outline: none;
  border: 1px solid #dadada;
  width: 100%;
  padding: 12px;
  color: #1d1d1b;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: "Gotham";
}
/* hallman-builder-page-css-start */
.pro-dealer-main {
  margin-bottom: 40px;
}
.hallman-builder-banner {
  background-image: url(https://hallmanimagesnew.s3-accelerate.amazonaws.com/header/hallman-builder-bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 410px;
}
.hallman-builder-content {
  padding: 0px 20px;
}
.hallman-builder-content h4 {
  color: #001f3f;
  font-family: "Gotham";
  font-size: 27px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0.05em;
  text-align: center;
  max-width: 92%;
  width: 100%;
  margin: auto;
}
.hallman-builder-content p {
  color: #1d1d1b;
  font-family: "Gotham";
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  width: 91%;
  margin: auto;
  margin-top: 10px;
}
/* hallman-builder-page-css-end */
/* Pro-dealer-page-css-start */
.pro-dealer-banner {
  background-image: url(https://hallmanimagesnew.s3-accelerate.amazonaws.com/header/pro-dealer-bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 410px;
}
.pro-dealer-banner-content h1 {
  color: #ffff;
  font-family: "Bookman";
  font-size: 40px;
  font-weight: 5;
  line-height: 55px;
  letter-spacing: 0.05em;
  text-align: center;
  text-transform: uppercase;
  padding-bottom: 6.41px;
  width: 70%;
  margin: auto;
}
.pro-dealer-banner-content p {
  color: #ffff;
  font-family: "Gotham";
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0.02em;
  text-align: center;
  width: 60%;
  margin: auto;
}
.pro-dealer-banner-btn a {
  background: #c0974f;
  color: #fff;
  font-family: "Gotham";
  font-size: 14px;
  font-weight: 500;
  line-height: 13.4px;
  text-align: left;
  padding: 17px 38px;
  margin-top: 21px;
}
.pro-dealer-top-content {
  padding: 0px 20px;
}
.pro-dealer-top-content h4 {
  color: #001f3f;
  font-family: "Gotham";
  font-size: 27px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0.05em;
  text-align: center;
}
.pro-dealer-top-content p {
  color: #1d1d1b;
  font-family: "Gotham";
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  width: 91%;
  margin: auto;
  margin-top: 10px;
}
.pro-dealer-mid-images {
  width: 85%;
  margin: auto;
  padding: 30px 0px 0px 0px;
}
.mid-images-top {
  top: 16px;
  right: 16px;
}
.mid-images-top img {
  width: 157px !important;
}
.mid-images-main img {
  transition: 300ms ease-in-out;
  width: 100%;
}
.mid-images-main:hover img {
  transform: scale(1.05);
  transition: 300ms ease-in-out;
}
.file-uploader .text-file {
  border: none;
  outline: none;
  border: 1px dashed #dadada;
  width: 100%;
  padding: 7px;
  color: #1d1d1b;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: "Gotham";
  margin: 12px 0px 15px 0px;
  border-radius: 4px;
  min-height: 100px;
}
.my-images-pop .file-uploader .text-file {
  margin: 0;
}
.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.file-choosen {
  color: #707070;
  font-family: "Gotham";
  font-size: 10px;
  font-weight: 600;
  line-height: 9.57px;
  text-align: left;
  border: 1px solid #9b9b9b;
  padding: 3px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
}
.text-file-right p {
  color: #707070;
  font-family: "Gotham";
  font-size: 12px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  margin-right: 18px;
}
.file-uploader label.control-label.file_up {
  width: 100%;
  color: #1d1d1b;
  font-family: "Gotham";
  font-size: 14px;
  font-weight: 400;
  line-height: 13.4px;
  text-align: left;
}
.design-arch-banner {
  background-image: url(https://hallmanimagesnew.s3-accelerate.amazonaws.com/header/design-arch-bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 410px;
}
.benefites-of-dealer {
  padding: 93px 0px 91px 0px;
  background: #001e42;
  margin: 112px 0px 91px 0px;
}
.benefits-of-main {
  padding: 0px 20px;
}
.benefits-of-main h4 {
  color: #ffffff;
  font-family: "Bookman";
  font-size: 35px;
  font-weight: 5;
  line-height: 50px;
  letter-spacing: 0.05em;
  text-align: left;
  text-transform: uppercase;
  max-width: 944px;
  margin: auto;
  width: 100%;
}
.benefits-of-dealer-list ul {
  padding-left: 20px;
}
.benefits-of-dealer-list ul li {
  color: #fff;
  font-family: "Gotham";
  font-size: 16px;
  font-weight: 500;
  line-height: 15.31px;
  text-align: left;
  margin-top: 30px;
}
.benefits-of-dealer-list p {
  color: #fff;
  font-family: "Gotham";
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  margin-top: 12px;
}
.benefits-of-dealer-list ul li::marker {
  display: none;

}
.benefits-of-dealer-list ul li {
  position: relative;

}
.benefits-of-dealer-list ul li:before {
  content: '';
  position: absolute;
  height: 5.92px;
  width: 5.92px;
  background: #C0974F;
  border-radius: 50px;
  left: -18px;
  top: 5px;
}
.ready-to-get-started {
  margin-bottom: 100px;
  background-color: #fff;
}
.ready-to-get-started .ready-get-head h4 {
  color: #001e42;
  font-family: "Bookman";
  font-size: 40px;
  font-weight: 5;
  line-height: 53px;
  letter-spacing: 0.05em;
  text-align: left;
  width: 100%;
  margin-bottom: 14.57px !important;
}
.ready-get-head {
  padding: 0px 20px;
}
.ready-to-get-started .get-ready-fields label {
  color: #1d1d1b;
  font-family: "Gotham";
  font-size: 14px;
  font-weight: 400;
  line-height: 13.4px;
  text-align: left;
  margin-bottom: 12px;
}
.ready-to-get-started .get-ready-fields input {
  background: #fff;
  border: none;
  outline: none;
  border: 1px solid #dadada;
  width: 100%;
  padding: 12px;
  color: #1d1d1b;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: "Gotham";
}
.ready-to-get-started .get-ready-fields {
  margin-bottom: 30px;
}
.get-ready-fields .form-select.equal {
  padding: 10px;
  border-radius: unset;
  width: 100%;
  padding: 12px;
  color: #1d1d1b;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: "Gotham";
}
.get-ready-fields .form-select:focus {
  border-color: #dadada;
  outline: 0;
  box-shadow: none;
}
.by-using .pass {
  gap: 8px;
}
.by-using input[type="checkbox"] {
  max-width: 15.11px;
  height: 15.11px;
}
.pass label.form-check-label {
  color: #383838;
  font-family: "Gotham";
  font-size: 14px;
  font-weight: 400;
  line-height: 13.4px;
  text-align: left;
}
.by-using .pass input[type="checkbox"]:checked {
  background-color: #c0974f;
  border-color: #c0974f;
}
button.pro-account-create {
  background: #c0974f;
  font-family: "Gotham";
  font-size: 14px;
  font-weight: 600;
  line-height: 13.4px;
  text-align: left;
  color: #ffffff;
  border: none;
  outline: none;
  padding: 17px 39px;
  margin-top: 23px;
}
/* Pro-dealer-page-css-end */
/* hallman-builder-page-css-start */
.design-arch-banner {
  background-image: url(https://hallmanimagesnew.s3-accelerate.amazonaws.com/header/design-arch-bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 410px;
}
/* hallman-builder-page-css-end */
span.mendatory-fields {
  color: #eb1c26;
}
.remember-forget-password {
  margin-top: 20px;
}
.remember-forget-password input[type="checkbox"] {
  width: 15.11px;
  height: 15.11px;
  border-color: #969696;
}
.remember-forget-password .pass input[type="checkbox"]:checked {
  background-color: #c0974f;
  border-color: #c0974f;
}
.form-check-input {
  margin-top: 0px !important;
}
.remember-forget-password .pass {
  gap: 8px;
}
.pass label.form-check-label {
  color: #8a8a8a;
  font-family: "Gotham";
  font-size: 14px;
  font-weight: 400;
  line-height: 13.4px;
  text-align: left;
}
.forget-password a {
  color: #001e42;
  font-family: "Gotham";
  font-size: 14px;
  font-weight: 400;
  line-height: 13.4px;
  text-decoration: underline;
  text-underline-offset: 3px;
}
.halman-signin-btn {
  padding: 43px 0px 50px;
  border-bottom: 1px solid #dadada;
}
button.hallman-signin-btn {
  background: #c0974f;
  border: 1px solid #c0974f !important;
  width: 100%;
  border: none;
  outline: none;
  color: #fff;
  font-family: "Gotham";
  font-size: 14px;
  font-weight: 500;
  line-height: 13.4px;
  text-align: center;
  padding: 18px 0px;
  text-transform: uppercase;
  transition: 0.5s all ease-in-out;
}
button.hallman-signin-btn:hover {
  background-color: #fff;
  border: 1px solid #001e42 !important;
  color: #001e42;
  transition: 0.5s all ease-in-out;
}
.hallman-Login-bottom {
  margin: 50px 0px 0px 0px;
}
.hallman-Login-bottom h2 {
  color: #1d1b1b;
  font-family: "Bookman";
  font-size: 14px;
  font-weight: 5;
  line-height: 15.88px;
  text-transform: uppercase;
  margin-bottom: 24px !important;
}
.hallman-Login-bottom a {
  display: block;
  background: #fff;
  border: 1px solid #99a5b3;
  padding: 17px 0px;
  color: #001e42;
  font-family: "Gotham";
  font-size: 14px;
  font-weight: 500;
  line-height: 13.4px;
  text-transform: uppercase;
  transition: 0.5s all ease-in-out;
}
.hallman-Login-bottom a:hover {
  background: #c0974f;
  color: #fff;
  border: 1px solid transparent;
  transition: 0.5s all ease-in-out;
}
.hallman-Login-right {
  background-image: url(https://hallmanimagesnew.s3-accelerate.amazonaws.com/header/login-right.webp);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 800px;
}
.hallman-Login-right img {
  height: 100%;
  width: 100%;
}
.Toastify__close-button {
  z-index: 9999;
}
/* Login-page-css-end */
/* Cart-page-css-start-here */
.cart-main-section {
  padding: 52px 0px;
  min-height: 500px;
}
.get-new-need {
  padding-bottom: 52px;
}
.cart-section-title {
  gap: 12px;
  padding-bottom: 18.86px;
  border-bottom: 1px solid #eaeaea;
}
.cart-items-main {
  padding: 50px 0px 40px 0px;
}

.cart-main-section .cart-items-main {
  border-bottom: 1px solid #eaeaea;
}
.cart-select-area .cart-section-title h1 {
  color: #001e42;
  font-family: "Bookman";
  font-size: 35px;
  font-weight: 400;
  line-height: 39.69px;
  letter-spacing: 0.05em;
  text-align: left;
}
.cart-select-area .cart-section-title p {
  color: #1d1d1b;
  font-family: "Gotham";
  font-size: 16px;
  font-weight: 400;
  text-align: left;
}
.cart-select-left-heading h2 {
  color: #022741;
  font-family: "Gotham";
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
}
.cart-select-left-heading p {
  color: #1d1d1b;
  font-size: 14px;
  font-weight: 400;
  line-height: 12.44px;
  padding-bottom: 16.36px;
  font-family: "Gotham";
}
.cart-select-left-delivery-option h2.deliveries {
  font-family: "Gotham";
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #001e42;
  padding-bottom: 5px;
}
.cart-select-left-delivery-option .deliveries a {
  text-decoration: underline;
  color: #2d58c6;
  font-weight: 400;
}
.cart-select-left-images {
  height: 64.76px;
  max-width: 64.76px;
  width: 100%;
}
.cart-select-left-images img {
  max-width: 100%;
  height: 100%;
  object-fit: cover;
}
.cart-select-main-area .cart-content-gap {
  gap: 27.46px;
}
.cart-select-left-delivery-option .cart-checking .cart-check-input:checked {
  background-color: #ffffff;
  border-color: #c0974f;
  box-shadow: none;
  border: 4px solid #c0974f;
  border-radius: 50px !important;
}
.cart-checking input {
  cursor: pointer;
  height: 15px;
  width: 15px;
}
s .form-checking .form-check-input:checked {
  background-color: #c0974f;
  border-color: #c0974f;
  box-shadow: none;
}
.cart-check-input {
  width: 18px;
  height: 18px;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  border-radius: 50px;
}
.cart-select-left-delivery-option .cart-checking label h2 {
  font-family: "Gotham";
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #001e42;
}
.cart-checking {
  gap: 5.35px;
}
.cart-select-left-delivery-option .cart-checking label h2 span {
  font-family: "Gotham";
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #0a8035;
}
.cart-schedule-date p {
  color: #1d1d1b;
  font-family: Gotham;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin-left: 19px;
}
.cart-select-left-delivery-options h2.deliveries-extend {
  font-family: "Gotham";
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #001e42;
}
.checked-extend-order-cps {
  gap: 23px;
  flex-wrap: wrap;
}
.cart-select-left-delivery-options .cps-by label {
  color: #1d1d1b;
  font-family: "Gotham";
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.02em;
  line-height: 24px;
  text-align: left;
}
.cart-select-left-delivery-options .cps-by input[type="checkbox"]:checked {
  background-color: #c0974f;
  border-color: #c0974f;
}
.cart-select-left-delivery-options {
  margin-top: 16.9px;
  margin-left: 15px;
}
.cps-by {
  gap: 10px;
  padding-top: 11px;
}
.cart-value-define {
  gap: 13.6px;
}
.cart-value-define .cart-value-text-area p {
  color: #1d1d1b;
  font-family: "Gotham";
  font-size: 14px;
  font-weight: 400;
  line-height: 13.4px;
  letter-spacing: 0.02em;
}
.cart-value-define .cart-value-increse-decrease {
  border: 1px solid #dedede;
  width: 99px;
  padding: 9px 0px;
}
button.cart-close-btn {
  border: 1px solid #dedede;
  padding: 11px;
  background: transparent;
  width: 35px;
}
button.cart-value-decrease-btn {
  background: transparent;
  border: none;
  outline: none;
  max-width: 33.33%;
  width: 100%;
}
.cart-value-text-area {
  max-width: 33.33%;
  width: 100%;
}
button.cart-value-increase-btn {
  background: transparent;
  border: none;
  outline: none;
  max-width: 33.33%;
  width: 100%;
}
.cart-subtotal-rating h2 {
  color: #001e42;
  font-family: "Bookman";
  font-size: 20px;
  font-weight: 500;
  line-height: 22.68px;
  letter-spacing: 0.05em;
  text-align: left;
}
p.cart-line-through {
  font-family: "Bookman";
  font-size: 12px;
  font-weight: 500;
  line-height: 13.61px;
  letter-spacing: 0.05em;
  text-align: left;
  text-decoration: line-through;
  margin-top: 3.88px;
}
.cart-share-section {
  padding-top: 19px;
  position: relative;
}
.cart-share-section:after {
  /* content: ""; */
  position: absolute;
  height: 1px;
  width: 97%;
  margin: auto;
  background: #eaeaea;
  top: 0px;
  left: 0;
  right: 0;
}
/* .cart-share-section::before {
  content: "";
  position: absolute;
  height: 1px;
  width: 97%;
  margin: auto;
  background: #eaeaea;
  bottom: 0px;
  left: 0;
  right: 0;
} */
.cart-page ul li.slide {
  justify-content: space-evenly;
}
.share-cart a {
  color: #2d58c6;
  font-family: "Gotham";
  font-size: 14px;
  font-weight: 500;
  line-height: 13.4px;
  letter-spacing: 0.02em;
  margin-left: 3.23px;
  text-decoration: underline;
  text-underline-offset: 2px;
}
.cart-page button.carousel-control-next.false {
  position: absolute;
  right: -4%;
}
.cart-page button.carousel-control-prev {
  position: absolute;
  left: -3%;
}
/* order-summary-css-start */
.order-summary-content {
  width: 88%;
  height: fit-content;
}
.order-summary-content-top {
  padding: 30px;
  background: #efefef;
}
.order-summary-head {
  border-bottom: 1px solid #001e42;
}
.order-summary-content .order-summary-head h2 {
  color: #001e42;
  font-family: "Bookman";
  font-size: 20px;
  font-weight: 5;
  line-height: 22.68px;
  letter-spacing: 0.05em;
  padding-bottom: 12.62px;
  text-transform: uppercase;
}
.order-summary-subtotal .subtotal-types h2 {
  color: #1d1d1b;
  font-family: "Gotham";
  font-size: 14px;
  font-weight: 500;
  line-height: 13.4px;
  text-transform: uppercase;
}
.order-summary-subtotal .subtotal-types p {
  color: #1d1d1b;
  font-family: "Gotham";
  font-size: 14px;
  font-weight: 400;
  line-height: 13.4px;
  letter-spacing: 0.05em;
}
.order-summary-subtotal {
  padding-bottom: 32px;
  border-bottom: 1px solid #cecece;
}
.subtotal-types {
  margin-top: 32px;
}
.subtotal-flat-txt p {
  color: #1d1d1b;
  font-family: "Gotham";
  font-size: 12px;
  font-weight: 400;
  line-height: 11.48px;
  margin-top: 6.27px;
}
.summary-today-order-text p {
  color: #0a8035;
  font-family: "Gotham";
  font-size: 13px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0.02em;
  width: 70%;
}
svg.summary-tick {
  margin-top: 3px;
}
.summary-today-order-text {
  gap: 6.36px;
  padding: 19.5px 0px 14.5px 0px;
  border-bottom: 1px solid #cecece;
}
.order-summary-estimated .order-estimated-text h2 {
  color: #001e42;
  font-family: "Gotham";
  font-size: 16px;
  font-weight: 500;
  line-height: 15.31px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}
.order-summary-estimated .order-estimated-text p {
  color: #2d58c6;
  font-family: "Gotham";
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.02em;
  margin-top: 6.64px;
  border-bottom: 1px solid #2d58c6;
}
.order-summary-estimated .order-estimated-price h3 {
  color: #001e42;
  font-family: "Bookman";
  font-size: 22px;
  font-weight: 500;
  line-height: 24.95px;
  letter-spacing: 0.05em;
}
.order-summary-estimated {
  padding-top: 30px;
}
/* order-summary-css-end */
.order-summary-content-bottom .cart-by input[type="checkbox"]:checked {
  background-color: #c0974f;
  border-color: #c0974f;
}
.order-summary-content-bottom .cart-by label {
  color: #1d1d1b;
  font-family: "Gotham";
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.02em;
  line-height: 24px;
  text-align: left;
}
.checked-extend-order {
  gap: 23px;
  flex-wrap: wrap;
  padding: 20px 0px;
}
.cart-by {
  gap: 10px;
  padding-bottom: 23px;
}
.cart-by:last-child {
  padding-bottom: 0px;
}
.cart-checkout button.cart-secure-checkout {
  background: #c0974f;
  font-family: "Gotham";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  padding: 13px 0px;
  color: #fff;
  gap: 7px;
}
/* Bottom-section-css-start */
.get-new-need .our-single-product-seller {
  text-align: center;
}
section.get-new-need {
  padding-bottom: 96px;
}
.cart-product {
  padding: 0px !important;
}
.form-check-input[type="checkbox"] {
  border-radius: 0px !important;
}
/* Cart-page-css-start-end */
/* Payment-types-css-start */
.pay-back {
  background: #fafafa;
  padding: 20px 13px;
  border: 1px solid #eaeaea;
}
.payment-methods-section {
  margin-top: 35px;
}
.row.payment-methods-all.bottom-space {
  margin-bottom: 20px;
}
.payment-methods-section .card-types img {
  width: 100%;
}
.payment-methods-section h2 {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #001e42 !important;
  font-family: "Bookman";
  text-transform: uppercase;
}
.row.payment-methods-all {
  padding: 10px;
}
.payment-methods-all .row>* {
  padding-right: 6px;
  padding-left: 6px;
}
.payment-methods-all .pay-back .single-des-one {
  padding-bottom: 20px;
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 20px;
}
.payment-methods-all .pay-back .single-product-des {
  display: flex;
  justify-content: space-between;
}
.single-product-table .single-product-hr{
  border-top: 1px solid #a7a7a7;
}
.payment-methods-all .table-body {
  padding: 0px;
  background-color: #fafafa;
}
.pay-back .bread-pay-text h2 {
  font-size: 12px;
  font-weight: 400;
  line-height: 11.48px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #001e42;
  font-family: "Gotham";
  padding-bottom: 5.66px;
  text-transform: capitalize;
}
.pay-back .bread-pay-text p {
  font-size: 12px;
  font-weight: 500;
  line-height: 11.48px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #1d1d1b;
  font-family: "Gotham";
}
.payment-methods-all .pay-back .single-product-des .logo {
  max-width: 30%;
  width: 100%;
  position: relative;
}
.pay-back .bread-pay-text {
  max-width: 60%;
  width: 100%;
}
.pay-links {
  max-width: 20%;
  width: 100%;
}
.pay-back .logo:after {
  content: "";
  background-color: #e3e3e3;
  height: 31px;
  width: 2px;
  position: absolute;
  right: 16px;
}
.pay-links a {
  font-size: 12px;
  font-weight: 400;
  color: #0c4ff9;
  line-height: 11.48px;
  border-bottom: 1px solid #2d58c6;
  font-family: "Gotham";
}

#home-page-header.blur,
#home-page-header.blur button {
  filter: blur(5px);
  pointer-events: none;
}
.cart-checkout a:hover {
  background-color: #fff;
  border: 1px solid #99a5b3 !important;
  color: #001e42;
  transition: all .5s ease-in-out;
}
.cart-checkout a:hover>p {
  color: #022741;
}
.cart-checkout a:hover>svg>path {
  fill: #022741;
}
.cart-checkout a p {
  color: #fff;
  font-family: "Gotham";
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
  margin-left: 8px;
}
/* Payment-types-css-start */
.hallman-signin-one img {
  height: 100%;
  width: 100%;
}
.hallman-signin-one.log {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}
/* cart-header-css-start */
.cart-page-header {
  background: #001e42;
  padding: 15px 0px;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 999;
}
/* cart-header-css-end */
.offcanvas.offcanvas-end {
  top: 85px !important;
  right: 0 !important;
  width: 500px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(100%);
  transition: all 0.3s ease-in-out;
}
.cps-by .form-check-input {
  margin-top: 5px !important;
  border-color: #a1a1a1;
}
/* online-dealer-page-css-start */
.online-dealer-banner {
  background-image: url(https://hallmanimagesnew.s3-accelerate.amazonaws.com/header/online-dealer-bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 410px;
}
.pro-dealer-banner-content sup {
  top: -25px;
  display: inline-block;
}
.pro-dealer-banner-content sub,
sup {
  position: relative;
  font-size: .75em;
  line-height: 0;
}
.shop-with-confidence {
  padding: 60px 0px 0px 0px;
}
.shop-confidence-top-text h2.warty {
  color: #B98426;
  font-family: 'Bookman';
  font-size: 20px;
  font-weight: 5;
  line-height: 22.68px;
  letter-spacing: 0.05em;
  text-align: center;
  margin-top: 31px;
}
.shop-confidence-top-text h2 {
  color: #001E42;
  font-family: 'Bookman';
  font-size: 30px;
  font-weight: 5;
  line-height: 34.02px;
  letter-spacing: 0.05em;
  text-align: center;
  text-transform: uppercase;
  padding: 10px 0px;
}
.shop-confidence-top-text p {
  color: #001F3F;
  font-family: 'Gotham';
  font-size: 16px;
  font-weight: 500;
  line-height: 15.31px;
  letter-spacing: 0.05em;
  text-align: center;
}
.shop-confidence-logos img {
  height: 100%;
}
.shop-conf-text p {
  color: #1D1D1B;
  font-family: 'Gotham';
  font-size: 16px;
  font-weight: 500;
  line-height: 15.31px;
  letter-spacing: 0.05em;
  padding-top: 17px;
  padding-bottom: 42px;
}
.shop-confidence-logos img {
  transition: 300ms ease-in-out;
}
.shop-confidence-logos:hover img {
  transform: scale(1.05);
  transition: 300ms ease-in-out;
}
.confidence-logo-section {
  padding: 40px 0px 56px 0px;
  border-bottom: 1px solid #DEDEDE;
}
.home-new-product-arriving-section.become-dealer {
  padding: 97px 0px 100px 0px;
  overflow-x: hidden;
}
.become-dealer h2 {
  color: #001E42;
  font-family: 'Bookman';
  font-size: 30px;
  font-weight: 5;
  line-height: 34.02px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  padding-bottom: 30px;
}
.home-new-product-arriving-section.become-dealer .register-detail p {
  color: #FFFFFF;
  font-family: 'Gotham';
  font-size: 16px;
  font-weight: 300;
  line-height: 25px;
  text-align: left;
  width: 70%;
  margin-bottom: 26px;
}
.home-new-product-arriving-section.become-dealer a.see-detail {
  background: transparent;
  border: none;
  font-family: 'Gotham';
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  padding: 0px;
  width: fit-content;
  text-transform: capitalize;
  text-decoration: underline;
  text-underline-offset: 3px;
  text-decoration-color: rgb(255 255 255 / 40%);
  color: #fff;
}
.home-new-product-arriving-section.become-dealer .register-detail {
  padding: 77px 50px;
}
.logo-border a {
  border-radius: 2px;
}
.shop-with-confidence .shop-confidence-logos.logo-border {
  border: 1px solid #7b7b7b;
  line-height: 0px;
}
.home-new-product-arriving-section .dealer-detail h2 {
  font-size: 25px;
  font-weight: 500;
  line-height: 40px;
  color: #FFFFFF;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  text-align: left;
  padding-bottom: 22px;
  font-family: 'Bookman';
  width: 85%;
}
.col-sm-6.became-dealing img {
  height: 100%;
}
.home-new-product-arriving-section.become-dealer .dealer-detail {
  padding: 77px 50px;
}
.home-new-product-arriving-section .dealer-detail {
  padding-left: calc((100vw - 1364px) / 2);
}
.home-new-product-arriving-section.become-dealer .dealer-detail p {
  color: #FFFFFF;
  font-family: 'Gotham';
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  width: 70%;
  margin-bottom: 26px;
}
/* online-dealer-page-css-end */
.file-uploader .dzu-previewContainer {
  padding: 0px 20px;
  display: flex;
  flex-direction: row;
  min-height: 30px;
}
.file-uploader .details-info-form .dzu-dropzone {
  color: #707070;
  font-family: 'Gotham';
  font-size: 10px;
  font-weight: 600;
  line-height: 9.57px;
  text-align: left;
  min-height: 30px;
}
.confirming .modal-content {
  padding: 30px 10px;
  max-width: 460px;
  margin: auto;
  width: 100%;
}
.popup-content.confir p {
  color: #000000;
  font-family: 'Gotham';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.06em;
  text-align: center;
}
.single-product-delivery-option .add-to-cart-wraper-single-pro .wishbtn {
  width: 50px;
  height: 50px;
  border: 1px solid #B8B8B8;
  padding: 10px;
  cursor: pointer;
}
.single-product-delivery-option .add-to-cart-wraper-single-pro .compare-outer button {
  outline: none;
  border: none;
}
.single-product-delivery-option .add-to-cart-wraper-single-pro .wishbtn-outer button {
  outline: none;
  border: none;
}
path.wishlist-icon {
  fill: none;
  stroke: #000000;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.pagination-container {
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.most-common-question {
  margin-bottom: 97px;
}
/* About us page css-start */
.About-banner {
  background-image: url(https://hallmanimagesnew.s3-accelerate.amazonaws.com/hallmanusa_cms/about-banner-1.webp);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 50px 0px;

}
.About-banner .abt-content {
  max-width: 1120px;
  margin: auto;
}
.About-banner .abt-content p.top {
  color: #001E42;
  font-family: 'Gotham';
  font-size: 16px;
  font-weight: 500;
  line-height: 15.31px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  position: relative;
}
.About-banner .abt-content p.top:before {
  content: '';
  position: absolute;
  height: 2px;
  background: #001E42;
  width: 15px;
  top: 40%;
  transform: translateX(-25px);
}
.About-banner .abt-content p.top:after {
  content: '';
  position: absolute;
  height: 2px;
  background: #001E42;
  width: 15px;
  top: 40%;
  transform: translateX(10px);
}
.About-banner .abt-content h1 {
  color: #001E42;
  font-family: 'Bookman';
  font-size: 50px;
  font-weight: 5;
  line-height: 65px;
  letter-spacing: 0.05em;
  text-align: center;
  text-transform: uppercase;
  margin: 34px 0px 13px 0px;
}
.About-banner .abt-content p {
  color: #1D1D1B;
  font-family: 'Bookman';
  font-size: 27px;
  font-weight: 5;
  line-height: 49px;
  letter-spacing: 0.05em;
  text-align: center;
}
.abt-parts-main {
  background-image: url(https://hallmanimagesnew.s3-accelerate.amazonaws.com/hallmanusa_cms/about-banner-1.png);
  padding-top: 37px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
span.top-star {
  left: 50.2%;
  transform: translateX(-50%);
  z-index: 1;
}
.abt-parts-main .abt-timeline img {
  max-width: 100%;
  height: 100%;
  padding-top: 60px;
}
.abt-parts-main .abt-month {
  top: 0;
  left: 36%;
  transform: translateX(-36%);
}
.abt-parts-main .abt-month h2 {
  color: #001E42;
  font-family: 'Bookman';
  font-size: 50px;
  font-weight: 5;
  line-height: 56.7px;
  text-align: center;
}
.abt-parts-main:after {
  content: '';
  position: absolute;
  width: 9.33px;
  height: 688.48px;
  height: 100%;
  background: #C2D6EF;
  transform: translateX(-50%);
  left: 50.2%;
  top: 60px;
}
.abt-parts-main span.abt-star {
  z-index: 1;
}
.abt-parts-one .abt-part {
  max-width: 1100px;
  width: 100%;
  margin: auto;
}
.abt-parts-one .moves-to-image img {
  height: 100%;
  width: 100%;
}
.abt-parts-one {
  background-image: url(https://hallmanimagesnew.s3-accelerate.amazonaws.com/hallmanusa_cms/about-banner-1.png);
  padding: 105px 0px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.abt-parts-one:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50.2%;
  background: #C2D6EF;
  height: 20%;
  transform: translateX(-50%);
  width: 9.33px;
}
.abt-parts-one:before {
  content: '';
  position: absolute;
  top: 0;
  left: 50.2%;
  background: #C2D6EF;
  height: 20%;
  transform: translateX(-50%);
  width: 9.33px;
}
.abt-parts-one .moves-to-text {
  max-width: 400px;
  width: 100%;
}
.abt-parts-one .moves-to-text h2 {
  color: #001E42;
  font-family: 'Bookman';
  font-size: 56px;
  font-weight: 5;
  line-height: 64px;
  letter-spacing: 0.05em;
  text-align: left;
}
.abt-parts-one .moves-to-text h3 {
  font-family: 'Bonanova';
  font-size: 56px;
  font-style: italic;
  font-weight: 400;
  line-height: 64px;
  letter-spacing: 0.05em;
  text-align: left;
  color: #001E42;
}
.abt-parts-one .moves-to-texts {
  margin: 26px 0px 0px;
}
.abt-parts-one .moves-to-texts p {
  color: #1D1D1B;
  font-family: 'Gotham';
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  padding-left: 18px;
  max-width: 75%;
  width: 100%;
}
.abt-parts-one .moves-to-texts p:after {
  content: '';
  position: absolute;
  width: 6.37px;
  height: 100%;
  background: #001E42;
  left: 0;
  top: 0;
}
/* Part-two-start */
.abt-parts-two {
  background-image: url(https://hallmanimagesnew.s3-accelerate.amazonaws.com/hallmanusa_cms/about-banner-1.png);
  padding: 74px 0px 119px 0px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.abt-parts-two:after {
  content: '';
  position: absolute;
  height: 100%;
  width: 9.33px;
  background: #C2D6EF;
  left: 50.2%;
  transform: translateX(-50%);
  top: 0;
}
.abt-parts-two span.mid-star {
  left: 50.2%;
  transform: translateX(-50%);
  z-index: 1;
  top: 20px;
}
.abt-parts-two .abt-part {
  max-width: 1100px;
  width: 100%;
  margin: auto;
}
.abt-parts-two .moves-to-text h2 {
  color: #001E42;
  font-family: 'Bookman';
  font-size: 56px;
  font-weight: 5;
  line-height: 64px;
  letter-spacing: 0.05em;
  text-align: left;
}
.abt-parts-two .moves-to-text h3 {
  font-family: 'Bonanova';
  font-size: 56px;
  font-style: italic;
  font-weight: 400;
  line-height: 64px;
  letter-spacing: 0.05em;
  text-align: left;
  color: #001E42;
}
.abt-parts-two .moves-to-texts {
  margin: 26px 0px 38px;
}
.abt-parts-two .moves-to-texts p {
  color: #1D1D1B;
  font-family: 'Gotham';
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  padding-left: 18px;
  max-width: 75%;
  width: 100%;
}
.abt-parts-two .moves-to-texts p:after {
  content: '';
  position: absolute;
  width: 6.37px;
  height: 100%;
  background: #001E42;
  left: 0;
  top: 0;
}
.abt-parts-two .moves-to-image.right {
  position: relative;
  right: 70px;
  z-index: 1;
}
.abt-parts-two .moves-to-image.right img {
  height: 100%;
  width: 100%;
}
.abt-parts-one span.mid-star {
  content: '';
  left: 50.2%;
  transform: translateX(-50%);
}
/* Part-two-end */
/* part-three-start */
.abt-parts-three {
  background-image: url(https://hallmanimagesnew.s3-accelerate.amazonaws.com/hallmanusa_cms/about-banner-1.png);
  padding: 105px 0px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.abt-parts-three:before {
  content: '';
  position: absolute;
  top: 0;
  left: 50.2%;
  background: #C2D6EF;
  height: 27%;
  transform: translateX(-50%);
  width: 9.33px;
}
.abt-parts-three:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50.2%;
  background: #C2D6EF;
  height: 27%;
  transform: translateX(-50%);
  width: 9.33px;
}
.abt-parts-three .abt-part {
  max-width: 1100px;
  width: 100%;
  margin: auto;
}
.abt-parts-three span.mid-star {
  content: '';
  left: 50.2%;
  transform: translateX(-50%);
  top: -15%;
}
.abt-parts-three .moves-to-image img {
  height: 100%;
  width: 100%;
}
.abt-parts-three .moves-to-text h2 {
  color: #001E42;
  font-family: 'Bookman';
  font-size: 56px;
  font-weight: 5;
  line-height: 64px;
  letter-spacing: 0.05em;
  text-align: left;
}
.abt-parts-three .moves-to-text span {
  font-family: 'Bonanova';
  font-size: 56px;
  font-style: italic;
  font-weight: 400;
  line-height: 64px;
  letter-spacing: 0.05em;
  text-align: left;
}
.abt-parts-three .moves-to-text h3 {
  font-family: 'Bonanova';
  font-size: 56px;
  font-style: italic;
  font-weight: 400;
  line-height: 64px;
  letter-spacing: 0.05em;
  text-align: left;
  color: #001E42;
}
.abt-parts-three .moves-to-texts {
  margin: 26px 0px 38px;
}
.abt-parts-three .moves-to-texts p {
  color: #1D1D1B;
  font-family: 'Gotham';
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  padding-left: 18px;
  max-width: 75%;
  width: 100%;
}
.abt-parts-three .moves-to-texts p:after {
  content: '';
  position: absolute;
  width: 6.37px;
  height: 100%;
  background: #001E42;
  left: 0;
  top: 0;
}
.moves-to-image {
  padding-right: 20px;
}
/* part-three-end */
/* part-four-start */
.abt-parts-four {
  background-image: url(https://hallmanimagesnew.s3-accelerate.amazonaws.com/hallmanusa_cms/about-banner-1.png);
  padding: 109px 0px 84px 0px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.abt-parts-four:after {
  content: '';
  position: absolute;
  height: 100%;
  width: 9.33px;
  background: #C2D6EF;
  left: 50.2%;
  transform: translateX(-50%);
  top: 0;
}
.abt-parts-four .abt-part {
  max-width: 1100px;
  width: 100%;
  margin: auto;
}
.abt-parts-four span.mid-star {
  left: 50.2%;
  transform: translateX(-50%);
  z-index: 1;
  top: -15%;
}
.abt-parts-four .moves-to-text h2 {
  color: #001E42;
  font-family: 'Bookman';
  font-size: 56px;
  font-weight: 5;
  line-height: 64px;
  letter-spacing: 0.05em;
  text-align: left;
}
.abt-parts-four .moves-to-text h3 {
  font-family: 'bonanova';
  font-size: 56px;
  font-style: italic;
  font-weight: 400;
  line-height: 64px;
  letter-spacing: 0.05em;
  text-align: left;
  color: #001E42;
}
.abt-parts-four .moves-to-texts {
  margin: 26px 0px 38px;
}
.abt-parts-four .moves-to-texts p {
  color: #1D1D1B;
  font-family: 'Gotham';
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  padding-left: 18px;
  max-width: 65%;
  width: 100%;
}
.abt-parts-four .moves-to-texts p:after {
  content: '';
  position: absolute;
  width: 6.37px;
  height: 100%;
  background: #001E42;
  left: 0;
  top: 0;
}
.abt-parts-four .moves-to-image.right {
  position: relative;
  z-index: 1;
  margin-left: -50px;
}
.abt-parts-four .moves-to-image.right img {
  height: 100%;
  width: 100%;
}
.abt-parts-four ul.abt-listing {
  padding-left: 20px;
  margin-top: 20px;
}
.abt-parts-four ul.abt-listing li {
  list-style-type: disc;
  color: #1D1D1B;
  font-family: 'Gotham';
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  margin-bottom: 10px;
}
.abt-parts-four ul.abt-listing li:last-child {
  margin-bottom: 0px;
}
/* part-four-end */
/* part-five-start */
.abt-parts-five {
  background-image: url(https://hallmanimagesnew.s3-accelerate.amazonaws.com/hallmanusa_cms/about-banner-1.png);
  padding: 149px 0px 111px 0px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.abt-parts-five:before {
  content: '';
  position: absolute;
  top: 0;
  left: 50.2%;
  background: #C2D6EF;
  height: 24%;
  transform: translateX(-50%);
  width: 9.33px;
}
.abt-parts-five:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50.2%;
  background: #C2D6EF;
  height: 24%;
  transform: translateX(-50%);
  width: 9.33px;
}
.abt-parts-five .abt-part {
  max-width: 1100px;
  width: 100%;
  margin: auto;
}
.abt-parts-five span.mid-star {
  content: '';
  left: 50.2%;
  transform: translateX(-50%);
  top: -15%;
}
.abt-parts-five span.mid-star {
  content: '';
  left: 50.2%;
  transform: translateX(-50%);
  top: -40%;
}
.abt-parts-five .moves-to-image img {
  height: 100%;
  width: 100%;
}
.abt-parts-five .moves-to-image.left {
  position: relative;
  left: 0px;
  z-index: 1;
  padding-right: 0px;
  margin-right: -50px;
}
.abt-parts-five .moves-to-text h2 {
  color: #001E42;
  font-family: 'Bookman';
  font-size: 56px;
  font-weight: 5;
  line-height: 64px;
  letter-spacing: 0.05em;
  text-align: left;
}
.abt-parts-five .moves-to-text h3 {
  font-family: 'Bonanova';
  font-size: 56px;
  font-style: italic;
  font-weight: 400;
  line-height: 64px;
  letter-spacing: 0.05em;
  text-align: left;
  color: #001E42;
}
.abt-parts-five .moves-to-texts {
  margin: 26px 0px 38px;
}
.abt-parts-five .moves-to-texts p {
  color: #1D1D1B;
  font-family: 'Gotham';
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  padding-left: 18px;
  max-width: 75%;
  width: 100%;
}
.abt-parts-five .moves-to-texts p:after {
  content: '';
  position: absolute;
  width: 6.37px;
  height: 100%;
  background: #001E42;
  left: 0;
  top: 0;
}
.abt-parts-five .right-sec {
  padding-bottom: 20px;
}
/* part-five-end */
/* part-six-start */
.abt-parts-six {
  background-image: url(https://hallmanimagesnew.s3-accelerate.amazonaws.com/hallmanusa_cms/about-banner-1.png);
  padding: 126px 0px 79px 0px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.abt-parts-six:before {
  content: '';
  position: absolute;
  top: 0;
  left: 50.2%;
  background: #C2D6EF;
  height: 20%;
  transform: translateX(-50%);
  width: 9.33px;
}
.abt-parts-six:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50.2%;
  background: #C2D6EF;
  height: 15%;
  transform: translateX(-50%);
  width: 9.33px;
}
.abt-parts-six .abt-part {
  max-width: 1100px;
  width: 100%;
  margin: auto;
}
.abt-parts-six span.mid-star {
  content: '';
  left: 50.2%;
  transform: translateX(-50%);
  top: -15%;
}
.moves-to-image {
  padding-right: 20px;
}
.abt-parts-six .moves-to-image img {
  height: 100%;
  width: 100%;
}
.abt-parts-six .moves-to-text h2 {
  color: #001E42;
  font-family: 'Bookman';
  font-size: 56px;
  font-weight: 5;
  line-height: 64px;
  letter-spacing: 0.05em;
  text-align: left;
}
.abt-parts-six .moves-to-text h3 {
  font-family: 'Bonanova';
  font-size: 56px;
  font-style: italic;
  font-weight: 400;
  line-height: 64px;
  letter-spacing: 0.05em;
  text-align: left;
  color: #001E42;
}
.abt-parts-six .moves-to-texts p {
  color: #1D1D1B;
  font-family: 'Gotham';
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  padding-left: 18px;
  max-width: 65%;
  width: 100%;
}
.abt-parts-six .moves-to-texts p:after {
  content: '';
  position: absolute;
  width: 6.37px;
  height: 100%;
  background: #001E42;
  left: 0;
  top: 0;
}
/* part-six-end */
/* part-seven-start */
.abt-parts-seven {
  background-image: url(https://hallmanimagesnew.s3-accelerate.amazonaws.com/hallmanusa_cms/about-banner-1.png);
  padding: 120px 0px 120px 0px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.abt-parts-seven:before {
  content: '';
  position: absolute;
  top: 0;
  left: 50.2%;
  background: #C2D6EF;
  height: 20%;
  transform: translateX(-50%);
  width: 9.33px;
}
.abt-parts-seven:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50.2%;
  background: #C2D6EF;
  height: 25%;
  transform: translateX(-50%);
  width: 9.33px;
}
.abt-parts-seven .abt-part {
  max-width: 1100px;
  width: 100%;
  margin: auto;
}
.abt-parts-seven span.mid-star {
  content: '';
  left: 50.2%;
  transform: translateX(-50%);
  top: -25%;
}
.moves-to-image {
  padding-right: 20px;
}
.abt-parts-seven .moves-to-image img {
  height: 100%;
  width: 100%;
}
.abt-parts-seven .moves-to-text h2 {
  color: #001E42;
  font-family: 'Bookman';
  font-size: 56px;
  font-weight: 5;
  line-height: 64px;
  letter-spacing: 0.05em;
  text-align: left;
}
.abt-parts-seven .moves-to-text h3 {
  font-family: 'Bonanova';
  font-size: 56px;
  font-style: italic;
  font-weight: 400;
  line-height: 64px;
  letter-spacing: 0.05em;
  text-align: left;
  color: #001E42;
}
.abt-parts-seven .moves-to-texts p {
  color: #1D1D1B;
  font-family: 'Gotham';
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  padding-left: 18px;
  max-width: 75%;
  width: 100%;
}
.abt-parts-seven .moves-to-texts p:after {
  content: '';
  position: absolute;
  width: 6.37px;
  height: 100%;
  background: #001E42;
  left: 0;
  top: 0;
}
.abt-parts-seven ul.abt-listing {
  padding-left: 20px;
  margin-top: 20px;
}
.abt-parts-seven ul.abt-listing li {
  list-style-type: disc;
  color: #1D1D1B;
  font-family: 'Gotham';
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  margin-bottom: 10px;
}
.abt-parts-seven ul.abt-listing li:last-child {
  margin-bottom: 0px;
}
/* part-seven-end */
/* part-eight-start */
.abt-parts-eight {
  background-image: url(https://hallmanimagesnew.s3-accelerate.amazonaws.com/hallmanusa_cms/about-banner-1.png);
  padding: 110px 0px 57px 0px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.abt-parts-eight:before {
  content: '';
  position: absolute;
  top: 0;
  left: 50.2%;
  background: #C2D6EF;
  height: 15%;
  transform: translateX(-50%);
  width: 9.33px;
}
.abt-parts-eight:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50.2%;
  background: #C2D6EF;
  height: 32%;
  transform: translateX(-50%);
  width: 9.33px;
}
.abt-parts-eight span.mid-star {
  content: '';
  left: 50.2%;
  transform: translateX(-50%);
  top: -15%;
}
.abt-parts-eight .abt-part {
  max-width: 1100px;
  width: 100%;
  margin: auto;
}
.abt-parts-eight .moves-to-image.left {
  position: relative;
  left: 0px;
  z-index: 1;
  padding-right: 0px;
  margin-right: -100px;
}
.abt-parts-eight .moves-to-image img {
  height: 100%;
  width: 100%;
}
.abt-parts-eight .right-sec {
  padding-bottom: 20px;
}
.abt-parts-eight .moves-to-text h2 {
  color: #001E42;
  font-family: 'Bookman';
  font-size: 56px;
  font-weight: 5;
  line-height: 64px;
  letter-spacing: 0.05em;
  text-align: left;
}
.abt-parts-eight .moves-to-text h3 {
  font-family: 'Bonanova';
  font-size: 56px;
  font-style: italic;
  font-weight: 400;
  line-height: 64px;
  letter-spacing: 0.05em;
  text-align: left;
  color: #001E42;
}
.abt-parts-eight .moves-to-texts {
  margin: 26px 0px 10px;
}
.abt-parts-eight .moves-to-texts p {
  color: #1D1D1B;
  font-family: 'Gotham';
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  padding-left: 18px;
  max-width: 75%;
  width: 100%;
}
.abt-parts-eight .moves-to-texts p:after {
  content: '';
  position: absolute;
  width: 6.37px;
  height: 100%;
  background: #001E42;
  left: 0;
  top: 0;
}
.moves-to-texts {
  margin-top: 26px;
}
/* part-eight-end */
/* part-nine-start */
.abt-parts-nine {
  background-image: url(https://hallmanimagesnew.s3-accelerate.amazonaws.com/hallmanusa_cms/about-banner-1.png);
  padding: 122px 0px 120px 0px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.abt-parts-nine:after {
  content: '';
  position: absolute;
  height: 100%;
  width: 9.33px;
  background: #C2D6EF;
  left: 50.2%;
  transform: translateX(-50%);
  top: 0;
}
.abt-parts-nine span.mid-star {
  content: '';
  left: 50.2%;
  transform: translateX(-50%);
  top: -25%;
  z-index: 1;
}
.abt-parts-nine .abt-part {
  max-width: 1100px;
  width: 100%;
  margin: auto;
}
.abt-parts-nine .moves-to-image.right {
  position: relative;
  z-index: 1;
  margin-left: -50px;
}
.abt-parts-nine .moves-to-image.right img {
  height: 100%;
  width: 100%;
}
.abt-parts-nine .moves-to-image.left {
  position: relative;
  left: 0px;
  z-index: 1;
  padding-right: 0px;
  margin-right: -100px;
}
.abt-parts-nine .moves-to-image img {
  height: 100%;
  width: 100%;
}
.abt-parts-nine .right-sec {
  padding-bottom: 20px;
}
.abt-parts-nine .moves-to-text h2 {
  color: #001E42;
  font-family: 'Bookman';
  font-size: 56px;
  font-weight: 5;
  line-height: 64px;
  letter-spacing: 0.05em;
  text-align: left;
}
.abt-parts-nine .moves-to-text h3 {
  font-family: 'Bonanova';
  font-size: 56px;
  font-style: italic;
  font-weight: 400;
  line-height: 64px;
  letter-spacing: 0.05em;
  text-align: left;
  color: #001E42;
}
.abt-parts-nine .moves-to-texts {
  margin: 26px 0px 10px;
}
.abt-parts-nine .moves-to-texts p {
  color: #1D1D1B;
  font-family: 'Gotham';
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  padding-left: 18px;
  max-width: 75%;
  width: 100%;
}
.abt-parts-nine .moves-to-texts p:after {
  content: '';
  position: absolute;
  width: 6.37px;
  height: 100%;
  background: #001E42;
  left: 0;
  top: 0;
}
/* part-nine-end */
/* part-ten-start */
.abt-parts-ten {
  background-image: url(https://hallmanimagesnew.s3-accelerate.amazonaws.com/hallmanusa_cms/about-banner-1.png);
  padding: 120px 0px 122px 0px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.abt-parts-ten:before {
  content: '';
  position: absolute;
  top: 0;
  left: 50.2%;
  background: #C2D6EF;
  height: 26%;
  transform: translateX(-50%);
  width: 9.33px;
}
.abt-parts-ten:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50.2%;
  background: #C2D6EF;
  height: 26%;
  transform: translateX(-50%);
  width: 9.33px;
}
.abt-parts-ten .abt-part {
  max-width: 1100px;
  width: 100%;
  margin: auto;
}
.abt-parts-ten span.mid-star {
  content: '';
  left: 50.2%;
  transform: translateX(-50%);
  top: -15%;
}
.moves-to-image {
  padding-right: 20px;
}
.abt-parts-ten .moves-to-image img {
  height: 100%;
  width: 100%;
}
.abt-parts-ten .moves-to-text h2 {
  color: #001E42;
  font-family: 'Bookman';
  font-size: 56px;
  font-weight: 5;
  line-height: 64px;
  letter-spacing: 0.05em;
  text-align: left;
}
.abt-parts-ten .moves-to-text h3 {
  font-family: 'Bonanova';
  font-size: 56px;
  font-style: italic;
  font-weight: 400;
  line-height: 64px;
  letter-spacing: 0.05em;
  text-align: left;
  color: #001E42;
}
.abt-parts-ten .moves-to-texts p {
  color: #1D1D1B;
  font-family: 'Gotham';
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  padding-left: 18px;
  max-width: 65%;
  width: 100%;
}
.abt-parts-ten .moves-to-texts p:after {
  content: '';
  position: absolute;
  width: 6.37px;
  height: 100%;
  background: #001E42;
  left: 0;
  top: 0;
}
/* part-ten-end */
/* part-eleven-start */
.abt-parts-eleven {
  background-image: url(https://hallmanimagesnew.s3-accelerate.amazonaws.com/hallmanusa_cms/about-banner-1.png);
  padding: 96px 0px 100px 0px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.abt-parts-eleven:after {
  content: '';
  position: absolute;
  height: 100%;
  width: 9.33px;
  background: #C2D6EF;
  left: 50.2%;
  transform: translateX(-50%);
  top: 0;
}
.abt-parts-eleven .abt-part {
  max-width: 1100px;
  width: 100%;
  margin: auto;
}
.abt-parts-eleven span.mid-star {
  content: '';
  left: 50.2%;
  transform: translateX(-50%);
  top: -15%;
  z-index: 1;
}
.abt-parts-eleven .moves-to-text h2 {
  color: #001E42;
  font-family: 'Bookman';
  font-size: 56px;
  font-weight: 5;
  line-height: 64px;
  letter-spacing: 0.05em;
  text-align: left;
}
.abt-parts-eleven .moves-to-text h3 {
  font-family: 'Bonanova';
  font-size: 56px;
  font-style: italic;
  font-weight: 400;
  line-height: 64px;
  letter-spacing: 0.05em;
  text-align: left;
  color: #001E42;
}
.abt-parts-eleven .moves-to-texts {
  margin: 26px 0px 10px;
}
.abt-parts-eleven .moves-to-texts p {
  color: #1D1D1B;
  font-family: 'Gotham';
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  padding-left: 18px;
  max-width: 75%;
  width: 100%;
}
.abt-parts-eleven .moves-to-texts p:after {
  content: '';
  position: absolute;
  width: 6.37px;
  height: 100%;
  background: #001E42;
  left: 0;
  top: 0;
}
.abt-parts-eleven .moves-to-image.right.el img {
  height: 100%;
  width: 100%;
}
.abt-parts-eleven .moves-to-image.right.el {
  position: relative;
  padding-left: 26px;
}
/* part-eleven-end */
/* part-twelve-start */
.abt-parts-twelve {
  background-image: url(https://hallmanimagesnew.s3-accelerate.amazonaws.com/hallmanusa_cms/about-banner-1.png);
  padding: 127px 0px 110px 0px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.abt-parts-twelve:before {
  content: '';
  position: absolute;
  top: 0;
  left: 50.2%;
  background: #C2D6EF;
  height: 20%;
  transform: translateX(-50%);
  width: 9.33px;
}
.abt-parts-twelve:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50.2%;
  background: #C2D6EF;
  height: 15%;
  transform: translateX(-50%);
  width: 9.33px;
}
.abt-parts-twelve span.mid-star {
  content: '';
  left: 50.2%;
  transform: translateX(-50%);
  top: -15%;
}
.abt-parts-twelve .abt-part {
  max-width: 1100px;
  width: 100%;
  margin: auto;
}
.moves-to-image {
  padding-right: 20px;
}
.abt-parts-twelve .moves-to-image img {
  height: 100%;
  width: 100%;
}
.abt-parts-twelve .moves-to-text h2 {
  color: #001E42;
  font-family: 'Bookman';
  font-size: 56px;
  font-weight: 5;
  line-height: 64px;
  letter-spacing: 0.05em;
  text-align: left;
}
.abt-parts-twelve .moves-to-text h3 {
  font-family: 'Bonanova';
  font-size: 56px;
  font-style: italic;
  font-weight: 400;
  line-height: 64px;
  letter-spacing: 0.05em;
  text-align: left;
  color: #001E42;
}
.abt-parts-twelve ul.abt-listing {
  padding-left: 20px;
  margin-top: 20px;
}
.abt-parts-twelve ul.abt-listing li {
  list-style-type: disc;
  color: #1D1D1B;
  font-family: 'Gotham';
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  margin-bottom: 10px;
}
.abt-parts-twelve ul.abt-listing li:last-child {
  margin-bottom: 0px;
}
/* part-twelve-end */
/* final-section-start */
.abt-parts-final {
  background-image: url(https://hallmanimagesnew.s3-accelerate.amazonaws.com/hallmanusa_cms/about-banner-1.png);
  padding: 100px 0px 65px 0px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.abt-parts-final:after {
  content: '';
  position: absolute;
  height: 87%;
  width: 9.33px;
  background: #C2D6EF;
  left: 50.2%;
  transform: translateX(-50%);
  top: 0;
}
.abt-parts-final .abt-part {
  max-width: 1100px;
  width: 100%;
  margin: auto;
}
span.botm-star {
  position: absolute;
  left: 50.3%;
  bottom: 0;
  transform: translateX(-50%);
  z-index: 1;
}
.abt-parts-final span.mid-star {
  content: '';
  left: 50.2%;
  transform: translateX(-50%);
  top: -15%;
  z-index: 1;
}
.abt-parts-final .moves-to-text h2 {
  color: #001E42;
  font-family: 'Bookman';
  font-size: 56px;
  font-weight: 5;
  line-height: 64px;
  letter-spacing: 0.05em;
  text-align: left;
}
.abt-parts-final .moves-to-text h3 {
  font-family: 'Bonanova';
  font-size: 56px;
  font-style: italic;
  font-weight: 400;
  line-height: 64px;
  letter-spacing: 0.05em;
  text-align: left;
  color: #001E42;
}
.abt-parts-final .moves-to-texts {
  margin: 26px 0px 10px;
}
.abt-parts-final .moves-to-texts p {
  color: #1D1D1B;
  font-family: 'Gotham';
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  padding-left: 18px;
  max-width: 90%;
  width: 100%;
}
.abt-parts-final .moves-to-texts p:after {
  content: '';
  position: absolute;
  width: 6.37px;
  height: 100%;
  background: #001E42;
  left: 0;
  top: 0;
}
.abt-parts-final .moves-to-image.right {
  position: relative;
  z-index: 1;
  margin-left: -50px;
}
.abt-parts-final .moves-to-image.right img {
  height: 100%;
  width: 100%;
}
/* final-section-end */
/* About us page css-end */
/* my team-page-css-start */
.my-team {
  padding: 56px 0px 100px 0px;
}
.my-team .my-team-area {
  max-width: 1093px;
  width: 100%;
  margin: auto;
  padding: 35px 0px 0px 0px;
}
.my-team h1 {
  color: #001E42;
  font-family: 'Bookman';
  font-size: 40px;
  font-weight: 5;
  line-height: 45.36px;
  letter-spacing: 0.05em;
  text-align: center;
  text-transform: uppercase;
}
.my-team-area .team-profile img {
  width: 100%;
  height: 100%;
  transition: 0.3s all ease-in-out;
  object-fit: cover;
}
.profile-over:hover .team-profile img {
  animation-fill-mode: backwards;
  filter: opacity(.9);
  transform: scale(1.05);
  transition: 0.3s all ease-in-out;
}

.my-team-area .team-text {
  margin-top: 27px;
  margin-bottom: 46px;
}

.profile-over .team-text h2 {
  color: #001E42;
  font-family: 'Bookman';
  font-size: 22px;
  font-weight: 5;
  line-height: 24.95px;
  letter-spacing: 0.05em;
  transition: 0.3s all ease-in-out;
  border-bottom: 2px solid transparent;
  display: inline-block;
  padding-bottom: 8px;
}
.profile-over:hover .team-text h2 {
  border-bottom: 2px solid #001E42;
  transition: 0.3s all ease-in-out;
}
.tean-btm-area {
  padding: 40px 0px 0px 0px;
}
.profile-over .team-text p {
  color: #656565;
  font-family: 'Gotham';
  font-size: 14px;
  font-weight: 500;
  line-height: 13.4px;
  letter-spacing: 0.05em;
  margin-top: 10px;
  text-transform: uppercase;
}
.tean-btm-area .abt-content p.top {
  color: #001E42;
  font-family: 'Gotham';
  font-size: 16px;
  font-weight: 500;
  line-height: 15.31px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  position: relative;
  margin-bottom: 10px !important;
}
.tean-btm-area .abt-content p.top:before {
  content: '';
  position: absolute;
  height: 2px;
  background: #001E42;
  width: 15px;
  top: 40%;
  transform: translateX(-25px);
}
.tean-btm-area .abt-content p.top:after {
  content: '';
  position: absolute;
  height: 2px;
  background: #001E42;
  width: 15px;
  top: 40%;
  transform: translateX(10px);
}
.tean-btm-area .abt-content p {
  color: #1D1D1B;
  font-family: 'Bookman';
  font-size: 27px;
  font-weight: 5;
  line-height: 49px;
  letter-spacing: 0.05em;
  text-align: center;
}
.tean-btm-area .abt-content a {
  color: #FFFFFF;
  font-family: 'Gotham';
  font-size: 14px;
  font-weight: 500;
  line-height: 13.4px;
  background: #C0974F;
  padding: 17px 31px;
  margin-top: 32px;
  border: 1px solid #c0974f;
  transition: 0.5s all ease-in-out;
  text-transform: uppercase;
  justify-content: center;
}
.tean-btm-area .abt-content a:hover {
  background-color: #fff;
  color: #001E42;
  border: 1px solid #001E42;
  transition: 0.5s all ease-in-out;
}
.my-team-area .profile-over {
  padding-top: 40px;
}
.my-team-area .profile-over img {
  border-radius: 50%;
  height: 190px !important;
  width: 190px !important;
}
.my-team-area .col-lg-4.col-md-4.col-sm-4 {
  border: 1px solid #E4E4E4;
  text-align: center;
  width: 31%;
  margin-bottom: 20px;
}
.my-team-area .row.gx-3.spex {
  gap: 2%;
}
/* my team-page-css-end */
/* my team-drawer-page-css-start */
.team-drawer .offcanvas {
  top: 0 !important;
  max-width: 1185px;
  width: 100%;
  z-index: 99999;
}
.teams .offcanvas-title {
  color: #ffffff;
  font-family: Bookman;
  font-size: 20px;
  font-weight: 5;
  letter-spacing: .05em;
  line-height: 22.68px;
}
.teams .offcanvas-header {
  padding: 34px 48px;
  background: #001E42;
}
.teams .btn-close {
  background: transparent url(https://hallmanimagesnew.s3-accelerate.amazonaws.com/hallmanusa_cms/close.svg);
  opacity: 1;
  background-repeat: no-repeat;
  background-position: center;
}
.team-drawer .modal-backdrop.show {
  opacity: .7;
}
.team-drawer .teams .offcanvas-body {
  padding: 0px;
  overflow-y: scroll;
  height: 100%;
  padding-bottom: 86px;
}

.team-drawer .teams .offcanvas-body::-webkit-scrollbar {
  display: none;
}

.teams .team-pro-pic img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.teams .team-discp {
  padding: 96px 48px 80px 48px;
}
.teams .team-discp .profile-desc-lft h3 {
  color: #1D1D1B;
  font-family: 'Gotham';
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.05em;
  text-align: left;
  text-transform: uppercase;
}
.teams .team-discp .profile-desc-rgt h2 {
  color: #001E42;
  font-family: 'Bookman';
  font-size: 40px;
  font-weight: 5;
  line-height: 45.36px;
  letter-spacing: 0.05em;
  text-align: left;
  margin-bottom: 0px !important;
  text-transform: capitalize;
  margin-bottom: 26px !important;
}
.teams .team-discp .profile-desc-rgt h3 {
  color: #001E42;
  font-family: 'Gotham';
  font-size: 30px;
  font-weight: 400;
  line-height: 42px;
  letter-spacing: 0.05em;
  text-align: left;
  margin: 0px 0px 20px 0px !important;
}
.teams .team-discp .profile-desc-rgt p {
  color: #1D1D1B;
  font-family: 'Gotham';
  font-size: 24px;
  font-weight: 500;
  line-height: 48px;
  letter-spacing: 0.05em;
  text-align: left;
  margin-bottom: 22px !important;
}
.teams .team-discp .profile-desc-rgt p:last-child {
  margin-bottom: 0px !important;
}
/* my team-drawer-page-css-end */
/* Single-product-info-page-css-start */
/* left-side-css-start */
.single-prod-info {
  padding: 48px 0px 15px 0px;
  border-top: 1px solid #ABABAB;
  position: relative;
  z-index: 1;
}
.prod-info-left {
  background: #F5F5F5;
  position: sticky;
  top: 100px;
  scroll-margin-top: 100px;
}
.prod-info-left .prod-info-title h2 {
  color: #022741;
  font-family: 'Gotham';
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  padding: 6.6px 14.9px;
  background: #E6E6E6;
}
.prod-info-left .prod-info-data h3 {
  color: #1D1D1B;
  font-family: 'Gotham';
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  padding: 14.9px;
}
.prod-info-left .prod-info-data span {
  font-weight: 500;
}
.lst-border .prod-info-data {
  border-bottom: 1px dashed #a7a7a7;
}
.lst-border .prod-info-data:last-child {
  border-bottom: 0px;
}
/* left-side-css-end */
/* center-side-css-start */
.prod-info-center {
  border: 1px solid #d5d5d5;
}
.prod-info-center ul#ex1 {
  justify-content: space-between;
}
.prod-info-center ul#ex1 .nav-item {
  width: 16.666%;
}
.prod-info-center ul#ex1 .nav-item.wide {
  width: 20%;
}
.prod-info-center .nav-tabs .nav-link {
  margin-bottom: 0px;
  border: 1px solid #d5d5d5;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  text-align: center;
  color: #000000;
  font-family: 'Gotham';
  font-size: 13px;
  font-weight: 400;
  line-height: 12.44px;
  background: #E6E6E6;
  padding: 12.15px 19.89px;
  transition: 0.5s all ease-in-out;
}

.prod-info-center .nav-tabs .nav-link:hover {
  font-weight: 500;
  transition: 0.5s all ease-in-out;
  color: #001E42;
}
.prod-info-center .nav-tabs .nav-link.prod.active {
  color: #001E42;
  background-color: #fff !important;
  border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: 0px !important;
  font-family: 'Gotham';
  font-size: 13px;
  font-weight: 500;
  line-height: 12.44px;
}
.prod-info-center .tab-body {
  padding: 20px;
}
.prod-info-center .specs-content {
  margin: 16px 0px;
}
.prod-info-center .tab-profile h2 {
  color: #001E42;
  font-family: 'Gotham';
  font-size: 20px;
  font-weight: 500;
  line-height: 19.14px;
  text-align: left;
  padding-bottom: 17px;
  border-bottom: 1px solid #D5D5D5;
}
.prod-info-center .specs-content p {
  color: #1D1D1B;
  font-family: 'Gotham';
  font-size: 13px;
  font-weight: 400;
  line-height: 25px;
  margin-bottom: 9px !important;
}
.prod-info-center .tab-specs-data {
  margin-bottom: 15px;
}
.prod-info-center .tab-specs-data:last-child {
  margin-bottom: 0px;
}
.prod-info-center .specs-content p:last-child {
  margin-bottom: 0px;
}
.prod-info-center .tab-specs-data table {
  width: 100%;
}
.prod-info-center .tab-specs-data h2 {
  color: #001E42;
  font-family: 'Gotham';
  font-size: 13px;
  font-weight: 500;
  line-height: 12.44px;
  letter-spacing: 0.02em;
  padding: 13px 18px;
  background: #E6E6E6;
}
.prod-info-center td.tab-spec-small {
  color: #1D1D1B;
  font-family: 'Gotham';
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  background: #F4F4F4;
  border: 1px solid #DFDFDF;
  padding: 18px 13px;
}
.prod-info-center td.tab-spec-big {
  color: #1D1D1B;
  font-family: 'Gotham';
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  background: #fff;
  border: 1px solid #DFDFDF;
  padding: 18px 13px;
}
.prod-info-center td.tab-spec-small.one {
  width: 18%;
}
.prod-info-center td.tab-spec-big.two {
  width: 31%;
}
.prod-info-center td.tab-spec-small.third {
  width: 20%;
}
.prod-info-center td.tab-spec-big.four {
  width: 30%;
}
.responsive-prod-info {
  overflow: auto;
  width: 100%;
}
.prod-info-center .tab-specs-data {
  overflow: hidden;
}
/* center-side-css-end */
/* right-side-css-start */
.prod-info-right {
  background: #F5F5F5;
  position: sticky;
  top: 100px;
  scroll-margin-top: 100px;
}
.prod-info-right .prod-info-title h2 {
  color: #022741;
  font-family: 'Gotham';
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  padding: 6.6px 14.9px;
  background: #E6E6E6;
}
.prod-info-right .prod-info-right-data {
  padding: 12.74px 14.9px;
}
.prod-info-right .right-data-list {
  margin-bottom: 20px;
}
.prod-info-right .right-data-list:last-child {
  margin-bottom: 0px;
}
.prod-info-right .file-pic img {
  height: 15px;
  width: 100%;
}
.file-pic {
  width: 10%;
}
.prod-info-right .file-txt {
  width: 90%;
}
.prod-info-right .file-pic {
  display: flex;
  align-items: start;
}
.prod-info-right .right-data-list p {
  color: #022741;
  font-family: 'Gotham';
  font-size: 13px;
  font-weight: 400;
  line-height: 16.44px;
  text-align: left;
  text-decoration: underline;
  transition: 0.5s all ease-in-out;
}

.prod-info-right .right-data-list p:hover {
  font-weight: 500;
  transition: 0.5s all ease-in-out;
}
/* right-side-css-end */
/* Shipping-tab-css-start */
.prod-info-center .shipping-content {
  margin-top: 16px;
}
.prod-info-center .shipping-content p {
  color: #1D1D1B;
  font-family: 'Gotham';
  font-size: 13px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  margin-bottom: 10px !important;
}
.prod-info-center .shiping-sub h3 {
  color: #001E42;
  font-family: 'Gotham';
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  text-align: left;
}
.prod-info-center .shiping-sub p {
  color: #1D1D1B;
  font-family: 'Gotham';
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  margin-bottom: 14px !important;
}
.prod-info-center .shiping-sub p:last-child {
  margin-bottom: 0px;
}
.prod-info-center .shiping-sub {
  margin: 10px 0px 14px 0px;
}
.prod-info-center .shiping-sub:last-child {
  margin: 10px 0px 0px 0px;
}
.prod-info-center .shiping-red {
  margin: 10px 0px 14px 0px;
}
.prod-info-center .shiping-red:last-child {
  margin: 10px 0px 0px 0px;
}
.prod-info-center .shiping-red h4 {
  color: #E81E1E;
  font-family: 'Gotham';
  font-size: 13px;
  font-weight: 500;
  line-height: 25px;
  text-align: left;
}
.prod-info-center .shiping-red p {
  color: #E81E1E;
  font-family: 'Gotham';
  font-size: 13px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  margin-bottom: 14px !important;
}
.prod-info-center .shiping-red p:last-child {
  margin-bottom: 0px;
}
/* Shipping-tab-css-end */
/* Warranty-tab-css-start */
.shiping-sub a.warn-link {
  color: #0C4FF9;
}
.prod-info-center .shiping-sub ul {
  padding-left: 20px;
  margin: 3px 0px 14px 0px;
}
.prod-info-center .shiping-sub ul li {
  list-style-type: disc;
  color: #1D1D1B;
  font-family: 'Gotham';
  font-size: 13px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
}
.prod-info-center .shiping-sub a.warn-cont {
  color: #1D1D1B;
  font-weight: 500;
}
/* Warranty-tab-css-end */
/* parts-tab-css-start */
.prod-info-center .parts-content {
  margin-top: 20px;
}
.prod-info-center .up-slide h3 {
  color: #001F3F;
  font-family: 'Gotham';
  font-size: 12px;
  font-weight: 400;
  line-height: 11.48px;
  text-align: center;
  max-width: 150px;
  margin: auto;
  background: #E6E6E6;
  padding: 7px 13px;
  position: relative;
  top: -15px;
}
.prod-info-center .part-tile-txt h2 {
  color: #022741;
  font-family: 'Gotham';
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
}
.prod-info-center .part-tile-txt h5 {
  color: #1D1D1B;
  font-family: 'Gotham';
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.06em;
  text-align: center;
  margin: 6px 0px;
}
.prod-info-center .part-tile-txt {
  text-align: center;
}
.prod-info-center .part-tile-txt h6 {
  color: #000;
  font-family: 'Gotham';
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.06em;
  text-align: center;
  margin: 5px 0px 8px 0px;
}
.prod-info-center .part-pic img {
  width: 100%;
  height: 100%;
}
.prod-info-center .part-all a {
  display: block;
  text-decoration: none;
}
.parts-content img.tag {
  max-width: 150px !important;
  width: 100%;
  height: 100%;
}
/* parts-tab-css-end */
/* service-tab-css-start */
.prod-info-center .service-content {
  max-width: 600px;
  margin: auto;
  padding: 50px 0px;
}
.prod-info-center .serv-text h2 {
  color: #001E42;
  font-family: 'Gotham';
  font-size: 35px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
}
.prod-info-center .serv-text p {
  color: #000000;
  font-family: 'Gotham';
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  margin: 15px 0px 20px !important;
}
.prod-info-center .notify-me {
  max-width: 480px;
  margin: auto;
  position: relative;
}
.prod-info-center .notify-input {
  width: 100%;
  background: white;
  display: block;
  outline: none;
  border: 1px solid #C8C8C8;
  height: 50px;
  padding: 14px;
  color: #8F8F8F;
  font-family: 'Gotham';
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.02em;
  text-align: left;
  padding-right: 160px;
}
.prod-info-center .notify-me input::placeholder {
  color: #8F8F8F;
}
.prod-info-center .notify-me button {
  position: absolute;
  top: 0;
  right: 0;
  border: 0;
  outline: 0;
  height: 50px;
  width: 155px;
  color: #fff;
  font-family: 'Gotham';
  font-size: 14px;
  font-weight: 500;
  line-height: 13.4px;
  text-align: center;
  background: #C0974F;
  border: 1px solid #c0974f;
  transition: 0.5s all ease-in-out;
}
.prod-info-center .notify-me button:hover {
  background-color: #fff;
  color: #001E42;
  border: 1px solid #001E42;
  transition: 0.5s all ease-in-out;
}
/* service-tab-css-end */
/* review-tab-css-start */
.prod-info-center .review-content {
  padding: 30px 0px 0px 0px;
}
.prod-info-center .rating-snap {
  background: #F5F5F5;
  padding: 20px;
  margin-bottom: 30px;
}
.prod-info-center .rating-snap-head h2 {
  color: #001E42;
  font-family: 'Gotham';
  font-size: 13px;
  font-weight: 500;
  line-height: 12.44px;
  text-align: left;
  margin-bottom: 16px !important;
}
.prod-info-center .rev-star {
  width: 15%;
}
.prod-info-center .rev-star p {
  color: #1D1D1B;
  font-family: 'Gotham';
  font-size: 13px;
  font-weight: 400;
  line-height: 12.44px;
  text-align: left;
}
.prod-info-center .rev-snap {
  gap: 15px;
  margin-bottom: 20px;
}
.prod-info-center .rev-snap:last-child {
  margin-bottom: 0px;
}
.prod-info-center .rev-stars {
  width: 15%;
}
.prod-info-center .rev-stars p {
  color: #1D1D1B;
  font-family: 'Gotham';
  font-size: 13px;
  font-weight: 400;
  line-height: 12.44px;
  text-align: left;
}
.prod-info-center .rev-bar-five {
  background: #D9D9D9;
  width: 70%;
  height: 12px;
  position: relative;
}
.prod-info-center .rev-bar-five:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background: #F5B62E;
  width: 95%;
  height: 12px;
}
.prod-info-center .rev-bar-four {
  background: #D9D9D9;
  width: 70%;
  height: 12px;
  position: relative;
}
.prod-info-center .rev-bar-four:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background: #F5B62E;
  width: 80%;
  height: 12px;
}
.prod-info-center .rev-bar-three {
  background: #D9D9D9;
  width: 70%;
  height: 12px;
  position: relative;
}
.prod-info-center .rev-bar-three:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background: #F5B62E;
  width: 50%;
  height: 12px;
}
.prod-info-center .rev-bar-two {
  background: #D9D9D9;
  width: 70%;
  height: 12px;
  position: relative;
}
.prod-info-center .rev-bar-two:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background: #F5B62E;
  width: 35%;
  height: 12px;
}
.prod-info-center .rev-bar-one {
  background: #D9D9D9;
  width: 70%;
  height: 12px;
  position: relative;
}
.prod-info-center .rev-bar-one:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background: #F5B62E;
  width: 20%;
  height: 12px;
}
.prod-info-center .rev-bold-txt h2 {
  color: #001E42;
  font-family: 'Gotham';
  font-size: 45px;
  font-weight: 700;
  line-height: 43.07px;
  text-align: left;
}
.prod-info-center .rev-bold-star p {
  color: #1D1D1B;
  font-family: 'Gotham';
  font-size: 13px;
  font-weight: 400;
  line-height: 12.44px;
  text-align: left;
  margin-top: 9px;
}
.prod-info-center .rev-bold-star {
  display: flex;
  flex-direction: column;
  align-items: start;
}
.prod-info-center .cust-rate p {
  color: #1D1D1B;
  font-family: 'Gotham';
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  margin: 3px 0px 9px 0px !important;
  max-width: 85%;
  width: 100%;
}
.prod-info-center .cust-rate a {
  background: #C0974F;
  color: #fff;
  font-family: 'Gotham';
  font-size: 14px;
  font-weight: 500;
  line-height: 13.4px;
  text-align: center;
  max-width: 173px;
  height: 46px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  border: 1px solid #c0974f;
  transition: 0.5s all ease-in-out;
}
.prod-info-center .cust-rate a:hover {
  background-color: #fff;
  color: #001E42;
  border: 1px solid #001E42;
  transition: 0.5s all ease-in-out;
}
.prod-info-center .happy-review {
  padding: 0px 0px 30px 0px;
  margin-bottom: 30px;
  border-bottom: 1px solid #D5D5D5;
}
.prod-info-center .hpy-rev-title h2 {
  color: #001F3F;
  font-family: 'Bookman';
  font-size: 25px;
  font-weight: 5;
  line-height: 28.35px;
  letter-spacing: 0.05em;
  margin: 17px 0px 11px 0px !important;
}
.prod-info-center .hpy-rev-title h4 {
  color: #001F3F;
  font-family: 'Gotham';
  font-size: 14px;
  font-weight: 500;
  line-height: 13.4px;
  text-align: left;
  margin: 17px 0px 11px 0px;
}
.prod-info-center .when-rev h6 {
  color: #636363;
  font-family: 'Gotham';
  font-size: 12px;
  font-weight: 400;
  line-height: 11.48px;
  text-align: left;
}
.prod-info-center .when-rev p {
  color: #1D1D1B;
  font-family: 'Gotham';
  font-size: 13px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  margin: 11px 0px 15px !important;
}
.prod-info-center .rec-abt {
  gap: 13px;
}
.prod-info-center .apper-rev h2 {
  color: #1D1D1B;
  font-family: 'Gotham';
  font-size: 12px;
  font-weight: 500;
  line-height: 11.48px;
  text-align: left;
}
.prod-info-center .apper-rev-point {
  background: #1D1D1B;
  height: 15px;
  margin: 9px 0px;
}
.prod-info-center .apper-rev {
  width: 20%;
}
.prod-info-center .rev-pt-one {
  border-right: 1px solid #FFFFFF;
  height: 15px;
  width: 20%;
}
.prod-info-center .rev-pt-one:last-child {
  border-right: 0px;
}
.prod-info-center .rev-pt-one.two {
  position: relative;
}
.prod-info-center .rev-pt-one.two:before {
  content: '';
  position: absolute;
  top: 0;
  right: -1px;
  background: #D9D9D9;
  width: 50%;
  height: 15px;
}
.prod-info-center .apper-rev p {
  color: #1D1D1B;
  font-family: 'Gotham';
  font-size: 12px;
  font-weight: 400;
  line-height: 11.48px;
  text-align: left;
}
.prod-info-center .hpy-rev-pics {
  gap: 20px;
  margin: 27px 0px;
}
.prod-info-center .hpy-rev-img {
  height: 72px;
  width: 72px;
}
.prod-info-center .hpy-rev-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.prod-info-center .rev-recomd h4 {
  color: #1D1D1B;
  font-family: 'Gotham';
  font-size: 12px;
  font-weight: 500;
  line-height: 11.48px;
  text-align: left;
}
.prod-info-center .rev-recomd span {
  color: #001E42;
}
.prod-info-center .more-review a {
  color: #FFFFFF;
  font-family: 'Gotham';
  font-size: 14px;
  font-weight: 500;
  line-height: 13.4px;
  text-align: left;
  background: #C0974F;
  padding: 17px 32px;
  border: 1px solid #c0974f;
  transition: 0.5s all ease-in-out;
}
.prod-info-center .more-review a:hover {
  background-color: #fff;
  color: #001E42;
  border: 1px solid #001E42;
  transition: 0.5s all ease-in-out;
}
/* review-tab-css-end */
/* PDP Quick-specs-start */
.sigle-product-content .quick-specs {
  padding: 25px 0 30px;
  border-bottom: 1px solid #EAEAEA;
}
.sigle-product-content .quick-specs .quick-area {
  width: fit-content;
}
.sigle-product-content .quick-specs h3 {
  color: #001E42;
  font-family: 'Gotham';
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  margin-bottom: 12px !important;
}
.quick-specs ul.quick-specs-list li {
  color: #1D1D1B !important;
  font-family: 'Gotham';
  font-size: 13px !important;
  font-weight: 500;
  line-height: 20px !important;
  text-align: left;
  margin-bottom: 10px !important;
}
.quick-specs ul.quick-specs-list li:last-child {
  margin-bottom: 0px !important;
}
.quick-specs ul.quick-specs-list li span {
  font-weight: 400;
}
.quick-specs .quick-gap {
  gap: 50px;
}
.quick-specs .quick-more a {
  color: #0C4FF9;
  font-family: 'Gotham';
  font-size: 14px;
  font-weight: 400;
  line-height: 13.4px;
  text-align: left;
  text-decoration: underline;
  text-transform: capitalize;
  text-underline-offset: 2px;
}
/* PDP Quick-specs-end */
/* Customer-training video-pages-css-start */
.customer-training-main {
  padding: 45px 0px 36px 0px;
}
.customer-training-main .customer-title h1 {
  color: #001E42;
  font-family: 'Bookman';
  font-size: 40px;
  font-weight: 5;
  line-height: 45.36px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}
.row.gallery-image-spacing {
  display: flex;
  flex-wrap: wrap;
}
.custom-tile .row.gallery-image-spacing a {
  width: 100%;
}
.custom-tile .cust-tile-pic {
  width: 100%;
}
.custom-tile .overlay-images {
  width: 100%;
}
.custom-tile iframe {
  width: 100%;
  margin: 0 auto;
}
.customer-training-tabs-section .nav-tabs .nav-link {
  margin-bottom: -2px;
  background: 0 0;
  border: 0px solid transparent;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.customer-training-tabs-section .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.new.active {
  color: #000000;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: 4px solid #001E42 !important;
}
.customer-training-tabs-section .nav-link {
  display: block;
  padding: 0px 45px;
  color: #001E42;
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
  font-family: 'Gotham';
  font-size: 13px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.06em;
  text-align: center;
}
.customer-training-tabs-section .nav-tabs {
  border-bottom: 1px solid #E1E1E1;
}
.cust-respons {
  overflow: auto;
  width: 100%;
}
.overlay-images:after {
  background: linear-gradient(180deg, #36363600, #000914);
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 70%;
  bottom: 0;
  transition: .3s ease-in-out;
  pointer-events: none;
}
.overlay-images:after,
.overlays-images:after {
  background: linear-gradient(180deg, #36363600, #000914);
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  transition: .3s ease-in-out;
  pointer-events: none;
}
.overlays-images:after {
  content: '';
  position: absolute;
  background: linear-gradient(180deg, rgba(54, 54, 54, 0) 0%, rgba(0, 9, 20, 1) 100%);
  left: 0;
  right: 0;
  bottom: 0;
  top: 20%;
  transition: ease-in-out 300ms;
  pointer-events: none;
}
.custom-tile .cust-content {
  left: 30px;
  right: 30px;
  bottom: 30px;
}
.custom-tile .cust-content-lft h2 {
  color: #fff;
  font-family: 'Gotham';
  font-size: 18px;
  font-weight: 700;
  line-height: 24.21px;
  text-transform: capitalize;
  max-width: 80%;
}
.custom-tile .cust-content-lft p {
  color: #fff;
  font-family: 'Gotham';
  font-size: 12px;
  font-weight: 400;
  line-height: 11.48px;
  text-align: left;
  text-transform: capitalize;
  margin-top: 8px;
}
.custom-tile .line {
  line-height: 0px !important;
}
.custom-tile .overlay-images {
  transition: 0.5s all ease-in-out;
}
.cust-tile-pic:hover .overlay-images {
  transform: scale(1.09);
  transition: 0.5s all ease-in-out;
}
.custom-tile .overlays-images {
  transition: 0.5s all ease-in-out;
}
.cust-tile-pic:hover .overlays-images {
  transform: scale(1.09);
  transition: 0.5s all ease-in-out;
}
.custom-tile .cust-content-lft {
  width: 80%;
}
.custom-tile .cust-content-rgt {
  width: 20%;
}
.custom-tile .cust-content-rgt img {
  transition: 0.5s all ease-in-out;
}
.cust-tile-pic:hover .cust-content-rgt img {
  transform: scale(1.2);
  transition: 0.5s all ease-in-out;
}
.custom-tile .cust-top {
  top: 15px;
  right: 15px;
}
.custom-tile .cust-top p {
  background: #001E42;
  padding: 5px 10px;
  border-radius: 6px;
  color: #fff;
  font-family: 'Gotham';
  font-size: 12px;
  font-weight: 400;
  line-height: 11.48px;
  text-align: center;
}
.custom-tile .overlay-images img {
  height: 100%;
}
.custom-tile .cust-content-rgt img {
  height: 100%;
}
.custom-tile .overlays-images img {
  height: 100%;
}
.img-gallery-selected {
  margin-bottom: 50px;
}
div#image-gallery-content .video-pagination {
  border-bottom: 1px solid #dedede;
  padding: 50px 0 50px;
}
/* Customer-training video-pages-css-end */
/* header-off-css-start */
.header-off button.btn-close {
  position: absolute;
  top: -34px;
  right: 20px;
  border: 1px solid #fff;
  padding: 7px 9px;
}
.header-off .btn-close {
  background: transparent url(https://hallmanimagesnew.s3-accelerate.amazonaws.com/hallmanusa_cms/close.svg);
  opacity: 1;
  background-repeat: no-repeat;
  background-position: center;
  height: 10px;
  width: 10px;
}
.header-off.offcanvas {
  top: 62px !important;
  max-width: 700px !important;
  width: 100%;
}
div.header-off {
  background: #001E42;
  z-index: 99999;
}
.header-off .offcanvas-header {
  padding: 0px;
}
.header-off .offcanvas-body {
  background: #001e42;
  padding: 0 20px;
  height: 100%;
  overflow-y: scroll;
}
.menu-accor .accordion-item .accordion-header .accordion-button {
  padding: 0px;
  background: #001e42;
  color: #fff;
  border-bottom: 0px;
  font-size: 16px;
  font-weight: 400;
  box-shadow: none;
}
.menu-accor .accordion-body {
  padding: 20px 0px;
}
.menu-accor .accordion-item {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  background: #001E42;
}
.menu-accor .accordion-item {
  border: 0px;
}
.menu-accor .accordion-button:not(.collapsed):after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url(https://hallmanimagesnew.s3.us-east-1.amazonaws.com/hallmanusa_cms/collapese-white.svg) !important;
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform .2s ease-in-out;
  transform: rotate(180deg) !important;
}
.menu-accor .accordion-button.collapsed:after {
  background-image: url(https://hallmanimagesnew.s3.us-east-1.amazonaws.com/hallmanusa_cms/collapese-white.svg) !important;
  transform: rotate(0deg) !important;
  position: absolute;
  right: 0;
  top: 3px;
}
.menu-accor .accordion-header {
  color: #fff;
  background: #001e42;
  padding: 30px 0px;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Gotham';
}
.menu-accor .accordion-item {
  border-bottom: 1px solid rgb(255 255 255 / 15%);
}
/* header-off-css-end */
.price-section .single-pricing h2 {
  color: #001E42;
  font-family: 'Bookman';
  font-size: 21px;
  font-weight: 5;
  line-height: 30.02px;
  letter-spacing: 0.05em;
  text-align: left;
}
.confirm-popup.confirming {
  z-index: 99999;
}
/* Comapre page-css-start */
.compare-prod-info .for-four .comp-information td.left {
  width: 33.33%;
}
.compare-prod-info .for-four .comp-information td.cent {
  width: 16.66%;
}
.compare-prod-info .for-three .comp-information td.left {
  width: 25%;
}
.compare-prod-info .for-three .comp-information td.cent {
  width: 25%;
}
.comapre-top-section {
  min-height: 460px;
}
.comapre-top-section .loading {
  padding-top: 52px;
}
.no-record-found-comparison {
  padding-top: 52px;
  text-align: -webkit-center;
}
.comp-top-btn {
  position: relative;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px 0;
  z-index: 1;
}
.dropdown-menu button {
  display: block;
  width: 100%;
  padding: 5px 10px;
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;
}
.dropdown-menu button:hover {
  background-color: #f0f0f0;
}
.compare-prod .compare-left .img img {
  height: 100%;
}
.compare-prod .img {
  width: 50%;
}
.compare-prod .compare-left-cont h1 {
  color: #001E42;
  font-family: 'Bookman';
  font-size: 35px;
  font-weight: 5;
  line-height: 45px;
  text-align: left;
  text-transform: uppercase;
  padding: 16px 0px 12px 0px;
  letter-spacing: 0.05em;
}
.compare-prod .compare-left-cont h2 {
  color: #1D1D1B;
  font-family: 'Gotham';
  font-size: 14px;
  font-weight: 400;
  line-height: 13.4px;
  text-align: left;
  margin-bottom: 25px !important;
}
.compare-prod .compare-left-cont ul {
  padding-left: 20px;
}
.compare-prod .compare-left-cont ul li {
  list-style-type: disc;
  color: #1D1D1B;
  font-family: 'Gotham';
  font-size: 14px;
  font-weight: 500;
  line-height: 13.4px;
  text-align: left;
  margin-bottom: 20px;
}
.compare-prod .compare-left-cont ul li:last-child {
  margin-bottom: 0px;
}
.compare-prod .comp-print {
  gap: 5px;
  margin-top: 35px;
  border-bottom: 0.5px solid #001E42;
  width: fit-content;
  padding-bottom: 4px;
  cursor: pointer;
}
.compare-prod .comp-print p {
  color: #001E42;
  font-family: 'Gotham';
  font-size: 14px;
  font-weight: 500;
  text-align: left;
}
.compare-prod .comp-upr-prod {
  border-right: 1px solid #D4D4D4;
  border-left: 1px solid #D4D4D4;
}
.compare-prod .comp-upr-prod a {
  display: block;
}
.compare-prod .compr-tile {
  max-width: 321px;
  margin: auto;
  margin-bottom: 44px;
}
.compare-prod .comp-top-btn {
  position: absolute;
  top: 5px;
  right: 10px;
}
.compare-prod .comp-top-btn button {
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
.compare-prod .compr-pic img {
  height: 321px;
  width: 100%;
  object-fit: contain;
  padding: 28px 0;
}
.compare-prod .compr-tile-txt {
  text-align: center;
}
.compare-prod .up-slide h3 {
  background: #E6E6E6;
  padding: 7px 13px;
  width: fit-content;
  text-align: center;
  margin: auto;
  color: #001F3F;
  font-family: 'Gotham';
  font-size: 12px;
  font-weight: 400;
  line-height: 11.48px;
}
.compare-prod .compr-tile-txt h4 {
  color: #022741;
  font-family: Gotham;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  margin: 17px 10px 12px !important;
  text-align: center;
  text-transform: capitalize;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  min-height: 74px;
}
.compare-prod .compr-tile-txt h5 {
  text-align: center;
  font-size: 22px;
  font-weight: 500;
  line-height: 34.2px;
  letter-spacing: 0.05em;
  color: #001e42;
  font-family: "Bookman";
}
.compare-prod .compr-tile-txt h6 {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.14px;
  letter-spacing: 0.05em;
  color: #595959;
  text-decoration: line-through;
  font-family: "Bookman";
  margin-top: 4.42px;
}
.compare-prod .compr-tile-txt img {
  margin: 16px 0px 10px 0px;
}
.compr-tile-txt img.tag {
  width: 50%;
}
.compare-prod-info {
  margin-bottom: 100px;
}
.comp-information table {
  width: 100%;
}
.comp-information {
  border: 1px solid #D4D4D4;
}
.comp-information table tr {
  border-bottom: 1px dashed #A7A7A7;
}
.comp-information table tr:last-child {
  border-bottom: 0px;
}
.compare-prod-info .compare-pro h2 {
  background: #001E42;
  color: #fff;
  font-family: 'Gotham';
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  padding: 13px 20px;
}
.comp-information td {
  width: 33.33%;
  border-right: 1px solid #D4D4D4;
  background: #F5F5F5;
}
.comp-information td.for-one {
  width: 66.33% !important;
}
.comp-information td:last-child {
  border-right: 0px;
}
.compare-prod-info .comp-information td.left {
  padding: 19px;
  font-family: 'Gotham';
  font-size: 13px;
  font-weight: 700;
  line-height: 12.44px;
  text-align: left;
  color: #1D1D1B;
  text-wrap: nowrap
}
.compare-prod-info .comp-information td.cent {
  padding: 19px;
  font-family: 'Gotham';
  font-size: 13px;
  font-weight: 400;
  line-height: 12.44px;
  text-align: left;
  color: #1D1D1B;
  text-wrap: nowrap
}
.scroll-prod .scroll-pic img {
  width: 80px;
  height: 80px;
  object-fit: contain;
}
.scroll-prod .scroll-tile-txt h2 {
  color: #022741;
  font-family: 'Gotham';
  font-size: 15px;
  font-weight: 500;
  line-height: 21px;
  max-width: 100%;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
}
.scroll-prod .scroll-tile-txt p {
  color: #1D1D1B;
  font-family: 'Gotham';
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.06em;
  margin-top: 3px;
}
.scroll-prod .scroll-upr-prod {
  border-right: 1px solid #D4D4D4;
  border-left: 1px solid #D4D4D4;
  padding: 20px 20px;
  background-color: #fff;
}
.scroll-prod .scroll-upr-prod:last-child {
  border-left: 0px;
}
.scroll-prod .comp-shade {
  box-shadow: 0px 4px 9px 0px rgb(0 0 0 / 15%);
}
.scroll-prod {
  position: fixed;
  top: 113px;
  width: 100%;
  z-index: 1;
}
.hide {
  display: none;
}
.show {
  display: block;
}
.scroll-tile-txt {
  width: calc(100% - 80px);
}
/* Comapre page-css-end */
/* PDP new-section-css-start */
.product-slider-single-pro .sigle-product-content>div p {
  color: #001E42;
  font-family: 'Gotham';
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  padding-bottom: 6px;
}
.product-slider-single-pro .sigle-product-content>div ul {
  padding-left: 20px;
}
.product-slider-single-pro .sigle-product-content>div ul li {
  color: #1D1D1B;
  font-family: 'Gotham';
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  margin-bottom: 15px;
  position: relative;
}
.product-slider-single-pro .sigle-product-content>div ul li:before {
  content: '';
  position: absolute;
  left: -15px;
  top: 9px;
  background: #001E42;
  height: 5px;
  width: 5px;
  border-radius: 50px;
}
.product-slider-single-pro .sigle-product-content>div ul li:last-child {
  margin-bottom: 0px;
}
.product-slider-single-pro .sigle-product-content>div ul li a {
  color: #001E42;
  font-family: 'Gotham';
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
}
.single-product-customizer .product-slider-single-pro .slider-wrapper{
  position: relative;
}
.single-product-customizer .product-slider-single-pro .carousel-container{
  position: relative;
}
.single-product-customizer .instock-txt {
  position: absolute;
  top: 8px;
  right: 8px;
  background: #001e42;
  z-index: 9;
}
.single-product-customizer .instock-txt p {
  padding: 2px 6px;
  color: #fff;
  font-weight: 500;
  font-family: Bookman;
}
/* PDP new-section-css-3nd */
.prod-info-center .specs-content>div ul {
  padding-left: 20px;
}
.prod-info-center .specs-content>div ul li {
  color: #1D1D1B;
  font-family: 'Gotham';
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  margin-bottom: 15px;
  position: relative;
}
.prod-info-center .specs-content>div ul li:before {
  content: '';
  position: absolute;
  left: -15px;
  top: 9px;
  background: #001E42;
  height: 5px;
  width: 5px;
  border-radius: 50px;
}
.prod-info-center .specs-content>div ul li:last-child {
  margin-bottom: 0px;
}
.prod-info-center .specs-content>div ul li a {
  color: #001E42;
  font-family: 'Gotham';
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  word-break: break-word;
}
.quick-specs ul.quick-specs-list {
  padding-left: 0px !important;
}
.quick-specs ul.quick-specs-list li {
  position: relative;
  font-weight: 500 !important;
}
.quick-specs ul.quick-specs-list li:before {
  position: absolute;
  background: #ff000000 !important;
}
.head-back a.navbar-brand img {
  height: 100%;
}
/* Customer-service-page-css-start */
.customer-service-banner {
  background-image: url(https://hallmanimagesnew.s3-accelerate.amazonaws.com/hallmanusa_cms/customer-service-bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 393px;
}

.customer-intes-main {
  padding: 47px 0px 50px 0px;
  border-top: 1px solid #DEDEDE;
  border-bottom: 1px solid #DEDEDE;
}
.customer-interested .customer-intes-main h2 {
  color: #001E42;
  font-family: 'Bookman';
  font-size: 30px;
  font-weight: 5;
  line-height: 34.02px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.customer-intes-main a.cust-serv-type {
  background: #E6E6E6;
  padding: 16px 20px;
  color: #001E42;
  transition: 0.8s all ease-in-out;
}
.customer-intes-main a.cust-serv-type:focus{
  color: #001E42;
}
.customer-intes-main a.cust-serv-type:focus-visible p {
  color: #000 !important;
  border: 1px solid #001f3f;
}
.customer-intes-main a.cust-serv-type p {
  color: #001E42;
  font-family: 'Gotham';
  font-size: 16px;
  font-weight: 500;
  line-height: 15.31px;
  text-decoration: underline;
  text-underline-offset: 3px;
  text-transform: capitalize;
  transition: 0.8s all ease-in-out;
}
.customer-intes-main a.cust-serv-type:hover {
  background: #001F3F;
  color: #fff;
  transition: 0.8s all ease-in-out;
}

.customer-intes-main a.cust-serv-type:hover p {
  color: #fff;
  transition: 0.8s all ease-in-out;
}

.customer-intes-main a.cust-serv-type:hover svg ellipse {
  fill: #C0974F;
  transition: 0.8s all ease-in-out;
}

.service-for-customer {
  margin-top: 96px;
}

.service-for-customer .service-title h2 {
  color: #001E42;
  font-family: 'Bookman';
  font-size: 30px;
  font-weight: 5;
  line-height: 34.02px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.service-for-customer .all-services img {
  width: 100%;
  height: 100%;
}

.overlay-image {
  transition: ease-in-out 300ms;
}

.overlay-image:after {
  content: '';
  position: absolute;
  background: linear-gradient(180deg, rgba(54, 54, 54, 0) 0%, rgba(0, 9, 20, 1) 100%);
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  transition: ease-in-out 300ms;
}

.all-services:hover .overlay-image {
  transform: scale(1.05);
  transition: ease-in-out 300ms;
  animation-fill-mode: backwards;
  filter: opacity(0.9);
}
.service-for-customer .serv-gap {
  row-gap: 20px;
}

.all-services .pop-ser-show h2 {
  font-family: 'Bookman';
  font-size: 30px;
  font-weight: 5;
  line-height: 43.2px;
  letter-spacing: 0.05em;
  color: #fff;
  text-transform: uppercase;
  width: 80%;
}

.all-services .pop-ser-hide p {
  font-family: 'Gotham';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #fff;
  width: 80%;
  margin-top: 9px;
}

.all-services .pop-ser-content {
  padding: 16px 30px;
}

.all-services:hover .pop-ser-hide {
  display: flex !important;
  transition: 0.5s all ease-in-out;
}

.pop-ser-hide {
  display: none !important;
  transition: 0.5s all ease-in-out;
}

/* Customer-service-page-css-end */
.plp-desc p>div {
  max-width: 705px;
  margin: auto;
}

.product-fliter.offcanvas-body {
  overflow-y: scroll;
  height: 100%;
  padding-bottom: 80px;
}

.product-fliter.offcanvas-body::-webkit-scrollbar {
  display: none;
}
/* new-lower-cart-modal-css-start */
.new-lower-modal .modal-dialog {
  max-width: 716px;
  margin: 1.75rem auto;
  top: 60px;
}
.new-lower-modal .modal-dialog .modal-content{
  margin: 0 5px;
}

.new-lower-modal .modal-header {
  padding: 20px;
  border-bottom: 0px;
  background: #001E42;
  border-radius: 0px;
}

.new-lower-modal .modal-header .btn-close {
  margin: 0px;
  position: absolute;
  right: 25px;
  opacity: 1;
  background: transparent url(/public/images/lower-close.svg);
  background-position: center;
  background-repeat: no-repeat;
  padding: 0px;
}

.new-lower-modal .modal-content {
  border-radius: 0px;
}

.new-lower-modal .modal-body {
  padding: 30px 50px 40px 50px;
}
.new-lower-modal .modal-body .new-lower-cont{
  max-height: 65vh;
  overflow: auto;
}

.new-lower-modal .n-lower-logo {
  display: contents;
}

.new-lower-modal .new-lower-cont .deal-of h3 {
  color: #001E42;
  font-family: 'Bookman';
  font-size: 27px;
  font-weight: 5;
  line-height: 30.62px;
  letter-spacing: 0.25em;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 13px !important;
}

.new-lower-modal .new-lower-cont .deal-of h2 {
  color: #fff;
  font-family: 'Bookman';
  font-size: 70px;
  font-weight: 5;
  line-height: 79.38px;
  text-align: center;
  background: #001E42;
  text-transform: uppercase;
  width: fit-content;
  margin: 0 auto;
  padding: 0px 43px;
}

.new-lower-modal .new-lower-cont .price-types {
  background-image: url(/public/images/price-bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  margin: 30px 0px 30px 0px;
}

.new-lower-modal .new-lower-cont .price-back {
  max-width: 459px;
  margin: auto;
  padding: 40px 0px;
}

.new-lower-modal .new-lower-cont .price-list {
  border-bottom: 1px solid #001E42;
  padding-bottom: 28px;
  margin-bottom: 28px;
}

.new-lower-modal .new-lower-cont .price-list:last-child {
  margin-bottom: 0px;
  padding-bottom: 0px;
  border-bottom: 0px;
}

.new-lower-modal .new-lower-cont .price-list h3 {
  color: #000000;
  font-family: 'Gotham';
  font-size: 18px;
  font-weight: 400;
  line-height: 17.23px;
  text-align: center;
}

.new-lower-modal .new-lower-cont .price-list h2 {
  color: #001E42;
  font-family: 'Gotham';
  font-size: 18px;
  font-weight: 700;
  line-height: 17.23px;
  text-align: center;
}

.new-lower-modal .new-lower-cont span.over-line {
  color: #8A8A8A;
  text-decoration: line-through;
  font-family: 'Gotham';
  font-size: 18px;
  font-weight: 400;
  line-height: 17.23px;
  text-align: center;
  text-decoration-color: #001E42;
  padding-right: 10px;
}

.new-lower-modal .new-lower-cont .add-cart a {
  background: #C0974F;
  color: #fff;
  display: block;
  width: 100%;
  padding: 14px 0px;
  font-family: 'Gotham';
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
  border: 1px solid #C0974F;
  transition: 0.5s all ease-in-out;
}

.new-lower-modal .new-lower-cont .add-cart a:hover {
  color: #001E42;
  background-color: #fff;
  border: 1px solid #001E42;
  transition: 0.5s all ease-in-out;
}
/* new-lower-cart-modal-css-end */
/* clearance-modal-css-end */
.clearance-modal ul li {
  list-style: disc;
  color: #000;
  font-family: Gotham;
  padding-bottom: 5px;
}
.clearance-modal ul {
  padding-left: 15px;
}
.clearance-modal h2 {
  color: #001e42;
  font-family: Bookman;
  font-size: 27px;
  font-weight: 5;
  letter-spacing: .25em;
  line-height: 30.62px;
  margin-bottom: 13px !important;
  text-align: center;
  text-transform: uppercase;
}
.clearance-modal p {
  color: #000;
  padding-bottom: 10px;
}
.clearance-modal ul li b {
  font-weight: 500;
}
.proposition-modal .new-lower-cont h4 {
  text-align: center;
  padding: 10px 0 5px;
}
/* clearance-modal-css-end */
/* Faq-new-update-css-start */
.faq-new-main {
  margin: 134px 0px 0px 0px;
}

.faq-new-main .faq-new-banner {
  /* background-image: url(../images/faq-bg.png); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 410px;
}

.faq-new-main .faq-new-content h1 {
  color: #fff;
  font-family: 'Bookman';
  font-size: 40px;
  font-weight: 5;
  line-height: 55px;
  letter-spacing: 0.05em;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 2px !important;
}

.faq-new-main .faq-new-content h2 {
  color: #ffff;
  font-family: 'Bookman';
  font-size: 40px;
  font-weight: 500;
  line-height: 45.36px;
  letter-spacing: 0.05em;
  text-align: center;
  padding-bottom: 12px;
  text-transform: uppercase;
}

.faq-new-search {
  background: #ffffff;
  border: 1px solid #ffffff;
  width: 100%;
  margin: auto;
  margin-top: 21px;
  max-width: 659px;
}

.faq-new-search input {
  padding: 14.33px 15px;
  max-width: 90%;
  background: none;
  border: none;
  outline: none;
  width: 100%;
  color: #474747;
  font-size: 14px;
  font-weight: 300;
  font-family: 'Gotham';
  background: transparent;
}

.faq-new-main span.faqnew-rgt-search {
  padding-right: 15px;
  cursor: pointer;
}

.faq-btm {
  padding: 50px 0px 40px 0px;
}

.faq-btm ul.faq-list li {
  margin-bottom: 25px !important;
}

.faq-btm ul.faq-list :last-child {
  margin-bottom: 0px;
}

.faq-btm ul.faq-list li.active a {
  font-weight: 500;
}

.faq-btm ul.faq-list li a {
  color: #001E42;
  font-family: 'Gotham';
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  text-decoration: underline;
  text-underline-offset: 8px;
  text-decoration-color: #001E42;
  text-decoration-thickness: 1px;
}

.faq-btm .faq-new-questions h2 {
  color: #001E42;
  font-family: 'Gotham';
  font-size: 30px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 5px !important;
}

.faq-new-questions .accordion-button p {
  width: 100%;
  padding: 35px 0px;
  font-size: 18px;
  color: #001E42;
  font-family: 'Gotham';
  background-color: #fff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
  font-weight: 500;
  text-align: left;
  max-width: 65%;
  width: 100%;
}

.faq-new-questions .accordion-button {
  padding: 0px;
}

.faq-new-questions .accordion-item {
  background-color: #fff;
  border-bottom: 1px solid #000000 !important;
  border: none;
  border-radius: 0;
}

.faq-new-questions .accordion-flush .accordion-item:last-child {
  border-bottom: 1px solid;
  
}

.faq-new-questions .accordion-button:not(.collapsed) {
  background-color: transparent;
  box-shadow: none;
}

.faq-new-questions .see-more-faqs a {
  font-family: 'Gotham';
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: #001E42;
  border: none;
  outline: none;
  background: transparent;
  text-decoration: underline;
  text-underline-offset: 3px;
  margin-top: 34px;
}

.faq-new-questions .accordion-button::after {
  background-image: url(/public/images/faq-increase.svg);
  background-repeat: no-repeat;
  transition: transform .2s ease-in-out;
}

.faq-new-questions .accordion-button:not(.collapsed)::after {
  background-image: url(/public/images/faq-decrease.svg);
  transform: rotate(0deg);
  transition: transform .2s ease-in-out;
}
.faq-new-questions .accordion-body {
  padding: 0px 0px 10px;
}

.faq-new-questions .accordion-body {
  padding: 20px 0px;
}

.faq-new-questions .accordion-body>div p {
  margin: 15px 0px !important;
}

.faq-new-questions .accordion-body>div p:first-child {
  margin-top: 0px !important;
}
.faq-new-questions .new-acor-content h3 {
  color: #1D1D1B;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  font-family: 'Gotham';
}

.faq-new-questions .new-acor-content p {
  color: #1D1D1B;
  font-family: 'Gotham';
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.faq-new-questions .new-acor-content ul.ship-numb {
  padding: 15px 0px;
  margin-left: 15px;
}

.faq-new-questions .new-acor-content ul.ship-numb li {
  list-style-type: decimal;
  color: #000000;
  font-family: 'Gotham';
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 12px !important;
}

.faq-new-questions .new-acor-content ul.ship-numb li:last-child {
  margin-bottom: 0px;
}

.faq-new-questions .new-acor-content ul.ship-numb li p {
  color: #1D1D1B;
  font-family: 'Gotham';
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
}

.new-acor-content p.ship-para {
  font-family: 'Gotham';
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  color: #1D1D1B;
}

.new-acor-content p.ship-para a.reflect {
  color: #1977F3;
}

.new-acor-content ul.bulet {
  padding: 12px 0px;
}

.new-acor-content ul.bulet li {
  color: #1D1D1B;
  font-family: 'Gotham';
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  list-style-type: disc;
  margin-left: 15px;
}

/* Faq-new-update-css-end */
/* Faq-new-update-left-side-css-start */
.faq-btm .faq-list-accor .accordion-item {
  background-color: #fff;
  border: 0px;
}

.faq-btm .faq-list-accor .accordion-button {
  padding: 0px 0px 30px 0px;
  color: #001E42;
  background-color: #fff;
  border: 0;
  font-family: 'Gotham';
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  text-align: left;
  text-transform: capitalize;
  transition: 0.5s all ease-in-out;
  align-items: baseline;
}

.faq-btm .faq-list-accor .accordion-button:not(.collapsed) {
  font-weight: 500 !important;
  box-shadow: none;
  padding-bottom: 15px;
  transition: 0.5s all ease-in-out;
}

.faq-btm .faq-list-accor .accordion-button {
  text-decoration: underline;
  text-underline-offset: 6px;
  text-decoration-color: #001E42;
  transition: 0.3s all ease-in-out;
}

.faq-btm .faq-list-accor .accordion-button:hover {
  font-weight: 500;
  transition: 0.3s all ease-in-out;
}

.faq-btm .faq-list-accor .accordion-button::after {
  background-repeat: no-repeat;
  background-size: cover;
  transition: transform .2s ease-in-out;
  background-position: center;
  max-width: 12px;
  height: 8px;
  background-image: url(https://hallmanimagesnew.s3-accelerate.amazonaws.com/header/range-drop.svg) !important;
  width: 100%;
  rotate: 180deg;
}

.faq-btm .faq-list-accor ul.faq-list li {
  margin-bottom: 10px !important;
}

.faq-btm .faq-list-accor ul.faq-list li a {
  color: #001E42;
  font-family: 'Gotham';
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  text-decoration: none;
  transition: 0.3s all ease-in-out;
}

.faq-btm .faq-list-accor ul.faq-list li.active a {
  font-weight: 500;
}

.faq-btm .faq-list-accor ul.faq-list li a:hover {
  font-weight: 500;
  transition: 0.3s all ease-in-out;
}

.faq-btm .faq-new-questions {
  max-width: 800px;
  margin-left: auto;
}

.faq-btm .faq-list-accor {
  position: sticky;
  top: 100px;
}

.faq-btm .faq-list-accor .accordion-body {
  padding: 10px 20px !important;
}
/* Faq-new-update-left-side-css-end */
/* Images-carousel-css-start */
.images-carousel {
  padding: 40px 0px;
}

.images-carousel .carousel-main {
  max-width: 1062px;
  margin: auto;
}

.images-carousel .modal-dialog {
  max-width: 100%;
  margin: 0px;
}

.images-carousel .modal-header {
  display: block;
  padding: 10px 50px 10px;
  border-bottom: 0px;
  border-radius: 0px !important;
}

.images-carousel .modal-content {
  border: 0px;
  border-radius: 0px;
  outline: 0;
}

.pages-reach .magnify {
  background: none;
  outline: none;
  border: none;
  margin-bottom: 4px !important;
}

.images-top-label .btn-close {
  opacity: 1;
  top: 1px;
  padding: 0px;
  margin: 0px;
}

.images-top-label .pages-reach {
  gap: 20px;
}

.images-top-label .pages-by p {
  color: #1D1D1B;
  font-family: 'Gotham';
  font-size: 14px;
  font-weight: 400;
  line-height: 13.4px;
  text-align: left;
}

.images-top-label .pages-head h2 {
  color: #001E42;
  font-family: 'Bookman';
  font-size: 25px;
  font-weight: 5;
  line-height: 28.35px;
  letter-spacing: 0.05em;
  text-align: center;
  text-transform: uppercase;
  word-wrap: break-word;
}

.images-carousel .modal-body {
  padding-bottom: 0;
}

.carousel-main .carousel-indicators button {
  height: 51px !important;
  max-width: 51px !important;
  width: 100% !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 1;
}

.carousel-main .carousel-indicators button.active {
  height: 70px !important;
  max-width: 70px !important;
  width: 100% !important;
  outline: 1px solid #828181;
  border: 6px solid #fff;
}

.carousel-main .carousel-indicators {
  position: initial;
  gap: 15px;
  margin-top: 25px;
  align-items: center;
}

.carousel-main .carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 0px;
  margin-left: 0px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 1;
  transition: opacity .6s ease;
}

.carousel-main .carousel-control-prev-icon {
  background-image: url(/public/images/left-side.svg);
  position: absolute;
  left: -90%;
}

.carousel-main .carousel-control-next-icon {
  background-image: url(/public/images/right-side.svg);
  position: absolute;
  right: -90%;
  opacity: 1;
}

.carousel-main .carousel-dark .carousel-control-next-icon,
.carousel-dark .carousel-control-prev-icon {
  filter: invert(0) grayscale(100);
}

.carousel-control-next,
.carousel-control-prev {
  opacity: 1;
}

.carousel-main .carousel-item img {
  transition: 0.5s all ease-in-out;
  object-fit: contain;
  max-height: 707px;
  width: 100%;
}
.carousel-main .carousel-item  {
  width: 100%;
}
.carousel-main .carousel-item img:hover {
  transform: scale(1.05);
  transition: 0.5s all ease-in-out;
  filter: opacity(0.9);
}
.images-carousel div#image-carousel::-webkit-scrollbar {
  display: none;
}
.images-carousel .modal:not(.removeTopRadius) .modal-content {
  border-radius: 0px !important;
}
.images-carousel .modal {
  z-index: 999999;
}
.images-carousel .see-btn button.see-more-img {
  background: transparent;
  max-width: 150px !important;
  width: 100% !important;
  outline: none;
  border: none;
  padding: 7px 10px;
  color: #022741;
  font-family: 'Gotham';
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  text-decoration: underline;
  text-underline-offset: 4px;
  cursor: pointer;
  white-space: nowrap;
  transition: 0.3s all ease-in-out;
}

.images-carousel .see-btn button.see-more-img:hover {
  color: #022742;
  transition: 0.3s all ease-in-out;
}

/* Images-carousel-css-end */
.fade.offcanvas-backdrop.show  {
  opacity: 0.5;
  z-index: 9999;
}
.team-drawer .fade.offcanvas-backdrop.show  {
  z-index: 99;
  opacity: 0.5;
}

.minicart-popup.offcanvas.offcanvas-end {
  z-index: 999;
}

.offcanvas.offcanvas-end.show {
  z-index: 9999999;
}

.team-drawer .offcanvas.offcanvas-end.show {
  z-index: 999999;
}
.share-cart-modal {
  z-index: 999999 !important;
}
.share-cart-modal::-webkit-scrollbar{
  display: none;
}
.new-lower-modal.modal {
  z-index: 999999;
}
.new-lower-modal.modal::-webkit-scrollbar{
  display: none;
}
.yarl__root.yarl__portal_open {
  opacity: 1;
  z-index: 999999 !important;
}
 /* shimmer-effect-color-css-start */
.shimmer {
  background-image: linear-gradient(to right, #f2f1f4 8%, #F5F7F8 18%, #f2f1f4 33%) !important;
  animation: shimmer1 2.2s linear infinite forwards !important;
}

@keyframes shimmer1 {
  0% {
    background-position: -1000px 0;
  }

  100% {
    background-position: 1000px 0;
  }
}

/* shimmer-effect-color-css-start */
/* Panel-ready-button-css-start */
.section-products .product-fliter .ready-select .sel-red {
  display: none;
}

.section-products .product-fliter .ready-select label.sel-leb {
  border: 1px solid #001e42;
  height: 33px;
  background: #fff;
  margin-top: 10px;
  transition: 0.5s all ease-in-out;
  cursor: pointer;
}

.section-products .product-fliter .ready-select label.sel-leb:hover {
  background: #001e42;
  transition: 0.5s all ease-in-out;
}

.section-products .product-fliter .ready-select label.sel-leb:hover p {
  color: #fff;
  transition: 0.5s all ease-in-out;
}

.section-products .product-fliter .ready-select label.sel-leb p {
  color: #001E42;
  font-family: 'Gotham';
  font-size: 14px;
  font-weight: 500;
  line-height: 13.4px;
  text-align: center;
}

.section-products .product-fliter .ready-select span.cust-check {
  background: transparent;
}

.section-products .product-fliter .ready-select input:checked+label .cust-check {
  background-image: url(/public/images/ready-check.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 12px;
  width: 26px;
}

.section-products .product-fliter .ready-select input:checked+label {
  background: #001E42;
  border: 1px solid #001E42;
}

.section-products .product-fliter .ready-select input:checked+label p {
  color: #fff;
}

/* Panel-ready-button-css-end */
/* Sort-by-css-start */
.sorting-by .sort-txt {
  height: 38px;
  border: 1px solid #DEDEDE;
  width: 85%;
}

.sorting-by .sort-txt .form-select:focus {
  border-color: none;
  outline: 0;
  box-shadow: none;
}

.sorting-by .sort-btn {
  border: 0px;
  outline: none;
}

.sorting-by .form-select {
  display: block;
  width: 100%;
  padding: 8px 40px 8px 15px;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  color: #1D1D1B;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: right .75rem center;
  background-size: 12px 14px;
  border: none;
  cursor: pointer;
  border-radius: 0px;
  font-family: "Gotham";
}

.section-products .filter-row {
  display: flex;
  justify-content: space-between;
  padding-bottom: 21.1px;
  align-items: center;
}

.filter-list {
  width: 17%;
}

.section-products .filter-row .showing-filter {
  width: 45%;
}
.section-products .filter-row .clear-filter {
  width: 38%;
  gap: 16px;
}

.section-products .filter-row .sorting-by {
  width: 70%;
  gap: 8px;
}

.section-products .filter-row .sorting-by span.bold {
  width: 15%;
  font-family: 'Gotham';
  font-size: 13px;
  font-weight: 500;
  line-height: 12.44px;
  text-align: left;
  color: #1D1D1B;
  text-transform: capitalize;
}

.section-products .filter-row .sorting-by .sort-btn {
  border: 0px;
  outline: none;
  width: 85%;
}

.section-products .filter-row .sorting-by .sort-btn:after {
  content: '';
  position: absolute;
  border-right: 1px solid #dedede;
  height: 21px;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.section-products .filter-row .sorting-by .arrow-selct {
  width: 15%;
  background: none;
  border: 0px;
  outline: none;
  padding: 11px 5px;
}

.section-products .filter-row .sorting-by .arrow-selct.active .arrow svg {
  rotate: 180deg;
}
/* Sort-by-css-end */
/* product-listing-slider-css-start */
.product-parent .single-product .image-wrapper .carousel ul>li.slide img {
  height: 300px !important;
  transition: 0.5s all ease-in-out;
  object-fit: contain;
  max-width: 369px;
  width: 100%;
}

.product-parent .single-product .product-hover-pic .carousel .slider {
  border: 0px;
}

.product-parent .single-product .product-hover-pic .carousel-control-prev-icon {
  box-shadow: 0px -2px 4px 0px #969696;
  color: #000;
  border-radius: 50%;
  z-index: 9;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
  color: #000;
  border-radius: 50%;
  height: 30px;
  width: 30px;
}

.product-parent .single-product .product-hover-pic .carousel-control-prev-icon {
  background-image: url(https://hallmanimagesnew.s3-accelerate.amazonaws.com/header/crousel-icon.svg);
  transform: rotate(177deg);
  background-size: 8px;
}

.product-parent .single-product .product-hover-pic .carousel-control-next-icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
  color: #000;
  border-radius: 50%;
  z-index: 9;
  background-color: #fff;
  box-shadow: 0px 2px 4px 0px #969696;
  height: 30px;
  width: 30px;
}

.product-parent .single-product .product-hover-pic .carousel-control-next-icon {
  background-image: url(https://hallmanimagesnew.s3-accelerate.amazonaws.com/header/crousel-icon.svg);
  background-size: 8px;
}
.product-parent .single-product .part-1 .image-wrapper:hover img {
  transform: scale(1.05);
  transition: 0.5s all ease-in-out;
  max-width: 369px;
  width: 100%;
}
.product-parent .single-product .part-1 img.Product-list-pic {
  max-width: 369px;
  width: 100%;
  height: 300px;
  object-fit: contain;
}
.product-parent .single-product .product-hover-pic .slider-wrapper {
  flex: 1;
  height: 300px;
  width: 100%;
}

.product-parent .single-product .product-hover-pic .carousel .slider {
  height: 300px;
  max-width: 369px;
  width: 100%;
}
.product-parent .single-product .carousel img {
  width: 100%;
  display: inline-block;
  pointer-events: inherit;
}
/* product-listing-slider-css-end */
/* Listing new slider start */
.single-product .part-1 .front-image {
  height: 303px;
  max-width: 370px;
  width: 100%;
  transition: 0.5s all ease-in-out;
  position: relative;
  z-index: 1;
  object-fit: cover;
}

.single-product .part-1:hover img.front-image {
  transform: scale(1.05);
  transition: 0.5s all ease-in-out;
}

.single-product .part-1 .back-slider {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
}

.single-product .part-1:hover .back-slider {
  opacity: 1;
}

.back-slider .carousel .carousel-inner {
  border-bottom: 6px solid #A18B64;
}

.back-slider .carousel .carousel-inner .carousel-item img {
  height: 303px;
  max-width: 370px;
  width: 100%;
  transition: 0.5s all ease-in-out;
  opacity: 0;
  visibility: hidden;
  object-fit: contain;
}

.back-slider .carousel .carousel-inner .carousel-item:hover {
  transform: scale(1.05);
  transition: 0.5s all ease-in-out;
}

.single-product .part-1:hover .front-image {
  opacity: 0;
}

.single-product .part-1:hover .back-slider .carousel .carousel-inner .carousel-item img {
  opacity: 1;
  visibility: visible;
}

.single-product .back-slider button.carousel-control-prev {
  height: 30px;
  width: 30px;
  margin: auto;
  background: #fff;
  border-radius: 50px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  position: absolute;
  left: 10px;
}

.single-product .back-slider .carousel-control-next {
  height: 30px;
  width: 30px;
  margin: auto;
  background: #fff;
  border-radius: 50px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  position: absolute;
  right: 10px;
}

.single-product .back-slider .carousel-control-next-icon {
  background-image: url(https://hallmanimagesnew.s3-accelerate.amazonaws.com/header/crousel-icon.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 8px;
  margin-left: 4px;
}

.single-product .back-slider .carousel-control-prev-icon {
  background-image: url(https://hallmanimagesnew.s3-accelerate.amazonaws.com/header/crousel-icon.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 8px;
  margin-right: 4px;
  transform: rotate(180deg);
}

/* Listing new slider start */
/* home page slider-start */
.home-page .back-slider .carousel .carousel-inner .carousel-item img {
  height: 100%;
  width: 100%;
  max-width: 100%;
  transition: 0.5s all ease-in-out;
  object-fit: cover;
  aspect-ratio: 1920 / 880;
  opacity: 1;
  visibility: visible;
}

.home-page .back-slider .carousel .carousel-inner .carousel-item:hover {
  transform: none;
  transition: 0.5s all ease-in-out;
}

.home-page .back-slider button.carousel-control-prev {
  height: 30px;
  width: 30px;
  margin: auto;
  background: rgb(255 255 255 / 60%);
  border-radius: 50px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  position: absolute;
  left: 20px;
  transition: 0.3s all ease-in-out;
}

.home-page .back-slider button.carousel-control-prev:hover{
  background: rgb(255 255 255 / 100%);
  transition: 0.3s all ease-in-out;
}

.home-page .back-slider .carousel-control-next {
  height: 30px;
  width: 30px;
  margin: auto;
  background: rgb(255 255 255 / 60%);
  border-radius: 50px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  position: absolute;
  right: 20px;
  transition: 0.3s all ease-in-out;
}

.home-page .back-slider .carousel-control-next:hover{
  background: rgb(255 255 255 / 100%);
  transition: 0.3s all ease-in-out;
}

.home-page .back-slider .carousel-control-next-icon {
  background-image: url(https://hallmanimagesnew.s3-accelerate.amazonaws.com/header/crousel-icon.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 8px;
  margin-left: 3px;
}

.home-page .back-slider .carousel-control-prev-icon {
  background-image: url(https://hallmanimagesnew.s3-accelerate.amazonaws.com/header/crousel-icon.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 8px;
  transform: rotate(180deg);
}

.home-page .back-slider .carousel .carousel-inner{
  border-bottom: 6px solid #A18B64;
  z-index: 1;
  line-height: 0px;
}

#home-page-header:after {
  content: "";
  position: absolute;
  background-color: rgb(0 0 0 / 25%);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
}

.home-page {
  position: relative;
  /* top: -20px; */
  top: 0px;
  z-index: 999;
}

.home-page .back-slider .carousel-item video {
  height: 100%;
  width: 100%;
  object-fit: cover;
  aspect-ratio: 1920 / 880;
}

.home-page .back-slider .carousel-item video source {
  line-height: 0px;
}
/* home page slider-end*/
/* about-updated-css-start */
.about-updates {
  background-image: url(https://hallmanimagesnew.s3-accelerate.amazonaws.com/hallmanusa_cms/about-banner-1.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.about-updates .abt-updts {
  max-width: 1152px;
  margin: auto;
  text-align: center;
}
.about-updates .abt-updts .abt-cont {
  max-width: 984px;
  margin: auto;
  padding: 35px 0px;
}
.abt-updts .abt-cont p {
  color: #001E42;
  font-family: 'Gotham';
  font-size: 16px;
  font-weight: 500;
  line-height: 15.31px;
  letter-spacing: 0.05em;
  text-align: center;
  text-transform: uppercase;
  position: relative;
}
.abt-updts .abt-cont p:after {
  content: '';
  position: absolute;
  top: 40%;
  background: #001E42;
  height: 2px;
  width: 15px;
  transform: translateX(10px);
}
.abt-updts .abt-cont p:before {
  content: '';
  position: absolute;
  top: 40%;
  background: #001E42;
  height: 2px;
  width: 15px;
  transform: translateX(-25px);
}
.abt-updts .abt-cont h1 {
  color: #001E42;
  font-family: 'Bookman';
  font-size: 50px;
  font-weight: 5;
  line-height: 65px;
  letter-spacing: 0.05em;
  text-align: center;
  text-transform: uppercase;
  padding-top: 35px;
}
.about-updates .frames {
  height: 648px;
  max-width: 1152px;
  width: 100%;
}
.about-updates .frames video {
  width: 100%;
  height: 100%;
}
.about-updates .abt-cont h2 {
  color: #1D1D1B;
  font-family: "Bookman";
  font-size: 27px;
  font-weight: 5;
  line-height: 49px;
  letter-spacing: 0.05em;
  text-align: center;
}
.about-updates .story {
  margin: 22px 0px 15px 0px;
  padding: 17px 43px;
  color: #fff;
  background: #C0974F;
  outline: none;
  border: 1px solid #C0974F;
  font-family: 'Gotham';
  font-size: 14px;
  font-weight: 500;
  line-height: 13.4px;
  text-transform: uppercase;
  transition: 0.5s all ease-in-out;
}
.about-updates .story:hover{
  background-color: transparent;
  color: #001E42;
  border: 1px solid #001E42;
  transition: 0.5s all ease-in-out;  
}
/* about-updated-css-end */
div#pre-buy-faq {
  scroll-margin-top: 230px;
}
.real-life-single-pro .each-slide-effect video {
  width: 100%;
  height: 100%;
}
/* Remove the duplicate backdrop element */
/* labor-day-modal-start */
.lab-day-modal.modal {
  padding-top: 60px;
  z-index: 999999999;
  padding-left: 0px !important;
}
.lab-day-modal::-webkit-scrollbar {
  display: none;
}
.lab-day-modal .modal-dialog {
  /* max-width: 1290px; */
  max-width: 669px;
  margin: 1.75rem auto;
}
.lab-day-modal .lft-lbr-area img {
  height: 100%;
  width: 100%;
}
.lab-day-modal .rgt-lbr-area {
  height: 100%;
}
.lab-day-modal .rgt-lbr-area img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.lab-day-modal .btn-close {
  right: 5px;
  top: 2px;
  z-index: 99;
  padding: 0px;
  margin: 0px;
  opacity: 1;
  background: transparent;
}
.lab-day-modal .btn-close svg {
  /* display: none; */
}
.lab-day-modal .modal-content {
  flex-direction: row;
  border: 0px;
  border-radius: 0px;
  outline: 0;
}
.lab-day-modal.show {
  opacity: 1;
  background: rgb(0 0 0 / 80%);
}
/* labor-day-modal-end */
.home-our-best-seller-section .our-single-product-seller p.sku-unit {
  font-family: 'Gotham';
  font-size: 14px;
  font-weight: 400;
  line-height: 13.4px;
  text-align: center;
  color: #1D1D1B;
  padding: 0px;
  padding-top: 18px;
}
.product-parent .single-product p.sku-unit {
  font-family: 'Gotham';
  font-size: 14px;
  font-weight: 400;
  line-height: 13.4px;
  text-align: center;
  color: #1D1D1B;
  padding: 18px 0px 0px 0px !important;
}
.single-product-customizer .show-more-button button{
  color: #022741;
  border-width: 2px;
  padding: 2px 5px;
  border: 1px solid #d5d5d5;
  background: #E6E6E6;
  font-size: 15px;
}
.single-product-customizer .show-more-button button:hover{
  color: #fff;
  background: #C0974F;
}
.single-product-customizer .show-more-button{
  text-align: center;
}
.dashboard-track-order .search-box button {
  color: #fff;
  background: #C0974F;
  text-transform: uppercase;
  border: 1px solid #C0974F;
  transition: 0.5s all ease-in-out;
  font-family: Gotham;
  font-size: 13px;
  font-weight: 500;
  line-height: 12.2px;
  outline: none;
  text-align: center;
  padding: 18px 27px;
  width: 15%;
  opacity: 1;
}
.dashboard-track-order .search-box button.disabled {
  opacity: 0.5;
}
.dashboard-track-order .order-details .status-txt{
  color: #1d1d1b;
  font-family: "Gotham";
  font-size: 15px;
  font-weight: 500;
  margin-top: 15px;
}
.dashboard-track-order .order-details .status-txt span{
  font-family: "Gotham";
  font-weight: 500;
  line-height: 10.48px;
  display: inline-block;
  padding: 7px;
  border-radius: 7px;
  text-transform: capitalize;
}
.dashboard-track-order h3{
  margin-bottom: 15px !important;
  color: #001e42;
  font-family: "Bookman";
  font-size: 25px;
  line-height: 28.35px;
  text-align: left;
  text-transform: uppercase;
  padding-bottom: 11px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dashboard-track-order .track-container {
  border: 1px solid #eaeaea;
  padding: 20px;
  background: #f5f5f5;
}
.dashboard-track-order .input-order, .dashboard-track-order .platform-box select {
  background: #fff;
  border: 1px solid #dadada;
  color: #1d1d1b;
  font-family: Gotham;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  outline: none;
  padding: 14px 10px;
  width: 100%;
  opacity: 1;
}
.dashboard-track-order .input-order.disabled {
  opacity: 0.5;
}
.dashboard-track-order .platform-box {
  margin-bottom: 10px;
}
.dashboard-track-order .search-box{
  display: flex;
  flex-direction: column;
  gap: 10px;
  width:100%;
}
.dashboard-track-order .search-box button:hover{
  background-color: #fff;
  border: 1px solid #001e42;
  color: #0b0b0b;
  transition: all .5s ease-in-out;
}
/* testimonial page css */
.testimonials-container .upload_file {
  align-items: center;
  background-image: repeating-linear-gradient(0deg,#9b9b9b,#9b9b9b 5px,#0000 0,#0000 11px,#9b9b9b 0),repeating-linear-gradient(90deg,#9b9b9b,#9b9b9b 5px,#0000 0,#0000 11px,#9b9b9b 0),repeating-linear-gradient(180deg,#9b9b9b,#9b9b9b 5px,#0000 0,#0000 11px,#9b9b9b 0),repeating-linear-gradient(270deg,#9b9b9b,#9b9b9b 5px,#0000 0,#0000 11px,#9b9b9b 0);
  background-position: 0 0,0 0,100% 0,0 100%;
  background-repeat: no-repeat;
  background-size: 2px 100%,100% 2px,2px 100%,100% 2px;
  border: 1px dashed #0000;
  display: flex;
  height: 213px;
  justify-content: center;
  text-align: center;
}
.testimonials-container .upload_file p {
  font-size: 14px;
  margin-bottom: 0;
}
.testimonials-container .upload_file p span {
  border-bottom: 1px solid;
  color: #001f3f;
  cursor: pointer;
  font-weight: 500;
  margin-right: 0;
  position: relative;
}
.testimonials-container {
  max-width: 707px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 80px;
}
.testimonials-container h2 {
  font-family: 'Bookman';
  font-size: 40px;
  font-weight: 400;
  line-height: 45px;
  letter-spacing: 3px;
  color:  #001E42;
}
.testimonials-container .header-section p {
  font-family: 'Gotham';
  font-size: 17px;
  font-weight: 500;
  line-height: 30px;
  color: #1D1D1B;
}
.testimonials-container .form-section .info {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  margin-bottom: 15px;
}
.testimonials-container .form-section .info div {
  width: 100%;
}
.testimonials-container .name-div, .testimonials-container .email-div, .testimonials-container .testimonial-div {
  display: flex;
  flex-direction: column;
}
span.red.error-message {
  color: red;
  text-align: left;
  padding-top: 2px;
}
.testimonials-container .form-section label {
  text-align: left;
  font-family: 'Gotham';
  font-size: 14px;
  font-weight: 400;
  line-height: 13px;
  color: #1D1D1B;
  padding: 10px 0;
  display: block;
}
.testimonials-container input {
  height: 50px;
  border: 1px solid #DADADA;
  padding: 10px;
}
.testimonials-container textarea {
  border: 1px solid #DADADA;
  padding: 10px;
}
.testimonials-container .testimonial-div {
  margin-bottom: 15px;
}
.testimonials-container .action button {
  width: 157px;
  height: 50px;
  background: #C0974F;
  font-family: 'Gotham';
  font-size: 14px;
  font-weight: 500;
  line-height: 13px;
  color: #fff;
  border: 1px solid #C0974F;
  margin-top: 30px;
}
.testimonials-container .action button:hover {
  background: #fff;
  color: #171717;
  border: 1px solid #001e42;
  transition: all 0.5s;
}

.testimonial-view-container h2 {
  font-family: 'Bookman';
  font-size: 40px;
  font-weight: 400;
  line-height: 54px;
  letter-spacing: 3px;
  color:  #001E42;
  text-transform: uppercase;
}
.testimonial-view-container button {
  width: 194px;
  height: 50px;
  background: #C0974F;
  font-family: 'Gotham';
  font-size: 14px;
  font-weight: 500;
  line-height: 13px;
  color: #fff;
  border: 1px solid #C0974F;
  text-transform: uppercase;
}
.testimonial-view-container .header-section {
  display: flex;
  justify-content: space-between;
}
.testimonial-view-container {
  margin-top: 15px;
}
.testimonials-container span.characters{
  text-align: left;
  padding: 5px;
  font-size: 14px;
  font-weight: 400;
}

.testimonial-list {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: space-between;
}
.testimonial-list .testimonial-view {
  width: 49%;
  margin-bottom: 40px;
}
.testimonial-list p {
  font-weight: 400;
  font-family: 'Gotham';
  font-size: 16px;
  font-style: italic;
  line-height: 30px;
  text-align: center;
  padding: 20px 0px;
}
.testimonial-view-container .testimonial-detail {
  background: #F1F1F1;
  position: relative;
}
.testimonial-view-container p.author-name {
  font-weight: 500;
  line-height: 15px;
  font-style: normal;
  color: #001E42;
  text-align: left;
  padding-top: 40px;
}
.testimonial-view-container .quote-end {
  position: absolute;
  left: 45px;
}
.testimonial-view-container .load-more {
  text-align: center;
  margin-bottom: 80px;
}
.testimonial-list .testimonial-detail p{
  padding: 25px 35px;
}
.testimonial-list .testimonial-detail p img{
  padding-right: 10px;
}
.testimonial-shimmer-container {
  display: flex;
  gap: 10px;
}
.testimonial-shimmer-upper {
  margin-top: 40px;
}
.testimonial-shimmer-container > * {
  flex: 1 1 calc(50% - 10px);
}
.testimonial-view-container .testimonial-image img {
  object-fit: cover;
  width: 100%;
  aspect-ratio: 425/273;
}
.notify-main input[type="text"] {
  background: #fff;
  border: 1px solid #dadada;
  color: #1d1d1b;
  font-family: Gotham;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  outline: none;
  padding: 14px 10px;
  width: 100%;
  opacity: 1;
  margin: 5px 0 2px;
}
.form-btn-site {
  background: #C0974F;
  color: #fff;
  display: block;
  outline: none;
  padding: 18px 25px;
  font-family: 'Gotham';
  font-size: 15px;
  font-weight: 500;
  line-height: 12px;
  text-align: center;
  text-transform: uppercase;
  border: 1px solid #C0974F;
  transition: 0.5s all ease-in-out;
}
.notify-main .form-btn-site {
  margin-top: 10px;
}
.customral-color span {
  text-wrap: nowrap;
}
.customral-color img {
  margin-right: 10px;
}
.cancel-form {
  margin-bottom: 50px;
}
.cancel-form select#orderItems, .my-images-pop select#orderItems {
  height: 100px;
}
.cancel-form select#orderItems option, .my-images-pop select#orderItems option {
  padding: 5px;
}
.cancel-form ul.suggestions, .my-images-pop ul.suggestions {
  max-height: 100px;
  overflow: auto;
  border: 1px solid #b7a9a9;
  padding: 5px;
  margin-bottom: 5px;
}
.cancel-form button.remove-sku, .my-images-pop button.remove-sku {
  background: no-repeat;
  border: none;
  color: red;
  padding-left: 5px;
}
.cancel-form .sku-item, .my-images-pop .sku-item {
  color: #001e42;
  text-align: left;
}
.share-cart-modal.my-img-upload {
  top: 70px;
}
.images-carousel .modal-dialog {
  height: 100%;
}
.images-carousel .modal-content {
  height: 100%;
  border-radius: 0;
}
.share-cart-modal.my-img-upload .modal-dialog {
  top: 0px;
  margin-top: 10px;
}
.share-cart-modal.my-img-upload .modal-content {
  max-height: 90vh;
  overflow: auto;
}
.share-cart-modal.my-img-upload .modal-header{
  position: sticky;
  top: 0;
  z-index: 9;
}

.minicart-price {
  display: flex;
  gap: 10px;
}
.minicart-price p {
  line-height: revert !important;
}
.minicart-price .actual-price {
  text-decoration: line-through;
}
.minicart-price .discount-percent {
  color: #0a8035;
}
.minicart-price .discounted-price {
  font-size: 16px;
}
.cart-price .discounted-price {
  color: #001e42;
  font-family: "Bookman";
  font-size: 20px;
  font-weight: 500;
  line-height: 22.68px;
  letter-spacing: 0.05em;
  text-align: left;
}
.cart-price .actual-price {
  color: #1D1D1B;
  text-decoration: line-through;
  font-size: 14px;
  align-self: flex-end;
  line-height: 15px;
}
.cart-price {
  display: flex;
  gap: 8px;
  margin-top: 8px;
  flex-wrap: wrap;
}
.cart-price .discount-percent {
  color: #0a8035;
  font-size: 14px;
  align-self: flex-end;
  line-height: 15px;
}
.cart-items-main p.ral-fee {
  font-size: 13px;
  line-height: 24px;
}
.cart-items-main p.ral-fee span {
  font-weight: 500;
}
.Toastify__toast-container--top-right{
  z-index: 99999999 !important;
}
.clear-cart a{
  cursor: pointer;
  color: #001E42;
  text-decoration: underline;
  text-align: start;
  font-family: 'Bookman';
  text-transform: uppercase;
}
.cart-main-section .cart-clear{
  padding-top: 19px !important;
}
.cart-clear a {
  cursor: pointer;
}
.cart-main-section .cart-links{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0px;
  border-bottom: 1px solid #eaeaea;
}
.product-slider-single-pro .customral-color p {
  padding: 10px 0;
}
.parts-content {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}
.parts-content .part-item {
  background: #E6E6E6;
  width: calc(33.33% - 10.66px);
  display: flex;
  padding: 10px;
}
.parts-content .part-left {
  margin-right: 10px;
  width: auto;
  min-width: 39px;
}
.parts-content h3 {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  cursor: pointer;
}
.parts-content p {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  word-break: break-all;
  cursor: pointer;
}
.parts-content .parts-action {
  width: 100%;
  display: flex;
  justify-content: center;
}
.parts-content .load-more-btn {
  background: #e6e6e6;
  border: 1px solid #d5d5d5;
  color: #022741;
  font-size: 15px;
  padding: 2px 5px;
}
.parts-content .add-icon {
  width: auto;
  min-width: 20px;
  cursor: pointer;
}
.parts-content .part-right {
  margin-left: 5px;
}
.parts-content .part-middle {
  width: 100%;
}
.parts-modal .modal-inner {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
.parts-modal .add-part-btn {
  background: #c0974f;
  border: 1px solid #c0974f;
  color: #fff;
  display: block;
  font-family: Gotham;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  padding: 10px;
  text-align: center;
  text-transform: uppercase;
  transition: all .5s ease-in-out;
  margin-left: auto;
}
.parts-modal .actions{
  width: 30%;
}
.parts-modal p.sku {
  width: 50%;
  word-break: break-all;
}
.parts-modal p.price {
  width: 20%;
  padding: 0 10px;
}
.parts-modal p.sku b {
  font-weight: 500;
}
.shimm.product-parent{
  padding: 0 10px;
}
.slick-prev:before, .slick-next:before{
  font-size: 25px !important;
}
.slick-prev, .slick-next {
  z-index: 9;
}
.single-product.accessories .image-wrapper{
  display: flex;
  justify-content: center;
}

/* Header bottom css starts */
.header-bottom {
  background: #A18B64;
  top: 86px;
  left: 0;
  width: 100%;
  position: fixed;
  z-index: 9;
}
.header-bottom-inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 6px 0;
  color: #fff;
  font-size: 15px;
  line-height: 15px;
}
.header-bottom .hb-right a {
  color: #fff;
  text-decoration: underline;
  padding-left: 8px;
}
.slick-prev:before, .slick-next:before{
  color: #000 !important;
}
.single-product.accessories h4 {
  margin-top: 10px;
}
h2.frequent-title {
  text-align: center;
  font-size: 30px;
  line-height: 34px;
  font-family: 'Bookman';
  text-transform: uppercase;
  color: #001E42;
  letter-spacing: 2px;
  margin-top: 20px;
  margin-bottom: 40px !important;
}
/* Header bottom css ends */

/* ============Media-Query-start-here=============== */

@media all and (max-width: 1440px) {
  .cate-goreis li a {
    font-size: 14px;
  }
  .slick-prev {
    left: -10px !important;
  }
  .slick-next {
    right: -10px !important;
  }
}
@media only screen and (max-width: 1320px) {
  .product-slider-single-pro .product-slider_single-show.left {
    max-width: 60%;
    width: 100%;
  }
  .product-slider-single-pro .product-slider_single-show.right {
    max-width: 40%;
    width: 100%;
    padding-left: 30px;
  }
  .carousel-main .carousel-control-next-icon{
    right: auto;
  }
  .carousel-main .carousel-control-prev-icon {
    left: auto;
  }
}

@media all and (max-width: 1280px) {
  .home-page .discover-category-section .categorie-image-wrpaer {
    width: 100%;
    padding: 0px;
  }
  .discover-category-section .categorie-image-wrpaer {
    width: 100%;
    /* padding: 0 10px; */
}
  nav #navbarSupportedContent ul#logo-desktop {
    width: 15%;
  }
  .home-new-product-arriving-section .new-product-detail {
    padding-left: 20px;
  }
  .cate-gallery-wraper .cate-gorygallery img {
    height: auto;
  }

  .cate-gallery-wraper .cate-gorygallery h3 {
    font-size: 20px;
    line-height: 33px;
  }
}

@media only screen and (max-width: 1200px) {
  .testimonial-view-container h2 {
    font-size: 38px;
    letter-spacing: 0px;
  }
  div#image-gallery-content .video-pagination {
    padding: 50px 0 0px;
}
  .helpful-link-section {
    margin: 60px 0px 60px;
}
  .cart-page .slider-wrapper .carousel-slider ul.slider {
    gap: 20px;
}

  .responsive-prod-info ul#ex1 {
    min-width: 1200px;
  }
  .single-product-delivery-option .sinolo-outer {
    width: 30%;
}
.single-product-delivery-option .add-to-cart-wraper-single-pro .riht {
  max-width: 40%;
  width: 100%;
}
  .cart-page .our-single-product-seller p.sku-unit {
    padding-top: 18px;
}
  .home-page .back-slider .carousel-item video {
    width: 100%;
    height: 100%;
  }

  /* sort-by-css-start */
  .section-products .filter-row .clear-filter {
    width: 50%;
  }

  .section-products .filter-row .showing-filter {
    width: 40%;
  }

  .sorting-by .sort-txt {
    width: 80%;
  }

  .section-products .filter-row .sorting-by span.bold {
    width: 20%;
  }

  .section-products .filter-row .showing-filter {
    width: 40%;
  }

  /* sort-by-css-end */
  .single-product-delivery-option .comp-wish {
    width: 30%;
    gap: 8px;
    justify-content: end;
  }

  .select-classic {
    height: auto;
  }
  .select-classic-bottom {
    bottom: 30px;
  }
  .product-slider-single-pro .sigle-product-content {
    padding-left: 20px;
  }
  .tow-single-pro-ex-del {
    padding-left: 20px;
  }
  .single-produt-choose-color .form-check-flie .choose-color {
    width: 70px;
  }
  .single-produt-choose-color .form-check-flie .choose-color label {
    width: 70px;
    height: 70px;
  }
  .bannner-botm-space .home-finacing-txt p {
    font-size: 12px;
    white-space: nowrap;
  }
  .select-classic-bottom p {
    font-size: 11px;
  }
  .select-classic-bottom h2 {
    font-size: 20px;
    margin: 2px 0px 0px 0px;
    padding-bottom: 5px;
  }
  .select-classic-bottom p.start {
    font-size: 13px;
    padding-bottom: 8px;
  }
  a.shop-now {
    padding: 10px 32px;
  }
}
@media all and (max-width: 1024px) {
  .service-for-customer {
    margin-top: 40px;
}
      /* about-update-css-start */
      .about-updates .frames {
        height: 100%;
    }
    .abt-updts .abt-cont h1 {
        font-size: 40px;
        line-height: 55px;
        padding-top: 25px;
    }
    /* about-update-css-end */
  .home-our-best-seller-section.upr-space {
    margin-bottom: 50px ;
  }
  .home-page {
    top: 0;
    /* top: 26px; */
  }
  .limited-warrenty-main {
    padding: 50px 0px 50px 0px;
  }

  .popular-manual-light-back {
    margin-top: 0px;
  }

  .home-new-product-arriving-section.become-dealer {
    padding: 30px 0px 30px 0px;
  }

  .shop-with-confidence {
    padding: 30px 0px 0px 0px;
  }

  .confidence-logo-section {
    padding: 30px 0px 30px 0px;
  }

  .showroom_partner_sec .form-group #react-tags-listbox {
    max-width: 360px !important;
    width: 100%;
  }
  /*Single-product-info-section-start */
  .responsive-prod-info ul#ex1 {
    min-width: 1024px;
  }
  .speces-gap {
    row-gap: 30px;
  }
  .prod-info-center .rev-star {
    width: 25%;
  }
  .prod-info-center .rev-stars {
    width: 25%;
  }
  /*  Single-product-info-section-end */
  .we-love-appliances-area {
    max-width: 100%;
    width: 100%;
  }
  .limited-warrenty-main-area {
    overflow: hidden;
  }
  .create-account-steps {
    margin-top: 10px;
  }
  .home-new-product-arriving-section .dealer-detail h2 {
    font-size: 25px;
    line-height: 31px;
  }
  .home-new-product-arriving-section .register-detail h2 {
    font-size: 25px;
    line-height: 38px;
  }
  .warrenty-banner-content h1 {
    font-size: 30px;
    line-height: 30.36px;
  }
  .most-comn-main {
    padding: 30px 10px;
  }
  .select-your-choice-data {
    padding: 30px 0px !important;
  }
  .cart-page-header {
    padding: 7px 0px;
  }
  main {
    /* padding-top: 78px; */
    padding-top: 141px;
  }
  .single-produt-choose-color .form-check-flie {
    gap: 10px;
  }
  .cate-gallery-wraper .cate-gorygallery h3 {
    font-size: 27px;
    padding: 0px 0 22px 0;
  }
  .prd-specification-guid-content p {
    font-size: 12px;
  }
  .logo-slider h3 {
    font-size: 10px;
  }
  .home-buy-with-confidence h2 {
    font-size: 36px;
  }
  nav #navbarSupportedContent ul#logo-desktop {
    width: 15%;
  }
  .home-new-product-arriving-section .new-product-detail h2 {
    font-size: 23px;
  }
  .cate-goreis li a {
    font-size: 14px;
  }
  .select-classic-bottom {
    bottom: 20px;
  }
  .sup {
    padding-left: 20px !important;
  }
  .discover-category-section {
    padding: 30px 0px;
  }
  .select-classic {
    height: auto;
  }
  .copyright-section .review-logo:after {
    display: none;
  }
  .support-area {
    top: 52px;
  }
  .better-together-parent .single-ventil-sub-total .add-cart a {
    font-size: 16px;
    width: 100%;
    padding: 12px 5px;
    line-height: 18.4px;
  }
  .single-product-customizer .product-slider-single-pro .tab-three li {
    width: 75px;
    height: 75px;
  }
  .product-slider-single-pro .product-image-vertical {
    width: 84%;
    height: 420px;
  }
  .product-slider-single-pro .carousel-control-prev-icon {
    padding: 0px;
}
.product-slider-single-pro .carousel-control-next-icon {
  padding: 0px;
}
  .carousel-control-prev-icon {
    padding: 0px;
  }
  .carousel-control-next-icon {
    padding: 0px;
  }
  .single-product-customizer .product-slider-single-pro .tab-three li#contact:after {
    width: 75px;
    height: 75px;
    font-size: 20px;
  }
  .better-together-parent .single-ventil-sub-total .sub-total {
    justify-content: left;
    flex-wrap: wrap;
    gap: 0px;
  }
  .single-product-better-together .better-together-parent {
    padding: 0px;
  }
  /* cart-page-css-start */
  .cart-subtotal-rating h2 {
    font-size: 16px;
  }
  /* cart-page-css-end */
}

@media only screen and (min-width: 991px) {

  li.nav-item:hover .submenu {
    display: block;
  }
  nav .submenu.full-width {
    display: none;
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    overflow: hidden;
    z-index: 99999999999;
    color: #fff;
    background: #fff;
    border-top: 4px solid #a18b64;
    box-shadow: 0px 4px 4px 0px rgb(0 0 0 / 25%);
  }
}
@media only screen and (max-width: 991px) {
  .home-our-best-seller-section .our-single-product-seller h3 {
    min-height: 74px;
}
  .single-product-delivery-option .add-to-cart-wraper-single-pro .riht {
    max-width: 60%;
}
.single-product-delivery-option .comp-wish {
  width: 20%;
}
  .product-slider-single-pro .sigle-product-content .price-section {
    margin-bottom: 30px;
    margin-top: 20px;
  }
  .price-section .single-pricing .prc-imt h3 {
    color: #595959;
    font-family: "Bookman";
    font-size: 16px;
    font-weight: 5;
    line-height: 18.14px;
    letter-spacing: 0.05em;
    text-align: left;
    text-decoration: line-through;
    margin-top: 0px;
}
    /* about-update-css-start */
    .abt-updts .abt-cont h1 {
      font-size: 40px;
      line-height: 50px;
      padding-top: 20px;
  }
  .about-updates .abt-cont h2 {
      font-size: 20px;
      line-height: 35px;
  }
  /* about-update-css-end */
  div#filter-row {
    width: 20% !important;
  }
  div#product-list-itmes {
    width: 80% !important;
  }
  .helpful-link-section {
    margin: 50px 0px 50px;
  }
  .cart-mini-mobile img.cart-in {
    height: auto;
    max-width: 30px;
  }
  .top-search-bar {
    top: 64px;
  }
  .offcanvas.offcanvas-end.show {
    z-index: 9999999;
  }
  .confirm-popup.confirming {
    z-index: 9999999;
  }
  .showroom_partner_sec .form-group #react-tags-listbox {
    max-width: 450px !important;
    width: 100%;
  }
  .checked-extend-single .extend-by {
    align-items: start !important;
  }
  .new-lower-modal {
    padding-left: 0px !important;
  }
  .single-product-delivery-option .add-to-cart-wraper-single-pro {
    align-items: start;
  }
  .custom-loader,
  .show-brown-border-home {
    top: 60px !important;
  }

  .product-slider-single-pro .quick-gap.main-area {
    flex-wrap: wrap;
  }
  .product-slider-single-pro .product-slider_single-show.left {
    max-width: 100%;
    width: 100%;
  }
  .product-slider-single-pro .product-slider_single-show.right {
    max-width: 100%;
    width: 100%;
    padding-left: 0px;
  }
  label.slect-time img {
    height: 100%;
    width: 100%;
  }
  .tow-single-pro-ex-del {
    padding-left: 0px;
  }
  .product-slider-single-pro .sigle-product-content {
    padding-left: 0px;
  }
  /* offcanvas-menu-start */
  .menu-accor .menu-outer {
    background: #fff;
    padding: 20px;
    border-radius: 5px;
  }
  .menu-accor .menu-outer .nav-item {
    margin-bottom: 20px;
  }
  .menu-outer .nav-item:last-child {
    margin-bottom: 0px;
  }
  .pic-onee-txt p>a {
    color: #ffffff;
    padding: 20px;
    display: flex;
    justify-content: center;
  }
  .pic-onee {
    height: 100%;
    width: 100%;
  }
  .prod-outer a.picture-one {
    width: 50%;
    display: grid;
  }

  .prod-outer {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    row-gap: 30px;
}
  .menu-outer .nav-item .support-txt p {
    border-bottom: 2px solid transparent;
    padding-bottom: 1px;
    transition: 0.3s all ease-in-out;
  }
  .menu-outer .nav-item:hover .support-txt {
    border-bottom: 2px solid #C0974F;
    transition: 0.3s all ease-in-out;
  }
  /* offcanvas-menu-end */
  /* Team-page-css-start */
  .my-team {
    padding: 30px 0px 30px 0px;
  }
  .tean-btm-area {
    padding: 30px 0px 0px 0px;
  }
  .my-team-area a {
    width: 100%;
  }
  .my-team h1 {
    font-size: 30px;
    line-height: 40.36px;
  }
  .my-team-area .team-text {
    margin-top: 15px;
    margin-bottom: 15px;
}
.my-team-area .profile-over .team-text h2 {
    font-size: 18px;
    padding-bottom: 0px;
  }
  .my-team-area .profile-over {
    width: 100%;
}
  .row.gx-3.spex {
    row-gap: 30px;
  }
  .tean-btm-area .abt-content p {
    font-size: 18px;
    line-height: 35px;
  }
  .teams .team-discp {
    padding: 31px 15px 30px 15px;
    background-color: #fff;
  }
  .teams .offcanvas-header {
    padding: 30px 15px;
  }
  .teams .team-discp .profile-desc-rgt h1 {
    font-size: 30px;
    line-height: 40.36px;
  }
  .teams .team-discp .profile-desc-rgt h2 {
    font-size: 30px;
    line-height: 40px;
    margin: 15px 0px;
  }
  .teams .team-discp .profile-desc-rgt p {
    font-size: 18px;
    line-height: 35px;
    margin-bottom: 22px;
  }
  /* Team-page-css-end */
  /* About-us page-css-start  */
  .About-banner .abt-content h1 {
    font-size: 40px;
    line-height: 50px;
  }
  .About-banner .abt-content p {
    font-size: 23px;
    line-height: 40px;
  }
  .abt-parts-main:after {
    display: none;
  }
  .abt-parts-one:before {
    display: none;
  }
  .abt-parts-one:after {
    display: none;
  }
  .abt-parts-two:after {
    display: none
  }
  .abt-parts-two span.mid-star {
    display: none;
  }
  .abt-parts-one {
    padding: 75px 0px 35px 0px;
  }
  .abt-parts-two {
    padding: 35px 0px 35px 0px;
  }
  .abt-parts-two .moves-to-text h2 {
    font-size: 40px;
    line-height: 50px;
  }
  .abt-parts-two .moves-to-text h2 br {
    display: none;
  }
  .abt-parts-two .moves-to-text h3 {
    font-size: 40px;
    line-height: 50px;
  }
  .abt-parts-two .moves-to-image.right {
    right: 0px;
  }
  .abt-parts-one .moves-to-text h2 {
    font-size: 40px;
    line-height: 50px;
  }
  .abt-parts-one .moves-to-text h3 {
    font-size: 40px;
    line-height: 50px;
  }
  .abt-parts-three span.mid-star {
    display: none;
  }
  .abt-parts-three:before {
    display: none;
  }
  .abt-parts-three {
    padding: 35px 0px;
  }
  .abt-parts-three .moves-to-text h2 {
    font-size: 40px;
    line-height: 50px;
  }
  .abt-parts-three .moves-to-text h2 br {
    display: none;
  }
  .abt-parts-three .moves-to-text h3 {
    font-size: 40px;
    line-height: 50px;
  }
  .abt-parts-three:after {
    display: none;
  }
  .abt-parts-four span.mid-star {
    display: none;
  }
  .abt-parts-four:after {
    display: none;
  }
  .abt-parts-four {
    padding: 35px 0px;
  }
  .abt-parts-four .moves-to-text h2 {
    font-size: 40px;
    line-height: 50px;
  }
  .abt-parts-four .moves-to-text h2 br {
    display: none;
  }
  .abt-parts-four .moves-to-text h3 {
    font-size: 40px;
    line-height: 50px;
  }
  .abt-parts-five span.mid-star {
    display: none;
  }
  .abt-parts-five:before {
    display: none;
  }
  .abt-parts-five {
    padding: 35px 0px 35px 0px;
  }
  .abt-parts-five .moves-to-image.left {
    margin-right: 0px;
  }
  .abt-parts-five .moves-to-text h2 {
    font-size: 40px;
    line-height: 50px;
  }
  .abt-parts-five .moves-to-text h3 {
    font-size: 40px;
    line-height: 50px;
  }
  .abt-parts-five:after {
    display: none;
  }
  .abt-parts-four .moves-to-image.right {
    margin-left: 0px;
  }
  .abt-parts-six span.mid-star {
    display: none;
  }
  .abt-parts-six:before {
    display: none;
  }
  .abt-parts-six .moves-to-text h2 {
    font-size: 40px;
    line-height: 50px;
  }
  .abt-parts-six .moves-to-text h3 {
    font-size: 40px;
    line-height: 50px;
  }
  .abt-parts-six:after {
    display: none;
  }
  .abt-parts-six {
    padding: 35px 0px 35px 0px;
  }
  .abt-parts-seven:before {
    display: none;
  }
  .abt-parts-seven {
    padding: 35px 0px 35px 0px;
  }
  .abt-parts-seven:after {
    display: none;
  }
  .abt-parts-seven .moves-to-text h2 {
    font-size: 40px;
    line-height: 50px;
  }
  .abt-parts-seven .moves-to-text h3 {
    font-size: 40px;
    line-height: 50px;
  }
  .abt-parts-eight .moves-to-image.left {
    margin-right: 0px;
  }
  .abt-parts-eight:after {
    display: none;
  }
  .abt-parts-eight .moves-to-text h2 {
    font-size: 40px;
    line-height: 50px;
  }
  .abt-parts-eight .moves-to-text h3 {
    font-size: 40px;
    line-height: 50px;
  }
  .abt-parts-nine span.mid-star {
    display: none;
  }
  .abt-parts-nine:after {
    display: none;
  }
  .abt-parts-eight {
    padding: 35px 0px 35px 0px;
  }
  .abt-parts-nine {
    padding: 35px 0px 35px 0px;
  }
  .abt-parts-nine .moves-to-text h2 {
    font-size: 40px;
    line-height: 50px;
  }
  .abt-parts-nine .moves-to-text h2 br {
    display: none;
  }
  .abt-parts-nine .moves-to-text h3 {
    font-size: 40px;
    line-height: 50px;
  }
  .abt-parts-ten span.mid-star {
    display: none;
  }
  .abt-parts-ten:before {
    display: none;
  }
  .abt-parts-ten:after {
    display: none;
  }
  .abt-parts-ten .moves-to-text h2 {
    font-size: 40px;
    line-height: 50px;
  }
  .abt-parts-ten .moves-to-text h3 {
    font-size: 40px;
    line-height: 50px;
  }
  .abt-parts-eleven span.mid-star {
    display: none;
  }
  .abt-parts-ten {
    padding: 35px 0px 35px 0px;
  }
  .abt-parts-eleven:after {
    display: none;
  }
  .abt-parts-eleven {
    padding: 35px 0px 35px 0px;
  }
  .abt-parts-eleven .moves-to-text h2 {
    font-size: 40px;
    line-height: 50px;
  }
  .abt-parts-eleven .moves-to-text h3 {
    font-size: 40px;
    line-height: 50px;
  }
  .abt-parts-twelve span.mid-star {
    display: none;
  }
  .abt-parts-twelve:before {
    display: none;
  }
  .abt-parts-twelve {
    padding: 35px 0px 35px 0px;
  }
  .moves-to-image {
    padding-right: 0px;
  }
  .abt-parts-twelve .moves-to-text h2 {
    font-size: 40px;
    line-height: 50px;
  }
  .abt-parts-twelve .moves-to-text h3 {
    font-size: 40px;
    line-height: 50px;
  }
  .abt-parts-twelve:after {
    display: none;
  }
  .abt-parts-final span.mid-star {
    display: none;
  }
  .abt-parts-final:after {
    display: none;
  }
  .abt-parts-final {
    padding: 35px 0px 35px 0px;
  }
  .abt-parts-final .moves-to-text h2 {
    font-size: 40px;
    line-height: 50px;
  }
  .abt-parts-final .moves-to-text h3 {
    font-size: 40px;
    line-height: 50px;
  }
  span.botm-star {
    display: none;
  }
  .abt-parts-nine .moves-to-image.right {
    margin-left: 0px;
  }
  .abt-parts-final .moves-to-image.right {
    margin-left: 0px;
  }
  .abt-parts-eleven .moves-to-image.right.el {
    position: relative;
    padding-left: 0px;
  }
  span.top-star {
    display: none;
  }
  .abt-parts-eight span.mid-star {
    display: none;
  }
  .abt-parts-eight:before {
    display: none;
  }

  .abt-parts-one .moves-to-text {
    margin-top: 30px;
  }
  .moves-to-text {
    margin-top: 30px;
  }
  .row.gx-0.align-items-center.first {
    display: flex;
    flex-direction: column-reverse;
  }
  .row.gx-0.align-items-center.second {
    display: flex;
    flex-direction: column-reverse;
  }
  .abt-parts-seven span.mid-star {
    display: none;
  }
  /* About-us page-css-end */
  .hallman-create-account-back,
  .hallman-Login-top {
    max-width: 90%;
  }
  .home-new-product-arriving-section .register-detail h2 {
    font-size: 20px;
    line-height: 30px;
  }
  .warrenty-banner-content h1 {
    font-size: 30px;
    line-height: 25.36px;
  }
  .limited-warrenty-main {
    padding: 30px 0px 30px 0px;
  }
  /* customer-service-page-css-start */
  .row.mt-4.customer-intes-gap {
    row-gap: 20px;
  }
  .service-for-customer {
    margin-top: 49px;
  }
  .all-services .pop-ser-show h2 {
    font-size: 24px;
    line-height: 30.2px;
    width: 100%;
  }
  .home-new-product-arriving-section.become-dealer {
    padding: 50px 0px 50px 0px;
  }
  /* customer-service-page-css-end */
  /* faq-qustion-page-css-start */
  .warrenty-banner-content.ques h1 {
    font-size: 30px;
    ;
    line-height: 38px;
    width: 100%;
  }
  /* faq-qustion-page-css-end */
  /* limited-warranty-one-page-css-start */
  .limited-warrenty-main-area {
    margin: 30px 0px 0px 0px;
  }
  .limited-warranty-main-right {
    padding: 20px 20px 60px 20px;
  }
  .limited-warranty-main-left {
    margin: 20px 0px 0px 20px;
  }
  /* limited-warranty-one-page-css-end */
  /* faq-page-css-start */
  .most-common-text h5 {
    font-size: 25px;
  }

  .most-common-text h3.cmn {
    font-size: 25px;
  }
  .most-comn-main img {
    height: 130px;
  }
  .common-all {
    padding: 30px 0px 40px 0px;
  }
  .more-frequent-ask {
    padding: 50px 0px 50px 0px;
  }
  /* faq-page-css-end */
  /* create-acount-page-css-start */
  .hallman-signin-one.log {
    /* height: 250px; */
    height: 100%;
  }
  .hallman-signin-two.log {
    height: 250px;
  }
  .hallman-signin-three.log {
    height: 250px;
  }
  .hallman-signin-four.log {
    height: 250px;
  }
  .hallman-signin-five.log {
    height: 250px;
  }
  .create-account-steps {
    margin-bottom: 50px;
  }
  /* create-acount-page-css-end */
  /* mobile-cart-mobile-css-start */
  .mobile-cart {
    position: relative;
  }
  .cart-value p {
    min-height: 15px;
    min-width: 15px;
    max-height: 15px;
    max-width: fit-content;
    border: 1px solid #fff;
    font-size: 10px;
  }
  /* mobile-cart-mobile-css-end */
  /* Login-page-css-start */
  .hallman-Login-left {
    padding: 80px 80px;
  }
  .hallman-Login-right {
    height: 250px;
  }
  /* Login-page-css-end*/
  /* warranty-page-css-start */
  .limited-warrenty-main .warrenty-banner-content h1 {
    font-size: 30px;
    line-height: 25.36px;
  }
  .limited-warrenty-main .warrenty-banner-content p {
    font-size: 16px;
    line-height: 30px;
    width: 100%;
  }
  .love-appliances-top {
    padding-bottom: 44px;
  }
  /* warranty-page-css-end */
  /* cart-css-start */
  .order-summary-content {
    width: 100%;
    margin-top: 50px;
  }
  /* cart-css-end */

  nav.navbar.navbar-expand-lg.head-back {
    height: 64px;
  }
  .cart-logo img {
    max-width: 130px;
    width: 100%;
  }
  main {
    /* padding-top: 63px; */
    padding-top: 89px;
  }
  .select-your-choice-data,
  .banner-list,
  .single-product-customizer,
  .section-products.result-amt {
    padding: 50px 0px 0px 0px;
  }
  .cart-value {
    position: absolute;
    top: -2px;
    right: -12px;
  }
  .cart-value p {
    border: 1px solid #fff;
    font-size: 11px;
    height: 18px !important;
    margin-left: 1px;
    max-height: 18px;
    max-width: fit-content;
    min-height: 18px;
    min-width: 18px;
    text-shadow: 0 0 7px #000;
    width: 18px !important;
    padding: 6px 5px 4px 5px;
  }
  .single-product-navigation {
    /* top: 64px; */
    top: 90px;
  }
  li.pros-data-list {
    padding: 5px 10px 10px 10px;
  }
  a.navbar-brand.mobile-logo img {
    width: 80%;
  }
  .navbar.navbar-expand-lg .navbar-toggler {
    padding: 2px 5px;
    font-size: 13px;
  }
  .not-for-mobile {
    display: none;
  }
  .main-top-title h2 {
    font-size: 15px;
  }
  .navbar-nav .nav-item #dropdown .products-main-list .list-anchor>h4 a {
    font-size: 13px !important;
    padding-top: 14px !important;
  }
  .head-items .drop-down {
    width: 100% !important;
    max-width: 100%;
    z-index: 99;
    top: 100%;
  }

  nav .head-items .submenu {
    top: 100%;
  }
  nav .head-items .submenu.account {
    top: 100%;
  }
  nav .navbar-collapse {
    z-index: 9;
    position: absolute;
    top: 60px;
    border-top: 4px solid #a18b64;
    width: 100%;
    background-color: #001e42;
    right: 0;
  }
  .navbar-nav .dropdown-menu {
    overflow-y: scroll;
    max-height: 500px;
  }
  .head-items .navbar-collapse .product-dropdown {
    display: flex;
    justify-content: space-between;
    margin: 15px 20px;
    padding-left: 0px !important;
  }
  ul.pros-data.dropdown-item:focus,
  .dropdown-item:hover {
    color: #1e2125;
    background-color: transparent;
  }
  .pros-data {
    margin-top: 10px;
  }
  ul#right-menu {
    margin-bottom: 0px !important;
    flex-direction: row;
  }
  nav .navbar-nav .nav-item {
    width: 100%;
    position: static;
  }
  nav .navbar-nav .nav-item {
    width: 100%;
    position: static;
  }
  .head-items {
    display: flex;
    align-items: center;
    vertical-align: middle !important;
  }
  #home-page-header #right-menu .nav-item a:last-child {
    padding-right: 0;
    padding-left: 20px;
  }
  /* .right-large-menu-side {} */
  .dropdown-toggle.show:after {
    transform: rotate(180deg);
  }
  .navbar .mobile-logo {
    display: block;
  }
  .navbar-light .navbar-toggler {
    border-color: #fff;
    border-radius: 5px;
  }
  .navbar-light .navbar-toggler-icon {
    filter: invert(1);
  }
  nav #navbarSupportedContent ul#logo-desktop {
    display: none;
  }
  nav ul#left-menu {
    width: 100%;
    height: 100%;
  }
  nav #right-menu {
    width: 100%;
    align-items: normal;
  }
  .search-item-top {
    width: 100%;
  }
  .sup {
    padding-left: 20px !important;
    margin-left: 0px;
  }
  .submenu.account {
    right: 10px;
    max-width: 200px;
    left: auto;
    min-width: 200px;
  }
  .navbar-nav .nav-item {
    width: 100%;
    position: static;
  }
  .support-list-parts {
    padding: 10px 0px 10px 10px;
  }
  #home-page-header #right-menu .nav-item a:last-child {
    padding-right: 0;
    padding-left: 20px;
  }
  .submenu li.support-list-parts .dropdown-item {
    padding-left: 0px !important;
  }
  .navbar-nav .nav-item>a {
    font-size: 16px !important;
  }
  .shiping-list .shiping-conteted h2 {
    padding: 15px 0;
  }
  .discover-category-section .categorie-image-wrpaer .shop-now {
    margin-bottom: 50px;
  }
  .select-classic-bottom h2 {
    font-size: 18px;
    line-height: 20px;
  }
  .home-buy-with-confidence h2 {
    font-size: 30px;
    line-height: 45px;
  }
  .home-our-best-seller-section .our-single-product-seller {
    margin-bottom: 30px;
  }
  .discover-category-section .categorie-image-wrpaer a img {
    width: 100%;
    aspect-ratio: 1 / 1;
    height: auto;
  }
  .mobile-show-links {
    margin-right: 20px;
    display: block;
  }
  svg.ico-cent {
    height: 10px;
    width: 21px;
  }
  .picture-all {
    justify-content: start;
    flex-wrap: wrap;
    row-gap: 30px;
    margin: 30px 0px;
    overflow-y: scroll;
    height: 400px;
  }
  .picture-all::-webkit-scrollbar {
    display: none !important;
  }
  .picture-one {
    width: 33.33%;
  }
  .home-finacing {
    gap: 41px;
    justify-content: left;
  }
  .finacing-home.justify-content-end {
    justify-content: start !important;
  }
  .limited-space {
    margin-left: 0px;
  }
  svg.finace-icon-show {
    display: block;
  }
  .home-finacings {
    gap: 41px;
  }
  .bannner-botm-space {
    row-gap: 30px;
  }
  .bannner-botm-space .home-finacing-txt p {
    font-size: 16px;
  }
  /* cart-css-start */
  .order-summary-content {
    width: 100%;
    margin-top: 50px;
  }
  .cart-main-section {
    margin-top: 30px;
  }
  /* cart-css-end */
  .header-bottom {
    top: 64px;
  }
  .header-bottom-inner p {
    font-size: 13px;
    line-height: 14px;
  }
}
@media all and (max-width: 800px) {
  .custom-loader,
  .show-brown-border-home {
    top: 60px !important;
  }
  .logo-slider h3 {
    font-size: 10px;
  }
}
/* responsive for mobile css start here */
/* responsive for mobile css start here */
@media all and (max-width: 851px) {
  div#product-list-itmes {
    width: 100% !important;
}
  .discover-category-section h1.heading {
    font-size: 25px;
    padding-bottom: 20px;
  }

  .discover-category-section .row .categorie-image-wrpaer h2 {
    padding-bottom: 0px;
    font-size: 15px;
    padding-top: 10px;
  }

  /* Customer-training vide-page-css-start */
  .customer-training-tabs-section ul#image-gallery {
    min-width: 500px;
    margin-bottom: 0px;
  }
  .cust-respons .nav {
    display: flex;
    flex-wrap: nowrap;
  }
  .customer-training-tabs-section .nav-link {
    text-wrap: nowrap;
    padding: 0px 30px;
  }
  .cust-respons {
    margin-bottom: 30px;
  }
  .cust-respons::-webkit-scrollbar {
    display: none;
  }
  .customer-training-tabs-section .nav-tabs {
    border-bottom: 0px;
  }
  .customer-training-main .customer-title h1 {
    font-size: 30px;
    line-height: 35.36px;
  }
  div#image-gallery-content .video-pagination {
    padding: 50px 0px 20px 0px;
  }
  .custom-tile .cust-content-lft h2 {
    font-weight: 500;
    max-width: 90%;
    font-size: 16px;
    line-height: 23px;
  }
  .custom-tile .cust-content {
    left: 20px;
    right: 20px;
    bottom: 20px;
  }
  .overlays-images:after {
    top: 0%;
  }
  .overlay-images:after {
    top: 40%;
  }
  /* Customer-training vide-page-css-end */
  .single-product .part-1 img.Product-list-pic {
    aspect-ratio: 1 / 1;
    height: auto;
    max-width: 100%;
    width: 100%;
  }
  img.product-hover-pic,  div.product-hover-pic {
    aspect-ratio: 1 / 1;
    height: auto;
    max-width: 100%;
    width: 100%;
  }
  .bannner-botm-space  .home-finacing-txt p {
    white-space: break-spaces;
  }
  ul#dropdown {
    position: absolute;
    left: auto;
  }
  .clear-filter {
    width: auto;
  }
  /* home page css start here */
  nav .navbar-collapse {
    z-index: 9;
    position: absolute;
    top: 60px;
    width: 100%;
    background-color: #001e42;
    right: 0;
  }
  .home-new-product-arriving-section .new-product-detail {
    padding: 0px 20px;
  }
  .real-life-single-pro .slideshow-container {
    height: auto;
  }
  .banner-list img {
    width: 100%;
    object-fit: cover;
    min-height: auto;
  }
  .banner-list h1 {
    padding-bottom: 0px;
  }
  .home-our-best-seller-section .single-product-seller .image-hover img {
    width: 100%;
    height: 100%;
  }
  .discover-category-section .categorie-image-wrpaer {
    overflow: hidden;
  }
  #product-list-itmes .single-product .part-1 .lower-cart-im {
    margin-bottom: 5px;
  }
  .home-our-best-seller-section .single-product-seller:hover .image-hover {
    transform: scale(0.7);
  }
  .home-new-product-arriving-section .new-product-detail a {
    margin-bottom: 10px;
  }
  .discover-category-section .categorie-image-wrpaer {
    margin-right: 0px;
    padding: 0;
  }
  .discover-category-section h2.heading {
    font-size: 25px;
    padding-bottom: 20px;
  }
  .discover-category-section .row .categorie-image-wrpaer h3 {
    padding-bottom: 0px;
    font-size: 15px;
    padding-top: 10px;
  }
  .discover-category-section .cate-gallery-wraper {
    padding-top: 0px;
  }
  .cate-gallery-wraper .cate-gorygallery img {
    width: 655px;
    height: auto;
  }
  .cate-gallery-wraper .cate-gorygallery h3 {
    font-size: 17px;
    text-align: left;
    line-height: inherit;
    padding: 24px 0 6px 0;
  }
  .cate-gallery-wraper .cate-gorygallery .shop-now {
    margin-bottom: 50px;
  }
  .home-banner-content h1,
  .home-banner-content .banner-title {
    font-size: 17px;
    line-height: 30px;
    color: #f6f5ee;
    padding-bottom: 2px;
    margin-bottom: .5rem !important;
  }
  .home-banner-content p {
    font-size: 14px;
    line-height: 27px;
  }
  .home-buy-with-confidence h2 {
    font-size: 24px;
    line-height: 46px;
  }
  .home-buy-with-confidence {
    padding: 50px 0;
  }
  .home-new-product-arriving-section .new-product-detail h2 {
    font-size: 18px;
    line-height: 26px;
  }
  .home-new-product-arriving-section .new-product-detail a {
    height: 39px;
  }
  .logo-slider h3 {
    font-size: 21px;
    white-space: nowrap;
    margin-bottom: 0px;
  }
  .logo-slider .swiper-slide img {
    width: 25px;
    height: 25px;
  }
  .logo-slider .swiper-slide {
    gap: 20px;
    align-items: center !important;
  }
  /* home page css end here */
  .banner-list h1 {
    font-size: 23px;
  }
  .banner-list .plp-desc p {
    font-size: 13px;
    line-height: 22px;
  }
  .banner-list .plp-desc button {
    font-size: 13px;
    line-height: 22px;
  }
  footer .newsletter button {
    margin-top: 38px;
  }
  footer .newsletter p {
    font-size: 14px;
  }
  footer .newsletter h4 {
    font-size: 20px;
  }
  .navbar #logo-desktop li {
    display: none;
  }
  #product-list-itmes .col-sm-4 {
    width: 50%;
  }
  .shiping-list .shiping-conteted h2 {
    gap: 20px;
  }
  .single-product .part-1 h4 {
    font-size: 14px;
  }
  .shiping-list .shiping-conteted h3 img {
    width: 25px;
    height: 25px;
  }
  footer .cate-goreis h2 {
    font-size: 15px;
  }
  .single-product .part-1 p {
    line-height: 20px;
    font-size: 13px;
  }
  nav #logo-desktop {
    display: none;
  }
  footer .newsletter {
    margin-bottom: 20px;
  }
  .cate-goreis li a {
    font-size: 13px;
  }
  footer .row .cate-goreis {
    margin-bottom: 20px;
  }
  footer {
    padding: 52.16px 0 50px 0;
  }
  .copyright-section .copyright-email {
    padding: 20px 0;
  }
  .copyright-section .review-logo:after {
    background-color: transparent;
  }
  .copyright-section .review-logo {
    justify-content: center;
    gap: 14px;
  }
  #filter-row .sidebar {
    display: block;
    left: -6px;
  }
  .filter-desktop {
    display: none;
  }
  .section-products .product-fliter .price-ranged .slider-track {
    background-color: #000;
  }
  .section-products .product-fliter .price-ranged #range1 {
    left: 21%;
  }
  #filter-row #mySidebar {
    background: #fafafa;
    box-shadow: 1px 1px 4px 2px #ccc;
  }
  #filter-row .sidebar .closebtn {
    color: #000;
  }
  .section-products .filter-row h2 {
    font-size: 18px;
  }
  .section-products .filter-row .clear-filter h3 {
    font-size: 14px;
  }
  .section-products {
    padding: 30px 0px;
  }
  .section-products .product-fliter .price-ranged input[type="range"] {
    width: 59%;
  }
  #mySidebar .list-fliter-aro {
    padding: 20px;
  }
  .single-produt-choose-color .form-check-flie {
    gap: 14px;
  }
  .single-product-delivery-option .add-to-cart-wraper-single-pro .cart-button a {
    width: 100%;
  }
  .single-product-specification .part-accordin-two .accordion-item h2 button {
    padding: 11px 12px;
  }
  .single-product-specification-body {
    padding: 8px;
  }
  .single-spci-manual-table .manual-cont th {
    width: 100%;
  }
  .single-pro-spread-sheet .single-note-content {
    padding: 20px;
  }
  .single-pro-spread-sheet .single-note-content li {
    font-size: 12px;
  }
  .better-together-parent .single-ventil {
    margin-bottom: 40px;
    width: 100%;
  }
  .better-together-parent .plsu_single-ventiled {
    justify-content: space-between;
  }
  .better-together-parent .plsu_single-ventiled .plus-singl-pro {
    padding-bottom: 43%;
    position: absolute;
    right: 4%;
  }
  .better-together-parent .single-ventil .select-itmes {
    padding-bottom: 20px;
  }
  .product-slider-single-pro .tow-single-pro-ex-del {
    padding: 0px;
  }
  .single-product-navigation {
    padding: 10px 0 10px 0px;
  }
  .single-product-navigation nav ul li a {
    font-size: 12px;
    white-space: nowrap;
  }
  .single-product-navigation nav ul {
    flex-wrap: wrap;
    column-gap: 10px;
  }
  .single-product-customizer h2.single-product-heading {
    font-size: 17px;
    line-height: 30px;
  }
  .extend-warranty-single-pro .checked-extend-single {
    gap: 10px;
  }
  .product-slider-single-pro .customizer-product {
    flex-direction: column-reverse;
  }
  .product-slider-single-pro .customizer-product ul {
    display: flex;
    width: 100%;
    gap: 10px;
    height: auto;
    margin: 30px 0 30px 0;
    flex-wrap: nowrap;
  }
  .product-slider-single-pro .product-image-vertical {
    height: auto;
    width: 100%;
  }
  .product-slider-single-pro .product-image-vertical img {
    width: 100%;
    height: 300px;
  }
  .product-slider-single-pro .sigle-product-content {
    padding-left: 0px;
  }
  .extend-warranty-single-pro .extend-by label {
    font-size: 14px;
  }
  .single-product-better-together {
    padding-top: 20px;
  }
  .single-product-better-together h3.better-together {
    padding-bottom: 15px;
  }
  .home-new-product-arriving-section {
    padding: 50px 15px;
  }
  .better-together-parent .single-ventil-sub-total .sub-total .subtotal-rating h2 {
    font-size: 25px;
  }
  .single-produt-choose-color .form-check-flie .choose-color label {
    margin: 0 auto;
  }
  .single-produt-choose-color .form-check-flie .choose-color {
    text-align: center;
    width: 100%;
    height: 100%;
  }
  .product-slider-single-pro .sigle-product-content .accordion-button:not(.collapsed)::after {
    background-size: 14px;
  }
  .product-slider-single-pro .sigle-product-content .accordion-button::after {
    background-size: 14px;
  }

  .single-pro-spread-sheet #accordionExample1 .accordion-button:not(.collapsed)::after {
    background-size: 14px;
  }
  .single-pro-spread-sheet #accordionExample1 .accordion-item .accordion-button::after {
    background-size: 14px;
  }
  .part-accordin-two .accordion-item .accordion-button::after {
    background-size: 14px;
  }
  .single-product-specification-body .single-hallman-burner {
    overflow: auto;
  }
  .single-product-specification-body .single-hallman-burner table {
    width: 1300px;
    margin: 0 auto;
    overflow: hidden;
  }
  .single-hallman-burner tr td {
    white-space: nowrap;
  }
  .prd-specification-guid-content p {
    font-size: 14px;
  }
  .single-spci-manual-table {
    margin: 25px 0;
    overflow: hidden;
  }
  .single-spci-manual-table .manual-cont th.dual-manual {
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
  }
  .real-life-single-pro h3 {
    padding-bottom: 20px;
  }
  .single-product-delivery-option .car-doller-del-single .delivery-option-doller input[type="checkbox"] {
    width: 20px;
  }
  .single-product-delivery-option .add-to-cart-wraper-single-pro #sinolo input {
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    color: #171717;
    letter-spacing: 0.02em;
  }
  .single-produt-choose-color .form-check-flie .choose-color label {
    width: 100%;
    height: 100%;
}
  .home-banner-content .div {
    display: block;
    padding: 100px 0 0;
  }
  .home-interting-fincing {
    right: 0px;
  }
  .product-slider-single-pro .carousel-control-next {
    right: 20px;
  }
  .product-slider-single-pro .carousel-control-prev {
    left: 20px;
  }
  .better-together-parent .align-items-center {
    align-items: center !important;
    justify-content: center;
  }
  #home-page-header ul#dropdown li a {
    color: #001f3f !important;
  }
  .single-product-better-together .single-product-seller .image-hover img {
    width: 269px;
    height: 195px;
    margin: 0 auto;
  }
  .product-slider-single-pro .sigle-product-content .price-section h2 {
    font-size: 18px;
  }
  .single-product-delivery-option .car-doller-del-single .delivery-option-doller label {
    font-size: 14px;
  }
  .sigle-product-content .price-section .lower-cart-icon {
    margin-top: 14px;
  }
  nav .navbar-nav .nav-item {
    position: static;
    width: 100%;
  }
  nav ul#left-menu {
    width: 100%;
  }
  nav #right-menu {
    width: 100%;
    text-align: right;
    display: flex;
    justify-content: end;
    align-items: start;
  }
  .submenu li.support-list-parts .dropdown-item {
    padding-left: 0px !important;
  }
  nav #right-menu {
    width: 100%;
    text-align: right;
    display: flex;
    justify-content: end;
    align-items: start;
  }
  .home-interting-fincing .numbertext p {
    right: 30px;
    color: #ffffff;
    font-size: 13px;
}
  body .navbar-nav .nav-item .drop-menu a {
    padding-top: 15px !important;
  }
  .sup {
    padding-left: 20px !important;
  }
  .support-area {
    max-width: 100%;
    width: 100%;
    top: 20px;
  }
  .sup {
    margin-left: 0px;
  }
  .head-items .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 20px !important;
  }
  .copyright-section .copyright-email {
    display: flex;
  }
  .copyright-section .review-logo h2:after {
    right: -10px;
  }
  .multi-range-slider .caption {
    right: -28px;
    left: unset !important;
  }
  .our-single-product-seller img.Product-list-pic {
    max-width: 100%;
    width: 100%;
    height: auto;
    aspect-ratio: 1/1;
  }
  .our-single-product-seller img.product-hover-pic, .our-single-product-seller div.product-hover-pic {
    max-width: 100%;
    width: 100%;
    height: auto;
    aspect-ratio: 1/1;
  }
  .single-ventil .single-ventils img.Product-list-pic {
    max-width: 100%;
    width: 100%;
    height: auto;
    aspect-ratio: 1/1;
  }
  .single-ventils img.product-hover-pic {
    max-width: 100%;
    width: 100%;
    height: auto;
    aspect-ratio: 1/1;
  }
  .single-product-customizer .product-slider-single-pro .tab-three li {
    width: 65px;
    height: 65px;
  }
  .single-product-customizer .product-slider-single-pro .tab-three li#contact:after {
    width: 65px;
    height: 65px;
  }
  .extend-warranty-single-pro .checked-extend-single {
    flex-direction: column;
  }
  .single-product-better-together .better-together-parent {
    padding: 0px 0px 30px 0px;
  }
  .select-size {
    gap: 10px;
  }
  .single-product .instock-txt p {
    padding: 2px 6px !important;
    font-size: 12px;
  }
  .single-product-customizer .instock-txt p {
    font-size: 14px;
  }
}
@media only screen and (max-width: 768px) {
  .home-our-best-seller-section {
    padding: 50px 0;
  }
  .About-banner {
    padding: 0px;
}
  .about-updates .story {
    margin:0px;
}
  .my-team-area .team-profile {
    height: auto;
  }
  .single-product .lower-cart-im .tag {
    width: 100%;
    height: 100%;
    max-width: 200px;
  }
      /* labor-day-modal-start */
      .lab-day-modal .btn-close svg {
        background: #000000;
        padding: 4px;
        border-radius: 50px;
        height: 20px;
        width: 20px;
        display: block;
    }
    .lab-day-modal .modal-dialog {
        padding: 0px 10px;
    }
    .lab-day-modal.modal {
      padding-top: 0px;
  }
  .lab-day-modal .btn-close {
    background: transparent;
    right: 11px;
    top: 4px;
  }

    /* labor-day-modal-end */
  /* about-update-css-start */
.abt-updts .abt-cont h1 {
  font-size: 25px;
  line-height: 35px;
  padding-top: 15px;
}
  .about-updates .abt-updts .abt-cont {
      padding: 20px 0px;
  }
  .about-updates .abt-cont h2 {
      font-size: 18px;
      line-height: 30px;
  }
  /* about-update-css-end */

  .home-our-best-seller-section.upr-space {
    margin-bottom: 0px;
}
  .product-parent .single-product .part-1 img.Product-list-pic {
    height: 300px;
}
.product-parent .single-product .carousel.carousel-slider {
  height: 300px;
}
.product-parent .single-product .image-wrapper .product-hover-pic .slider-wrapper {
    flex: 1 1;
    height: 400px;
}
.product-parent .single-product .image-wrapper .product-hover-pic .slider-wrapper {
  flex: 1 1;
  height: 300px;
}
.product-parent .single-product .image-wrapper .carousel ul>li.slide img {
  height: 300px !important;
}
.product-parent .single-product .product-hover-pic .carousel .slider {
  height: 300px;
  width: 100%;
  margin: auto;
}
/* sort-by-css-start */
    .section-products .filter-row .showing-filter h3 {
      text-align: right !important;
  }

  .product-parent .single-product .image-wrapper .product-hover-pic .slider-wrapper {
    flex: 1 1;
    height: 300px;
  }

  .product-parent .single-product .image-wrapper .carousel ul>li.slide img {
    height: 300px !important;
  }

  .product-parent .single-product .product-hover-pic .carousel .slider {
    height: 300px;
    width: 100%;
    margin: auto;
  }


  /* sort-by-css-start */
  .section-products .filter-row .showing-filter h3 {
    text-align: right !important;
  }

  .section-products .filter-row {
    flex-wrap: wrap;
    row-gap: 15px;
  }
  .section-products .filter-row .clear-filter {
    width: 100%;
  }

  .section-products .filter-row .sorting-by span.bold {
      width: auto;
      text-wrap: nowrap;
      white-space: nowrap;
  }

  .section-products .filter-row .sorting-by {
    width: 75%;
    justify-content: end;
  }

  /* sort-by-css-end */
  .images-carousel {
    padding: 0px 0px;
  }

  /* Image-carousel-css-start */
  .images-carousel .modal-header {
    padding: 30px 20px 30px;
  }

  .images-top-label .pages-head h2 {
    font-size: 18px;
  }

  .carousel-main .carousel-item img {
    aspect-ratio: 1 / 1;
  }

  .images-carousel .see-btn button.see-more-img {
    font-size: 12px;
  }

  .carousel-main .carousel-indicators {
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 15px;
  }

  /* Image-carousel-css-end */
  .most-common-question {
    margin-bottom: 30px;
  }

  .product-extra-info .table-responsive table tbody tr {
    display: flex;
    flex-wrap: wrap;
  }
  .product-extra-info .table-responsive table tbody tr td {
    width: 50%;
    flex: 1 1 50%;
  }
/* faq-new-page-css-start */
.faq-new-main .faq-new-banner {
  min-height: 370px;
}
.faq-new-main {
  margin: 83px 0px 0px 0px;
}
.faq-new-main .faq-new-content h1 {
  font-size: 30px;
  line-height: 30px;
}
.faq-new-main .faq-new-content h2 {
  font-size: 25px;
  line-height: 31.36px;
  padding: 12px 0px 0px 0px;
}
.faq-new-questions {
  margin-top: 30px !important;
}
.faq-new-questions .accordion-button p {
  padding: 28px 0px;
  font-size: 18px;
  max-width: 85%;
  width: 100%;
}
.faq-btm ul.faq-list li {
  margin-bottom: 20px !important;
}
.faq-btm .faq-new-questions h2 {
  font-size: 25px;
}
/* faq-new-page-css-start */
  .showroom_partner_sec .form-group .ReactTags__suggestions{
    max-width: 360px !important;
    width: 100%;
  }
  .single-produt-choose-color .form-check-flie {
    gap: 20px;
    justify-content: space-between;
  }

  .single-produt-choose-color .form-check-flie .choose-color {
    margin-bottom: 10px;
    max-width: 100px;
  }

  .limited-warrenty-main-faq {
    margin: 0px 0px 0px 0px;
    position: relative;
    z-index: 1;
  }

  .single-product-delivery-option .add-to-cart-wraper-single-pro .riht {
    max-width: 100% !important;
    width: 100%;
  }

  .single-product-delivery-option .sinolo-outer {
    width: 100% !important;
    display: flex;
    align-items: center;
    gap: 20px;
}
.single-product-delivery-option .comp-wish.mobile {
    display: flex !important;
    width: 43% !important;
    gap:10px;
}
.single-product-delivery-option .comp-wish.mobile.unavailable{
  justify-content: left;
  width: 21% !important;
}
.single-product-delivery-option .comp-wish {
    display: none !important;
}
.single-product-delivery-option .add-to-cart-wraper-single-pro {
    gap: 20px;
}
  .scroll-prod {
    top: 90px;
  }

  .comapre-top-section {
    margin-top: 30px;
  }

  .compare-prod .comp-upr-prod {
    margin-top: 30px;
  }

  .single-product-navigation {
    padding: 10px 0 10px 0px;
    margin-top: 0px;
    position: static;
  }
  .product-slider-single-pro .real-life-single-pro {
    margin-top: 35px;
  }
  .sigle-product-content .quick-specs {
    padding: 25px 0 20px;
  }
  .sigle-product-content .single-produt-choose-color {
    padding: 21px 0px 21px 0px;
  }
  .product-slider-single-pro .sigle-product-content>div ul li:last-child {
    margin-bottom: 15px !important;
  }
  /* team-page-css-start */
  .teams .team-discp .profile-desc-rgt h3 {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 10px !important;
  }
  .teams .team-discp .profile-desc-rgt h1 {
    margin-bottom: 10px !important;
  }
  .teams .offcanvas-header {
    padding: 20px 15px;
  }
  /* team-page-css-end */
  /* About us page -css-start */
  .About-banner .abt-content h1 {
    font-size: 30px;
    line-height: 40px;
  }
  .About-banner .abt-content p {
    font-size: 20px;
    line-height: 35px;
  }
  .abt-parts-main .abt-month h2 {
    font-size: 40px;
    line-height: 40.7px;
  }
  .abt-parts-one .moves-to-texts p {
    max-width: 100%;
    width: 100%;
  }
  .abt-parts-five .moves-to-texts p {
    max-width: 100%;
    width: 100%;
  }
  .abt-parts-six .moves-to-texts p {
    max-width: 100%;
    width: 100%;
  }
  .abt-parts-eight .moves-to-texts p {
    max-width: 100%;
    width: 100%;
  }
  .abt-parts-nine .moves-to-texts p {
    max-width: 100%;
    width: 100%;
  }
  .abt-parts-ten .moves-to-texts p {
    max-width: 100%;
    width: 100%;
  }
  .abt-parts-eleven .moves-to-texts p {
    max-width: 100%;
    width: 100%;
  }
  .abt-parts-final .moves-to-texts p {
    max-width: 100%;
    width: 100%;
  }
  .abt-parts-two .moves-to-text h2 {
    font-size: 40px;
    line-height: 40px;
  }
  .abt-parts-two .moves-to-text h3 {
    font-size: 30px;
    line-height: 40px;
  }
  .abt-parts-two .moves-to-texts p {
    max-width: 100%;
  }
  .abt-parts-two .moves-to-texts {
    margin: 26px 0px 0px;
  }
  .abt-parts-three .moves-to-text h2 {
    font-size: 40px;
    line-height: 40px;
  }
  .abt-parts-three .moves-to-text h3 {
    font-size: 30px;
    line-height: 40px;
  }
  .abt-parts-three .moves-to-texts p {
    width: 100%;
    max-width: 100%;
  }
  .abt-parts-three .moves-to-texts {
    margin: 26px 0px 0px;
  }
  .abt-parts-four .moves-to-text h2 {
    font-size: 40px;
    line-height: 40px;
  }
  .abt-parts-four .moves-to-text h3 {
    font-size: 30px;
    line-height: 40px;
  }
  .abt-parts-four .moves-to-texts p {
    max-width: 100%;
    width: 100%;
  }
  .abt-parts-five .moves-to-text h2 {
    font-size: 40px;
    line-height: 40px;
  }
  .abt-parts-five .moves-to-text h3 {
    font-size: 30px;
    line-height: 40px;
  }
  .abt-parts-five .moves-to-texts {
    margin: 26px 0px 0px;
  }
  .abt-parts-six .moves-to-text h2 {
    font-size: 40px;
    line-height: 40px;
  }
  .abt-parts-six .moves-to-text h3 {
    font-size: 30px;
    line-height: 40px;
  }

  .abt-parts-seven .moves-to-text h2 {
    font-size: 40px;
    line-height: 40px;
  }
  .abt-parts-seven .moves-to-text h3 {
    font-size: 30px;
    line-height: 40px;
  }
  .abt-parts-nine .moves-to-text h2 {
    font-size: 40px;
    line-height: 40px;
  }
  .abt-parts-nine .moves-to-text h3 {
    font-size: 30px;
    line-height: 40px;
  }
  .abt-parts-ten .moves-to-text h2 {
    font-size: 40px;
    line-height: 40px;
  }
  .abt-parts-ten .moves-to-text h3 {
    font-size: 30px;
    line-height: 40px;
  }
  .abt-parts-eleven .moves-to-text h2 {
    font-size: 40px;
    line-height: 40px;
  }
  .abt-parts-eleven .moves-to-text h3 {
    font-size: 30px;
    line-height: 40px;
  }
  .abt-parts-twelve .moves-to-text h2 {
    font-size: 40px;
    line-height: 40px;
  }
  .abt-parts-twelve .moves-to-text h3 {
    font-size: 30px;
    line-height: 40px;
  }
  .abt-parts-final .moves-to-text h2 {
    font-size: 40px;
    line-height: 40px;
  }
  .abt-parts-final .moves-to-text h3 {
    font-size: 30px;
    line-height: 40px;
  }
  .abt-parts-three .moves-to-text h3,
  .abt-parts-three .moves-to-text span {
    font-size: 30px;
    line-height: 40px;
  }
  /* About us page -css-end */
  .limited-warrenty-main {
    padding: 0px 0px 30px 0px;
  }
  .we-promise .we-promise-main h2 {
    font-size: 25px;
    line-height: 30px;
  }
  .we-promise {
    padding: 50px 0px 50px 0px;
  }
  .pro-dealer-main {
    padding-top: 0px;
  }
  .home-new-product-arriving-section.become-dealer .dealer-detail {
    padding: 0px 0px 20px 50px;
  }
  /* customer-service-page-css-start */
  .customer-service-banner {
    min-height: 250px;
  }
  .customer-intes-main {
    padding: 30px 0px 30px 0px;
  }
  .customer-intes-main h2 {
    font-size: 25px;
  }
  .service-for-customer {
    margin-top: 30px;
  }
  .service-for-customer .service-title h2 {
    font-size: 25px;
  }
  .dealer-gap {
    row-gap: 20px;
  }
  /* customer-service-page-css-end */
  /* faq-question-page-css-start */
  .faq-ques-banner {
    min-height: 250px;
  }
  .warrenty-banner-content.ques h1 {
    font-size: 25px;
  }
  .faq-question-top-heading p {
    padding: 31px 0px 0px 0px;
    font-size: 20px;
    line-height: 30px;
    width: 100%;
  }
  .faq-question-accordions {
    width: 100%;
    margin: 30px 0px;
}
  .faq-qustion-main-area {
    padding-bottom: 0px;
  }
  .faq-question .accordion-item {
    padding: 20px 0px;
  }
  .faq-question-accordions .accordion-button:not(.collapsed) {
    font-size: 16px;
    line-height: 18px;
  }
  .faq-question-accordions .accordion-button {
    font-size: 16px;
    line-height: 18px;
  }
  .faq-question-accordions .faq-question-content-steps p {
    line-height: 20px;
  }
  /* faq-question-page-css-end */
  /* limited-warranty-one-page-css-start */
  .limited-warranty-main-title h1 {
    font-size: 25px !important;
    width: 100% !important;
}
  .created-account h2 {
    font-size: 25px;
    line-height: 30px;
  }
  .created-account-fields-list input {
    padding: 9px 10px;
  }
  .created-account-fields-list {
    margin-bottom: 10px;
  }
  .limited-warranty-main-left .limited-warranty-main-title p.six {
    width: 100%;
  }
  .limited-warranty-main-left-image img {
    width: 100%;
    margin-bottom: 20px;
  }
  .limited-warranty-main-right {
    margin-top: 30px;
  }
  /* limited-warranty-one-page-css-end */
  /* faq-page-css-start */
  .faq-banner {
    min-height: 250px;
    position: relative;
    z-index: 1;
  }
  .most-common-question h4 {
    font-size: 25px;
  }

  .most-common-question h2.cmn {
    font-size: 25px;
  }
  .most-common-text h5 {
    font-size: 20px;
    margin-top: 20px;
  }
  .most-common-text h3.cmn {
    font-size: 20px;
    margin-top: 20px;
  }
  .common-all {
    padding: 30px 0px 30px 0px;
    margin-bottom: 40px;
  }
  .row.most-com-bottom {
    padding-bottom: 30px;
  }
  .more-frequent-ask {
    padding: 30px 0px 30px 0px;
  }
  .more-frequent-ask h4 {
    font-size: 25px;
  }
  /* faq-page-css-end */
  /* create-account-page-css-start */
  .create-account-heading h2 {
    font-size: 20px;
  }
  .create-account-heading {
    margin-bottom: 20px;
  }

  /* create-account-page-css-end */
  /* online-dealer-page-css-start */
  .online-dealer-banner {
    min-height: 260px;
  }
  .shop-with-confidence {
    padding: 30px 0px 0px 0px;
  }
  .shop-confidence-top-text h2 {
    font-size: 25px;
    line-height: 30.02px;
  }
  .shop-conf-text p {
    font-size: 13px;
    padding-bottom: 25px;
  }
  .confidence-logo-section {
    padding: 40px 0px 30px 0px;
  }
  .home-new-product-arriving-section.become-dealer {
    padding: 30px 0px 0px 0px !important;
  }
  .home-new-product-arriving-section .dealer-detail h2 {
    font-size: 18px;
    line-height: 27px;
    width: 90%;
    padding: 30px 10px 10px 0px;
  }
  /* online-dealer-page-css-end */
  /* hallman-arch-page-css-start */
  .design-arch-banner {
    min-height: 260px;
  }
  /* hallman-arch-page-css-end */
  /* hallman-builder-page-css-start */
  .hallman-builder-banner {
    min-height: 260px;
  }
  .hallman-builder-content h4 {
    font-size: 20px;
    line-height: 30px;
    max-width: 100%;
  }
  .hallman-builder-content p {
    width: 100%;
  }
  /* hallman-builder-page-css-end */
  /* Pro-dealer-page-css-start */
  .pro-dealer-banner {
    min-height: 260px;
  }
  .pro-dealer-main .pro-dealer-banner-content p {
    font-size: 16px;
    font-weight: 300;
    width: 100%;
  }
  .pro-dealer-banner-btn a {
    padding: 14px 38px;
  }
  .pro-dealer-top-content h4 {
    font-size: 20px;
    line-height: 30px;
  }
  .pro-dealer-top-content p {
    width: 100%;
  }
  .benefites-of-dealer {
    margin: 30px 0px 30px 0px;
    padding: 50px 0px 50px 0px;
  }
  .benefits-of-main h4 {
    font-size: 25px;
    line-height: 30px;
  }
  .ready-to-get-started .ready-get-head h4 {
    font-size: 25px;
    line-height: 30px;
  }
  .ready-to-get-started .get-ready-fields {
    margin-bottom: 15px;
  }
  .pro-dealer-main .pro-dealer-banner-content h1 {
    font-size: 24px;
    line-height: 25.36px;
    width: 100%;
  }
  /* Pro-dealer-page-css-end */
  /* cart-page-popup-css-start */
  .share-cart-popup .share-pop-fields {
    margin-bottom: 8px;
  }
  .share-cart-popup .modal-body {
    padding: 20px 25px;
  }
  .share-cart-popup .share-pop h2 {
    font-size: 20px;
    margin-bottom: 5px;
  }
  .share-cart-popup .share-pop-fields input {
    font-size: 14px;
  }
  /* cart-page-popup-css-end */
  /* login-page-css-start */
  .hallman-Login-left {
    padding: 60px 20px;
  }
  .hallman-Login-top h2 {
    font-size: 20px;
  }
  .hallman-Login-fields {
    margin-top: 10px;
  }
  .hallman-Login-fields input {
    padding: 14px 10px;
    font-size: 14px;
  }
  .hallman-Login-fields label {
    margin-bottom: 8px;
  }
  .halman-signin-btn {
    padding: 30px 0px 30px;
  }
  button.hallman-signin-btn {
    font-size: 14px;
    line-height: 13.4px;
    padding: 17px 0px;
  }
  .hallman-Login-bottom {
    margin: 30px 0px 0px 0px;
  }
  .hallman-Login-bottom a {
    font-size: 14px;
  }
  /* login-page-css-end */
  /* Image-gallery-page-css-start */
  .image-gallery-main-top .gallery-main-title h1 {
    font-size: 28px;
  }
  .image-more-load {
    margin-bottom: 50px;
  }
  /* Image-gallery-page-css-end */
  /* manual-docs-page-css-start */
  .manual-banner {
    min-height: 250px;
  }
  .row.manual-light-area {
    padding-top: 60px;
    row-gap: 60px;
  }
  .popular-manual-light-back {
    background: #F1F1F1;
    padding: 48.9px 0px 50.89px 0px;
    margin: 30px 0px
  }
  .popular-manual-light-parts h4 {
    font-size: 16px;
  }

  .popular-manual-light-back .popular-manual-light-parts h3 {
    font-size: 16px;
  }
  .popular-manual-light-back h2 {
    font-size: 25px;
  }
  /* manual-docs-page-css-end */
  /* replacement-page-css-start */
  .replacement-banner {
    min-height: 250px;
  }
  .find-your-range-replacement {
    margin: 50px 0px 50px 0px;
  }
  .home-new-product-arriving-section .register-detail h2 {
    font-size: 18px;
    line-height: 27px;
    width: 90%;
    padding: 30px 10px 10px 0px;
  }
  .home-new-product-arriving-section .register-detail a {
    margin: 0px 0px 40px 0px;
    padding: 12px 0px;
  }
  .helpful-link-section {
    margin: 0px 0px 50px;
  }
  .helpful-detail h3 {
    font-size: 20px;
  }
  .find-bottom-text h3 {
    font-size: 16px;
  }
  .helpful-detail p {
    line-height: 20px;
    width: 100%;
    padding: 5px 0px 4px 0px;
  }
  .home-our-best-seller-section h2 {
    line-height: 30.2px;
    padding-bottom: 20px;
  }
  .helpful-link-section h2 {
    font-size: 25px;
    line-height: 34.02px;
    padding-bottom: 20px;
    padding-top: 20px;
  }
  .home-new-product-arriving-section {
    padding: 50px 0px !important;
  }
  .ranges-finding .container {
    padding: 0px;
  }
  /* replacement-page-css-end */
  /* warrenty-page-css-start */
  .main-images {
    min-height: 250px;
  }
  span.indus-rgt-search {
    padding-bottom: 0px;
  }
  .limited-warrenty-main .warrenty-banner-content h1 {
    font-size: 24px;
    line-height: 25.36px;
  }
  .love-appliances-top {
    padding-bottom: 32px;
  }
  .limited-warranty-image-area {
    margin: 30px 0px;
  }
  .unlock-each-heading h2 {
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 10px;
  }
  .unlock-each-head-if-part h2 {
    font-size: 20px;
    line-height: 25px;
    width: 100%;
    padding-bottom: 10px;
  }
  .love-appliances-top h2 {
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 10px !important;
  }
  .industry-product-search input {
    padding: 8.33px 6px;
    max-width: 90%;
    font-size: 11px;
  }
  span.indus-rgt-search svg {
    height: 14px;
    width: 14px;
  }
  .unlock-each-head h2 {
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 10px !important;
  }
  .unlock-each-services-list h3 {
    font-size: 14px;
    line-height: 20px;
  }
  .warenty-bold {
    font-size: 15px;
    line-height: 24px;
  }
  .unlock-each-benefit-parts {
    margin-top: 30px;
  }
  .unlock-each-head-if h2 {
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 10px;
  }
  .parts-covered li {
    font-size: 16px;
    padding-bottom: 5px;
  }
  .unlock-each-heading-small h2 {
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 10px;
  }

  .unlock-each-services p {
    line-height: 30px;
  }
  .unlock-each-benefit-small {
    margin-bottom: 30px;
  }
  .unlock-each-services-small {
    max-width: 100%;
  }
  /* warrenty-page-css-end */
  .select-classic img {
    width: 100%;
  }
  .select-top-content h1 {
    font-size: 24px;
    line-height: 25.3px;
  }
  .select-classic-bottom.text-center {
    display: grid;
  }
  .select-classic {
    height: auto;
    max-width: 100%;
    position: relative;
  }
  .series-buton {
    margin-bottom: 20px;
}
  .single-product-customizer .single-product-heading {
    font-size: 20px;
    line-height: 30.69px;
    padding: 0px 0px 10px;
  }
  .category-back {
    min-height: 250px;
  }
  .share-cart-popup .modal-body {
    padding: 20px 25px;
  }
  .share-cart-popup .share-pop h2 {
    font-size: 20px;
    margin-bottom: 5px;
  }
  .share-cart-popup .share-pop-fields input {
    font-size: 14px;
  }
  .home-our-best-seller-section {
    margin-bottom: 0px;
  }
  .home-our-best-seller-section h4 {
    font-size: 25px;
  }
  .single-product-information-body tr .first-rate {
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .single-product-information-body tr .btu-text {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  td.first-rate {
    width: 60% !important;
  }
  td.btu-text {
    width: 40% !important;
  }
  .select-your-choice-data,
  .banner-list,
  .single-product-customizer,
  .section-products.result-amt {
    padding: 20px 0px 10px 0px;
  }
  .hallman-Login-left {
    padding: 60px 20px;
  }
  .top-search-bar .bar-area {
    padding: 22px 40px 22px 40px;
  }
  .seach-contents {
    padding: 31px 40px 14px 40px;
  }
  .single-product-navigation nav ul li a {
    white-space: normal;
    width: fit-content;
    line-height: 15px;
  }
  .bannner-botm-space {
    row-gap: 15px;
  }
  footer .cate-goreis h2 {
    font-size: 18px;
  }
  footer .newsletter p {
    font-size: 12px;
  }
  .support-list-parts a.dropdown-item {
    padding-top: 0px !important;
    line-height: inherit;
  }
  .head-items {
    display: flex;
    align-items: center;
    vertical-align: middle !important;
  }
  .navbar-nav>li.nav-item {
    position: relative;
  }
  ul#right-menu {
    margin-bottom: 0px !important;
    flex-direction: row;
  }
  .mobs {
    display: none !important;
  }
  .mob-profile::after {
    display: none !important;
  }
  .support-area {
    width: 100%;
  }
  nav .navbar-nav .nav-item {
    width: 100%;
    position: static;
  }
  .submenu.account {
    right: 10px;
    max-width: 200px;
    left: auto;
    min-width: 200px;
  }
  .select-your-choice-data {
    padding: 70px 10px 60px 10px;
  }
  /* navbar-mobile-css-end */
  .select-top-content h1 {
    font-size: 24px;
    line-height: 25.3px;
  }
  .select-two-flex {
    margin-top: 20px;
  }
  .select-bold {
    padding: 40px;
  }
  .select-classic {
    height: auto;
    max-width: 100%;
    position: relative;
  }
  .select-classic-bottom {
    bottom: 0;
    padding: 0px 20px;
  }
  .select-choice-up {
    top: 20px;
  }
  .select-classic-bottom {
    bottom: 20px;
    padding: 0px 10px;
  }
  .navbar-nav .nav-item .drop-menu a {
    padding-top: 0px !important;
  }
  .support-list-parts {
    padding: 10px 0px 10px 10px;
  }
  .home-interting-fincing {
    top: 50px;
  }
  .home-banner-content {
    padding-top: 80px;
  }
  .home-buy-with-confidence h2 {
    line-height: 31px;
  }
  .logo-slider h3 {
    font-size: 13px;
  }
  .home-new-product-arriving-section .row>* {
    padding-left: 0px;
    padding-right: 0px;
  }
  .cate-gallery-wraper .home-gallery {
    height: auto;
  }
  .picture-one {
    padding: 0px 10px;
    width: 33.33%;
  }
  .mobile-show-links {
    display: block;
  }
  .select-classic-bottom h2 {
    margin: 0px 0px 20px 0px;
  }
  footer .newsletter h4 {
    font-size: 14px;
  }
  footer .newsletter p {
    font-size: 12px;
  }
  svg.social-circle {
    height: 40px;
    width: 40px;
  }
  footer .newsletter button {
    padding: 10px 0px;
    font-weight: 400;
  }
  .copyright-section .copyright-email {
    padding: 15px 0;
  }
  .home-buy-with-confidence {
    padding: 30px 0;
  }
  footer .newsletter .form-control {
    padding: 0px 10px 5px 0px;
  }
  .select-classic-bottom h2 {
    margin: 15px 0px 10px 0px;
    padding-bottom: 15px;
    font-size: 35px;
    line-height: 30px;
  }
  .select-classic-bottom p.start {
    font-size: 14px;
    padding-bottom: 12px;
  }
  a.shop-now {
    padding: 15px 20px;
  }
  .new-product-detail h2 {
    padding-left: 60px;
  }
  .new-product-detail a {
    margin-left: 60px;
  }
  .logo-slider h3 {
    font-size: 13px;
    word-wrap: break-word !important;
    white-space: pre-wrap;
  }
  .home-new-product-arriving-section .new-product-detail h2 {
    max-width: 90%;
    width: 100%;
  }
  /* .single-product .part-1 img.Product-list-pic {
        height: auto;
    } */
  .plp-desc {
    width: 95% !important;
  }
  .banner-list .plp-desc button,
  .banner-list .plp-desc p {
    font-size: 13px;
  }
  .banner-list .plp-desc button {
    font-size: 16px;
  }
  .section-products .filter-row {
    padding-bottom: 30px;
  }
  .product-result-amount h1 {
    font-size: 20px;
    padding-bottom: 7px;
  }
  .product-result-amount p {
    padding-bottom: 20px;
  }
  .cart-main-section {
    padding: 32px 0px;
  }
  .dashboard-track-order input[type="text"] {
    width: 100%;
    padding: 9px 10px;
  }
  .dashboard-track-order .search-box button{
    width: 100%;
    margin-top : 15px;
    margin-left:0px;
    padding: 14px 0px;
    font-size: 13px;
  }
  .dashboard-track-order .search-box {
    display: block;
  }
  .testimonials-container .form-section .info {
    display: block;
  }
  .testimonials-container .name-div {
    margin-bottom: 15px;
  }
  .testimonials-container h2 {
    font-size: 32px;
  }
  .testimonials-container .header-section p {
    font-size: 14px;
  }
  .testimonial-view-container button {
    height: 45px;
    font-size: 13px;
  }
  .testimonial-view-container h2 {
    font-size: 35px;
    line-height: 45px;
    text-align: center;
  }
  .testimonial-view-container .header-section {
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding-bottom: 25px;
  }
  .testimonial-list .testimonial-detail p img {
    width: 40px;
  }
  .testimonial-shimmer-container .shimmer {
    height: 380px !important;
  }
  .clearance-modal p {
    font-size: 14px;
  }
  .clearance-modal ul li {
    padding-bottom: 2px;
    font-size: 14px;
  }
  .customral-color p {
    padding-bottom: 10px;
  }
  .share-cart-modal.my-img-upload {
    top: 60px;
  }
  .share-cart-modal.my-img-upload .modal-body button.share-pop-btns {
    margin-bottom: 20px;
  }
  .tow-single-pro-ex-del .prop65-main h3 {
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
    font-size: 15px;
    line-height: 19px;
    width: 100%;
    padding-left: 10px;
  }
  .tow-single-pro-ex-del .prop65-main h3 a {
    padding-top: 5px;
    width: 100%;
    padding-left: 0;
    display: inline;
  }

    /* cart-page-css-start */
    .value-sub {
      justify-content: start !important;
      margin-top: 0px;
    }
    .cart-value-define {
      margin: 20px 0px 20px 0px;
    }
    .cart-subtotal-rating {
      margin-left: 0px;
    }
    section.cart-main-section {
      padding: 22px 0px;
    }
    .cart-items-main {
      padding: 20px 0px 20px 0px;
    }
    .cart-by {
      padding-bottom: 11px;
    }
    .col.share-column {
      display: flex;
      justify-content: center;
    }
    .cart-main-section .cart-clear {
      display: flex !important;
      justify-content: center;
    }
    .cart-select-main-area .cart-content-gap {
      gap: 10px;
      flex-direction: column;
    }
    .subtotal-types {
      margin-top: 20px;
    }
    .order-summary-subtotal {
      padding-bottom: 20px;
    }
    .summary-today-order-text {
      padding: 15px 0px 14.5px 0px;
    }
    .order-summary-estimated {
      padding-top: 20px;
    }
    .cart-select-area .cart-section-title h1 {
      font-size: 25px;
    }
    .cart-section-title {
      gap: 5px;
      padding-bottom: 14px;
    }
    .cart-select-left-delivery-option h2.deliveries {
      padding-bottom: 10px;
    }
    .order-summary-content-top {
      padding: 20px;
    }
    .cart-content-gap {
      width: 100%;
    }
    .cart-main-section {
      padding: 30px 0px;
    }
    .cart-select-left-delivery-options {
      margin-left: 0px;
    }
    .cart-select-left-images {
      height: 200px;
      width: 100%;
      display: flex;
      justify-content: center;
      max-width: 100%;
    }
    .cart-select-left-images img {
      max-width: 200px;
      height: 200px;
      object-fit: cover;
    }
    /* cart-page-css-end */
}
@media only screen and (max-width: 667px) {
  ul.term-left-list {
    max-width: 100% !important;
    width: 100%;
}
  .single-product-delivery-option .add-to-cart-wraper-single-pro #sinolo {
    max-width: 70%;
    width: 100%;
}
.single-product-delivery-option .comp-wish.mobile {
  width: 30% !important;
  justify-content: end;
}
.lower-cart-icon.ced.mob {
  display: block;
  max-width: 200px;
  width: 100%;
  margin-top: 0px;
}

  .images-carousel .modal {
    z-index: 999999;
    top: 64px;
    padding: 0 !important;
  }

  div#call_us h4 a {
    font-size: 16px !important;
  }

  div#partner_machine.partner_machine .space .box {
    padding-left: 15px !important;
    padding-right: 15px;
  }

  div#partner_machine .box {
    padding: 0px 15px;
  }

  .product-slider-single-pro .large-screen {
    display: block;
    align-items: flex-start;
}
.product-slider-single-pro .large-screen .thumbs-wrapper {
  height: auto;
}
.product-slider-single-pro .carousel .control-next:after, .carousel.carousel-slider .control-next:after {
  content: '>' !important;
  display: none;
}
.product-slider-single-pro .carousel .control-prev:before, .carousel.carousel-slider .control-prev:before {
  content: '<' !important;
  display: none;
}
  .showroom_partner_sec .form-group .ReactTags__suggestions{
    max-width: 600px !important;
    width: 100%;
  }
  .discover-category-section .row .categorie-image-wrpaer h2 {
    font-size: 15px;
}
/* new-lower-cart-css-start */
.new-lower-modal .modal-body {
  padding: 30px 15px 30px 15px;
}
.new-lower-modal .new-lower-cont .deal-of h2 {
  font-size: 30px;
  line-height: 40px;
  padding: 0px 33px;
}
.new-lower-modal .new-lower-cont .price-back {
  padding: 30px 20px;
}
.new-lower-modal .new-lower-cont .price-list {
  padding-bottom: 18px;
  margin-bottom: 18px;
}
.new-lower-modal .new-lower-cont .deal-of h3 {
  font-size: 24px;
  line-height: 30.62px;
  letter-spacing: 0.05em;
  margin-bottom: 10px;
}
.new-lower-modal .new-lower-cont .price-types {
  background-image: none;
  background-position: center;
  background-size: cover;
  width: 100%;
  margin: 30px 0px 30px 0px;
  background: #E6E6E6;
  background-image: repeating-linear-gradient(2deg, #3d3d3e, #3d3d3e 6px, transparent 6px, transparent 16px, #3d3d3e 16px), repeating-linear-gradient(92deg, #3d3d3e, #3d3d3e 6px, transparent 6px, transparent 16px, #3d3d3e 16px), repeating-linear-gradient(182deg, #3d3d3e, #3d3d3e 6px, transparent 6px, transparent 16px, #3d3d3e 16px), repeating-linear-gradient(272deg, #3d3d3e, #3d3d3e 6px, transparent 6px, transparent 16px, #3d3d3e 16px);
  background-size: 2px 100%, 100% 2px, 2px 100% , 100% 2px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
}
/* new-lower-cart-css-end*/
  .cate-goreis .footer-navigations li a {
    font-size: 16px;
    line-height: 18px;
  }
  .large-screen .thumbs {
    gap: 10px;
  }
  /* Comapre-page-css-start */
  .compare-prod .compare-left-cont h1 {
    font-size: 25px;
    line-height: 35px;
    padding: 10px 0px;
  }
  .compare-prod .comp-upr-prod {
    border: 0px;
    margin-top: 30px;
  }
  .compare-prod-info {
    margin-bottom: 50px;
  }
  .scroll-prod .scroll-upr-prod {
    border: 0px;
    padding: 20px 20px;
  }
  .scroll-prod .scroll-tile-txt h2 {
    max-width: 100%;
  }
  /* Comapre-page-css-end */
  .single-product-delivery-option .add-to-cart-wraper-single-pro {
    flex-wrap: wrap;
  }
  .single-product-delivery-option .add-to-cart-wraper-single-pro .riht {
    max-width: 48%;
    width: 100%;
  }

  .custom-tile .vid-frame iframe {
    height: 300px;
  }
  .share-cart-modal .modal-body {
    padding: 30px 15px 30px 15px;
  }
  .share-cart-modal .modal-body .share-pop-fields {
    margin-bottom: 20px;
  }
  .share-cart-modal {
    padding-left: 0px !important;
  }
  /* <!-- Single-product-info-section-start --> */
  .single-prod-info .container {
    padding: 0px;
  }
  .prod-info-center .tab-body {
    padding: 20px 10px;
  }
  .prod-info-center td.tab-spec-small {
    text-wrap: nowrap;
  }
  .prod-info-center .parts-gap {
    row-gap: 30px;
  }
  .parts-content img.tag {
    max-width: 170px !important;
  }
  .responsive-prod-info ul#ex1 {
    min-width: 870px;
  }
  .prod-info-center .service-content {
    padding: 30px 0px 10px 0px;
  }
  .prod-info-center .serv-text h2 {
    font-size: 30px;
    line-height: 30px;
  }
  .prod-info-center .serv-text p {
    font-size: 16px;
    line-height: 25px;
  }
  .prod-info-center .notify-me button {
    height: 40px;
    width: 125px;
  }
  .prod-info-center .notify-input {
    height: 40px;
    padding-right: 135px;
  }
  .prod-info-center .ret-gp {
    margin-bottom: 30px;
  }
  .prod-info-center .cust-rate p {
    max-width: 100%;
    width: 100%;
  }
  .table-responsive::-webkit-scrollbar {
    display: none;
  }
  .responsive-prod-info::-webkit-scrollbar {
    display: none;
  }
  /* <!-- Single-product-info-section-end --> */
  .cart-page button.carousel-control-prev {
    position: absolute;
    left: 0%;
  }
  .cart-page button.carousel-control-next.false {
    position: absolute;
    right: 0%;
  }
  .cart-checkout a {
    height: 42px;
    padding: 14px 0px;
  }
  .limited-warranty-main-left {
    margin: 0px;
  }
  .details-info-form .file-uploader .text-file {
    padding: 5px 0px;
    margin-bottom: 10px
  }
  .we-promise .we-promise-main h2 {
    font-size: 20px;
  }
  .thumbs img {
    margin: 0px !important;
    padding: 0px !important;
  }
  .carousel .thumb {
    padding: 0px !important;
  }
  .product-slider_single-show .thumbs:not(.animated) {
    flex-direction: row !important;
  }
  /* customer-service-page-css-start */
  .customer-interested .customer-intes-main h2 {
    font-size: 20px;
}
  .service-for-customer .service-title h2 {
    font-size: 20px;
  }
  .all-services .pop-ser-show h2 {
    font-size: 20px;
  }
  .helpful-link-section {
    margin: 30px 0px 30px;
  }
  /* customer-service-page-css-end */
  /* faq-questuion-page-css-start */
  .warrenty-banner-content.ques h1 {
    font-size: 20px;
    line-height: 30px;
  }

  .faq-question-top-heading p {
    font-size: 18px;
    line-height: 26px;
  }
  .see-more-faqs a {
    margin-top: 25px;
  }
  /* faq-questuion-page-css-end */
  /* limited-warranty-one-page-css-start */
  .limited-warranty-main-right {
    margin-top: 30px;
  }
  .limited-warranty-main-title h1 {
    font-size: 20px;
    line-height: 30px;
  }
  .read-benefits a {
    margin: 10px 0px 20px 0px;
  }
  .created-account h2 {
    font-size: 20px;
  }
  .details-info-form .get-started-fields input {
    padding: 14px 10px;
  }
  .limited-warranty-main-right {
    padding: 20px 10px 60px 10px;
  }
  .get-started-fields {
    margin-bottom: 10px;
  }
  .for-mats {
    flex-wrap: wrap;
  }
  .limited-warranty-main-title.warranty-two h1 {
    font-size: 25px;
    line-height: 30px;
  }
  .limited-warranty-main-title p {
    width: 100%;
  }
  .regards-msg p {
    width: 100%;
  }
  /* limited-warranty-one-page-css-end */
  /* faq-page-css-start */
  .most-common-question h4 {
    font-size: 20px;
  }
  .most-common-question h2.cmn {
    font-size: 20px;
  }

  .more-frequent-ask h4 {
    font-size: 20px;
  }
  .more-frequent-ask-all {
    padding: 15px 0px;
    row-gap: 15px;
  }
  a.more-frequent-ask-steps {
    padding: 20px 20px 20px 20px;
  }
  /* faq-page-css-end */
  /* header-cart-popup-start */
  .cart-mini-mains .cart-value-define {
    margin: 0px;
  }
  .mini-easy-finacing-head h2 {
    font-size: 12px;
  }
  .mini-credit-card h2 {
    font-size: 12px;
  }
  .mini-cartsub-total-top h2 {
    font-size: 16px;
  }
  .minicart-select-left-heading {
    margin-top: 10px;
  }
  /* header-cart-popup-end */
  /* online-dealer-page-css-start */
  .home-new-product-arriving-section .dealer-detail h2 {
    padding: 30px 10px 10px 0px;
  }
  .home-new-product-arriving-section.become-dealer .dealer-detail p {
    padding-left: 0px;
    font-size: 14px;
    margin-bottom: 6px;
  }
  .home-new-product-arriving-section.become-dealer a.see-detail {
    padding-left: 0px;
  }
  /* online-dealer-page-css-end */
  /* become-builder-page-css-start  */
  .shop-with-confidence {
    padding: 0px 0px 0px 0px;
  }
  .shop-confidence-top-text h2 {
    font-size: 20px;
  }
  .home-new-product-arriving-section.become-dealer .register-detail p {
    padding-left: 50px;
    font-size: 14px;
    margin-bottom: 6px;
  }
  .confidence-logo-section {
    padding: 30px 0px 10px 0px;
  }
  .become-dealer h2 {
    font-size: 20px;
    padding-bottom: 20px;
  }
  .pro-dealer-banner-content svg {
    width: 10px;
  }
  .pro-dealer-banner-content sup {
    top: -15px;
  }
  /* become-builder-page-css-end  */
  /* hallman-builder-page-css-start */
  .hallman-builder-content h4 {
    font-size: 18px;
    line-height: 25px;
  }
  .hallman-builder-content p {
    line-height: 26px;
  }
  .hallman-builder-content {
    padding: 0px 0px;
  }
  .mid-images-top img {
    width: 90px !important;
  }
  .mid-images-top {
    top: 8px;
    right: 8px;
  }
  /* hallman-builder-page-css-end */
  /* pro-dealer-page-css-start */
  .pro-dealer-main .pro-dealer-banner-content h1 {
    font-size: 20px;
    line-height: 25.36px;
  }
  .pro-dealer-top-content {
    padding: 0px 0px;
  }
  .pro-dealer-top-content h4 {
    font-size: 18px;
    line-height: 25px;
  }
  .pro-dealer-top-content p {
    line-height: 26px;
  }
  .benefits-of-main h4 {
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 10px;
  }
  .benefits-of-main {
    padding: 0px 10px;
  }
  .benefits-of-dealer-list ul li {
    margin-top: 10px;
  }
  .ready-to-get-started .ready-get-head h4 {
    font-size: 20px;
    line-height: 25px;
  }
  .ready-to-area {
    width: 100%;
  }
  .ready-get-head {
    padding: 0px 0px;
  }
  .ready-to-get-started {
    margin-bottom: 50px;
  }
  /* pro-dealer-page-css-end */
  /* Image-gallery-page-css-start */
  a.gallary-filter {
    margin-top: 30px;
  }
  .image-more-load a {
    padding: 12px 25px;
  }
  .gallery-images-collections {
    margin: 30px 0px 30px 0px;
  }
  .image-more-load {
    margin-bottom: 30px;
  }
  .image-gallery-tabs-section .nav-link {
    padding: 0px 10px;
  }
  /* Image-gallery-page-css-end */
  /* manual-docs-page-css-start */
  .manual-product-search {
    width: 100% !important;
  }
  .popular-manual-light-back h2 {
    font-size: 20px;
    line-height: 25px;
  }
  .manual-product-search input {
    padding: 8.33px 15px;
    font-size: 12px;
  }
  span.manual-rgt-search svg {
    width: 15px;
    padding-bottom: 3px;
  }
  /* manual-docs-page-css-end */
  /* replacement-page-css-start */
  .home-new-product-arriving-section .register-detail h2 {
    padding: 30px 50px 10px 0px;
  }
  .home-new-product-arriving-section .register-detail a {
    margin: 0px 0px 40px 0px;
    padding: 12px 0px;
    width: 110px;
    font-size: 12px;
  }
  .helpful-detail {
    padding: 40px 0px 40px 40px;
  }
  .helpful-link-section h2 {
    font-size: 20px;
  }
  .find-your-range-content a {
    font-size: 12px;
    line-height: 13.4px;
    padding: 5px 20px;
  }
  /* replacement-page-css-end */
  /* warranty-page-css-start */
  .main-images {
    padding: 70px 10px;
  }
  .limited-warrenty-main .warrenty-banner-content h1 {
    font-size: 21px;
    line-height: 32.36px;
    color: #ffffff;
    margin-bottom: 10px;
    letter-spacing: 0;
  }
  /* warranty-page-css-end */
  .home-our-best-seller-section .our-single-product-seller {
    padding: 0px 0px;
  }

  .our-single-product-seller img.tag {
    height: 100%;
}

  .pay-links a {
    font-size: 10px;
  }
  .pay-back {
    padding: 10px 10px;
  }
  .order-summary-content {
    margin-top: 30px;
  }
  .cart-main-section {
    margin-top: 0px;
  }

  .single-spci-manual-table .manual-cont {
    flex-wrap: wrap;
  }
  .single-product-des .logo:after {
    right: -12px;
  }
  .pay-back .single-product-des .logo:after {
    right: 12px;
  }
  .product-result-list .pros-parts-txt p {
    font-weight: 600;
  }
  .top-search-bar .bar-area {
    padding: 10px 30px 10px 30px;
  }
  .logo-slider h3 {
    font-size: 16px;
  }
  .home-new-product-arriving-section .new-product-detail h2 {
    font-size: 18px;
    line-height: 29px;
    padding-bottom: 5px;
    padding-left: 30px;
  }
  .new-product-detail a {
    margin-left: 30px;
  }
  .home-new-product-arriving-section .new-product-detail a {
    width: 120px;
    height: 40px;
    font-size: 13px;
  }
  .home-buy-with-confidence h2 {
    font-size: 20px;
  }
  .discover-category-section .categorie-image-wrpaer .shop-now {
    margin-bottom: 30px;
  }
  .discover-category-section .row .categorie-image-wrpaer h3 {
    font-size: 15px;
  }
  .cate-gallery-wraper .cate-gorygallery h5 {
    font-size: 15px;
  }
  footer .newsletter h4 {
    font-size: 18px;
  }
  footer .newsletter p {
    font-size: 14px;
  }
  .cate-gallery-wraper .cate-gorygallery .shop-now a {
    font-size: 14px;
  }
  .cate-goreis li a {
    font-size: 14px;
    line-height: 12px;
    margin: 7px 0;
  }
  footer .newsletter {
    margin-bottom: 10px;
  }
  .select-top-content h1 {
    font-size: 26px;
    padding-top: 16px;
    line-height: 30px;
  }
  .select-top-content p {
    font-size: 14px;
  }
  .select-classic-bottom p {
    font-size: 14px;
  }
  .left-large-menu-side {
    max-width: 100%;
    width: 100%;
  }
  .right-large-menu-side {
    max-width: 100%;
    width: 100%;
  }
  .large-menu-all {
    flex-wrap: wrap;
  }
  .picture-all .pic-onee-txt h4 a {
    font-size: 13px !important;
  }
  .shipping-center {
    justify-content: start !important;
  }
  .home-banner-content .div p br {
    display: none;
  }
  .our-single-product-seller img {
    margin-bottom: 10px;
  }
  .home-banner-content p {
    max-width: 100%;
    width: 100%;
  }
  .Ship-details {
    padding: 0px;
  }
  .single-ventil-sub-total {
    width: 100% !important;
  }
  .better-together-parent .single-ventil-sub-total .sub-total {
    gap: 10px;
  }
}
@media only screen and (max-width: 576px) {

  h2.frequent-title {
    font-size: 20px !important;
    line-height: 25px !important;
  }
  .single-product-customizer .series-buton {
    margin-top: 15px;
}
  div#pre-buy-faq {
    scroll-margin-top: 230px;
}

  .home-page .back-slider button.carousel-control-prev {
    left: 10px;
}
.home-page .back-slider .carousel-control-next {
  right: 10px;
}
  .new-lower-modal .modal-dialog {
    top: 0px;
}
  .product-slider-single-pro button.carousel-control-prev {
    left: 0px;
  }
  .product-slider-single-pro button.carousel-control-next {
    right: 0px;
  }
  .product-slider-single-pro .carousel-control-prev-icon, .cart-page .carousel-control-prev-icon, .product-hover-pic .carousel-control-prev-icon {
    background-size: 6px;
}
.product-slider-single-pro .carousel-control-next-icon, .cart-page .carousel-control-next-icon, .product-hover-pic .carousel-control-next-icon {
  background-size: 6px;
}
  .home-page .back-slider .carousel-control-prev-icon {
    background-size: 6px;
}
.home-page .back-slider .carousel-control-next-icon {
  background-size: 6px;
}
.home-page .back-slider button.carousel-control-prev {
  height: 25px;
  width: 25px;
}
.home-page .back-slider .carousel-control-next {
  height: 25px;
  width: 25px;
}
  .images-carousel .modal-body {
    overflow-y: scroll;
    height: 70vh;
  }

  .video-pagination .pagination {
    margin-bottom: 30px;
  }

  .product-slider_single-show.left .carousel-root:hover .slide img {
    transform: scale(1);
  }

  /* Image-carousel-css-start */
  .images-top-label .pages-head h2 {
    font-size: 20px;
    line-height: 25px;
    text-align: center;
  }

  .images-top-label .row {
    gap: 20px;
  }

  .images-carousel .modal-header {
    padding: 30px 20px 10px;
  }

  .images-top-label .pages-reach {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .carousel-main .carousel-indicators {
    gap: 10px;
  }

  .carousel-main .carousel-indicators button {
    height: 40px !important;
    max-width: 40px !important;
    width: 100% !important;
  }

  .carousel-main .carousel-indicators button.active {
    height: 50px !important;
    max-width: 50px !important;
    width: 100% !important;
  }

  /* Image-carousel-css-end */
  .pagination.justify-content-center {
    margin-bottom: 0px;
  }

  .faq-list-accor .accordion-body {
    padding: 10px 20px !important;
  }

  .faq-new-questions .accordion-button p {
    padding: 15px 0px;
    font-size: 16px;
  }

  .showroom_partner_sec .form-group .ReactTags__suggestions {
    max-width: 350px !important;
    width: 100%;
  }

  .discover-category-section h1.heading {
    font-size: 20px;
  }

  .discover-category-section .row .categorie-image-wrpaer h2 {
    font-size: 16px;
    padding: 10px 0 2px 0px;
  }

  .quick-specs .quick-gap {
    gap: 10px;
  }

  .single-product-des .fin-more {
    flex-wrap: wrap;
  }
  .product-slider_single-show.left .carousel .slide img {
    object-fit: contain;
    /* object-fit: cover; */
    width: 600px;
    height: 450px;
  }
  .product-slider_single-show.left .carousel .slider {
    height: 400px;
  }
  .product-slider_single-show.left .slider-wrapper {
    height: 400px;
  }
  .single-product-customizer .series-buton a {
    margin-top: 0px;
  }
  .series-buton {
    margin-top: 0px;
    margin-bottom: 20px;
  }
  .prod-outer {
    row-gap: 0px;
    margin-bottom: 0px;
  }
  .prod-outer a.picture-one {
    width: 100%;
    margin-bottom: 13px;
  }
  /* <!-- Single-product-info-section-start --> */
  .prod-info-center .hpy-rev-title {
    flex-wrap: wrap;
  }
  .prod-info-center .hpy-rev-title h2 {
    margin: 17px 0px 0px 0px;
    font-size: 22px;
  }
  .prod-info-center .hpy-rev-title h4 {
    margin: 10px 0px 17px 0px;
  }
  .prod-info-center .rec-abt {
    flex-wrap: wrap;
  }
  .prod-info-center .apper-rev {
    width: 100%;
  }
  .quick-specs .quick-area {
    width: 100%;
  }
  /* <!-- Single-product-info-section-end --> */
  .hallman-create-account-back,
  .hallman-Login-top {
    max-width: 100%;
  }
  /* customer-service-page-css-start */
  .all-services .pop-ser-show h2 {
    font-size: 18px;
    line-height: 21.2px;
  }
  .all-services .pop-ser-hide p {
    font-size: 14px;
    line-height: 18px;
  }
  /* customer-service-page-css-end */
  .product-slider-single-pro .thumbs-wrapper .thumb:before {
    background-color: transparent !important;
    border: 0 !important;
  }
  /* Lower-modal-css-start */
  .lower-level-head p {
    font-size: 16px;
  }
  .lower-modals .modal-content {
    padding: 0px;
  }
  .lower-text-price {
    padding: 20px 10px;
  }
  .lower-text-price h4 {
    font-size: 16px;
  }
  /* Lower-modal-css-end */
  /* faq-page-css-start */
  .common-all {
    padding: 0px 0px 0px 0px;
    margin-bottom: 40px;
  }
  .more-frequent-ask-left h5 {
    font-size: 16px;
    width: 90%;
  }
  a.more-frequent-ask-steps {
    padding: 10px 10px 10px 10px;
  }
  /* faq-page-css-end */
  /* create-account-page-css-start */
  .create-account-steps {
    margin-top: 0px;
    padding: 0px 20px;
  }
  .hallman-create-account-back {
    padding: 22px 20px 0px;
  }
  .hallman-Login-top h1 {
    font-size: 22px;
  }
  /* create-account-page-css-end */
  /* login-page-css-start */
  .hallman-Login-left {
    padding: 0px;
  }
  .hallman-Login-top h2 {
    font-size: 18px;
  }
  /* login-page-css-end */
  /* cart-modal-page-css-start  */
  .share-cart-popup .share-pop-fields input {
    padding: 13px;
  }
  /* cart-modal-page-css-end  */
  /* pro-dealer-page-css-start */
  .pro-dealer-main .pro-dealer-banner-content h1 {
    font-size: 30px;
    line-height: 35.36px;
  }
  .pro-dealer-banner-btn a {
    padding: 11px 32px;
    font-size: 11px;
    margin-top: 10px;
  }
  .benefits-of-dealer-list p {
    line-height: 20px;
    margin-top: 8px;
  }
  .ready-to-get-started .get-ready-fields input {
    padding: 11px;
  }
  .ready-to-get-started .ready-get-head h4 {
    font-size: 18px;
  }
  .ready-to-get-started .get-ready-fields {
    margin-bottom: 10px;
  }
  .file-uploader .text-file {
    margin: 6px 0px 13px 0px;
  }
  .text-file-right p {
    margin-right: 8px;
  }
  .text-file-left p {
    display: none;
  }
  .ready-to-get-started .get-ready-fields label {
    margin-bottom: 6px;
  }
  button.pro-account-create {
    font-size: 11px;
    padding: 11px 31px;
    margin-top: 20px;
  }
  /* pro-dealer-page-css-end */
  /* Image-gallery-page-css-start */
  .uploaders {
    justify-content: center;
  }
  .image-gallery-main-top {
    padding: 25px 0px 25px 0px;
  }
  .img-gallery-select {
    margin-bottom: 30px;
    gap: 10px;
  }
  .image-gallery-tabs-section .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    border-bottom: 2px solid #C0974F !important;
  }
  /* Image-gallery-page-css-end */
  /* replacement-page-css-start */
  .home-our-best-seller-section h2 {
    font-size: 20px !important;
    line-height: 25px !important;
  }
  .replacement-banner {
    padding: 30px 10px;
  }
  .limited-warrenty-main .warrenty-banner-content h1 {
    font-size: 30px;
    margin-bottom: 0px;
    line-height: 42px;
  }
  .limited-warrenty-main .warrenty-banner-content p {
    font-size: 16px;
    line-height: 25px;
    font-weight: 400;
  }
  .find-your-range-content H2 {
    font-size: 19px;
    line-height: 21.12px;
    margin-bottom: 5px;
  }
  .find-your-range-replacement {
    margin: 30px 0px 30px 0px;
    position: relative;
  }
  .home-new-product-arriving-section .register-detail h2 {
    width: 100%;
  }
  .home-new-product-arriving-section {
    padding: 30px 0px 0px 0px !important;
}
  .helpful-detail {
    padding: 30px 0px 40px 30px;
  }
  .helpful-detail h3 {
    font-size: 18px;
    line-height: 20px;
  }
  .helpful-detail a {
    font-size: 12px;
  }
  .find-your-range-content {
    padding: 20px;
    z-index: 1;
    left: 50%;
    top: 10%;
    transform: translateX(-50%);
    width: 100%;
  }
  /* replacement-page-css-end */
  /* warranty-page-css-start */
  .warrenty-banner-content {
    position: static !important;
    transform: translate(0%, 0%) !important;
    padding: 30px 10px;
  }
  .main-images {
    padding: 0px 10px;
  }
  .love-appliances-top h2 {
    font-size: 18px;
    letter-spacing: 0em;
    padding-bottom: 10px;
  }
  .unlock-each-head h2 {
    font-size: 18px;
    letter-spacing: 0em;
    padding-bottom: 10px;
  }
  .unlock-each-heading h2 {
    font-size: 18px;
    padding-bottom: 10px;
  }
  .unlock-each-head-if h2 {
    font-size: 18px;
    letter-spacing: 0em;
    padding-bottom: 10px;
  }
  .unlock-each-heading-small h2 {
    font-size: 18px;
    padding-bottom: 10px;
  }
  .love-appliances-top {
    padding-bottom: 20px;
  }
  .industry-product-search input {
    font-size: 10px;
    max-width: 95%;
    padding: 18px 10px;
  }
  /* warranty-page-css-end */
  /* cart-page-css-start */
  .cart-select-left-heading h2 {
    font-size: 16px;
  }
  .cart-select-left-delivery-option .cart-checking label h2 span {
    font-size: 14px;
  }
  .cart-select-left-delivery-option h2.deliveries {
    font-size: 14px;
  }
  .cart-select-left-delivery-option .cart-checking label h2 {
    font-size: 16px;
  }
  .cart-schedule-date p {
    font-size: 14px;
    line-height: 18px;
  }
  .order-summary-subtotal .subtotal-types p {
    font-size: 16px;
  }
  .order-summary-subtotal .subtotal-types p {
    font-size: 16px;
  }
  .subtotal-flat-txt p {
    font-size: 14px;
  }
  .summary-today-order-text p {
    font-size: 16px;
    width: 100%;
  }
  .order-summary-estimated .order-estimated-text p {
    font-size: 14px;
    line-height: 15px;
  }
  .order-summary-estimated .order-estimated-price h4 {
    font-size: 16px;
  }
  .value-sub {
    margin-top: 0px;
  }
  /* cart-page-css-end */
  .pagination.justify-content-center {
    gap: 5px;
  }
  .single-hallman-burner tr td {
    white-space: normal;
  }
  .page-link .page-item span {
    display: flex;
    align-items: center;
    gap: 4px;
  }
  .share-cart-popup .share-pop-fields input {
    padding: 13px;
  }
  .modal-body .share-pop h2 {
    font-size: 20px;
    margin-bottom: 5px !important;
    text-align: center;
  }
  .modal-body .share-pop-fields input {
    padding: 13px;
  }
  .single-product-specification-body .single-hallman-burner tr,
  .single-product-information-body .single-hallman-burner tr {
    flex-wrap: wrap;
  }
  div#collapse-7avmdr {
    height: auto !important;
  }
  .single-hallman-burner {
    flex-wrap: wrap;
  }
  .product-slider-single-pro .mt-60px {
    margin-top: 20px;
  }
  .product-slider-single-pro .row {
    --bs-gutter-y: 0;
  }
  .product-slider-single-pro .row {
    --bs-gutter-x: 0;
  }
  .product-slider-single-pro .mt-68px {
    margin-top: 10px;
  }
  .hallman-Login-left {
    padding: 30px 20px;
  }
  .discover-category-section .categorie-image-wrpaer a img {
    aspect-ratio: auto;
    height: auto;
  }
  .short-name {
    width: 100%;
  }
  .bannner-botm-space {
    padding: 20px 15px;
    gap: 10px;
  }
  .home-finacing {
    gap: 30px;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
  .home-finacings {
    flex-direction: row-reverse;
    gap: 30px;
    justify-content: flex-end;
  }
  .logo-slider .swiper-wrapper {
    justify-content: flex-start;
  }
  .head-items .navbar-collapse .product-dropdown {
    margin: 15px 20px;
  }
  .main-top-title h2 {
    font-size: 12px;
  }
  .navbar-nav .nav-item #dropdown .products-main-list .list-anchor>h4 a {
    font-size: 14px !important;
    padding-top: 10px !important;
  }
  .logo-slider .swiper-slide img {
    width: 24px;
    height: 24px;
  }
  .select-two-flex {
    display: flex;
    flex-wrap: wrap;
  }
  .select-your-choice-data {
    padding: 30px 0px 50px 0px !important;
}
  .select-classic {
    height: auto;
  }
  .select-classic-bottom {
    bottom: 30px;
  }
  footer .cate-goreis li {
    display: flex;
  }
  footer .cate-goreis h2 {
    text-align: left;
  }
  .copyright-section .copyright-email a {
    display: flex;
  }
  .copyright-section .review-logo {
    justify-content: center;
  }
  .copyright-section .review-logo:after {
    background-color: transparent;
  }
  .home-new-product-arriving-section .new-product-detail h2 {
    padding-top: 40px;
    padding-left: 60px;
    padding-bottom: 20px;
  }
  .discover-category-section {
    padding: 30px 0px;
  }
  .footer-social-links {
    margin: 17.8px 0px 28.71px 0px;
  }
  .discover-category-section .categorie-image-wrpaer .shop-now {
    margin-bottom: 30px;
  }
  .picture-one {
    width: 50%;
  }
  .navbar>.container,
  .navbar>.container-fluid,
  .navbar>.container-lg,
  .navbar>.container-md,
  .navbar>.container-sm,
  .navbar>.container-xl,
  .navbar>.container-xxl {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
  }
  .pic-onee {
    max-width: 100%;
    padding: 5px;
    border: 1px solid #c7c7c7;
    background: #E6E6E6;
    transition: 0.5s all ease-in-out;
  }
  .pic-onee:hover {
    box-shadow: 0px 5px 10px -1px #001e42;
    transition: 0.5s all ease-in-out;
    border-top: 4px solid #001e42;
    border-radius: 5px 5px 0px 0px;
  }
  .picture-one {
    width: 33.33%;
  }
  .picture-one {
    border-left: 0px;
  }
  nav .navbar-nav .nav-item a {
    font-size: 14px;
  }
  li.pros-data-list {
    padding: 6px 10px 10px 10px;
  }
  nav .navbar-nav .nav-item a {
    font-size: 16px;
  }
  .home-new-product-arriving-section {
    padding: 30px 0px;
  }
  .home-our-best-seller-section .our-single-product-seller h3 {
    font-size: 16px !important;
    text-transform: capitalize;
    margin: 0px 20px;
    display: flex;
    justify-content: center;
    font-weight: 500;
    line-height: 22px;
    min-height: 54px;
  }
  .home-our-best-seller-section .our-single-product-seller p {
    font-size: 16px;
    /* margin: 8px 0px; */
    /* padding: 5px 0px 15px 0px; */
  }
  .home-our-best-seller-section .our-single-product-seller p.sku-unit {
    padding-top: 18px;
}
  .home-our-best-seller-section .our-single-product-seller h4 {
    font-size: 16px;
  }
  .home-our-best-seller-section h4 {
    font-size: 22px;
    /* line-height: 30px; */
    margin-bottom: 0px !important;
  }
  .discover-category-section h2.heading {
    font-size: 20px;
  }
  .home-new-product-arriving-section .new-product-detail h2 {
    font-size: 20px;
    line-height: 25px;
  }
  .home-new-product-arriving-section .new-product-detail a {
    font-size: 12px;
    width: 130px;
    padding: 10px 0px;
    font-weight: 400;
  }
  .shiping-list .shiping-conteted h2 {
    font-size: 16px;
    align-items: center;
    justify-content: left !important;
  }
  .home-banner-content a {
    width: 130px;
    padding: 10px 0px;
    font-size: 12px;
    font-weight: 400;
  }
  .dropdown-menu.drop-menu {
    box-shadow: 0 0 7px #000;
  }
  .single-product .part-1 h3.product-old-price {
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
  }
  .single-product .part-1 p {
    font-size: 15px;
    /* margin: 8px 0px; */
  }
  .lower-cart-icon.ced .clearance-tag p {
    font-size: 14px;
  }
  .single-product .part-1 h4 {
    font-size: 16px;
  }
  .shiping-list {
    padding: 20px 0px 20px 0px;
  }
  .section-products .filter-row .clear-filter h3 {
    font-size: 13px;
    font-size: 13px;
    justify-content: right;
    text-wrap: nowrap;
    white-space: nowrap;
    width: 25%;
  }
  .section-products .filter-row .showing-filter h3 {
    font-size: 14px;
    text-align: end;
    line-height: 40px;
    justify-content: end;
    white-space: nowrap;
  }
  .section-products .filter-row {
    flex-wrap: wrap;
  }
  .section-products .filter-row h2 {
    display: flex;
    font-size: 18px;
    justify-content: left;
    white-space: nowrap;
    align-items: center;
    flex-direction: row-reverse;
  }
  .filter-list {
    width: 30%;
  }
  .section-products .filter-row .showing-filter {
    width: 70%;
  }
  .clear-filter {
    width: 100%;
  }
  svg.social-circle {
    height: 30px;
    width: 30px;
  }
  footer .cate-goreis h2 {
    font-size: 15px;
    margin-bottom: 7px !important;
  }
  .cate-gallery-wraper .cate-gorygallery .shop-now {
    margin-bottom: 40px;
  }
  .home-our-best-seller-section {
    padding: 30px 0;
  }
  footer {
    padding: 40px 0 40px 0;
  }
  .copyright-section .copyright-email>div {
    font-size: 12px;
  }
  .copyright-section .review-logo p {
    font-size: 12px;
  }
  .discover-category-section .row .categorie-image-wrpaer h3 {
    padding-bottom: 0px;
  }
  .home-buy-with-confidence h2 {
    font-size: 20px;
  }
  .discover-category-section .row .categorie-image-wrpaer h3 {
    padding: 10px 0 2px 0px;
  }
  .cate-gallery-wraper .cate-gorygallery h3 {
    font-size: 16px;
    padding: 10px 0 6px 0;
  }
  a.shop-now {
    padding: 10px 20px;
  }
  .logo-slider .swiper-slide img {
    margin-right: 0;
  }
  .home-new-product-arriving-section .new-product-detail {
    padding: 40px 0px 50px;
  }
  .picture-all .pic-onee-txt h4 a {
    font-size: 16px !important;
  }
  .new-product-detail a {
    margin-left: 60px;
  }
  .discover-category-section .row .categorie-image-wrpaer h3 {
    font-size: 16px;
  }
  .discover-category-section .row .categorie-image-wrpaer .shop-now a {
    font-size: 15px;
  }
  .home-our-best-seller-section .our-single-product-seller .classisco-heading a {
    font-size: 14px;
  }
  .single-product-seller h2 {
    -webkit-line-clamp: 3;
  }
  .single-product .part-1 a {
    font-size: 14px;
  }
  .picture-one {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .picture-all {
    row-gap: 20px;
  }
  .seach-contents {
    padding: 20px 30px 20px 30px;
  }
  .single-product-better-together h3.better-together {
    font-size: 20px;
  }
  .real-life-single-pro h3 {
    font-size: 20px;
  }
  .product-slider-single-pro .carousel-control-next {
    right: 0px;
  }
  .product-slider-single-pro .carousel-control-prev {
    left: 0px;
  }
  .delvery-schedule-date p {
    line-height: 15px;
  }
  img.filter-molile-show {
    display: block;
    width: 15px;
    margin-right: 5px;
  }
  .single-product-delivery h2.delivery {
    font-size: 16px;
  }
  .single-product-delivery-option h3 {
    font-size: 16px;
  }
  .single-produt-choose-color h4 {
    font-size: 16px;
  }
  .single-product-delivery .choose-delivery-date .form-checking label h2 {
    font-size: 16px;
  }
  .lower-cart-icon.ced a {
    font-size: 14px;
  }
  .better-together-parent .single-ventil p {
    font-size: 15px;
  }
  .better-together-parent .single-ventil .rating h6 {
    font-size: 15px;
  }
  .better-together-parent .single-ventil h3 {
    font-size: 16px;
    font-weight: 500;
  }
  /* cart-page-css-start */
  .cart-select-left-heading h2 {
    font-size: 16px;
  }
  .cart-select-left-delivery-option h2.deliveries {
    font-size: 14px;
  }
  .cart-select-left-delivery-option .cart-checking label h2 {
    font-size: 14px;
  }
  .cart-schedule-date p {
    font-size: 14px;
    line-height: 18px;
  }
  .order-summary-subtotal .subtotal-types p {
    font-size: 16px;
  }
  .order-summary-subtotal .subtotal-types p {
    font-size: 14px;
  }
  .subtotal-flat-txt p {
    font-size: 14px;
  }
  .summary-today-order-text p {
    font-size: 14px;
    width: 100%;
  }
  .order-summary-estimated .order-estimated-text p {
    font-size: 12px;
    line-height: 15px;
  }
  .order-summary-estimated .order-estimated-price h4 {
    font-size: 16px;
  }
  .cart-select-left-heading p {
    font-size: 15px;
    margin-top: 7px;
  }
  .cart-select-left-delivery-option .cart-checking label h2 span {
    font-size: 13px;
  }
  .order-summary-content .order-summary-head h2 {
    font-size: 17px;
  }
  .order-summary-subtotal .subtotal-types h2 {
    font-size: 13px;
  }
  .order-summary-estimated .order-estimated-text h2 {
    font-size: 14px;
  }
  .cart-checkout button.cart-secure-checkout {
    font-size: 14px;
  }
  .cart-select-area .cart-section-title p {
    font-size: 13px;
  }
  .cart-select-area .cart-section-title h1 {
    font-size: 20px;
    margin-bottom: 0px;
  }
  .widget-box #Revolving{
    display: none;
  }
  /* cart-page-css-end */
  /* my-team-css-starts */
  .my-team-area .row.gx-3.spex {
    flex-direction: column;
  }
  .my-team-area .col-lg-4.col-md-4.col-sm-4 {
    width: 100%;
  }
  .my-team-area{
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  /* my-team-css-end */
  .testimonial-list {
    gap: 0px;
  }
  .testimonial-list .testimonial-detail p {
    padding: 20px 18px;
    font-size: 14px;
    line-height: 25px;
  }
  .set.dropzone p{
    font-size: 13px;
  }
  .seach-contents .search-item-top {
    max-height: 500px;
    overflow: auto;
  }
}
@media only screen and (max-width: 425px) {
  .single-produt-choose-color .form-check-flie .choose-color {
    max-width: 90px;
}
  /* faq-page-css-start */
  .comm-questions {
    width: 100%;
  }
  /* faq-page-css-end*/
  /* Image-gallery-page-css-start */
  .select-option-parts::after {
    right: -6px;
  }
  .gallery-area {
    width: 100%;
  }
  .small-hidden-txt {
    font-size: 16px;
  }
  .image-gallery-tabs-section .nav-tabs {
    padding-bottom: 25px;
  }
  .large-image img {
    height: 100%;
    object-fit: cover;
  }
  .small-image img {
    height: 100%;
    object-fit: cover;
  }
  .large-image {
    height: 300px;
  }
  .small-image {
    height: 300px;
  }
  /* Image-gallery-page-css-end *
  /* manual-docs-page-css-start */
  .popular-manual-light-parts {
    width: 100%;
  }
  span.manual-rgt-search svg {
    padding-bottom: 0px;
  }
  /* manual-docs-page-css-end */
  /* replacement-page-css-start */
  .single-product-seller {
    width: 100%;
  }
  .find-your-range-content h2 br {
    display: none;
  }
  .find-your-range-content a {
    padding: 10px 17px;
  }
  .find-your-range-content H2 {
    font-size: 14px;
    line-height: 18.12px;
  }
  /* replacement-page-css-end */
  .discover-category-section .first-categoryparent {
    width: 100%;
  }
  .home-finacings {
    gap: 20px;
  }
  .bannner-botm-space .home-finacing-txt p {
    font-size: 14px;
  }
  .home-finacing {
    gap: 20px;
  }
  .discover-product-area {
    width: 100%;
  }
  .single-product-seller {
    width: 100%;
  }
  .product-parent {
    width: 100% !important;
  }

  .select-classic-bottom {
    bottom: 20px;
  }
  .pic-onee img {
    max-width: 100%;
    width: 100%;
  }
  .home-buy-with-confidence h2 {
    font-size: 18px;
  }
  .sub-canvas {
    width: 100%;
  }
  .single-produt-choose-color .form-check-flie {
    flex-wrap: wrap;
    align-items: unset;
  }
  .ventil-sub {
    width: 100% !important;
  }
  .select-gap {
    padding-bottom: 20px;
  }
  .my-img-sec .small-image {
    height: 275px;
  }
}
/* responsive for mobile css end here */
/* ============================Dashboard-one-page-css-start========================= */
/* ============================Dashboard-one-page-css-start========================= */
.dashboard-outer-main {
  margin: 50px 0;
  /* position: relative;
  z-index: 88; */
}
.dashboard-my-order h1 {
  color: #001e42;
  font-family: "Bookman";
  font-size: 25px;
  line-height: 28.35px;
  text-align: left;
  text-transform: uppercase;
  padding-bottom: 11px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dashboard-my-order p {
  font-size: 16px;
  margin-bottom: 21px !important;
}
.dashboard-my-order-detail-one {
  border: 1px solid #e0e0e0;
  margin-bottom: 30px;
  max-width: 962px;
}
.my-order-track {
  padding: 20px 30px;
  border-bottom: 1px solid #eaeaea;
  flex-wrap: wrap;
}
.my-order-category-top-one-type h2 {
  color: #1d1d1b;
  font-family: "Gotham";
  font-size: 14px;
  font-weight: 500;
  line-height: 13.4px;
  text-align: left;
}
.my-order-category-top-one-type h3 {
  color: #1d1d1b;
  font-family: "Gotham";
  font-size: 14px;
  font-weight: 400;
  line-height: 13.4px;
  text-align: left;
  margin-top: 8px;
}
.my-order-category-top-one-type {
  padding-right: 40px;
  margin-right: 40px;
  border-right: 1px solid #e0e0e0;
}
.track-my-order a {
  color: #c0974f;
  font-family: "Gotham";
  font-size: 14px;
  font-weight: 500;
  line-height: 13.4px;
  text-align: left;
  text-decoration: underline;
  text-underline-offset: 3px;
  text-transform: capitalize;
}
.dashboard-order-info,
.view-by-links {
  padding: 30px 30px 12px 30px;
}
.dashboard-my-order-detail-one .dashboard-order-left {
  gap: 30px;
}
.dashboard-my-order-detail-one .dashboard-order-left img {
  max-width: 82px;
  width: 100%;
  height: 100%;
}
.dashboard-order-left-content h2 {
  color: #022741;
  font-family: "Gotham";
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  max-width: 70%;
  width: 100%;
}
.dashboard-order-left-content h3 {
  color: #1d1d1b;
  font-family: "Gotham";
  font-size: 14px;
  font-weight: 400;
  line-height: 13.4px;
  padding: 12px 0px;
}
.dashboard-order-left-content h4 {
  color: #1d1d1b;
  font-family: "Gotham";
  font-size: 14px;
  font-weight: 500;
  line-height: 13.4px;
}
.view-product-btm.p {
  color: #001e42;
  font-family: "Gotham";
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
}
.dashboard-order-info span.order-highlight {
  font-family: "Gotham";
  font-size: 14px;
  font-weight: 500;
  line-height: 25px;
  text-align: left;
  color: #001E42;
}
.dashboard-order-info .view-product-btm p {
  color: #242424;
  font-family: 'Gotham';
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
}
.dashboard-order-center h4 {
  color: #1d1d1b;
  font-family: "Gotham";
  font-size: 14px;
  font-weight: 500;
  line-height: 13.4px;
  text-align: left;
}
.dashboard-order-center h5 {
  font-family: "Gotham";
  font-size: 12px;
  font-weight: 500;
  line-height: 11.48px;
  display: inline-block;
  padding: 7px;
  border-radius: 7px;
  margin-top: 12px;
  text-transform: capitalize;
}
.dashboard-order-right h2 {
  color: #001e42;
  font-family: "Bookman";
  font-size: 25px;
  font-weight: 5;
  line-height: 28.35px;
  letter-spacing: 0.05em;
}
.view-buy-buttons a,
a.view-product-differ {
  color: #001e42;
  font-family: "Gotham";
  font-size: 14px;
  font-weight: 500;
  line-height: 13.4px;
  text-align: left;
  padding-left: 10px;
  margin-left: 10px;
  text-transform: capitalize;
}
.view-buy-buttons a {
  text-decoration: underline;
  text-underline-offset: 4px;
}
.dashboard-my-order-detail-one .view-by-links a {
  color: #fff;
  background: #C0974F;
  padding: 13px 15px;
  text-transform: uppercase;
  border: 1px solid #C0974F;
  transition: 0.5s all ease-in-out;
}
.dashboard-my-order-detail-one .view-by-links a:hover {
  background: #fff;
  border: 1px solid #001E42;
  color: #001E42;
  transition: 0.5s all ease-in-out;
}
.view-product-btm {
  margin-top: 20px;
}
.dashboard-my-order-detail-one .view-by-links.seprate {
  padding-top: 12px;
}
.dashboard-order-center.second h5 {
  background: #001e42;
}
.dashboard-order-center.third h5 {
  background: #4887ef;
}
/* Right-side-penel-css-start */
.dashboard-my-order-panel {
  background: #efefef;
  padding: 30px 25px;
  position: sticky;
  top: 0;
}
.dashboard-my-order-panel-head p {
  color: #001e42;
  font-family: "Gotham";
  font-size: 16px;
  font-weight: 400;
  line-height: 15.31px;
  text-align: left;
}
.dashboard-my-order-panel-head h2 {
  color: #001e42;
  font-size: 20px;
  font-family: "Gotham";
  margin-top: 8px;
  border-bottom: 1px solid #000;
  padding-bottom: 26px;
}
.my-order-panel-list ul li a {
  color: #1d1d1b;
  font-size: 15px;
  border-bottom: 1px solid;
  transition: all 0.3s ease-in-out;
}
.my-order-panel-list ul li.active a {
  color: #001e42;
  font-weight: 500;
}
.my-order-panel-list ul li:hover a {
  font-weight: 500;
  color: #001e42;
}
.my-order-panel-list ul {
  margin-top: 28px;
}
.my-order-panel-list ul li+li {
  margin-top: 38px;
}
.my-order-category-top-one .my-order-category-top-one-type:last-child {
  border: none;
  margin-right: 0px;
  padding-right: 0px;
}
.dashboard-my-order h1 a {
  font-size: 12px;
  font-weight: 500;
  color: #2d58c6;
  border-bottom: 1px solid;
}
.dashboard-my-order h1 a svg {
  margin-right: 5px;
  vertical-align: middle;
}
.my_images_sec img {
  width: 100%;
  transition: ease-out 300ms;
  animation-fill-mode: backwards;
  filter: opacity(0.9);
  object-fit: cover;
}
.delivery_que_sec h2 {
  font-size: 18px;
  color: #1D1D1B;
  font-weight: 500;
  line-height: 17.23px;
  font-family: 'Gotham';
}
.delivery_que_sec .note_sec h3 {
  color: #1D1D1B;
  font-family: 'Gotham';
  font-size: 18px;
  font-weight: 500;
  line-height: 17.23px;
  text-align: left;
}
.delivery_que_sec {
  margin-top: 14px;
}

.delivery_que_sec .form-group {
  margin-bottom: 20px;
}

.delivery_que_sec .form-group:last-child {
  margin-bottom: 0px;
}

.delivery_que_sec .form-group label.form-label {
  font-size: 14px;
  margin-bottom: 10px;
  display: block;
  line-height: 22px;
  color: #1D1D1B;
  font-weight: 400;
}
.delivery_que_sec .form-group label.form-label span {
  color: #eb1c26;
  margin-right: 0;
}
.delivery_que_sec .form-group .form-control {
  border: 1px solid #dadada;
  border-radius: 0px;
  height: 50px;
  font-size: 14px;
}
.delivery_que_sec .box {
  border-bottom: 1px solid #dadada;
  padding-bottom: 50px;
  margin-bottom: 50px;
}
.delivery_que_sec .form-group span input {
  margin-right: 8px;
  position: relative;
  top: 3px;
  cursor: pointer;
  border: 1px solid #969696;
}
.delivery_que_sec .form-group span {
  margin-right: 10px;
}
.delivery_que_sec .form-check-input:checked {
  background-color: #c0974f;
  border-color: #c0974f;
}
.delivery_que_sec .form-group textarea {
  height: 150px !important;
  resize: none;
}
.delivery_que_sec .upload_file {
  border: 1px dashed transparent;
  border-style: dashed;
  height: 213px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: repeating-linear-gradient(0deg, #9b9b9b, #9b9b9b 5px, #0000 0, #0000 11px, #9b9b9b 0), repeating-linear-gradient(90deg, #9b9b9b, #9b9b9b 5px, #0000 0, #0000 11px, #9b9b9b 0), repeating-linear-gradient(180deg, #9b9b9b, #9b9b9b 5px, #0000 0, #0000 11px, #9b9b9b 0), repeating-linear-gradient(270deg, #9b9b9b, #9b9b9b 5px, #0000 0, #0000 11px, #9b9b9b 0);
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
  background-size: 2px 100%, 100% 2px, 2px 100%, 100% 2px;
}

.delivery_que_sec .upload_file img {
  height: 36px;
  margin-bottom: 20px;
  object-fit: contain;
}
.delivery_que_sec .upload_file h5 {
  font-size: 16px;
  max-width: 165px;
  margin: 0 auto 10px;
  line-height: 22px;
}
.delivery_que_sec .upload_file p {
  margin-bottom: 0;
  font-size: 14px;
}
.delivery_que_sec .upload_file p span {
  margin-right: 0;
  color: #001F3F;
  font-weight: 500;
  border-bottom: 1px solid;
  position: relative;
  cursor: pointer;
}
.delivery_que_sec .upload_file p span input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.delivery_que_sec .form-group span label {
  font-size: 14px;
}
 .delivery_que_sec  .box.last-box  {
  border: none;
  margin-bottom: 20px;
  padding-bottom: 0px;
} 
.delivery_que_sec .note_sec p {
  font-size: 14px;
  line-height: 25px;
  padding-top: 2px;
}
.delivery_que_sec button.submit {
  background: #c0974f;
  height: 50px;
  width: 178px;
  border: none;
  color: #fff;
  margin-top: 20px;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid #c0974f;
  transition: 0.5s all ease-in-out;
}
.delivery_que_sec button.submit:hover{
  background-color: #fff;
  color: #001e42;
  border: 1px solid #001e42;
  transition: 0.5s all ease-in-out;
}
.my_images_sec .box {
  position: relative;
  overflow: hidden;
  line-height: 0px;
}
.my_images_sec .box video {
  object-fit: cover;
  line-height: 0px;
}
.my_images_sec .box .text {
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  left: 0px;
  right: 0px;
  top: 0;
  bottom: 0;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 32.68px;
  letter-spacing: 0.05em;
  font-family: "Bookman";
  display: none;
  transition: ease-out 300ms;
  text-align: center;
  padding: 0 10px;
}
.my_images_sec .box:hover .text {
  display: flex;
}
.my_images_sec .box:hover img {
  transform: scale(1.2);
  transition: 0.7s all ease-in-out;
  animation-fill-mode: backwards;
  filter: opacity(0.9);
}
.payment_sec {
  max-width: 625px;
}
.payment_sec .filed {
  border: 1px solid #d3d3d3;
  padding: 15px;
  position: relative;
  margin-bottom: 18px;
}
.payment_sec .filed img.visa {
  position: absolute;
  height: 40px;
}
.payment_sec .filed .text {
  padding-left: 78px;
  padding-top: 3px;
}
.payment_sec .filed .text h2 {
  font-size: 14px;
}
.payment_sec .filed .d-flex {
  justify-content: space-between;
  align-items: center;
}
.payment_sec .filed .text p {
  font-size: 12px;
}
.payment_sec .filed .edit {
  display: flex;
  gap: 16px;
  align-items: center;
}
.payment_sec .filed .edit img {
  width: 18px;
}
.payment_sec .filed .edit a+a {
  border-left: 1px solid #d3d3d3;
  padding-left: 14px;
}
.payment_sec a.add_card_btn {
  font-size: 14px;
  font-weight: 500;
  color: #001e42;
  border-bottom: 1px solid;
}
.payment_sec a.add_card_btn i {
  -webkit-text-stroke: 0.8px;
  margin-right: 4px;
}
.payment_sec .card_box {
  border-top: 1px solid #d3d3d3;
  margin-top: 38px;
  padding-top: 30px;
}
.payment_sec .accordion button.accordion-button {
  padding: 0px !important;
  background-color: transparent;
  border: none;
  box-shadow: none;
}
.payment_sec .accordion button.accordion-button:after {
  background-image: none !important;
}
.payment_sec .card_box .form-group {
  position: relative;
  margin-bottom: 25px;
}
.payment_sec .card_box .form-group .form-control {
  height: 50px;
  font-size: 14px;
  font-weight: 400;
  border-radius: 0px;
  border: 1px solid #d3d3d3;
  color: #000;
}
.payment_sec .card_box .form-group .form-control::placeholder {
  color: #a8a8a8;
}
.payment_sec .card_box .form-group select.form-select.form-control {
  color: #a8a8a8;
}
.payment_sec .card_box .form-group label {
  font-size: 15px;
  color: #1D1D1B;
  font-weight: 400;
  line-height: 30px;
}
.payment_sec .card_box .form-group span img {
  height: 18px;
}
.payment_sec .card_box .form-group span {
  position: absolute;
  right: 14px;
  bottom: 14px;
  gap: 10px;
  display: flex;
}
.payment_sec .card_box .form-control.extra {
  padding-right: 250px;
}
.payment_sec .card_box h3 {
  font-size: 14px;
  align-items: center;
  display: flex;
  color: #1D1D1B;
  font-weight: 500;
  line-height: 22px;
}

.payment_sec .card_box svg {
  margin-left: 10px;
}
.payment_sec .card_box h3 img.lock {
  height: 22px;
  vertical-align: top;
  margin-right: 2px;
  margin-left: 0;
}
.payment_sec .card_box h3 img {
  height: 23px;
  margin-left: 7px;
}
.payment_sec .card_box h4 {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  margin: 14px 0 23px !important;
  color: #1D1D1B;
}
.payment_sec button.add_cart {
  height: 50px;
  width: 178px;
  background: #c0974f;
  color: #fff;
  border: none;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid #c0974f;
  transition: 0.5s all ease-in-out;
}
.payment_sec button.add_cart:hover {
  border: 1px solid #001e42;
  transition: 0.5s all ease-in-out;
  background-color: #fff;
  color: #001e42;
}
.address_book_sec h2 {
  font-size: 25px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 6px !important;
  color: #001E42;
}
.address_book_sec h3 {
  font-size: 14px;
  margin-bottom: 24px !important;
}
.address_book_sec .box {
  background: #efefef;
  padding: 30px 28px;
  height: fit-content;
  min-height: 290px;
}
.address_book_sec .box h4 {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 6px !important;
  color: #1D1D1B;
  text-transform: uppercase;
}
.address_book_sec .box .bi-three-dots-vertical::before {
  content: "\f5d3";
  color: black;
}
.address_book_sec .box h5 {
  font-size: 17px;
  margin-bottom: 27px !important;
  line-height: 22px;
  color: #1D1D1B;
}
.address_book_sec .box .d-flex {
  justify-content: space-between;
  align-items: flex-start;
}
.address_book_sec .box .d-flex .dropdown button {
  border: none;
  font-size: 20px;
}
.address_book_sec a .box_two {
  font-size: 16px;
  color: #001e42;
  font-weight: 500;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #efefef;
  padding: 30px 28px;
  height: 290px;
  background-image: repeating-linear-gradient(0deg, #afafaf, #afafaf 13px, transparent 13px, transparent 25px, #afafaf 25px), repeating-linear-gradient(90deg, #afafaf, #afafaf 13px, transparent 13px, transparent 25px, #afafaf 25px), repeating-linear-gradient(180deg, #afafaf, #afafaf 13px, transparent 13px, transparent 25px, #afafaf 25px), repeating-linear-gradient(270deg, #afafaf, #afafaf 13px, transparent 13px, transparent 25px, #afafaf 25px);
  background-size: 2px 100%, 100% 2px, 2px 100%, 100% 2px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
}
a.address-add {
  display: block;
}
.address_book_sec a .box_two span {
  display: block;
  text-align: center;
  font-size: 24px;
}
.address_book_sec a.full-wid {
  display: block;
}
.address_book_sec a .box_two span i {
  -webkit-text-stroke: 1px;
}
.address_book_sec .default {
  margin-top: 14px;
  font-size: 14px;
  font-weight: bold;
  font-style: italic;
  color: #001E42;
}
.address_book_sec {
  margin-bottom: 16px;
  max-width: 842px;
}
.address_book_sec .default i {
  -webkit-text-stroke: 1px;
  margin-right: 2px;
  font-size: 15px;
  vertical-align: middle;
}
.dashboard-my-order .back_btn a {
  font-size: 14px;
  font-weight: 500;
  color: #1d1d1b;
  display: flex;
  align-items: center;
}
.dashboard-my-order .back_btn {
  margin-bottom: 25px;
}
.dashboard-my-order .back_btn a i {
  font-size: 18px;
  margin-right: 6px;
  -webkit-text-stroke: 0.8px;
  vertical-align: middle;
}
.add_new_add_sec .form-group label.form-label {
  font-size: 14px;
  margin-bottom: 10px;
  display: block;
  line-height: 22px;
  color: #1D1D1B;
}
.add_new_add_sec .form-group .form-control {
  border: 1px solid #dadada;
  border-radius: 0px;
  height: 50px;
  font-size: 14px;
}
.add_new_add_sec .form-group {
  margin-bottom: 28px;
}
.add_new_add_sec {
  max-width: 920px;
  margin-bottom: 16px;
}
.add_new_add_sec .form-group label.form-label span {
  color: #eb1c26;
  margin-right: 0;
}
.add_new_add_sec .image_upload {
  background: #f5f5f5;
  padding: 40px 40px 30px;
  margin-top: 12px;
  margin-bottom: 38px;
}
.add_new_add_sec .image_upload h3 span {
  font-size: 14px;
  color: #666666;
  font-weight: 400;
}
.add_new_add_sec .image_upload h3 {
  font-size: 18px;
  margin-bottom: 16px;
  line-height: 22px;
}
.add_new_add_sec .image_upload h4 {
  font-size: 14px;
  margin-bottom: 10px;
}
.add_new_add_sec .image_upload .set {
  padding: 45px 15px 33px;
  background: #fff;
  border: 1px solid #9b9b9b;
  border-style: dashed;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  margin-bottom: 20px;
}
.add_new_add_sec .image_upload .set h5 {
  font-size: 14px;
  font-weight: 400;
}
.add_new_add_sec .image_upload .set h5 span {
  font-weight: 500;
  border-bottom: 1px solid;
}
.add_new_add_sec .image_upload .set h5 i {
  font-size: 20px;
  margin-right: 4px;
  vertical-align: middle;
  -webkit-text-stroke: 0.3px;
  color: #001e42;
}
.add_new_add_sec .image_upload .set input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  opacity: 0;
}
.add_new_add_sec .image_upload p {
  font-size: 14px;
  line-height: 24px;
  position: relative;
  padding-left: 20px;
}
.add_new_add_sec .image_upload p i {
  position: absolute;
  left: 0;
  color: #e30c00;
}
.add_new_add_sec .form-check {
  margin-bottom: 14px;
}
.add_new_add_sec .form-check input {
  border: 1px solid #dadada;
  position: relative;
  top: 4px;
}
.add_new_add_sec .form-check label {
  font-size: 14px;
  margin-left: 4px;
}
.add_new_add_sec .form-check-input:checked {
  background-color: #c0974f;
  border-color: #c0974f;
}
.add_new_add_sec button.save_btn {
  height: 50px;
  width: 150px;
  border: none;
  background: #c0974f;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  margin-top: 20px;
  border: 1px solid #c0974f;
  transition: 0.5s all ease-in-out;
}
.add_new_add_sec button.save_btn:hover {
  background-color: #fff;
  color: #001e42;
  border: 1px solid #001e42;
  transition: 0.5s all ease-in-out;
}
.dashboard-my-order p i {
  color: #e30c00;
  font-size: 14px;
  margin-right: 6px;
}
.wishlist_sec .box {
  text-align: center;
  margin-top: 50px;
}
.wishlist_sec .box img {
  height: 263px;
  margin: auto;
  object-fit: contain;
}
.wishlist_sec .box img.tag {
  height: 100%;
  width: 100%;
  max-width: 162px;
}
.wishlist_sec .box h2 {
  font-size: 12px;
  font-weight: 400;
  background: #e6e6e6;
  padding: 7px 10px;
  max-width: 140px;
  margin: 30px auto 17px !important;
  color: #001F3F;
}
.wishlist_sec .box h3 {
  font-size: 15px;
  margin-bottom: 15px !important;
  color: #022741 !important;
  line-height: 24px;
  min-height: 74px;
}
.wishlist_sec .box p.sku-unit {
  font-family: 'Gotham';
  font-size: 14px;
  font-weight: 400;
  line-height: 13.4px;
  text-align: center;
  color: #1D1D1B;
  padding: 0px 0px 18px 0px !important;
}
.wishlist_sec .box h4 {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 5px !important;
  color: #1D1D1B;
}
.wishlist_sec .box span.rating i {
  color: #f5b62e;
  font-size: 15px;
  margin: 0 2px;
}
.wishlist_sec .box span.rating {
  margin-bottom: 12px !important;
  display: block;
}
.wishlist_sec .box h5 {
  margin-bottom: 5px !important;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  line-height: 34.2px;
  letter-spacing: 0.05em;
  color: #001e42;
  font-family: "Bookman";
}
.wishlist_sec .box h6 {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.14px;
  letter-spacing: 0.05em;
  color: #595959;
  text-decoration: line-through;
  font-family: "Bookman";
  margin-top: 4.42px;
}
.wishlist_sec .box img.coupon {
  height: 22px;
  margin-left: 0px;
}
.wishlist_sec .box a.remove {
  display: inline;
  color: #1D1D1B;
  font-size: 14px;
  margin-top: 20px;
  text-decoration: underline;
}
.wishlist_sec {
  margin-bottom: 16px;
}
.account_info_sec .accordion-header button {
  padding: 0px;
  background-color: transparent;
  background: transparent;
  color: #000;
  border-bottom: none;
  box-shadow: none;
}
.account_info_sec .accordion-button:after {
  content: none !important;
}
.account_info_sec h2 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #1D1D1B;
}
.account_info_sec {
  max-width: 830px;
}
.account_info_sec .box h3 {
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 15px !important;
  color: #1D1D1B;
}
.account_info_sec .box h3 {
  font-size: 16px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #1D1D1B;
}
.account_info_sec .box .accordion-header h3 span {
  font-family: 'Gotham';
  font-size: 16px;
  font-weight: 400;
  line-height: 15.31px;
  text-align: left;
  color: #1D1D1B;
  text-transform: capitalize;
}

.account_info_sec .box .accordion-header h3 span.small {
  text-transform: none;
}

.account_info_sec .box .accordion-header h3 a {
  font-family: 'Gotham';
  font-size: 13px;
  font-weight: 400;
  line-height: 12.44px;
  text-align: left;
  color: #001E42;
  text-transform: capitalize;
}

.account_info_sec .box h3 a {
  font-size: 13px;
  color: #001e42;
  border-bottom: 1px solid;
}
.account_info_sec .box {
  border-bottom: 1px solid #d3d3d3;
  padding-bottom: 36px;
  margin-top: 50px;
}
.account_info_sec .box .set {
  background: #efefef;
  padding: 32px 30px 42px;
  margin-top: 30px;
}
.account_info_sec .box .set .form-group {
  margin-bottom: 21px;
}
.account_info_sec .box .set .form-group label {
  font-size: 14px;
  margin-bottom: 10px;
  color: #1D1D1B;
  font-weight: 400;
}
.account_info_sec .box .set .form-group label span {
  color: #E30C00;
}
.account_info_sec .box .set .form-group input {
  height: 50px;
  border-radius: 0px;
  border: 1px solid #dadada;
  font-size: 14px;
}
.account_info_sec .box .set button {
  background: #c0974f;
  height: 50px;
  width: 178px;
  color: #fff;
  border: none;
  font-size: 14px;
  font-weight: 500;
  margin-top: 6px;
  border: 1px solid #c0974f;
  transition: 0.5s all ease-in-out;
}
.account_info_sec .box .set button:hover {
  background-color: #fff;
  border: 1px solid #001e42;
  transition: 0.5s all ease-in-out;
  color: #001e42;
}
.showroom_partner_sec .nav-pills .nav-link {
  border: 0;
  border-radius: 0;
  background: #efefef;
  color: #001e42;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  height: 50px;
  width: 100%;
}
.showroom_partner_sec .nav-pills .nav-link.active {
  background: #001e42;
  color: #fff;
}
.showroom_partner_sec ul#pills-tab {
  justify-content: space-between;
}
.showroom_partner_sec ul#pills-tab li.nav-item {
  width: 25%;
}
.showroom_partner_sec {
  max-width: 920px;
  margin-bottom: 16px;
}
.showroom_partner_sec h2 {
  font-size: 18px;
  margin-top: 25px;
  margin-bottom: 19px !important;
  color: #1D1D1B;
}
.showroom_partner_sec .form-group .form-label {
  font-size: 14px;
  margin-bottom: 11px;
  color: #1D1D1B;
}
.showroom_partner_sec .form-group .ReactTags__suggestions{
  overflow: hidden;
  max-width: 448px;
  width: 100%;
  height: 200px;
  overflow-y: scroll;
  color: #1D1D1B;
  font-family: 'Gotham';
font-size: 14px;
font-weight: 400;
line-height: 30px;
text-align: left;
}
.showroom_partner_sec .form-group .ReactTags__selected span.tag-wrapper.ReactTags__tag {
  color: #001E42;
  font-family: 'Gotham';
  font-size: 14px;
  font-weight: 400;
  line-height: 13.4px;
  letter-spacing: 0.05em;
  text-align: left;
}

.showroom_partner_sec .form-group .ReactTags__selected span.tag-wrapper.ReactTags__tag .ReactTags__remove {
  background: #fff;
  border: 1px solid #969696;
  margin: 0px 5px 0px;
  border-radius: 50px;
  overflow: hidden;
  height: 17px;
  width: 17px;
  display: inline-grid;
  justify-content: center;
  align-items: center;
}
.showroom_partner_sec .form-group .ReactTags__selected {
  row-gap: 10px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
  order: 3;
}
.showroom_partner_sec .form-group .ReactTags__selected span.tag-wrapper.ReactTags__tag button.ReactTags__remove svg {
  fill: #969696;
  margin: 0px 1px 0px 4px;
  height: 10px;
}
.showroom_partner_sec .form-group .ReactTags__tags button.ReactTags__clearAll {
  display: flex;
  justify-content: end;
  margin-left: auto;
  margin-top: 8px;
  background: transparent;
  color: #001e42;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  outline: none;
  border: 0;
  border-bottom: 1px solid #001E42;
  line-height: 15px
}
.showroom_partner_sec .form-group .form-label span {
  color: #eb1c26;
}
.showroom_partner_sec .form-group .form-control {
  height: 50px;
  background-color: #fff;
  border: 1px solid #dadada;
  border-radius: 0px;
  font-size: 14px;
}
.showroom_partner_sec .form-group textarea.form-control {
  height: 128px;
  resize: none;
}
.showroom_partner_sec .form-group input[type="date"] {
  color: #a8a8a8;
  text-transform: uppercase;
}
.showroom_partner_sec .form-group {
  margin-bottom: 28px;
}
.showroom_partner_sec button.next_btn {
  width: 130px;
  background: #c0974f;
  height: 50px;
  border: none;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid #c0974f;
  transition: 0.5s all ease-in-out;
}
.showroom_partner_sec button.next_btn:hover{
  background-color: #fff;
  color: #001e42;
  border: 1px solid #001e42;
  transition: 0.5s all ease-in-out;
}

.showroom_partner_sec .responsive_bar {
  overflow: auto;
  width: 100%;
}
.showroom_partner_sec .accordion-button:after {
  background-image: url(/public/images/show-arrow.svg);
  background-repeat: no-repeat;
  background-size: cover;
  transition: transform .2s ease-in-out;
  background-position: center;
  max-width: 9px;
  height: 6px;
}

.showroom_partner_sec .accordion-button:not(.collapsed):after {
  background-image: url(/public/images/show-arrow.svg);
  transform: rotate(-180deg);
}
.showroom_partner_sec button.accordion-button {
  background: #efefef;
  border: none;
  margin-top: 20px;
  border-radius: 0px;
  font-size: 14px;
  color: #1d1d1b;
  padding: 18px;
}
.showroom_partner_sec .accordion-item {
  border: none;
}
.showroom_partner_sec .accordion-body {
  background: #efefef;
  margin-bottom: 20px;
  padding: 20px;
}
.showroom_partner_sec .accordion-button:not(.collapsed) {
  font-weight: 500;
  border-bottom: 1px solid #dadada;
}
.showroom_partner_sec .accordion-body label.form-label {
  font-weight: 500;
  font-size: 14px;
}
.showroom_partner_sec .accordion-body .form-group {
  margin-bottom: 16px;
}
.showroom_partner_sec .copy_btn {
  font-size: 12px;
  color: #001e42;
  font-weight: 500;
  border-bottom: 1px solid #001E42;
  width: fit-content;
  padding-bottom: 2px;
  cursor: pointer;
}
.showroom_partner_sec a.copy_btn img {
  height: 16px;
  margin-right: 3px;
  vertical-align: sub;
}
.showroom_partner_sec .form-group .form-label span.optional {
  color: #797979;
}
.showroom_partner_sec .image_upload h4 {
  font-size: 14px;
  margin-bottom: 10px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #1D1D1B;
}
.showroom_partner_sec .image_upload h4 span {
  font-weight: 400;
}
.showroom_partner_sec .image_upload .set {
  padding: 45px 15px 33px;
  background: #fff;
  border: 1px solid #ffffff;
  border-style: dashed;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  background-image: repeating-linear-gradient(0deg, #9b9b9b, #9b9b9b 5px, transparent 5px, transparent 11px, #9b9b9b 11px), repeating-linear-gradient(90deg, #9b9b9b, #9b9b9b 5px, transparent 5px, transparent 11px, #9b9b9b 11px), repeating-linear-gradient(180deg, #9b9b9b, #9b9b9b 5px, transparent 5px, transparent 11px, #9b9b9b 11px), repeating-linear-gradient(270deg, #9b9b9b, #9b9b9b 5px, transparent 5px, transparent 11px, #9b9b9b 11px);
  background-size: 2px 100%, 100% 2px, 2px 100%, 100% 2px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
}
.showroom_partner_sec .image_upload {
  margin-bottom: 40px;
}
.showroom_partner_sec .image_upload .set input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  opacity: 0;
}
.showroom_partner_sec .image_upload .set h5 {
  font-size: 14px;
  font-weight: 400;
}
.showroom_partner_sec .image_upload .set h5 i {
  font-size: 20px;
  margin-right: 4px;
  vertical-align: middle;
  -webkit-text-stroke: 0.3px;
  color: #001e42;
}
.showroom_partner_sec .image_upload .set h5 span {
  font-weight: 500;
  border-bottom: 1px solid;
  color: #001E42;
}
.showroom_partner_sec hr {
  margin: 0px 0 40px;
}
.showroom_partner_sec .form-check label {
  font-size: 14px;
  vertical-align: top;
  margin-left: 3px;
  color: #1D1D1B;
}
.showroom_partner_sec .form-check-input:checked {
  background-color: #c0974f;
  border-color: #c0974f;
}

.showroom_partner_sec .form-check-input {
  border: 1px solid #9B9B9B;
}
/* ********** Front End Css Start *************** */
div#showroom_sec {
  color: #fff;
  text-align: center;
  height: 750px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
div#showroom_sec h1 {
  font-size: 20px;
  margin-bottom: 18px;
  font-weight: 500;
  line-height: 19.14px;
  letter-spacing: 0.05em;
}
div#showroom_sec h2 {
  font-family: 'Bookman';
  font-size: 45px;
  line-height: 55px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
div#showroom_sec .box {
  max-width: 750px;
  margin: auto;
}
div#showroom_sec a.btn {
  width: 165px;
  height: 50px;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 0px;
  text-transform: uppercase;
  margin-top: 24px;
  font-size: 14px;
  font-weight: 500;
  line-height: 37px;
  transition: all 0.3s ease-in-out;
}
div#showroom_sec a.btn:hover {
  background: #c0974f;
  border: 1px solid #c0974f;
  color: #fff;
}
.earn_showing {
  background-color: #03202f;
  color: #fff;
  padding: 19px 0;
  text-align: center;
}
.earn_showing p {
  align-items: center;
  display: flex;
  gap: 16px;
  justify-content: center;
  text-transform: uppercase;
  font-family: 'Bookman';
  font-size: 18px;
  font-weight: 5;
  line-height: 20.41px;
  letter-spacing: 0.05em;
}
.earn_showing p img {
  height: 19px;
  vertical-align: middle;
}
div#partner_machine img {
  width: 100%;
  height: 100%;
}
div#partner_machine h2 {
  font-size: 30px;
  color: #001f3f;
  font-family: "Bookman";
  text-transform: uppercase;
  margin-bottom: 13px !important;
  line-height: 43px;
  letter-spacing: 0.05em;
}
div#partner_machine p {
  font-size: 15px;
  line-height: 25px;
  margin-bottom: 17px !important;
  color: #1D1D1B;
}
div#partner_machine a.btn {
  width: 165px;
  height: 50px;
  color: #001e42;
  border: 1px solid #001e42;
  border-radius: 0px;
  text-transform: uppercase;
  margin-top: 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: 37px;
  transition: all 0.3s ease-in-out;
}
div#partner_machine a.btn:hover {
  background: #c0974f;
  border: 1px solid #c0974f;
  color: #fff;
}
div#partner_machine .box {
  max-width: 580px;
}
div#partner_machine .space {
  padding-left: 76px;
  padding-right: 15px;
}
div#partner_machine.partner_machine .space {
  padding-left: calc((100vw - 1364px) / 2);
}
.partner_machine.one {
  padding-bottom: 0px;
}
div#partner_machine.partner_machine .space .box {
  padding-left: 30px;
}
.partner_machine {
  background-color: #001e42;
  color: #fff;
}
div#partner_machine.partner_machine .box p {
  color: #fff;
}
.partner_machine h2 {
  color: #fff !important;
}
.partner_machine a.btn {
  color: #ffffff !important;
  border: 1px solid #feffff !important;
}
.partner_machine a.btn:hover {
  border: 1px solid #c0974f !important;
}
div#commission {
  padding: 100px 0 110px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}
div#commission .box {
  background: #fff;
  max-width: 600px;
  padding: 86px 64px;
  text-align: center;
}
div#commission h2 {
  font-size: 40px;
  font-family: "Bookman";
  text-transform: uppercase;
  margin-bottom: 10px !important;
  color: #001E42 !important;
  letter-spacing: 0.05em;
}
div#commission h3 {
  font-size: 20px;
  max-width: 390px;
  margin: 0 auto 10px !important;
  line-height: 30px;
  color: #1D1D1B;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
div#commission p {
  font-size: 15px;
  line-height: 25px;
  color: #1D1D1B;
  font-weight: 400;
}
div#commission p span {
  font-weight: 500;
  display: block;
}
div#commission a.btn {
  width: 165px;
  height: 50px;
  color: #001e42;
  border: 1px solid #001e42;
  border-radius: 0px;
  text-transform: uppercase;
  margin-top: 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 37px;
  transition: all 0.3s ease-in-out;
}
div#commission a.btn:hover {
  background: #c0974f;
  border: 1px solid #c0974f;
  color: #fff;
}
div#blog_sec {
  margin: 45px 0;
}
div#blog_sec h1 {
  text-align: center;
  font-size: 40px;
  font-family: "Bookman";
  margin-bottom: 15px;
  color: #001E42;
}
div#blog_sec .box img {
  height: 254px;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
div#blog_sec .box a.read-more {
  margin-top: 12px !important;
}
div#blog_sec .box a {
  color: #001e42;
  font-size: 16px;
  width: 100%;
  display: block;
}
div#blog_sec .box .img a {
  margin-top: 0;
}
div#blog_sec .box a i {
  margin-left: 6px;
  vertical-align: middle;
}
div#blog_sec .box .set {
  padding: 30px;
  background: #f5f5f5;
  transition: 0.3s ease-out;
}
div#blog_sec .box:hover .set {
  background: #001e42;
  color: #fff;
  transition: 0.3s ease-out;
}
div#blog_sec .box h2 {
  font-size: 25px;
  font-weight: 400;
  line-height: 35px;
  min-height: 70px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
}
div#blog_sec .box {
  margin-bottom: 14px;
  overflow: hidden;
}
div#blog_sec .box:hover img {
  transform: scale(1.05);
  transition: 0.3s ease-out;
}
div#blog_sec .box:hover .set a {
  color: #fff;
}
div#blog_sec .box:hover .set a i {
  margin-left: 15px;
  transition: 0.3s ease-out;
}
div#blog_detail_banner {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 600px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
}
div#blog_detail_banner::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(10 24 39 / 50%);
}
div#blog_detail_banner p {
  font-size: 14px;
  margin-bottom: 16px !important;
}
div#blog_detail_banner h1 {
  font-size: 45px;
  font-family: "Bookman";
  line-height: 62px;
  letter-spacing: 0.05em;
}
div#blog_detail_banner .box {
  max-width: 920px;
  margin: auto;
  text-transform: uppercase;
  position: relative;
}
div#blog_detail_banner h2 {
  color: #daac5d;
  font-size: 14px;
  text-transform: uppercase;
  margin-top: 18px;
}
div#single_blog {
  margin: 55px 0;
}
div#single_blog .left h3 {
  font-size: 18px;
  margin-bottom: 11px !important;
}
div#single_blog .left ul.links li a {
  color: #001e42;
  font-size: 14px;
  border-bottom: 1px solid #001e42;
}
div#single_blog .left ul.links li+li {
  margin-top: 20px;
}
div#single_blog .left ul.links {
  margin-bottom: 60px;
}
div#single_blog .left ul.socail_icon {
  display: flex;
  gap: 9px;
  margin-top: 18px;
}
div#single_blog .left {
  position: sticky;
  top: 15px;
}
div#single_blog .left ul.socail_icon li img {
  width: 29px;
  height: 29px;
}
div#single_blog .center h2 {
  font-size: 30px;
  font-family: "Bookman";
  margin-bottom: 14px !important;
  text-transform: uppercase;
}
div#single_blog .center p {
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 25px !important;
}
div#single_blog .center .img img {
  width: 100%;
  margin-bottom: 30px;
  height: 100%;
}
div#single_blog .center h3 {
  font-size: 22px;
  margin-bottom: 9px !important;
}
div#single_blog .center .faq button.accordion-button {
  padding: 33px 0;
  border: none;
  font-family: "Gotham";
  font-size: 18px;
  font-weight: 500;
  color: #001e42;
}
div#single_blog .center .faq .accordion-item {
  border-bottom: 1px solid #000000;
}
div#single_blog .center .faq .accordion-header {
  margin-bottom: 0;
}
div#single_blog .center .faq .accordion-button:not(.collapsed) {
  background-color: transparent;
  box-shadow: none;
}
div#single_blog .center .faq .accordion-body {
  padding: 0 0 15px;
  font-size: 15px;
}
div#single_blog .center .faq .accordion-button::after {
  background-image: url(https://hallmanimagesnew.s3-accelerate.amazonaws.com/header/faq-plus.svg);
}
div#single_blog .center .faq .accordion-button:not(.collapsed)::after {
  background-image: url(https://hallmanimagesnew.s3-accelerate.amazonaws.com/header/faq-minus.svg);
  transform: rotate(0deg);
}
div#single_blog .right h3 {
  font-size: 18px;
  margin-bottom: 11px !important;
  color: #001E42;
}
div#single_blog .right h4 {
  font-size: 14px;
  font-weight: 400;
  max-width: 278px;
  line-height: 24px;
  color: #001E42;
  transition: 0.5s all ease-in-out;
}

div#single_blog .right h4:hover {
  font-weight: 500;
  transition: 0.5s all ease-in-out;
}
div#single_blog .right h4+h4 {
  border-top: 1px solid #00000036;
  padding-top: 23px;
  margin-top: 23px;
}
div#single_blog .right {
  position: sticky;
  top: 15px;
}
div#error_sec {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  padding: 80px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
div#error_sec .img img {
  height: 294px;
  width: auto;
}
div#error_sec .img {
  margin-bottom: 65px;
}
div#error_sec h2 {
  font-size: 30px;
  margin-bottom: 15px;
}
div#error_sec p {
  font-size: 16px;
  max-width: 516px;
  margin: 0 auto 60px;
}
div#error_sec h3 {
  font-size: 30px;
  color: #3783f6;
  font-weight: 400;
  margin-bottom: 30px;
}
div#error_sec .down_btn span {
  height: 50px;
  width: 50px;
  background: #fff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  margin-bottom: 20px;
  box-shadow: 0px 2px 15px #00000033;
}
div#error_sec .down_btn span i {
  -webkit-text-stroke: 0.5px;
  font-size: 18px;
}
div#error_sec ul {
  display: flex;
  justify-content: center;
  gap: 30px;
  align-items: center;
  flex-wrap: wrap;
}
div#error_sec ul li a {
  font-size: 14px;
  color: #001e42;
  text-decoration: underline;
  transition: all 0.3s ease-in-out;
}
div#error_sec ul li a:hover {
  font-weight: 500;
}
div#privacy_policy .left {
  position: sticky;
  top: 135px;
  padding-bottom: 100px;
}
div#privacy_policy .left::-webkit-scrollbar {
  display: none;
}
div#privacy_policy .left h3 {
  font-size: 18px;
  margin-bottom: 11px !important;
  color: #001e42;
}

div#privacy_policy .left h2.title {
  font-size: 18px;
  margin-bottom: 11px !important;
  color: #001e42;
}
div#privacy_policy .left ul li+li {
  margin-top: 30px;
}
div#privacy_policy .left ul li a {
  color: #001e42;
  font-size: 14px;
  /* border-bottom: 1px solid #001e42; */
  transition: all 0.3s ease-in;
  text-decoration: underline;
  text-underline-offset: 5px;
}
div#privacy_policy .left ul li a:hover {
  font-weight: 500;
}
div#privacy_policy {
  padding: 60px 0;
  margin: 0 auto;
}
.returns-policy {
  max-width: 811px;
  width: 100%;
  margin: 0 auto;
}
div#privacy_policy h1 {
  font-size: 40px;
  font-family: "Bookman";
  text-transform: uppercase;
  margin-bottom: 50px;
  color: #001e42;
  letter-spacing: 0.05em;
}
div#privacy_policy .right h2 {
  font-size: 30px;
  font-family: "Bookman";
  text-transform: uppercase;
  margin-bottom: 8px !important;
  margin-top: 55px !important;
  color: #001e42;
  letter-spacing: 0.05em;
  gap: 10px;
}
div#privacy_policy .right h2:first-child {
  margin-top: 0px !important;
}

div#privacy_policy .right p {
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 15px !important;
  color: #1d1d1b;
}
div#privacy_policy .right p b {
  font-weight: 500;
}
div#privacy_policy .right p a {
  color: #1977F3;
  font-weight: 500;
}
div#privacy_policy .right h3 {
  font-size: 22px;
  margin-bottom: 9px !important;
  margin-top: 24px;
  color: #001e42;
}
div#privacy_policy .right h2.head {
  font-size: 22px;
  margin-bottom: 17px !important;
  margin-top: 24px !important;
  color: #001e42;
  font-family: 'Gotham';
  font-weight: 500;
  text-transform: capitalize;
}
div#privacy_policy .right ul {
  margin-bottom: 30px;
  padding-left: 20px;
}
div#privacy_policy .right ul li {
  font-size: 16px;
  margin-bottom: 8px;
  list-style: disc !important;
  line-height: 30px;
  color: #1d1d1b;
}
div#privacy_policy .right ul.num li {
  list-style: auto !important;
}
div#privacy_policy .right h2 label {
  font-size: 16px;
  font-weight: 400;
  text-transform: lowercase;
  line-height: 22px;
  letter-spacing: normal;
}
div#contact_us h1 {
  text-align: center;
  font-size: 40px;
  font-family: "Bookman";
  margin-bottom: 36px;
  text-transform: uppercase;
  color: #001E42 !important;
}
div#contact_us h1 {
  text-align: center;
  font-size: 40px;
  font-family: "Bookman";
  margin-bottom: 36px !important;
  text-transform: uppercase;
  color: #001E42 !important;
  letter-spacing: 0.05em;
}
div#contact_us {
  margin-top: 45px;
}
div#contact_us .box {
  background: #f5f5f5;
  text-align: center;
  transition: all 0.3s ease-in-out;
  padding: 45px 20px 30px;
  height: 100%;
  cursor: pointer;
}
div#privacy_policy .right ul li b {
  font-weight: 500;
}
div#contact_us .box svg {
  width: 45px;
  display: block;
  text-align: center;
  margin: auto;
}
div#contact_us .box a h2 {
  font-size: 18px;
  margin-top: 12px;
  border-bottom: 1px solid;
  display: inline-block;
  color: #001E42;
  margin-bottom: 0px !important;
}

div#contact_us .box a h2.italic {
  font-style: normal;
}

div#contact_us .box:hover a h2 {
  color: #fff !important;
}
div#contact_us .box a p {
  font-size: 14px;
  max-width: 285px;
  margin: 12px auto 0 !important;
  line-height: 20px;
  color: #1D1D1B;
}
div#contact_us .box:hover {
  background: #001e42;
}
div#contact_us .box:hover h3 {
  color: #fff;
}
div#contact_us .box:hover p {
  color: #fff;
}
div#contact_us .box:hover svg path {
  fill: #fff;
  stroke: #fff;
}
div#contact_us .box:hover svg circle {
  fill: #fff;
  stroke: #fff;
}
div#contact_us .box:hover svg rect {
  stroke: #fff;
}
div#contact_form .box {
  max-width: 770px;
  margin: auto;
}
div#contact_form {
  margin-top: 90px;
}
div#contact_form h2 {
  text-align: center;
  font-size: 40px;
  font-family: "Bookman";
  margin-bottom: 36px !important;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: #001E42;
}
div#contact_form .form-group .form-control {
  border: none;
  border-bottom: 1px solid #001e42;
  border-radius: 0px;
  font-size: 14px;
  padding-left: 0;
}
div#contact_form .form-group .form-control::placeholder {
  color: #1D1D1B;
}
div#contact_form .form-group {
  margin-bottom: 50px;
  position: relative;
}
div#contact_form .form-group .form-select {
  background-position: right 2px center;
  border-right: 7px solid transparent;
}
select.form-select.form-control {
  color: #1D1D1B;
}
div#contact_form a {
  background: #c0974f;
  height: 50px;
  width: 157px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  border: none;
  margin-bottom: 5px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
div#contact_form a:hover {
  background: #fff;
  border: 1px solid #001e42;
  color: #001e42;
  transition: all 0.3s ease-in-out;
}
div#contact_form .form-group span {
  position: absolute;
  right: 0;
  bottom: 0px;
  color: #ff0000;
  font-size: 20px;
}
div#call_us {
  text-align: center;
  border-top: 2px solid #001e42;
  padding: 40px 0px;
  margin-top: 100px;
}
div#call_us h3 {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 22px !important;
  color: #001E42;
}
div#call_us h4 a {
  font-size: 20px;
  color: #001e42;
}
div#call_us .box {
  border-right: 1px solid;
}
div#hallman_indus {
  background-color: #001e42;
  color: #fff;
}
div#hallman_indus .box img {
  width: 100%;
  height: 100%;
}
div#hallman_indus .box .set {
  max-width: 360px;
  margin: auto;
}
div#hallman_indus h3 {
  font-size: 35px;
  font-weight: bold;
  border-bottom: 2px solid #c0974f;
  padding-bottom: 44px;
  margin-bottom: 32px !important;
  max-width: 332px;
}
div#hallman_indus p {
  font-size: 20px;
  font-weight: 500;
  line-height: 39px;
}
div#coming_soon {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  padding: 128px 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(/public/images/error-bg.png);
}
div#coming_soon .img img {
  height: 125px;
  width: auto;
}
div#coming_soon h1 {
  font-size: 22px;
  max-width: 823px;
  line-height: 36px;
  margin: 37px auto 20px !important;
  color: #000000;
}
div#coming_soon .form-group input {
  height: 50px;
  border-radius: 0px;
  font-size: 14px;
  color: #000;
  border: 1px solid #c8c8c8;
}
div#coming_soon .form-group input::placeholder {
  color: #8f8f8f;
}
div#coming_soon .form-group {
  position: relative;
  max-width: 540px;
  margin: auto;
}
div#coming_soon .form-group button {
  background: #c0974f;
  height: 50px;
  width: 155px;
  border: none;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  position: absolute;
  top: 0;
  right: 0;
  border: 1px solid #c0974f;
  transition: 0.5s all ease-in-out;
}
div#coming_soon .form-group button:hover {
  background-color: #fff;
  border: 1px solid #001e42;
  transition: 0.5s all ease-in-out;
  color: #001e42;
}
div#coming_soon h2 {
  font-size: 20px;
  font-weight: 400;
  margin-top: 114px;
  color: #000000;
}
div#coming_soon ul.links {
  display: flex;
  justify-content: center;
  gap: 30px;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 12px;
}
div#coming_soon ul.links li a {
  font-size: 14px;
  color: #001e42;
  text-decoration: underline;
  transition: all 0.3s ease-in-out;
  text-underline-offset: 4px;
}
div#coming_soon ul.links li a:hover {
  font-weight: 500;
}
div#coming_soon ul.socal_icon svg.social-circle {
  border: 1px solid #001e42;
  border-radius: 50px;
}
div#coming_soon ul.socal_icon svg.social-circle path {
  fill: #001e42;
}
div#coming_soon h3 {
  font-size: 14px;
  margin-top: 136px;
  margin-bottom: 12px !important;
  font-weight: 400;
  color: #000000;
}
div#coming_soon ul.socal_icon {
  display: flex;
  justify-content: center;
  gap: 10px;
}
div#coming_soon ul.socal_icon svg.social-circle:hover {
  background: #001e42;
}
div#coming_soon ul.socal_icon svg.social-circle:hover path {
  fill: #fff;
}
div#financing_hallman {
  color: #fff;
  text-align: center;
  height: 750px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
div#financing_hallman h1 {
  font-size: 40px;
  font-family: "Bookman";
  text-transform: uppercase;
  max-width: 782px;
  margin: auto;
  line-height: 56px;
  letter-spacing: 0.05em;
}
div#financing_hallman h2 {
  font-size: 18px;
  margin-top: 15px;
}
div#big_purchase h2 {
  font-size: 32px;
  font-family: "Bookman";
}
div#buy_now_pay {
  overflow: hidden;
}
div#big_purchase {
  background-color: #03202f;
  color: #fff;
  padding: 40px 0;
  overflow: hidden;
}
div#big_purchase p {
  font-size: 18px;
  margin-top: 12px;
}
div#big_purchase .img img {
  height: 66px;
  width: auto;
}
div#big_purchase .img {
  display: flex;
  align-items: center;
  gap: 30px;
  justify-content: end;
  flex-wrap: wrap;
}
div#buy_now_pay .img img {
  width: 100%;
  height: 100%;
}
div#buy_now_pay .box {
  max-width: 530px;
  margin: auto;
  text-align: center;
}
div#buy_now_pay .box h2 {
  font-size: 32px;
  font-weight: bold;
  color: #1d1d1b;
}
div#buy_now_pay .box h2 img {
  height: 44px;
  width: auto;
  vertical-align: baseline;
}
div#buy_now_pay .box .set h3 {
  font-size: 45px;
  margin-top: 36px;
}
div#buy_now_pay .box .set h4 {
  font-size: 15px;
  font-weight: 400;
  border-bottom: 1px solid;
  padding-bottom: 9px;
  max-width: 224px;
  margin: 34px auto 0;
  color: #1D1D1B;
}
div#buy_now_pay .box .link {
  font-size: 15px;
  margin-top: 33px;
  color: #1d1d1b;
}
div#buy_now_pay .box .link a {
  color: #1d1d1b;
  font-weight: bold;
  text-decoration: underline;
}
div#buy_now_pay .box p {
  font-size: 15px;
  margin-top: 32px;
  color: #1D1D1B;
}
div#buy_now_pay .box h3 {
  font-size: 20px;
  margin-top: 24px;
  line-height: 26px;
}
div#buy_now_pay .box h3 span {
  font-size: 18px;
  font-weight: 400;
  display: block;
  margin-top: 4px;
  color: #1D1D1B;
}
div#pay_later .box ul li {
  list-style: disc;
}
div#pay_later {
  background-color: #001e42;
  color: #fff;
  padding: 90px 0;
  overflow: hidden;
}
div#pay_later h2 {
  font-family: "Bookman";
  text-align: center;
  line-height: 50px;
  font-size: 35px;
  max-width: 818px;
  margin: 0 auto 30px;
  margin-bottom: 30px !important;
}
.row.offers-area {
  max-width: 1116px;
  width: 100%;
  margin: auto;
}
div#pay_later .box ul li {
  font-size: 16px;
  font-weight: 500;
}
div#pay_later .box ul li span {
  font-size: 15px;
  font-weight: 300;
  display: block;
  line-height: 25px;
  margin-top: 7px;
}
div#pay_later .box {
  max-width: 480px;
}
div#pay_later .box ul {
  padding-left: 20px;
}
div#pay_later .box ul li+li {
  margin-top: 40px;
}
div#pay_later .box ul li::marker {
  color: #c0974f;
  font-size: 21px;
}
div#pay_later .space {
  padding-left: 6rem;
}
div#buy_now_pay .box h2 img.pay-img {
  vertical-align: middle;
  height: 39px;
}
div#buy_now_pay .box h3 {
  font-size: 24px;
  max-width: 388px;
  margin: 20px auto 0;
  line-height: 32px;
}

div#buy_now_pay .box.color h3 {
  font-size: 20px;
  max-width: 500px;
  margin: 20px auto 0;
  line-height: 32px;
}
div#faq .accordion-item {
  border-bottom: 1px solid #000000;
  background: transparent;
}
div#faq .accordion-header {
  margin-bottom: 0px !important;
}
div#faq button.accordion-button {
  padding: 33px 0;
  border: none;
  font-family: "Gotham";
  font-size: 18px;
  font-weight: 500;
  color: #001e42;
  background: transparent;
}
div#faq .accordion-button::after {
  background-image: url(https://hallmanimagesnew.s3-accelerate.amazonaws.com/header/faq-plus.svg);
}
div#faq .accordion-button:not(.collapsed)::after {
  background-image: url(https://hallmanimagesnew.s3-accelerate.amazonaws.com/header/faq-minus.svg) !important;
  transform: rotate(0deg);
}
div#faq .accordion-body {
  padding: 0 0 15px;
  font-size: 15px;
}
div#faq .accordion-button:not(.collapsed) {
  background-color: transparent;
  box-shadow: none;
}
div#faq {
  background-color: #f5f5f5;
  padding: 90px 0;
}
div#faq .accordion {
  max-width: 830px;
  margin: auto;
}
div#faq h2 {
  text-align: center;
  color: #001e42;
  font-size: 35px;
  font-family: "Bookman";
  margin-bottom: 30px !important;
}
div#privacy_policy .right p a.web-link {
  color: #1977F3;
  font-weight: 400;
  border-bottom: 1px solid;
  line-height: normal;
}
div#privacy_policy .right p a.web-links {
  color: #1d1d1b;
  font-weight: 400;
  text-decoration: underline;
}
.contact-mid p {
  margin-bottom: 0px !important;
}
.contact-mid span.cont-mid {
  font-weight: 400;
}
ul.term-left-list {
  max-width: 230px;
  width: 100%;
}
.right h2#policy1 {
  scroll-margin-top: 140px;
}
.right h2#policy2 {
  scroll-margin-top: 140px;
}
.right h2#policy3 {
  scroll-margin-top: 140px;
}
.right h2#policy4 {
  scroll-margin-top: 140px;
}
.right h2#policy5 {
  scroll-margin-top: 140px;
}
.right h2#policy6 {
  scroll-margin-top: 140px;
}
.right h2#policy7 {
  scroll-margin-top: 140px;
}
.right h2#policy8 {
  scroll-margin-top: 140px;
}
.right h2#policy9 {
  scroll-margin-top: 140px;
}
.right h2#policy10 {
  scroll-margin-top: 140px;
}
.right h2#policy11 {
  scroll-margin-top: 140px;
}
.right h2#policy12 {
  scroll-margin-top: 140px;
}
.right h2#policy13 {
  scroll-margin-top: 140px;
}
.right h2#policy14 {
  scroll-margin-top: 140px;
}
.right h2#policy15 {
  scroll-margin-top: 140px;
}
.right h2#policy16 {
  scroll-margin-top: 140px;
}
.right h2#policy17 {
  scroll-margin-top: 140px;
}
.right h2#policy18 {
  scroll-margin-top: 140px;
}
.right h2#term1 {
  scroll-margin-top: 140px;
}
.right h2#term2 {
  scroll-margin-top: 140px;
}
.right h2#term3 {
  scroll-margin-top: 140px;
}
.right h2#term4 {
  scroll-margin-top: 140px;
}
.right h2#term5 {
  scroll-margin-top: 140px;
}
.right h2#term6 {
  scroll-margin-top: 140px;
}
.right h2#term7 {
  scroll-margin-top: 140px;
}
.right h2#term8 {
  scroll-margin-top: 140px;
}
.right h2#term9 {
  scroll-margin-top: 140px;
}
.right h2#term10 {
  scroll-margin-top: 140px;
}
.right h2#term11 {
  scroll-margin-top: 140px;
}
.right h2#term12 {
  scroll-margin-top: 140px;
}
.right h2#term13 {
  scroll-margin-top: 140px;
}
div#privacy_policy .right p a.pri-weblink {
  color: #1977F3;
}
#privacy_policy .sub-head{
  margin-bottom: 5px !important;
}
/* 404-page-css-start */
div#page_error {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 200px 0;
}
div#page_error h1 {
  color: #001e42;
  font-size: 120px;
  font-weight: bold;
  margin-bottom: 30px;
}
div#page_error h3 {
  font-size: 28px;
  font-weight: 400;
  line-height: 36px;
  margin-bottom: 34px;
  max-width: 500px;
}
div#page_error h4 {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 35px;
}
div#page_error ul li a {
  font-size: 14px;
  color: #001e42;
  text-decoration: underline;
  transition: all 0.3s ease-in-out;
}
div#page_error ul li a:hover {
  font-weight: 500;
}
div#page_error ul li {
  width: 40%;
}
div#page_error ul {
  display: flex;
  flex-wrap: wrap;
  row-gap: 24px;
}
div#page_error .width_box {
  max-width: 1168px;
  margin: auto;
}
div#page_error .img {
  margin-top: 40px;
  text-align: center;
}
div#page_error .img img {
  width: auto;
  height: 295px;
}
div#page_error ul li:last-child {
  width: 100%;
}
/* 404-page-css-end */
#single_blog .center h4 {
  margin-top: 20px;
}
.single-product .instock-txt {
  position: absolute;
  top: 5px;
  right: 5px;
  background: #001e42;
  z-index: 8;
}
.single-product .instock-txt p {
  padding: 6px;
  color: #fff ;
  font-weight: 500;
  font-family: Bookman;
}
.customral-color button.dropdown-toggle {
  height: 50px;
  border: 1px solid #DADADA;
  padding: 10px;
  background: #fff;
  color: #000;
}

.dropdown-toggle::after {
  display: none;
}
.dropdown-toggle p::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  position: relative;
  left: 67px;
}
.dropdown-toggle .dropdown-icon::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.1em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  position: relative;
  left : 0;
}
.dropdown-toggle .ral-swatch p{
  position: relative;
  right: 27px;
  margin-right: 0;
}

.customral-color .dropdown-menus {
  height: 140px;
  border: 1px solid #DADADA;
  background: #fff;
  margin-bottom: 10px;
  overflow: auto;
  position: absolute;
  width: 100%;
}
.customral-color .dropdown-menus .dropdown-item {
  line-height: 30px;
  padding: 2px 10px;
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dfd9d9;
}
.customral-color .ral-swatch {
  height: 20px;
  width: 50px;
  border: 1px solid #bbb2b2;
  margin-right: 16px;
}
.dropdown-toggle .selected-ralSwatch {
  margin-right: 31px;
}
.customral-color {
  margin-bottom: 15px;
  z-index: 99999999999;
  position: relative;
}
.cart-select-left-heading .attributes, .minicart-select-left-heading .attributes {
  padding-top: 10px;
}
.cart-select-left-heading p.additional-attr span {
  color: #001E42;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
.cancel-order{
  margin: 0 auto;
}
.cancel-order ul.cancel-ul li {
  list-style: disc;
  font-size: 16px;
  line-height: 30px;
  color: #1d1d1b;
}
h1.main-heading {
  font-size: 40px;
  font-family: "Bookman";
  text-transform: uppercase;
  margin: 30px 0;
  color: #001e42;
  letter-spacing: 0.05em;
  text-align: center;
}
.cancel-order h2 {
  font-size: 30px;
  font-family: "Bookman";
  text-transform: uppercase;
  margin-bottom: 8px !important;
  margin-top: 30px !important;
  color: #001e42;
  letter-spacing: 0.05em;
}
.cancel-form .form-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}
.cancel-form form {
  max-width: 500px;
}
.cancel-form .form-field label {
  text-align: left;
  font-family: 'Gotham';
  font-size: 14px;
  font-weight: 400;
  line-height: 13px;
  color: #1D1D1B;
  padding: 10px 0;
  display: block;
}
.cancel-form .form-field input, .cancel-form .form-field select {
  height: 50px;
  border: 1px solid #DADADA;
  padding: 10px;
  background: #fff;
  color: #000;
  border-radius: 0px;
}
.cancel-form .action button {
  width: 157px;
  height: 50px;
  background: #C0974F;
  font-family: 'Gotham';
  font-size: 14px;
  font-weight: 500;
  line-height: 13px;
  color: #fff;
  border: 1px solid #C0974F;
  margin-top: 15px;
}
.cancel-form ul.suggestions li {
  cursor: pointer;
}
span.red {
  color: red;
}
.cancel-content ul.cancel-ul {
  padding-left: 20px;
}
/* X-Large devices (large desktops, less than 1400px) */
@media (max-width: 1399.98px) {
  div#page_error ul {
    row-gap: 18px;
  }
  div#page_error h4 {
    font-size: 18px;
    margin-bottom: 22px;
  }
  div#page_error h3 {
    font-size: 24px;
    line-height: 34px;
    margin-bottom: 20px;
  }
  div#page_error .img img {
    height: 240px;
  }
  div#page_error h1 {
    font-size: 100px;
    margin-bottom: 34px;
  }
  div#page_error {
    padding: 140px 0;
  }
  div#faq {
    padding: 60px 0;
  }
  div#faq h2 {
    font-size: 32px;
  }
  div#buy_now_pay .box h3 {
    font-size: 20px;
    margin: 16px auto 0;
    line-height: 28px;
  }
  div#pay_later h2 {
    line-height: 48px;
    font-size: 32px;
  }
  div#pay_later {
    padding: 60px 0;
  }
  div#buy_now_pay .box h3 span {
    font-size: 16px;
  }
  div#buy_now_pay .box h3 {
    font-size: 18px;
    margin-top: 15px;
  }
  div#buy_now_pay .box .set h4 {
    font-size: 14px;
    margin: 24px auto 0;
  }
  div#buy_now_pay .box .set h3 {
    font-size: 32px;
    margin-top: 30px;
  }
  div#buy_now_pay .box h2 img {
    height: 36px;
  }
  div#buy_now_pay .box h2 {
    font-size: 26px;
  }
  div#big_purchase p {
    font-size: 16px;
    margin-top: 10px;
  }
  div#big_purchase .img {
    gap: 15px;
  }
  div#big_purchase .img img {
    height: 60px;
  }
  div#big_purchase h2 {
    font-size: 28px;
  }
  div#financing_hallman {
    height: 570px;
  }
  div#coming_soon h2 {
    font-size: 18px;
    margin-top: 70px;
  }
  div#coming_soon h3 {
    margin-top: 80px;
  }
  div#coming_soon h1 {
    font-size: 20px;
    line-height: 30px;
    margin: 22px auto 20px;
  }
  div#coming_soon .img img {
    height: 100px;
  }
  div#hallman_indus p {
    font-size: 17px;
    line-height: 32px;
  }
  div#hallman_indus h3 {
    font-size: 28px;
    padding-bottom: 24px;
    margin-bottom: 20px;
    max-width: 268px;
  }
  div#call_us h4 {
    font-size: 18px;
  }
  div#call_us h3 {
    font-size: 18px;
    margin-bottom: 14px;
  }
  div#call_us {
    margin-top: 50px;
    padding: 30px 0px;
  }
  div#contact_form {
    margin-top: 40px;
  }
  div#contact_form h2 {
    font-size: 34px;
    margin-bottom: 30px;
  }
  div#contact_us .box h2 {
    font-size: 18px;
    margin-top: 16px !important;
    border-bottom: 1px solid;
    display: inline-block;
  }
  div#contact_us h1 {
    font-size: 34px;
    margin-bottom: 30px;
  }
  div#contact_us h2 {
    font-size: 34px;
    margin-bottom: 30px !important;
  }
  div#privacy_policy .right h2 label {
    font-size: 14px;
  }
  div#privacy_policy .right ul li {
    font-size: 14px;
    margin-bottom: 5px;
    line-height: 22px;
  }
  div#privacy_policy .right h3 {
    font-size: 20px;
    margin-top: 16px;
  }

  div#privacy_policy .right h2.head {
    font-size: 20px;
    margin-top: 16px;
  }
  div#privacy_policy .right p {
    font-size: 14px;
    line-height: 24px;
  }
  div#privacy_policy .right h2 {
    font-size: 25px;
    margin-top: 22px;
  }
  div#error_sec h2 {
    font-size: 28px;
  }
  div#error_sec {
    padding: 50px 0 40px;
  }
  div#error_sec .img {
    margin-bottom: 40px;
  }
  div#error_sec p {
    font-size: 15px;
    margin: 0 auto 20px;
  }
  div#error_sec h3 {
    font-size: 24px;
    margin-bottom: 18px;
  }
  div#error_sec .img img {
    height: 210px;
  }
  div#single_blog .right h4+h4 {
    padding-top: 15px;
    margin-top: 15px;
  }
  div#single_blog .center .faq button.accordion-button {
    padding: 20px 0;
    font-size: 15px;
  }
  div#single_blog .center .img img {
    margin-bottom: 15px;
  }
  div#single_blog .center .mb-4 {
    margin-bottom: 0.6rem !important;
  }
  div#single_blog .center h3 {
    font-size: 18px;
  }
  div#single_blog .left ul.links {
    margin-bottom: 28px;
  }
  div#single_blog .center h2 {
    font-size: 25px;
  }
  div#single_blog .center p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 10px !important;
  }
  div#single_blog .center .mt-5 {
    margin-top: 1rem !important;
  }
  div#blog_detail_banner {
    height: 500px;
  }
  div#blog_detail_banner h1 {
    font-size: 40px;
    line-height: 55px;
  }
  div#showroom_sec h2 {
    font-size: 45px;
  }
  div#blog_sec h1 {
    font-size: 36px;
  }
  div#blog_sec .box .set {
    padding: 20px;
  }
  div#blog_sec .box a {
    font-size: 15px;
  }
  div#blog_sec .box h2 {
    font-size: 20px;
    line-height: 28px;
  }
  div#commission h3 {
    font-size: 18px;
    line-height: 27px;
  }
  div#commission h2 {
    font-size: 35px;
  }
  div#commission .box {
    padding: 45px 30px;
  }
  div#partner_machine p {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 12px;
  }
  div#partner_machine h2 {
    font-size: 24px;
    margin-bottom: 8px !important;
    line-height: 36px;
  }
  div#partner_machine .space {
    padding-left: 30px;
    padding-right: 20px;
  }
  .earn_showing p {
    font-size: 14px;
  }
  div#showroom_sec h1 {
    font-size: 18px;
    margin-bottom: 10px;
    letter-spacing: 0.05em;
  }
  div#showroom_sec {
    height: 490px;
  }
  .my-order-panel-list ul li+li {
    margin-top: 24px;
  }
  .my-order-panel-list ul li a {
    font-size: 14px;
  }
  .my-order-panel-list ul {
    margin-top: 15px;
  }
  .dashboard-my-order-panel-head p {
    font-size: 15px;
  }
  .dashboard-my-order-panel-head h2 {
    font-size: 18px;
    padding-bottom: 14px;
  }
  .dashboard-my-order-panel {
    padding: 20px 16px;
  }
  .dropdown-toggle .selected-ralSwatch {
    margin-right: 16px;
  }
  .dropdown-toggle p::after {
    left: 56px;
  }
}
/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
  div#pay_later .box ul li+li {
    margin-top: 22px;
  }
  div#big_purchase {
    padding: 30px 0;
  }
  div#big_purchase p {
    font-size: 15px;
  }
  div#big_purchase h2 {
    font-size: 22px;
  }
  div#big_purchase .img img {
    height: 52px;
  }
  div#financing_hallman {
    height: 450px;
  }
  div#financing_hallman h2 {
    font-size: 16px;
  }
  div#financing_hallman h1 {
    font-size: 32px;
    max-width: 635px;
    line-height: 50px;
  }
  div#coming_soon {
    padding: 100px 0 20px;
  }
  div#privacy_policy {
    padding: 30px 0;
  }
  div#privacy_policy h1 {
    font-size: 25px !important;
    margin-bottom: 20px;
}
  div#single_blog {
    margin: 35px 0;
  }
  div#blog_sec .box h2 {
    font-size: 18px;
    line-height: 25px;
    min-height: 50px;
  }
  div#blog_sec .box a {
    font-size: 14px;
  }
  div#blog_sec .box .set {
    padding: 15px;
  }
  div#blog_sec .box img {
    height: 195px;
  }
  div#showroom_sec a.btn {
    height: 45px;
    margin-top: 20px;
    line-height: 30px;
  }
  div#partner_machine a.btn {
    height: 45px;
    line-height: 30px;
  }
  div#showroom_sec {
    height: 430px;
  }
  .showroom_partner_sec .accordion-body {
    margin-bottom: 12px;
    padding: 14px;
  }
  .showroom_partner_sec button.accordion-button {
    padding: 14px;
    margin-top: 12px;
  }
  .dashboard-my-order .back_btn {
    margin-bottom: 14px;
  }
  .add_new_add_sec .form-group {
    margin-bottom: 14px;
  }
  .add_new_add_sec .form-check {
    margin-bottom: 8px;
  }
  .add_new_add_sec .image_upload {
    padding: 20px;
    margin-top: 12px;
    margin-bottom: 20px;
  }
  .address_book_sec .box {
    padding: 20px;
  }
  .address_book_sec .box_two {
    padding: 20px;
  }
  .address_book_sec a .box_two {
    font-size: 15px;
  }
  .address_book_sec .box h5 {
    font-size: 15px;
    margin-bottom: 15px;
  }
  .address_book_sec h2 {
    font-size: 20px !important;
  }
  .my_images_sec .box .text {
    font-size: 14px;
    line-height: 24px;
  }
  .delivery_que_sec .box {
    padding-bottom: 20px;
    margin-bottom: 30px;
  }
  .delivery_que_sec .form-group {
    margin-bottom: 14px;
  }
  .delivery_que_sec h2 {
    font-size: 15px;
  }
  .dashboard-order-right h2 {
    font-size: 22px;
  }
  .dashboard-order-info {
    padding: 15px 12px;
  }
  .my-order-track {
    padding: 16px 12px;
  }
}
/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .dashboard-my-order-panel {
    margin-top: 30px;
  }

  div#page_error ul li:last-child {
    width: auto;
  }
  div#page_error h1 {
    font-size: 85px;
    margin-bottom: 28px;
  }
  div#page_error .img {
    margin-top: 20px;
  }
  div#page_error .img img {
    height: 215px;
  }
  div#page_error ul {
    row-gap: 10px;
    column-gap: 30px;
    justify-content: center;
  }
  div#page_error ul li {
    width: auto;
  }
  div#page_error h3 {
    font-size: 22px;
    line-height: 30px;
    margin: 0 auto 20px;
  }
  div#page_error {
    padding: 35px 0 140px;
  }
  div#page_error .order {
    order: 2;
    text-align: center;
  }
  div#faq .accordion-body {
    font-size: 14px;
  }
  div#faq {
    padding: 40px 0;
  }
  div#faq h2 {
    font-size: 28px;
    margin-bottom: 24px;
  }
  div#faq button.accordion-button {
    padding: 24px 0;
    font-size: 16px;
  }
  div#buy_now_pay .box h2 img.pay-img {
    height: 35px;
  }
  div#buy_now_pay .box .link {
    margin-top: 18px;
  }
  div#buy_now_pay .box p {
    font-size: 14px;
    margin-top: 15px;
  }
  div#buy_now_pay .box h3 {
    font-size: 18px !important;
    margin: 14px 0 0 !important;
  }
  div#pay_later .space {
    padding-left: 2rem;
  }
  div#pay_later {
    padding: 35px 0;
  }
  div#pay_later .box ul li {
    font-size: 15px;
  }
  div#pay_later .box ul li span {
    font-size: 14px;
    line-height: 24px;
    margin-top: 5px;
  }
  div#pay_later h2 {
    line-height: 45px;
    font-size: 28px;
  }
  div#buy_now_pay .box .set h3 {
    font-size: 28px;
    margin-top: 22px;
  }
  div#buy_now_pay .box .set h4 {
    font-size: 14px;
    margin: 20px 0 0;
    max-width: 100%;
  }
  div#buy_now_pay .box.color {
    background: #f5f5f5;
  }
  div#buy_now_pay .box {
    max-width: 100%;
    text-align: start;
    padding: 40px 15px;
  }
  div#big_purchase h2 {
    font-size: 24px;
  }
  div#big_purchase .img {
    justify-content: start;
    margin-top: 14px;
  }
  div#financing_hallman {
    height: 400px;
  }
  div#buy_now_pay .revert {
    flex-direction: column-reverse;
  }
  div#coming_soon h1 {
    font-size: 16px;
    line-height: 26px;
    margin: 22px auto 20px;
  }
  div#coming_soon .img img {
    height: 80px;
  }
  div#hallman_indus p {
    font-size: 15px;
    line-height: 28px;
  }
  div#hallman_indus h3 {
    font-size: 22px;
    padding-bottom: 12px;
    margin-bottom: 12px;
  }
  div#call_us {
    margin-top: 40px;
    padding: 20px 0px;
    border-width: 1px;
  }
  div#call_us h3 {
    font-size: 16px;
    margin-bottom: 12px;
  }
  div#call_us h4 {
    font-size: 16px;
  }
  div#contact_form a {
    height: 45px;
    width: 155px;
  }
  div#contact_form .form-group {
    margin-bottom: 35px;
  }
  div#contact_form .box {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 20px;
  }
  div#contact_form h2 {
    font-size: 28px;
  }
  div#contact_us {
    margin-top: 30px;
  }
  div#contact_us .mb-4 {
    margin-bottom: 1rem !important;
  }
  div#contact_us h1 {
    font-size: 28px;
    margin-bottom: 22px;
  }
  div#contact_us h2 {
    font-size: 28px;
    margin-bottom: 22px;
  }
  div#privacy_policy h2 {
    font-size: 28px;
    margin-bottom: 6px;
  }
  div#privacy_policy .left {
    background: #f6f6f6;
    padding: 15px;
  }
  div#single_blog .right h4 {
    max-width: 100%;
  }
  div#single_blog .right {
    background: #f6f6f6;
    padding: 15px;
  }
  div#single_blog .right h3 {
    font-size: 20px;
    margin-bottom: 14px;
  }
  div#single_blog .center .faq .accordion-button::after {
    width: 1rem;
    height: 1rem;
    background-size: 1rem;
  }
  div#single_blog .center .faq button.accordion-button {
    padding: 18px 10px;
    font-size: 15px;
  }
  div#single_blog .center .faq .accordion-body {
    padding: 4px 10px 10px;
    font-size: 14px;
  }
  div#single_blog .center {
    background: #f6f6f6;
    padding: 15px;
  }
  div#single_blog .left ul.links {
    margin-bottom: 22px;
  }
  div#single_blog .left ul.links li a {
    width: 100%;
    padding-bottom: 8px;
  }
  div#single_blog .left h3 {
    font-size: 20px;
    margin-bottom: 14px !important;
  }
  div#single_blog {
    margin: 25px 0;
  }
  div#single_blog .left {
    background: #f6f6f6;
    padding: 15px;
  }
  div#blog_detail_banner {
    height: 410px;
  }
  div#blog_detail_banner h1 {
    font-size: 32px;
    line-height: 48px;
  }
  div#blog_sec .box img {
    height: 225px;
  }
  div#blog_sec .box {
    margin-bottom: 0px;
  }
  div#blog_sec {
    margin: 30px 0;
  }
  div#blog_sec h1 {
    font-size: 32px;
  }
  div#commission {
    padding: 50px 0;
  }
  div#commission .container {
    justify-content: start !important;
  }
  div#showroom_sec h1 {
    font-size: 16px;
  }
  div#showroom_sec h2 {
    font-size: 32px;
    max-width: 400px;
    margin: auto;
  }
  div#showroom_sec {
    height: 310px;
  }
  div#partner_machine a.btn {
    margin-bottom: 12px;
  }
  div#partner_machine .space {
    padding: 15px;
  }
  .showroom_partner_sec hr {
    margin: 0px 0 25px;
  }
  .showroom_partner_sec .image_upload {
    margin-bottom: 25px;
  }
  .showroom_partner_sec .form-group textarea.form-control {
    height: 100px;
  }
  .showroom_partner_sec button.next_btn {
    width: 110px;
    height: 45px;
    margin-top: 10px;
  }
  .showroom_partner_sec .form-group {
    margin-bottom: 15px;
  }
  .showroom_partner_sec .form-group .form-control {
    height: 45px;
  }
  .showroom_partner_sec .form-group .form-label {
    margin-bottom: 8px;
  }
  .showroom_partner_sec h2 {
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 18px;
  }
  .showroom_partner_sec .nav-pills .nav-link {
    height: 45px;
  }
  .account_info_sec .box .set button {
    height: 45px;
    width: 170px;
  }
  .account_info_sec .box .set .form-group {
    margin-bottom: 12px;
  }
  .account_info_sec .box .set .form-group input {
    height: 45px;
  }
  .account_info_sec .box .set {
    padding: 20px;
    margin-top: 20px;
  }
  .account_info_sec .box h3 {
    font-size: 14px;
  }
  .account_info_sec .box h3 {
    font-size: 14px;
    margin-bottom: 12px !important;
    flex-wrap: wrap;
    gap: 20px;
  }
  .account_info_sec .box {
    padding-bottom: 22px;
    margin-top: 25px;
  }
  .account_info_sec h2 {
    font-size: 14px;
    line-height: 22px;
  }
  .wishlist_sec .box h2 {
    margin: 10px auto 17px !important;
  }
  .wishlist_sec .box h3 {
    font-size: 14px;
  }
  .payment_sec {
    margin-bottom: 16px;
    max-width: 100%;
  }
  .delivery_que_sec .upload_file {
    height: 185px;
  }
  .delivery_que_sec .upload_file h5 {
    font-size: 14px;
  }
  .delivery_que_sec .form-group .form-control {
    height: 45px;
  }
  .delivery_que_sec .form-group textarea {
    height: 115px !important;
  }
  .delivery_que_sec h2{
    font-size: 14px;
  }
  .my_images_sec {
    margin-bottom: 12px;
  }
  .dashboard-my-order h1 {
    font-size: 22px !important;
    padding-bottom: 14px;
  }
  .dashboard-my-order-detail-one {
    margin-bottom: 15px;
  }
  .dashboard-outer-main {
    margin: 30px 0;
  }
  footer .row .newsletter{
    margin-bottom: 20px;
  }
}
/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .parts-content .part-item {
    width: calc(50% - 8px);
  }
  .parts-modal h2 {
    letter-spacing: .20em;
  }
  .parts-modal .modal-inner {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .parts-modal p.sku {
    width: 100%;
    font-size: 16px;
    padding-bottom: 5px;
  }
  .parts-modal p.price {
    width: 100%;
    font-size: 16px;
    padding-bottom: 15px;
  }
  .parts-modal .actions {
    width: 100%;
  }
  .parts-modal .add-part-btn {
    margin: 0 auto;
  }
  .dashboard-my-order-detail-one .view-by-links.seprate {
    padding: 0px 10px 12px 10px;
  }
  div#partner_machine.partner_machine .space .box {
    padding-left: 0px;
  }
  div#privacy_policy .left {
    height: 100%;
    overflow: auto;
  }
  #privacy_policy .sub-head{
    font-size: 16px;
  }
  div#page_error h4 {
    font-size: 15px;
  }
  div#page_error h3 {
    font-size: 18px;
    line-height: 22px;
    margin: 0 auto 15px;
  }
  div#page_error h1 {
    font-size: 58px;
    margin-bottom: 20px;
  }
  div#page_error .img img {
    height: 174px;
  }
  div#faq .accordion-button::after {
    background-size: 1rem;
  }
  div#faq button.accordion-button {
    padding: 20px 0;
    font-size: 14px;
    line-height: 22px;
    gap: 20px;
  }
  div#faq h2 {
    font-size: 24px;
  }
  div#pay_later .box {
    max-width: 100%;
  }
  div#pay_later .box ul li+li {
    margin-top: initial;
  }
  div#pay_later .box ul li {
    margin-bottom: 12px;
  }
  div#pay_later h2 {
    line-height: 40px;
    font-size: 24px;
  }
  div#pay_later .space {
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
  div#buy_now_pay .box h2 img {
    height: 33px;
  }
  div#buy_now_pay .box h3 {
    font-size: 16px;
    margin-top: 12px;
  }
  div#buy_now_pay .box h3 span {
    font-size: 15px;
  }
  div#buy_now_pay .box .link {
    font-size: 14px;
    margin-top: 20px;
  }
  div#buy_now_pay .box p {
    font-size: 14px;
    margin-top: 22px;
  }
  div#buy_now_pay .box h2 {
    font-size: 24px;
  }
  div#big_purchase .img {
    gap: 10px;
  }
  div#big_purchase .img img {
    height: 45px;
  }
  div#financing_hallman {
    height: 340px;
  }
  div#financing_hallman h2 {
    font-size: 15px;
    line-height: 26px;
  }
  div#financing_hallman h1 {
    font-size: 28px;
    line-height: 44px;
  }
  div#coming_soon .form-group button {
    height: 45px;
    width: 145px;
  }
  div#coming_soon .form-group input {
    height: 45px;
  }
  #coming_soon h2 {
    font-size: 15px;
    line-height: 24px;
  }
  div#coming_soon .img img {
    height: 60px;
  }
  div#coming_soon ul.links {
    gap: 15px;
  }
  div#hallman_indus h3 {
    font-size: 26px;
  }
  div#hallman_indus .box .set {
    max-width: 100%;
    padding: 35px 15px;
  }
  div#call_us {
    margin-top: 30px;
  }
  div#call_us h4 {
    font-size: 14px;
  }
  div#call_us h3 {
    font-size: 14px;
    margin-bottom: 10px;
  }
  div#contact_us .box p {
    margin: 8px auto 0;
  }

  div#contact_us .box svg {
    width: 36px;
  }
  div#contact_us .box {
    padding: 25px 12px 25px;
  }
  div#privacy_policy .right ul {
    margin-bottom: 20px;
  }
  div#privacy_policy .right h3 {
    font-size: 18px;
    margin-top: 10px;
  }
  div#privacy_policy .right h2.head {
    font-size: 18px;
    margin-top: 10px;
  }

  div#privacy_policy .right h2 {
    font-size: 22px !important;
    margin-top: 16px !important;
    line-height: 30px;
}
  div#error_sec .down_btn span i {
    font-size: 16px;
  }
  div#error_sec .down_btn span {
    height: 40px;
    width: 40px;
  }
  div#error_sec ul {
    gap: 15px;
  }
  div#error_sec h3 {
    font-size: 22px;
  }
  div#error_sec p {
    font-size: 14px;
  }
  div#error_sec .img img {
    height: 150px;
  }
  div#error_sec h2 {
    font-size: 24px;
  }
  div#single_blog .center h3 {
    font-size: 16px;
  }
  div#single_blog .center p {
    line-height: 22px;
  }
  div#single_blog .center h2 {
    font-size: 22px;
  }
  div#blog_detail_banner h2 {
    margin-top: 12px;
  }
  div#blog_detail_banner p {
    margin-bottom: 10px;
  }

  div#blog_detail_banner {
    height: 350px;
  }
  div#blog_detail_banner h1 {
    font-size: 28px;
    line-height: 42px;
  }
  div#blog_sec .box h2 {
    font-size: 16px;
    line-height: 23px;
  }
  div#blog_sec h1 {
    font-size: 28px;
    margin-bottom: 10px;
  }
  div#blog_sec .box img {
    height: 165px;
  }
  div#commission p {
    font-size: 14px;
    line-height: 24px;
  }
  div#commission h3 {
    font-size: 16px;
    line-height: 24px;
    margin: 0 auto 6px;
  }
  div#commission h2 {
    font-size: 30px;
  }
  div#partner_machine p {
    line-height: 22px;
    margin-bottom: 8px !important;
    font-size: 16px;
  }
  div#partner_machine h2 {
    font-size: 22px;
    margin-bottom: 6px;
    line-height: 32px;
    letter-spacing: 0.05em;
  }
  .earn_showing {
    padding: 12px 0;
  }
  .earn_showing p {
    gap: 8px;
    line-height: 24px;
  }
  div#showroom_sec {
    height: 275px;
  }
  div#showroom_sec h2 {
    font-size: 28px;
  }
  .showroom_partner_sec .image_upload .set {
    padding: 20px;
  }
  .showroom_partner_sec .nav-pills .nav-link {
    padding: 6px;
  }
  .wishlist_sec .box {
    margin-top: 20px;
    padding: 17px;
  }
  .add_new_add_sec button.save_btn {
    height: 45px;
    margin-top: 12px;
  }
  .add_new_add_sec .image_upload {
    margin-bottom: 14px;
    padding: 15px;
  }
  .add_new_add_sec .image_upload .set {
    padding: 25px 10px;
    margin-bottom: 16px;
  }
  .add_new_add_sec .image_upload p {
    line-height: 21px;
  }
  .add_new_add_sec .image_upload h4 {
    margin-bottom: 7px;
  }
  .add_new_add_sec .image_upload h3 {
    font-size: 15px;
  }
  .add_new_add_sec .form-group label.form-label {
    margin-bottom: 7px;
  }
  .add_new_add_sec .form-group {
    margin-bottom: 12px;
  }
  .add_new_add_sec .form-group .form-control {
    height: 45px;
  }
  .dashboard-my-order p {
    font-size: 14px;
    margin-bottom: 18px;
  }
  .address_book_sec a .box_two {
    margin: 12px 0 5px;
    height: 200px;
  }
  .address_book_sec a .box_two h5 {
    font-size: 15px !important;
  }
  .delivery_que_sec button.submit {
    height: 45px;
    width: 150px;
    margin-top: 15px;
    font-size: 14px;
  }
  .delivery_que_sec h2 {
    font-size: 18px;
    margin-bottom: 10px !important;
  }
  .dashboard-order-left-content h2 {
    font-size: 16px;
  }
  .dashboard-order-left.d-flex {
    margin-bottom: 15px;
  }
  .my-order-category-top-one-type h3 {
    font-size: 13px;
  }
  .my-order-category-top-one-type {
    padding-right: 20px;
    margin-right: 15px;
  }
  .select-classic-bottom p {
    font-size: 16px;
  }
  .select-classic-bottom p.start {
    font-size: 15px;
    padding-bottom: 20px;
  }
  .cancel-order {
    padding: 0;
  }
  h1.main-heading {
    font-size: 30px;
    margin-bottom: 20px;
    margin-top: 15px;
  }
  .cancel-order h2 {
    font-size: 25px;
    margin-top: 30px !important;
  }
  .cancel-order ul.cancel-ul li {
    font-size: 14px;
    line-height: 26px;
  }
}
/* X-Small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .wishlist_sec .box h3 {
    min-height: 54px;
}
  .account_info_sec .box .accordion-header h3 a {
    margin-left: auto;
  }

  .dashboard-my-order-detail-one .view-by-links a {
    padding: 10px 10px;
  }
  .dashboard-my-order-detail-one .view-by-links a {
    font-size: 13px;
    line-height: 13px;
  }
  .partner_machine.one .flip {
    flex-direction: column-reverse;
  }
  div#partner_machine.partner_machine .space {
    padding-left: 16px !important;
  }
  ul.term-left-list {
    max-width: 100%;
    width: 100%;
  }
  div#faq {
    padding: 30px 0;
  }
  div#buy_now_pay .box h2 img.pay-img {
    height: 30px;
  }
  div#buy_now_pay .box h3 {
    font-size: 16px;
    line-height: 25px;
  }
  div#buy_now_pay .box h3 {
    font-size: 15px;
    margin-top: 10px;
    line-height: 22px;
  }
  div#buy_now_pay .box h3 span {
    font-size: 14px;
  }
  div#buy_now_pay .box p {
    margin-top: 16px;
  }
  div#buy_now_pay .box .set h3 {
    font-size: 20px;
    margin-top: 10px;
  }
  div#buy_now_pay .box h2 {
    font-size: 22px;
  }
  div#buy_now_pay .box h2 img {
    height: 30px;
  }
  div#buy_now_pay .box .set h3 {
    font-size: 22px;
    margin-top: 14px;
  }
  div#big_purchase h1 {
    font-size: 22px;
  }
  div#big_purchase p {
    font-size: 14px;
  }
  div#big_purchase {
    padding: 25px 0;
  }
  div#big_purchase .img img {
    height: 37px;
  }
  div#financing_hallman h2 {
    font-size: 14px;
    line-height: 25px;
    margin-top: 10px;
  }
  div#financing_hallman {
    height: 295px;
  }
  div#financing_hallman h1 {
    font-size: 24px;
    line-height: 38px;
  }
  div#coming_soon h1 {
    font-size: 14px;
  }
  div#coming_soon .img img {
    height: 55px;
  }
  div#call_us {
    margin-top: 20px;
    padding: 10px 0px;
    border: none;
  }
  div#call_us .box {
    border: none;
    background: whitesmoke;
    margin-bottom: 10px;
    padding: 40px 15px;
  }
  div#contact_form h2 {
    font-size: 24px;
    margin-bottom: 26px;
  }
  div#contact_us .mb-4 {
    margin-bottom: 0.7rem !important;
  }
  div#contact_us .box {
    padding: 30px 12px;
  }
  div#contact_us h1 {
    font-size: 24px;
  }
  div#contact_us h1 {
    font-size: 24px;
  }
  div#privacy_policy h1 {
    font-size: 30px;
  }
  div#privacy_policy .right h3 {
    font-size: 16px;
  }
  div#privacy_policy .right h2.head {
    font-size: 16px;
  }

  div#privacy_policy .right h2 {
    font-size: 24px;
    flex-wrap: wrap;
  }
  div#single_blog .left {
    padding: 12px;
  }
  div#single_blog .right {
    padding: 12px;
  }
  div#single_blog .right h3 {
    font-size: 18px;
  }
  div#single_blog .center .faq button.accordion-button {
    font-size: 14px;
  }
  div#single_blog .center {
    padding: 12px;
  }
  div#single_blog .center h2 {
    margin-bottom: 10px !important;
  }
  div#single_blog .left ul.links li+li {
    margin-top: 10px;
  }
  div#single_blog .left ul.links li a {
    padding-bottom: 10px;
  }
  div#single_blog .left h3 {
    font-size: 18px;
  }
  div#blog_detail_banner {
    height: 280px;
  }
  div#blog_detail_banner h1 {
    font-size: 24px;
    line-height: 38px;
  }
  div#blog_sec .box h2 {
    font-size: 18px;
    font-weight: 500;
  }
  div#blog_sec .box img {
    height: 275px;
  }
  div#showroom_sec h2 {
    font-size: 24px;
    line-height: 30px;
  }
  .showroom_partner_sec ul#pills-tab {
    min-width: 520px;
    margin-bottom: 0px !important;
  }
  .account_info_sec .box .set {
    padding: 15px;
  }
  .wishlist_sec .box img {
    height: 100%;
  }
  .address_book_sec .box h4 {
    font-size: 16px !important;
  }
  .address_book_sec .box h5 {
    font-size: 16px;
    margin-bottom: 10px !important;
  }
  .address_book_sec .box h4 {
    font-size: 16px !important;
  }
  .address_book_sec h3 {
    margin-bottom: 18px !important;
  }
  .address_book_sec h2 {
    font-size: 18px !important;
  }
  .payment_sec button.add_cart {
    height: 45px;
    width: 160px;
  }
  .payment_sec .card_box .form-group span {
    bottom: 14px;
    gap: 0px;
  }
  .payment_sec .card_box h3 {
    margin-top: 20px;
  }
  .payment_sec .card_box h4 {
    margin: 12px 0 14px;
  }
  .payment_sec .card_box .form-group {
    margin-bottom: 14px;
  }
  .payment_sec .card_box .form-group span img {
    height: 16px;
  }
  .payment_sec .card_box .form-control.extra {
    padding-right: 182px;
  }
  .payment_sec .card_box .form-group .form-control {
    height: 45px;
  }
  .payment_sec .card_box .form-group label {
    font-size: 14px;
  }
  .payment_sec .card_box {
    margin-top: 24px;
    padding-top: 16px;
  }
  .payment_sec .filed img.visa {
    height: 35px;
  }
  .payment_sec .filed .text {
    padding-left: 68px;
    padding-top: 0px;
  }
  .payment_sec .filed {
    padding: 10px;
    margin-bottom: 14px;
  }
  .delivery_que_sec .box {
    padding-bottom: 15px;
    margin-bottom: 20px;
  }
  .delivery_que_sec .form-group {
    margin-bottom: 10px;
  }
  .my-order-category-top-one-type {
    padding-right: 20px;
    margin-right: 20px;
  }
  .my-order-category-top-one {
    align-items: baseline !important;
  }
  .my-order-category-top-one-type h2 {
    font-size: 13px;
  }
  .view-buy-buttons a {
    font-size: 13px;
    margin-left: 0;
    padding-left: 0;
    padding-right: 0px;
    position: relative;
    padding-bottom: 2px;
  }
  a.view-product-differ {
    border-left: 0px;
    border-right: 0px;
  }
  .dashboard-order-right h2 {
    font-size: 24px;
    text-align: start;
    margin-top: 10px;
  }
  .view-product-btm p {
    font-size: 14px;
  }
  .view-product-btm {
    margin-top: 10px;
    flex-wrap: wrap;
  }
  .dashboard-order-left-content h2 {
    max-width: 100%;
    width: 100%;
  }
  .view-buy-buttons.d-flex {
    gap: 20px;
  }
  span.order-highlight {
    font-size: 13px;
  }
  .dashboard-order-left.d-flex {
    gap: 18px;
  }
  .dashboard-order-left-content h2 {
    font-size: 14px;
  }
  .dashboard-order-left img {
    height: 80px;
    width: auto;
  }
  .dashboard-order-left-content h3 {
    padding: 8px 0px;
  }
  .cart-page .slider-wrapper .carousel-slider ul.slider {
    gap: 0px;
  }
  .cart-main-section .carousel .slide img {
    padding : 15px; 
  }
}
/* For Small devices mentioned in react code (isSmallScreen) */
@media (max-width: 540px) {
}
/* X-Small devices (portrait phones, less than 350px) */
@media (max-width: 480px) {
  /* css for header bottom changes starts */
  .header-bottom-inner{
    flex-direction: column;
    align-items: center;
  }
  .header-bottom-inner p {
    font-size: 12px;
    line-height: 14px;
  }
  main {
    padding-top: 103px;
  }
  /* css for header bottom changes ends */
  .parts-content .part-item {
    width: 100%;
  }
  .pagination li.page-item a {
    padding: 10px !important;
  }
  .parts-content .part-right {
    margin-left: 10px;
}

  div#page_error h4 {
    font-size: 14px;
  }
  div#page_error ul {
    column-gap: 20px;
  }
  div#page_error ul li a {
    font-size: 13px;
  }

  div#page_error h3 {
    font-size: 15px;
  }
  div#page_error h1 {
    font-size: 40px;
  }
  div#page_error .img img {
    height: 124px;
  }
  div#faq button.accordion-button {
    padding: 15px 0;
  }
  div#faq h2 {
    font-size: 22px;
    line-height: 30px;
  }
  div#buy_now_pay .box h3 {
    font-size: 15px;
    line-height: 24px;
  }
  div#buy_now_pay .box h2 img.pay-img {
    height: 27px;
  }
  div#pay_later .box ul li::marker {
    font-size: 19px;
  }
  div#pay_later .box ul li span {
    font-size: 14px;
    line-height: 22px;
    margin-top: 2px;
  }
  div#pay_later h2 {
    line-height: 34px;
    font-size: 22px;
  }
  div#buy_now_pay .box h2 img {
    height: 27px;
  }
  div#buy_now_pay .box .set h4 {
    margin: 12px 0 0;
  }
  div#buy_now_pay .box .set h3 {
    font-size: 18px;
  }
  div#buy_now_pay .box h2 {
    font-size: 20px;
  }
  div#financing_hallman h1 {
    font-size: 22px;
    line-height: 32px;
  }
  div#financing_hallman {
    height: 250px;
  }
  div#coming_soon ul.links {
    row-gap: 10px;
  }
  div#coming_soon h2 {
    font-size: 16px;
    margin-top: 40px;
  }
  div#coming_soon .form-group button {
    width: 100%;
    margin-top: 8px;
    position: inherit;
  }
  div#coming_soon h1 {
    line-height: 22px;
  }
  div#coming_soon {
    padding: 45px 0 20px;
  }
  div#coming_soon .img img {
    height: 40px;
  }
  div#hallman_indus .box .set {
    max-width: 100%;
    padding: 24px 15px;
  }
  div#hallman_indus p {
    font-size: 14px;
    line-height: 39px;
  }
  div#hallman_indus h3 {
    font-size: 22px;
    padding-bottom: 9px;
    margin-bottom: 9px;
    max-width: 100%;
  }
  div#error_sec h3 {
    font-size: 20px;
  }
  div#error_sec {
    padding: 25px 0;
  }
  div#error_sec .img {
    margin-bottom: 26px;
  }
  div#error_sec h2 {
    font-size: 22px;
  }
  div#error_sec .img img {
    height: 115px;
  }
  div#blog_detail_banner h1 {
    font-size: 22px;
    line-height: 32px;
  }
  div#blog_sec .box h2 {
    font-size: 20px;
    line-height: 22px;
    min-height: 0px;
  }
  div#blog_sec .box img {
    height: 200px;
  }
  div#commission {
    padding: 30px 0;
  }
  div#commission .box {
    padding: 30px 20px;
  }
  div#commission h3 {
    font-size: 14px;
    line-height: 22px;
  }
  div#commission h2 {
    font-size: 24px;
  }
  div#partner_machine .space {
    padding: 30px 12px;
  }
  div#partner_machine h2 {
    font-size: 24px;
    margin-bottom: 15px !important;
    line-height: 30px;
  }
  div#showroom_sec {
    height: 225px;
  }
  div#showroom_sec h2 {
    font-size: 22px;
  }
  .showroom_partner_sec .image_upload .set h5 i {
    font-size: 18px;
  }
  .showroom_partner_sec .image_upload .set {
    padding: 20px 6px;
  }
  .showroom_partner_sec .image_upload h4 span {
    display: block;
    margin-top: 5px;
  }
  .showroom_partner_sec .image_upload h4 {
    display: block;
  }
  .account_info_sec .box h3 {
    font-size: 13px;
  }

  .add_new_add_sec .image_upload h3 {
    font-size: 14px;
  }
  .add_new_add_sec .image_upload .set h5 i {
    font-size: 17px;
  }
  .add_new_add_sec .image_upload .set h5 {
    font-size: 13px;
  }
  .address_book_sec h2 {
    font-size: 16px;
  }
  .payment_sec .filed .edit {
    gap: 10px;
  }
  .payment_sec .filed .edit a+a {
    padding-left: 10px;
  }
  .payment_sec .filed .edit img {
    width: 16px;
  }
  .dashboard-my-order h1 {
    font-size: 20px;
  }
  .my_images_sec .col-4 {
    width: 100%;
  }
  .my_images_sec .col-8 {
    width: 100%;
  }
  .dashboard-order-left.d-flex {
    gap: 14px;
  }
  .my-order-panel-list ul li+li {
    margin-top: 17px;
  }
  .dashboard-order-right h2 {
    font-size: 22px;
  }
  div#blog_sec .mt-3 {
    margin-top: 30px !important;
  }
  .select-classic-bottom p {
    font-size: 13px;
  }
  .select-classic-bottom h2 {
    margin: 6px 0px 10px 0px;
    padding-bottom: 7px;
    font-size: 31px;
  }
  .select-classic-bottom p.series-small {
    font-size: 14px;
    padding-bottom: 10px;
  }
  .select-classic-bottom p.start {
    font-size: 13px;
    padding-bottom: 10px;
  }
  .select-classic-bottom a.shop-now {
    padding: 8px 18px;
    font-size: 13px;
  }
  .cancel-form .form-field label {
    line-height: 26px;
    padding: 4px 0 2px;
  }
}
/*========================== Dashboard-css-end ===========================*/

#SA_review_wrapper * {
  font-family: "Gotham" !important;
}
.SA__verified_customer tspan {
  letter-spacing: -0.5px;
}
#SA_review_wrapper .sa_selectbox_list_item {
  font-size: 14px;
}
/* .thumb::before{
  content: "";
    background-color: white !important;
    box-shadow: none !important;
    border: solid 2px #c0974f !important;
    border-radius: 0 !important;
    cursor: pointer !important;
    width: 15px !important;
    height: 15px !important;
    top: -5px !important;
    position: absolute !important;outline: 0 !important;
} */
.thumb.thumb-0 {
  position: relative;
}
.thumb.thumb-0::before {
  content: "";
  background-color: white !important;
  box-shadow: none !important;
  border: solid 2px #c0974f !important;
  border-radius: 0 !important;
  cursor: pointer !important;
  width: 15px !important;
  height: 15px !important;
  top: -5px !important;
  position: absolute !important;
  outline: 0 !important;
  left: 0;
}
.thumb.thumb-1 {
  right: 0;
}
.thumb.thumb-1::after {
  content: "";
  background-color: white !important;
  box-shadow: none !important;
  border: solid 2px #c0974f !important;
  border-radius: 0 !important;
  cursor: pointer !important;
  width: 15px !important;
  height: 15px !important;
  top: -5px !important;
  position: absolute !important;
  outline: 0 !important;
}

.multi-range-slider * {
  outline: 0 !important;
}
.multi-range-slider {
  margin: 10px 0px 0px 0px !important;
  box-shadow: none !important;
  border: none !important;
}
.thumb1-0 .caption,
.thumb1-1 .caption {
  box-shadow: none !important;
  outline: 0 !important;
}
.thumb1-0::before {
  left: 0 !important;
  box-shadow: none !important;
  outline: 0 !important;
}
.thumb1-1::before {
  right: 0 !important;
  box-shadow: none !important;
  outline: 0 !important;
}
.bar-inner {
  border: solid 2px black !important;
}
.multi-range-slider * {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
}
.multi-range-slider {
  display: flex;
  position: relative;
  border: solid 1px gray;
  border-radius: 10px;
  padding: 20px 10px;
  box-shadow: 1px 1px 4px black;
  flex-direction: column;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Edge,*/
}
.multi-range-slider .bar {
  display: flex;
}
.multi-range-slider .bar-left {
  width: 25%;
  background-color: #f0f0f0;
  border-radius: 10px 0px 0px 10px;
  box-shadow: inset 0px 0px 5px black;
  padding: 4px 0px;
}
.multi-range-slider .bar-right {
  width: 25%;
  background-color: #f0f0f0;
  border-radius: 0px 10px 10px 0px;
  box-shadow: inset 0px 0px 5px black;
}
.multi-range-slider .bar-inner {
  background-color: lime;
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  justify-content: space-between;
  position: relative;
  border: solid 1px black;
  justify-content: space-between;
  box-shadow: inset 0px 0px 5px black;
}
.multi-range-slider .bar-inner-left {
  width: 50%;
}
.multi-range-slider .bar-inner-right {
  width: 50%;
}
.multi-range-slider .thumb {
  background-color: white;
  position: relative;
  z-index: 1;
  cursor: pointer;
}
.thumb.thumb-1 .caption {
  left: -30px !important;
}
.multi-range-slider .input-type-range:focus+.thumb::after {
  content: '';
  position: absolute;
  top: -4px;
  left: -7px;
  width: 11px;
  height: 11px;
  z-index: 2;
  border-radius: 50%;
  border: dotted 1px black;
  box-shadow: 0px 0px 5px white, inset 0px 0px 10px black;
}
.multi-range-slider .caption {
  position: absolute;
  bottom: 35px;
  width: 2px;
  height: 2px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: visible;
  display: none;
}
.multi-range-slider .thumb .caption * {
  position: absolute;
  min-width: 30px;
  height: 30px;
  font-size: 75%;
  text-align: center;
  line-height: 30px;
  background-color: blue;
  border-radius: 15px;
  color: white;
  box-shadow: 0px 0px 5px black;
  padding: 0px 5px;
  white-space: nowrap;
}
.multi-range-slider .thumb:active .caption {
  display: flex;
}
.multi-range-slider .input-type-range:focus+.thumb .caption {
  display: flex;
}
.multi-range-slider .input-type-range {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  opacity: 0;
  pointer-events: none;
}
.multi-range-slider .bar {
  display: flex;
}
.multi-range-slider.disabled .bar-left {
  background-color: #c9c9c9;
  box-shadow: inset 0px 0px 5px rgb(160, 160, 160);
}
.multi-range-slider.disabled .bar-right {
  background-color: #c9c9c9;
  box-shadow: inset 0px 0px 5px rgb(160, 160, 160);
}

.multi-range-slider.disabled .bar-inner {
  background-color: rgb(130 243 130);
  border: solid 1px rgb(149, 149, 149);
  box-shadow: inset 0px 0px 5px rgb(103, 103, 103);
}
.multi-range-slider.disabled .thumb {
  background-color: white;
}

.multi-range-slider.disabled .thumb::before {
  border: solid 1px rgb(200, 200, 200);
  box-shadow: 0px 0px 3px rgb(35, 35, 35), inset 0px 0px 5px gray;
}

.multi-range-slider.disabled .input-type-range:focus+.thumb::after {
  border: dotted 1px rgb(35, 35, 35);
  box-shadow: 0px 0px 5px white, inset 0px 0px 10px rgb(35, 35, 35);
}

.multi-range-slider.disabled .thumb .caption * {
  background-color: rgb(84, 84, 137);
  color: rgb(199, 199, 199);
  box-shadow: 0px 0px 5px rgb(35, 35, 35);
}

div.offcanvas.show {
  z-index: 99999;
}

.session-expired-message {
  padding: 12px 13px 10px 13px;
  background: #fae5e5;
  text-align: left;
  margin-bottom: 10px;
}

.love-appliances-top .product-result p {
  font-size: 17px;
  font-weight: 500;
  line-height: 30px;
}

.no-record-found-message {
  border: 1px solid #d3d3d3;
  padding: 15px;
  position: relative;
  margin-bottom: 18px;
  background: #d9edf7;
  max-width: 625px;
}
/* HTML: <div className="loader"></div> */
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* Full viewport height */
  width: 100vw;
  /* Full viewport width */
  position: fixed;
  top: 0;
  left: 0;
  /* background-color: rgba(255, 255, 255, 0.8); Optional: Add a background color to cover the whole screen */
  z-index: 9999;
  /* Ensure it is above other elements */
}
/* #001e42 */
/* HTML: <div className="loader"></div> */
.circlespinner {
  animation: spin 1s infinite ease-in-out;
  border-radius: 50%;
  border-top: 2px solid #fff;
  display: inline-block;
  height: 2em;
  margin: calc(50vh - 1em) calc(50vw - 1em);
  width: 2em;
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes dash {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.dropzone {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: auto;
  flex-wrap: wrap;
}
/* coming soon css start */
.coming_soon_section {
  background-image: none !important;
  padding: 0 0 25px !important;
}

.service-content p.com {
  font-family: "Gotham";
  font-size: 35px;
  font-weight: 500;
  line-height: 30px;
  color: #001E42;
  margin-bottom: 10px !important;
}

.service-content p {
  font-family: "Gotham";
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  color: #000000;
}

.prod-info-center .service-content {
  padding: 50px 0px 20px;
}

/* coming soon css ends */

.modal {
  z-index: 9999;
}

.text-sm {
  font-size: smaller;
}

#react-tags {
  display: flex;
  flex-direction: column;
}
#react-tags .ReactTags__selected {
  display: flex;
  flex-wrap: wrap;
  order: 3;
  /* Moves the selected tags below the input */
}
#react-tags #react-tags-combobox {
  order: 2;
  /* Keeps the input field at the top */
  width: 100%;
}

#react-tags .is-selected {
  background-color: grey;
  color: white
}

.carousel-indicators button {
  display: none;
}
/* Show the first 5 indicators */
.carousel-indicators button:nth-child(-n+5) {
  display: inline-block;
}

.section-products .product-fliter .ready-select .sel-red {
  display: none;
}

.section-products .product-fliter .ready-select label.sel-leb {
  background: #001E42;
  border: 1px solid #001E42;
  height: 33px;

  margin-top: 10px;
  transition: 0.5s all ease-in-out;
  cursor: pointer;
}

.section-products .product-fliter .ready-select span.cust-check {
  background: transparent;
}

.section-products .product-fliter .ready-select label.sel-leb p {
  color: #fff;
  font-family: 'Gotham';
  font-size: 14px;
  font-weight: 500;
  line-height: 13.4px;
  text-align: center;
}

.unsubscribe-main{
  min-height: 50vh;
}