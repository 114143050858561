body.home-page {
    padding: 0 !important;
}
 
.minicart-popup .offcanvas.offcanvas-end {
    top: 85px !important;
    right: 0 !important;
    width: 500px !important;
    border-left: 1px solid rgba(0, 0, 0, .2);
    transform: translateX(100%);
    transition: all .3s ease-in-out;
    z-index: 9999;
}
.minicart-popup .offcanvas-body, .minicart-popup .offcanvas-body .mini-sub-total-area {
    overflow-y: auto;
    height:100%;
    overflow-x: clip;
}
.minicart-popup .offcanvas-body .mini-sub-total-area {
    padding-bottom: 30px;
}
.minicart-popup .offcanvas-body .mini-sub-total-area::-webkit-scrollbar{
    display: none;
}

.minicart-popup .offcanvas-header.mini-cart-content {
    padding: 20px;
}
.mini-cart-content h5 {
    color: #1D1D1B;
    font-family: 'Gotham';
    font-size: 14px;
    font-weight: 400;
    line-height: 13.4px;
    text-align: left;
}
.mini-cart h5.modal-title {
    color: #1D1D1B;
    font-family: 'Gotham';
    font-size: 14px;
    font-weight: 300;
    line-height: 13.4px;
    text-align: left;
}
.mini-cart .modal-header{
    padding: 30px 20px ;
    border-bottom: 0px ;
}
.mini-cartsub-total-top {
    padding: 0px 0px 13px 0px;
    margin: 0px 0px 1px 0px;
}
.cart-sub-bottom {
    /* border-bottom: 1px solid #DCDCDC; */
    margin-bottom: 10px;
}
.mini-cartsub-total-top h2 {
    color: #1D1D1B;
    font-family: 'Gotham';
    font-size: 18px;
    font-weight: 500;
    line-height: 17.23px;
    text-align: left;
    text-transform: uppercase;
}
.header-bottom-border {
    position: relative;
}
.header-bottom-border:after {
    content: '';
    position: absolute;
    height: 1px;
    width: 93%;
    background-color: #DCDCDC;
    left: 0;
    right: 0;
    margin: auto;
}






.mini-cart-content .btn-close {
    box-sizing: content-box;
    width: 10px;
    height: 10px;
    color: #000;
  background-image: url(https://hallmanimagesnew.s3-accelerate.amazonaws.com/header/min-cart-close.svg);
    border: 0;
    border-radius: .25rem;
    opacity: 1;
}


.minicart-select-left-images {
    height: 64.76px;
    width: 64.76px;
}
.minicart-select-left-images img {
    max-width: 64.76px;
    height: 64.76px;
    object-fit: cover;
}
.modal-body.mini-sub-total-area {
    padding: 30px 0px;
    margin: 0px 20px;
    border-top: 1px solid #DCDCDC;
}

.minicart-select-left-heading h3 {
    color: #022741;
    font-family: 'Gotham';
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    text-align: justify;
}
.minicart-select-left-heading p.additional-attr {
    padding-top: 0;
}
.minicart-select-left-heading p.additional-attr span {
    color: #001E42;
}

.minicart-select-left-heading p {
    color: #1D1D1B;
    font-family: 'Gotham';
    font-size: 14px;
    font-weight: 400;
    line-height: 13.4px;
    text-align: left;
    padding: 0 0 11px;
}
.minicart-select-left-heading h4 {
    color: #001E42;
    font-family: 'Bookman';
    font-size: 15px;
    font-weight: 5;
    line-height: 17.01px;
    letter-spacing: 0.05em;
    text-align: left;
    margin-bottom: 17.5px !important;
}
.sub-total-mini-items{
    padding-bottom: 34px;
}
.sub-total-mini-items:last-child{
    padding-bottom: 0px;
}

.mini-credits.row>* {
    padding-right: 5px;
    padding-left: 5px;
}
.row.mini-credits {
    margin-left: 0px;
}

.mini-credit-card {
    padding: 20px 0px 25px 0px;
    border-top: 1px solid #DCDCDC;
    border-bottom: 1px solid #DCDCDC;
}
.minicart-select-left-content .w-60:not(.shimmer-text){
    width: 100% !important;
    padding-left: 10px;
}
.mini-credit-card h2 {
    color: #001E42;
    font-family: 'Bookman';
    font-size: 14px;
    font-weight: 5;
    line-height: 24px;
    text-align: left;
    text-transform: uppercase;
    margin-bottom: 6.64px !important;
}
.mini-easy-finacing {
    padding: 20px 0px 30px 0px;
}
.mini-easy-finacing-head h2 {
    color: #001E42;
    font-family: 'Bookman';
    font-size: 14px;
    font-weight: 5;
    line-height: 24px;
    text-align: left;
    text-transform: uppercase;
}
.mini-easy-finacing-head p {
    color: #1D1D1B;
    font-family: 'Gotham';
    font-size: 12px;
    font-style: italic;
    font-weight: 500;
    line-height: 11.48px;
    text-align: left;
}
.mini-easy-finacing-head {
    margin-bottom: 5px;
}
.mini-checkout {
    background: #C0974F;
    outline: none;
    border: none;
    width: 100%;
    color: #fff;
    font-family: 'Gotham';
    font-size: 14px;
    font-weight: 600;
    line-height: 13.4px;
    text-align: left;
    padding: 18px 0px;
    text-align: center;
    transition: 0.5s all ease-in-out;
}


.mini-cart-button a{
    color: #001E42 !important;
    font-family: 'Gotham';
    font-size: 14px;
    font-weight: 500;
    line-height: 13.4px;
    justify-content: center;
    border: 1px solid #99A5B3;
    width: 100%;
    padding: 18px 0px;
    transition: 0.5s all ease-in-out;
}
 .mini-cart-button a:hover{
    background: #C0974F;
    color: #fff !important;
    transition: 0.5s all ease-in-out;
    /* font-weight: 300; */
    border-color: transparent;

}
.mini-finacing-logo img {
    width: 100%;
}





@media only screen and (max-width: 1024px){
    .offcanvas.offcanvas-end {
        top: 77px !important;
    }
}

@media only screen and (max-width: 991px){

    .offcanvas.offcanvas-end {
        top: 62px !important;
    }
}


@media only screen and (max-width: 667px){
    .minicart-select-left-images img {
        max-width: 100%;
        height: 130px;
        width: 100%;
    }
    .minicart-select-left-images {
        height: 130px;
        width: 130px;
        margin: auto;
    }
    .offcanvas-header.mini-cart-content {
        padding: 20px 17px 30px 17px;
    }
}


@media only screen and (max-width: 576px){
 
    .sub-total-mini-items {
        padding-bottom: 10px;
    }
    .mini-easy-finacing {
        padding: 20px 0px 20px 0px;
    }
    .mini-cartsub-total-top h2 {
        font-size: 14px;
    }
 .mini-cart-button a {
        padding: 14px 0px;
    }
    .mini-checkout {
        padding: 14px 0px;
    }
    .mini-easy-finacing-head {
        margin-bottom: 20px;
        flex-wrap: wrap;
        gap: 5px;
    }

}

