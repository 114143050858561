
 
img{
    max-width:100%;
}
a{
    text-decoration:none;
    display:inline-flex;
}
*{
    margin:0;
    padding:0;
}
ul,li{
    list-style:none;
    margin:0;
    padding:0;
}
p{
    margin-bottom:0 !important;
}
/* header section start here */
.header-section {
    padding:25px 0;
}
.project svg {
    background: transparent;
}
.header-wraper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 80px;
}
.header-contact a {
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    display: inline-flex;
    text-align: center;
    align-items: center;
    color: #000000;
    border-radius: 10px;
    border: 1px solid #FFA800;
    background-color: #FFA800;
    padding: 12px 12px 12px 26px;
}
.header-contact a span{
    padding-left:10px;
}
.header-contact svg{
    border-radius:50%;
}
.header-contact a:hover svg{
    transform: rotate(45deg);
    transition: all 0.5s;
}
.header-contact a:hover{
    background-color:#fff;
}
/* header section end here */
/* banner section start here */
.banner{
    /* background-image:url('/public/images/banner.jpg'); */
    background-position:center;
   background-size:cover;
    padding:187px 0 186px 0;
    background-repeat: no-repeat;
}
.inner-content{
    text-align: center;
    color:#fff;
}
.inner-content h2{
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
}
.inner-content h1 {
    font-size: 58px;
    font-weight: 600;
    line-height: 62px;
    letter-spacing: 0em;
    padding-bottom: 22px;
}
.inner-content p {
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
}
.banner-contact{
    margin-top:31px;
}
.banner-contact svg{
    border-radius:50%;
}
.banner-contact a:hover{
    background-color:#fff;
}
.banner-contact a:hover svg{
    transform: rotate(45deg);
    transition: all 0.5s;
}
.banner-contact a{
    font-family:'Poppins';
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    border-radius:10px;
    display: inline-flex;
    align-items: center;
    background-color:#FFA800;
    padding:12px 12px 12px 26px;
    width: 253px;
    margin:0 auto;
    letter-spacing: 0em;
    text-align: center;
    color:#000000;
}

.react-slideshow-container .default-nav:first-of-type {
  
    padding: 0px 8px;
    left: 15px !important;
}
.react-slideshow-container .default-nav:last-of-type {
    padding: 0px 8px;
    right: 15px !important;
}
.banner-contact a span{
    padding-left:10px;
}
/* banner section end here */

/* main section start here */
/* main img{
    width:100%;
} */
.craft-version-inner-content  h2{
    font-size: 35px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: left;
    position: relative;
    color:#000000;
}
.craft-version-inner-content  h2::before {
    position: absolute;
    width: 118px;
    height:4px;
    background-color:#FFA800;
    content: "";
    top: 112px;
    border-radius:2px;
}
.craft-version-inner-content  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
}
.craft-version-inner-content p span a{
    font-size: 16px;
    font-style: italic;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
    color:#000;
    text-decoration: underline;
}
.craft-version-inner-content{
    padding: 0 138px;
    max-width: 720px;
    width: 100%;
}
.craft-version-inner-content .hallman-premium {
    padding: 30px 0 10px 0;
}
/* main section end here */
/* Exclusive Trade Partner Benefits section start here */
.exclusive-trade-section{
    background-color:#0A0A0A;
    padding:100px 0;
    color:#fff;
    text-align: center;
}
.exclusive-trade-section h2{
    font-size: 35px;
    font-weight: 600;
    line-height: 55px;
    letter-spacing: 0em;
    position: relative;
}
.exclusive-trade-section h2::before {
    position: absolute;
    width: 118px;
    height:4px;
    background-color: #FFA800;
    content: "";
    left: 46%;
    top: 70px;
    margin: 0 auto;
    border-radius:2px;
}
.exclusive-trade-section  p{
    font-size: 16px;
    font-weight:400;
    line-height: 30px;
    letter-spacing: 0em;
    padding-top:32px;
}
.exclusive-trade-section h3 {
    font-size: 25px;
    font-weight:600;
    padding-top:22px;
    line-height: 35px;
    position: relative;
    margin-top:10px;
    letter-spacing: 0em;
}
.exclusive-trade-section h3 ::after {
    position: absolute;
    width: 56%;
    height: 1px;
    background-color: #545454;
    content: "";
    left: 22%;
    top: 7px;
    margin: 0 auto;
    border-radius: 2px;
}
.exclusive-trade-section h3 ::before {
    position: absolute;
    width: 56%;
    height: 1px;
    background-color: #545454;
    content: "";
    left: 22%;
    top: 102px;
    margin: 0 auto;
    border-radius: 2px;
}
.exclusive-trade-section h3 a{
    color:#fff;
    font-style: italic;
    text-decoration: underline;
}
.exclusive-trade-section h3 span {
    color: #FFA800;
    text-transform: uppercase;
}
/* Exclusive Trade Partner Benefits setion end here */
.factory-direct img {
    width: 100%;
}
/* .factory-direct .hallman-premium{
    padding: 30px 0 0 0;
} */
.craft-version-inner-content .sign-paragraph{
  padding-top:12px;
}
/* factory-direct section end here */
/* Enjoy longer  section strat here */

.dedicated .craft-version-inner-content h2::before {
    top: 162px;
}
.dedicated img{
    width:100%;
}
.enjoy-longer img{
    width:100%;
}
.enjoy-longer  .craft-version-inner-content h2::before {
    top: 160px;
}

/* Enjoy longer  section end here */

/* why choose section start here */
.why-choose-section{
    background-color:#0A0A0A;
    padding:100px 0;
    text-align: center;
    color:#fff;
}
.why-choose-section h2{
    font-size: 35px;
    font-weight: 600;
    line-height: 55px;
    letter-spacing: 0em;
    padding-bottom:30px;
    position: relative;
}
.why-choose-section h2:before{
    position: absolute;
    width: 118px;
    height: 5px;
    background-color: #FFA800;
    content: "";
    left: 46%;
    top: 70px;
    margin: 0 auto;
    border-radius: 2px;
}
.why-choose-section .project h3{
    font-size: 18px;
    font-weight:500;
    line-height: 28px;
    padding-top:20px;
    letter-spacing: 0em;
}
.why-choose-section .project {
    border-right:1px solid #2E2E2E;
    margin-top:35px;
}
.why-choose-section :last-child{
    border-right:0;
}
.why-choose-section .row{
    border-right: 0;
    justify-content: center;
    align-items: baseline;
}
.project svg:hover{
    transform: translateY(10px);
    transition: 0.5s;
}
/* why choose section end here */
/* sign up section start here */
.sign-up{
    text-align: center;
    background-color: #E9E9E9;
    padding:60px 0 0 0;
}
.sign-up h3{
    font-size: 35px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: 0em;
    padding-top:17px;
} 
.sign-up p{
    font-size: 14px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
}
.sign-up-contact {
    margin-top:21px;
}
.sign-up-contact a:hover{
    background-color:#fff;
}
.sign-up-contact svg{
    border-radius:50%;
}
.sign-up-contact:hover path{
    fill:#fff;
}
.sign-up-contact:hover svg{
    transform: rotate(45deg);
    transition: all 0.5s;
}

.sign-up-contact a {
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    display: inline-flex;
    align-items: center;
    text-transform: uppercase;
    text-align: center;
    border-radius: 10px;
    background-color: #FFA800;
    padding: 12px 12px 12px 26px;
    width:164px;
    margin: 0 auto;
    color: #000000;
}
.sign-up-contact a span {
    padding-left: 10px;
}
.ben-hallman-ph{
    border-top:1px solid #C3C3C3;
    margin-top:60px;
}
.ben-hallman-ph p{
    text-align: center;
    padding:30px 0 20px 0;
    color:#121212;
}
.ben-hallman-ph p a{
    text-decoration: underline;
    font-style: italic;
    color:#000;
}
/* sign up sectiion end here */
/* copy right section start here */
.copy-right{
    background-color:#DDDDDD;
    text-align: center;
    padding:16px 0 14px 0;
}
.copy-right p{
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
}
/* copy right section end here */


/* responsive css start here */
@media all and (max-width:1280px){
    .craft-version-inner-content {
        padding: 0 93px;
    }
}
@media all and (max-width:1024px){
    .craft-version-inner-content {
        padding: 0 47px;
    }
    .craft-version-inner-content h2 {
        font-size: 24px;
        line-height: 33px;
    }
    .craft-version-inner-content h2::before {
        top: 83px;
    }
    .enjoy-longer .craft-version-inner-content h2::before {
        top:84px;
    }
    .dedicated .craft-version-inner-content h2::before {
        top: 117px;
    }
    .sign-up p {
        font-size: 12px;
    }
}
@media  all and (max-width:851px){
    .sign-up p {
        font-size:9px;
        text-align: left;
    }
    .sign-up a{
        display:flex;
        justify-content: center;
    }
    .sign-up p br{
        display: none;
    }
    .why-choose-section .project h3 {
        font-size: 14px;
    }
    .sign-up h3 {
        font-size: 23px;
        line-height: 34px;
        padding-top: 17px;
    }
    .why-choose-section h2 {
        font-size:25px;
        line-height: 33px;
    }
    .why-choose-section h2:before {
        left: 33%;
        top: 94px;
    }
    .craft-version-inner-content {
        padding: 30px 16px;
    }
    .exclusive-trade-section h2 {
        font-size:17px;
    }
    .exclusive-trade-section {
        padding: 45px 0;
    }
    .exclusive-trade-section  p {
        font-size: 12px;
        line-height: 30px;
        padding-top: 32px;
        text-align: left;
    }
    .exclusive-trade-section  p br{
        display: none;
    }
    .exclusive-trade-section h2::before {
        left: 29%;
        top: 67px;
    }
    .exclusive-trade-section h3 {
        font-size:9px;
        line-height: 31px;
    }
    .exclusive-trade-section h3 ::after {
        width: 100%;
        left: 0%;
    }
    .exclusive-trade-section h3 ::before {
        width: 100%;
        left: 0%;
    }
    .dedicated-image{
        margin-top:50px;
    }
    .project svg{
        background: transparent;
    }
    #Rectangle{
        background: transparent;
    }
    .inner-content p br{
        display: none;
    }
    .inner-content p{
        text-align: left;
        font-size: 16px;
        font-weight:300;
    }
    .inner-content h1 br{
        display: none;
    }
    .inner-content {
      text-align: left;
    }
    .inner-content h1 {
        font-size:22px;
        line-height: 37px;
    }
    .banner-contact {
       margin:30px 0 0 0 ;
    }
    .banner {
        padding: 59px 0;
        overflow-x: hidden;
    }
    main{
        overflow-x: hidden; 
    }
    .exclusive-trade-section{
        overflow-x: hidden;  
    }
    .factory-direct{
        overflow-x: hidden;
    }
    .enjoy-longer{
        overflow-x: hidden;
    }
    .dedicated{
        overflow-x: hidden;
    }
    .why-choose-section{
        overflow-x: hidden;
    }
    .header-contact {
       padding:10px ;
    }
    .header-contact svg {
        width: 22px;
        display: inline-flex;
    }
    .header-contact svg :hover{
        transform: rotate(45deg);
        transition: all 0.5s;
    }
    .header-contact a span {
       padding-left:5px;
    }
    .header-logo{
        width:50%;
    }
    .header-contact a {
        font-size: 13px;
        padding: 8px 12px 8px 26px;
    }
    .header-section {
        padding: 9px 0;
    }
    .craft-version-inner-content h2 {
        font-size:18px;
    }
    .copy-right p {
        font-size: 10px;
    }
    .craft-version-inner-content p {
        font-size: 14px;
    }
    .exclusive-trade-section h3 a br{
        display: none;
    }
    .header-wraper {
        padding:0 0 0 3px;
    }
    .header-contact svg {
        width:auto;
        background: transparent;
        overflow: hidden;
        max-width: 100%;
    }
}
/* responsive css end here */
.multi-range-slider .thumb::before{
    box-shadow: none !important;
}
.slideshow-container div{
    height: 100% !important;
}
