body{
  margin:0;
  padding:0;
  font-family: 'Poppins';
  overflow-x: hidden;
}

  .counter_title {
    margin-top: 20px;
  }
  .counter_title {
    font-size: 20px;
    margin-bottom: 15px;
  }
  
  .button {
    font-size: 16px;
    padding: 10px 20px;
    margin: 0 5px;
    cursor: pointer;
    background-color: #100e0e; 
    color: #ffffff; 
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .Counter button:hover {
    background-color: #c9302c;
  }
  
  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  @media (max-width: 600px) {
    .Counter button {
      font-size: 14px;
      padding: 8px 16px;
    }
  }